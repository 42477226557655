import * as React from 'react';
import AntModal from 'common/components/antHelpers/AntModal';
import DeleteAccountDialog from 'web/screen/HomePage/UserInfo/DeleteAccountDialog/DeleteAccountDialog';
import { useTranslation } from 'react-i18next';

interface DeleteAccountDialogViewProps {
    dialog: DeleteAccountDialog;
}

const DeleteAccountDialogView: React.FC<DeleteAccountDialogViewProps> = ({
    dialog
}) => {
    const { t } = useTranslation('homePage');
    return (
        <AntModal
            cancel={dialog.cancel}
            okCommand={dialog.confirm}
            title={t('homePage:deleteAccountTitle')}>
            <p>{t('homePage:deleteAccountMsg')}</p>
        </AntModal>
    );
};

export default DeleteAccountDialogView;
