import SchoolInfo from 'web/screen/Membership/SchoolInfo/SchoolInfo';

import * as React from 'react';
import style from '../Membership.module.scss';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { FieldInputProps } from 'common/components/antHelpers/AntField/AntField';
import { Moment } from 'moment';
import AntDatePicker from 'common/components/antHelpers/AntDatePicker';
import AntTextInput from 'common/components/antHelpers/AntTextInput';

interface FormProps {
    model: SchoolInfo;
}

const Form: React.FC<FormProps> = observer(({ model }) => {
    const { t } = useTranslation('membership');
    if (!model.form) {
        return null;
    }
    return (
        <AntForm form={model.form} layout={'vertical'}>
            {(fields) => {
                return (
                    <div className={style.personalData}>
                        {!!fields.schoolName && (
                            <AntField
                                field={fields.schoolName}
                                label={t('schoolName')}>
                                {(props) => <AntTextInput {...props} />}
                            </AntField>
                        )}
                        {!!fields.expectedGraduationDate && (
                            <AntField
                                field={fields.expectedGraduationDate}
                                label={t('graduatedDate')}>
                                {(
                                    props: FieldInputProps<Moment | undefined>
                                ) => <AntDatePicker {...props} />}
                            </AntField>
                        )}
                    </div>
                );
            }}
        </AntForm>
    );
});

export default Form;
