import * as React from 'react';
import style from './HeaderBreadcrumbs.module.scss';
import { observer } from 'mobx-react';

export type HeaderBreadcrumbsData = {
    name: string;
    onClick?: () => void | undefined;
};

interface HeaderBreadcrumbsProps {
    list: HeaderBreadcrumbsData[];
}

const HeaderBreadcrumbs: React.FC<HeaderBreadcrumbsProps> = observer(
    ({ list }) => {
        if (!list.length) {
            return null;
        }

        return (
            <div className={style.list}>
                {list.map((el, index) => {
                    return !!el.onClick ? (
                        <span
                            key={`${el.name}_${index}`}
                            onClick={el.onClick}
                            className={`${style.el} ${style.elLink}`}>
                            {el.name}
                        </span>
                    ) : (
                        <span className={style.el} key={`${el.name}_${index}`}>{el.name}</span>
                    );
                })}
            </div>
        );
    }
);

export default HeaderBreadcrumbs;
