import * as React from 'react';
import { FormField } from 'react-mvvm/forms';
import AntField from 'common/components/antHelpers/AntField';
import { Checkbox } from 'antd';
import style from './ProfessionalForm.module.scss';
import { useTranslation } from 'react-i18next';

interface MembershipApplicationInterestsViewProps {
    field: FormField<string[] | undefined>;
    showLabel?: boolean;
    disabled?: boolean;
}

const options: string[] = [
    'Grafisk design',
    'Illustrasjon',
    'Tegneserie',
    'Animasjon/ Motion Design',
    'Interaktiv / Digital Design',
    'Tjenestedesign'
];

const MembershipApplicationInterestsView: React.FC<MembershipApplicationInterestsViewProps> = ({
    field,
    showLabel = true,
    disabled
}) => {
    const { t } = useTranslation('membership');
    return (
        <AntField field={field} label={showLabel ? t('interestLabel') : ''}>
            {(props) => {
                return (
                    <Checkbox.Group
                        disabled={disabled}
                        value={
                            !!props.value
                                ? props.value.map((v) => v)
                                : undefined
                        }
                        onChange={(e) => {
                            props.onChange(e as any);
                        }}>
                        {options.map((item, index) => {
                            return (
                                <Checkbox
                                    disabled={disabled}
                                    value={item}
                                    key={`intrests-checkbox__${index}`}
                                    className={style.checkbox}>
                                    {item}
                                </Checkbox>
                            );
                        })}
                    </Checkbox.Group>
                );
            }}
        </AntField>
    );
};

export default MembershipApplicationInterestsView;
