import * as React from 'react';
import { Spin } from 'antd';
import style from './LoaderDataContainer.module.scss';
import { observer } from 'mobx-react';
import { ILoader } from 'react-mvvm/loading/loader';
import classNames from 'classnames';

interface LoaderDataContainerProps {
    loader: ILoader;
    customStyle?: string
}

const LoaderDataContainer: React.FC<LoaderDataContainerProps> = observer(
    ({ loader, customStyle, children }) => {
        const containerStyle = classNames(style.container, {[customStyle as string]: !!customStyle})
        return (
            <div className={containerStyle}>
                {loader.isLoading ? (
                    <div className={style.loader}>
                        <Spin size={'large'} className={style.spin} />
                    </div>
                ) : children}
                {loader.error && <div>{loader.error}</div>}
            </div>
        );
    }
);

export default LoaderDataContainer;
