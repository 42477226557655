import { Form, FormField, FormFieldValidator } from 'react-mvvm/forms';

export const formatPrice = (price: number | undefined) => {
    if (!price) return '-';
    const fraction = price - Math.floor(price);
    if (fraction === 0) return `${price},-`;
    else return price.toFixed(2).replace('.', ',');
};

export const buildUrl = (
    validator: FormFieldValidator<any>,
    url: string | undefined = undefined
) => {
    return new Form({
        url: new FormField(url, validator)
    });
};
