import * as React from 'react';
import style from './MembershipApplication.module.scss';
import MembershipApplication from './MembershipApplication';
import { Button, Steps } from 'antd';
import SubscriptionStepView from 'web/screen/MembershipApplication/SubscriptionStep/SubscriptionStepView';
import PaymentStepView from 'web/screen/MembershipApplication/PaymentStep/PaymentStepView';
import PageWrapper from 'web/components/PageWrapper';
import FormStepView from 'web/screen/MembershipApplication/FormStep/FormStepView';
import { observer } from 'mobx-react';
import PageLoader from 'common/components/PageLoader';
import { useTranslation } from 'react-i18next';
import Arrow from 'web/components/Arrow';
import Flex from 'common/components/Flex';
import PaymentSummary from './PaymentSummary';
import MembershipTitle from 'web/components/MembershipTitle/MembershipTitle';

const { Step } = Steps;

interface MembershipApplicationViewProps {
    model: MembershipApplication;
}

const stepsContent = (model: MembershipApplication, t: any) => [
    {
        title: (
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                {t('membership:stepOne')}
                <Arrow />
            </Flex>
        ),
        description: model.subscriptionStep.subscription?.name,
        content: <SubscriptionStepView model={model.subscriptionStep} />
    },
    {
        title: (
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                {t('membership:stepTwo')}
                <Arrow />
            </Flex>
        ),
        content: <FormStepView model={model.formStep} />
    },
    {
        title: t('membership:stepThree'),
        content: <PaymentStepView model={model.paymentStep} />
    }
];

const MembershipApplicationView: React.FC<MembershipApplicationViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['membership', 'common']);
        const stepsData = stepsContent(model, t);
        return (
            <PageLoader loader={model.api}>
                <PageWrapper>
                    <div className={style.container}>
                        <MembershipTitle
                            title={model.parent.isMember ? t('upgradeSubscription') : t('becomeMember')}
                            intro={model.userName}
                        />
                        <Steps
                            type='navigation'
                            current={model.step}
                            className={style.steps}>
                            {stepsData.map((item, index) => (
                                <Step
                                    key={item.title}
                                    title={item.title}
                                    description={
                                        !!item.description
                                            ? item.description
                                            : ''
                                    }
                                />
                            ))}
                        </Steps>
                        <div className={style.content}>
                            <div>{stepsData[model.step].content}</div>
                            <div className={style.footer}>
                                {model.step !== 0 && (
                                    <Button
                                        className={style.backBtn}
                                        type={'text'}
                                        onClick={model.onPrevStep}
                                        disabled={model.isPrevDisable}>
                                        {t('common:prev')}
                                    </Button>
                                )}
                                {stepsData.length - 1 !== model.step &&
                                    !model.api.isLoading &&
                                    !model.subscriptionStep.api.isLoading && (
                                        <Button
                                            className={style.btn}
                                            type={'primary'}
                                            onClick={model.onNextStep}
                                            disabled={model.isNextDisable}>
                                            {t('common:next')}
                                        </Button>
                                    )}
                                {stepsData.length - 1 === model.step && (
                                    <Flex
                                        justifyContent={'space-between'}
                                        alignItems={'center'}>
                                        <PaymentSummary model={model} />
                                        <Button
                                            type={'primary'}
                                            className={style.btn}
                                            onClick={model.onPay}
                                            disabled={model.isPaymentDisable}>
                                            {t('common:pay')}
                                        </Button>
                                    </Flex>
                                )}
                            </div>
                        </div>
                    </div>
                </PageWrapper>
            </PageLoader>
        );
    }
);

export default MembershipApplicationView;
