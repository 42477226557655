import * as React from 'react';
import Flex from 'common/components/Flex';
import LoginPage from 'web/screen/LoginPage/LoginPage';
import { observer } from 'mobx-react';
import { login, store, getRedirectUri } from 'auth/Authentication';
import { externalAuth } from 'model/Api/Account/ExternalAuthRequest';
import { TokenResult } from 'model/Api/Account/Model/TokenResult';
import { HelloJSLoginEventArguement } from 'hellojs';
import { Col, Row, Button, Typography } from 'antd';
import { GooglePlusOutlined } from '@ant-design/icons';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import AntPassword from 'common/components/antHelpers/AntPassword';
import style from './LoginBox.module.scss';
import Link from 'web/components/Link';
import { ReactComponent as FacebookIco } from 'common/icons/facebook.svg';
import { ReactComponent as TwitterIco } from 'common/icons/twitter.svg';
import { useTranslation } from 'react-i18next';
import { CloseCircleFilled } from '@ant-design/icons';

interface LoginBoxProps {
    state: LoginPage;
    onResetPasswordClick: () => void;
    onCreateAccount: () => void;
}

const LoginBox: React.FC<LoginBoxProps> = observer(
    ({ state, onResetPasswordClick, onCreateAccount }) => {
        const { t } = useTranslation('login');
        /*const facebookLogin = () => (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
            login('facebook').then(
                (response: HelloJSLoginEventArguement) => {
                    if (
                        response &&
                        response.authResponse &&
                        response.authResponse.access_token
                    ) {
                        externalAuth({
                            facebook: {
                                accessToken: response.authResponse.access_token
                            }
                        }).then((value: TokenResult) => {
                            store('customJWT', {
                                access_token: value.accessToken.token,
                                expires_in: value.accessToken.expiresIn,
                                refresh_token: value.refreshToken.token
                            });
                            window.location.href = getRedirectUri();
                        });
                    }
                },
                (reason) => {
                    state.errorMessage = 'Facebook authentication was rejected';
                }
            );
        };*/

        return (
            <Row>
                <Col sm={24} md={12}>
                    <div className={style.container}>
                        <Typography.Title level={3} className={style.title}>
                            {t('title')}
                        </Typography.Title>
                        <AntForm
                            form={state.loginForm}
                            id={'LoginFormDialog'}
                            layout={'vertical'}>
                            {(fields) => {
                                return (
                                    <>
                                        <AntField field={fields.userName}>
                                            {(props) => (
                                                <AntTextInput
                                                    placeholder={t(
                                                        'inputPlaceholder'
                                                    )}
                                                    {...props}
                                                />
                                            )}
                                        </AntField>
                                        <AntField field={fields.password}>
                                            {(props) => (
                                                <AntPassword
                                                    onKeyDown={(e) =>
                                                        e.keyCode === 13
                                                            ? state.onSubmit.execute()
                                                            : null
                                                    }
                                                    placeholder={t(
                                                        'passwordPlaceholder'
                                                    )}
                                                    {...props}
                                                />
                                            )}
                                        </AntField>
                                    </>
                                );
                            }}
                        </AntForm>
                        <Flex
                            justifyContent={'center'}
                            flexDirection={'column'}
                            alignItems={'center'}>
                            <div className={style.messageBox}>
                                {state.errorMessage && (
                                    <Typography.Paragraph
                                        className={style.message}>
                                        {state.errorMessage}
                                    </Typography.Paragraph>
                                )}
                            </div>
                            <Button
                                loading={state.isLoading}
                                type={'primary'}
                                htmlType={'submit'}
                                onClick={() => state.onSubmit.execute()}
                                className={style.btn}>
                                {t('loginBtn')}
                            </Button>
                            <Link
                                onClick={onResetPasswordClick}
                                size={'small'}
                                title={t('forgotPassword')}
                                style={style.link}>
                                {t('forgotPassword')}
                            </Link>
                        </Flex>
                        <span className={style.or}>{t('or')}</span>
                    </div>
                </Col>
                <Col sm={24} md={12}>
                    <div className={`${style.container} ${style.bg}`}>
                        <Typography.Title level={3} className={style.title}>
                            {t('whyRegister')}
                        </Typography.Title>
                        <span className={style.reason}>{t('registerReason')}</span>
                        <Button
                            onClick={onCreateAccount}
                            type={'primary'}
                            className={`${style.btn} ${style.createBtn}`}>
                            {t('createUser')}
                        </Button>
                        {/*<Typography.Title level={3} className={style.title}>
                            Logg inn med e-postadresse
                        </Typography.Title>
                        <Flex
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'space-between'}>
                            <Button
                                type={'ghost'}
                                className={`${style.button} ${style.btn} ${style.google}`}>
                                <GooglePlusOutlined width={24} height={22} />{' '}
                                Google
                            </Button>
                            <Button
                                onClick={facebookLogin()}
                                type={'ghost'}
                                className={`${style.button} ${style.btn} ${style.facebook}`}>
                                <FacebookIco className={style.icon} /> Facebook
                            </Button>
                            <Button
                                type={'ghost'}
                                className={`${style.button} ${style.btn} ${style.twitter}`}>
                                <TwitterIco className={style.icon} /> Twitter
                            </Button>
                        </Flex>*/}
                    </div>
                </Col>
            </Row>
        );
    }
);

export default LoginBox;
