import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface DeleteCompetitionEntryRequest {
    competitionEntryRef: number
}
export const deleteCompetitionEntry = (deleteCompetitionEntryRequest: DeleteCompetitionEntryRequest): Promise<any> => {
    return send("DeleteCompetitionEntry", deleteCompetitionEntryRequest)
}
