import * as React from 'react';
import AntForm from 'common/components/antHelpers/AntForm';
import moment, { Moment } from 'moment';
import AntField from 'common/components/antHelpers/AntField';
import { FieldInputProps } from 'common/components/antHelpers/AntField/AntField';
import AntDatePicker from 'common/components/antHelpers/AntDatePicker';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import StudentForm from 'web/screen/MembershipApplication/FormStep/StudentForm/StudentForm';
import FieldPolicy from '../FieldPolicy';
import GenderField from 'common/components/antHelpers/GenderField/GenderField';
import { useTranslation } from 'react-i18next';
import SingleUserAddressesDialogsView from 'common/components/UserAddresses/SingleUserAddressesDialogsView';
import SingleUsersAddressDropdown from 'common/components/SignleUserAddressDropdown/SingleUsersAddressDropdown';
import BirthDatePicker from 'common/components/BirthDatePicker/BirthDatePicker';
import BirthDatePickerView from 'common/components/BirthDatePicker/BirthDatePickerView';
import style from '../FormStep.module.scss';
import { Form } from 'react-mvvm/forms';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';

interface StudentFormViewProps {
    form: Form<any>;
    userAddresses: UserAddresses | undefined;
    isEditView?: boolean;
}

const StudentFormView: React.FC<StudentFormViewProps> = ({
    form,
    userAddresses,
    isEditView = false
}) => {
    const { t } = useTranslation('membership');
    return (
        <AntForm form={form} layout={'vertical'}>
            {(fields) => {
                return (
                    <>
                        <div className={style.row}>
                            <BirthDatePickerView
                                model={fields.birthDate as BirthDatePicker}
                            />
                        </div>
                        {!!fields.gender && (
                            <GenderField field={fields.gender} />
                        )}
                        <AntField
                            field={fields.privatePartyId}
                            label={t('selectPrivateAddress')}>
                            {(props) => {
                                return (
                                    <SingleUsersAddressDropdown
                                        model={userAddresses}
                                        type={'private'}
                                    />
                                );
                            }}
                        </AntField>
                        {!!fields.schoolName && (
                            <AntField
                                field={fields.schoolName}
                                label={t('schoolName')}>
                                {(props) => <AntTextInput {...props} />}
                            </AntField>
                        )}
                        {!!fields.expectedGraduationDate && (
                            <AntField
                                field={fields.expectedGraduationDate}
                                label={t('graduatedDate')}>
                                {(
                                    props: FieldInputProps<Moment | undefined>
                                ) => (
                                    <AntDatePicker
                                        {...props}
                                        disabledDate={(current) =>
                                            current &&
                                            current < moment(new Date())
                                        }
                                    />
                                )}
                            </AntField>
                        )}
                        {!isEditView && (
                            <FieldPolicy field={fields.acceptPrivacyPolicy} />
                        )}
                        <SingleUserAddressesDialogsView model={userAddresses} />
                    </>
                );
            }}
        </AntForm>
    );
};

export default StudentFormView;
