import { route } from 'react-mvvm/routing';
import HomePage from 'web/screen/HomePage/HomePage';
import LoginPage from 'web/screen/LoginPage/LoginPage';
import AcceptInvitationPage from './screen/AcceptInvitation/AcceptInvitationPage';
import CompetitionsListPage from 'web/screen/CompetitionsListPage/CompetitionsListPage';
import Competition from 'web/screen/Competition/Competition';
import SubmissionFormPage from './screen/SubmissionFormPage/SubmissionFormPage';
import SubmissionFormPreviewPage from './screen/SubmissionFormPreview/SubmissionFormPreviewPage';
import JudgingCompetitions from 'web/screen/JudgingCompetitions/JudgingCompetitions';
import JudgingIntroPage from './screen/JudgingIntroPage/JudgingIntroPage';
import JudgingCategoryPage from 'web/screen/JudgingCategoryPage/JudgingCategoryPage';
import JudgingEntriesPage from 'web/screen/JudgingEntriesPage/JudgingEntriesPage';
import JudgingEntryDetails from 'web/screen/JudgingEntryDetails/JudgingEntryDetails';
import AskForPasswordResetPage from './screen/AskForPasswordResetPage/AskForPasswordResetPage';
import ResetPasswordByEmailPage from './screen/ResetPasswordPage/ResetPasswordByEmailPage/ResetPasswordByEmailPage';
import ResetPasswordByPhonePage from './screen/ResetPasswordPage/ResetPasswordByPhonePage/ResetPasswordByPhonePage';
import CreateAccountPage from './screen/CreateAccountPage/CreateAccountPage';
import Membership from 'web/screen/Membership/Membership';
import Invoices from 'web/screen/Invoices/Invoices';
import OnlinePaymentStatusPage from './screen/SubmissionStatusPage/OnlinePaymentStatusPage/OnlinePaymentStatusPage';
import SubmissionPayment from 'web/screen/SubmissionPayment/SubmissionPayment';
import SubmissionsMyPagePayment from 'web/screen/SubmissionsMyPagePayment/SubmissionsMyPagePayment';
import InvoicePaymentStatusPage from './screen/SubmissionStatusPage/InvoicePaymentStatusPage/InvoicePaymentStatusPage';
import MembershipApplication from 'web/screen/MembershipApplication/MembershipApplication';
import ConfirmPhoneNumberPage from './screen/ConfirmPhoneNumberPage/ConfirmPhoneNumberPage';
import Submissions from 'web/screen/Submissions/Submissions';
import MembershipPaymentPage from 'web/screen/MembershipPaymentPage/MembershipPaymentPage';
import { PaymentStatus } from "../model/Externals";
import MembershipReapplyPage from "./screen/MembershipReapplyPage/MembershipReapplyPage";
import Handelkurv from "web/screen/Handelkurv/Handelkurv";

export const homeRoute = route('', () => new HomePage());

//Login/Register
export const loginPageRoute = homeRoute.addRoute<LoginPage>(
    '/login',
    LoginPage,
    (home) => home.show(new LoginPage()),
    (page) => ({})
);

export const askForPasswordReset = loginPageRoute.addRoute<
    AskForPasswordResetPage
>(
    '/resetpassword',
    AskForPasswordResetPage,
    (loginPage) => loginPage.showPasswordResetPage(),
    (page) => ({})
);

export const resetPassword = homeRoute.addRoute<ResetPasswordByEmailPage>(
    '/setnewpassword',
    ResetPasswordByEmailPage,
    (homePage) =>
        homePage.showChildPage(new ResetPasswordByEmailPage(homePage)),
    (page) => ({})
);

export const resetPasswordByPhone = homeRoute.addRoute<
    ResetPasswordByPhonePage
>(
    '/setnewpasswordbyphone',
    ResetPasswordByPhonePage,
    (homePage) =>
        homePage.showChildPage(new ResetPasswordByPhonePage(homePage)),
    (page) => ({})
);

export const createAccountRoute = loginPageRoute.addRoute<CreateAccountPage>(
    '/register',
    CreateAccountPage,
    (loginPage) => loginPage.showChildPage(new CreateAccountPage(loginPage)),
    (page) => ({})
);

//Membership
export const membershipRoute = homeRoute.addRoute<Membership>(
    '/membership',
    Membership,
    (home) => home.showMembershipPage(),
    (page) => ({})
);

export const membershipPaymentPageRoute = membershipRoute.addRoute<
    MembershipPaymentPage
>(
    '/payment',
    MembershipPaymentPage,
    (membership) => membership.showMembershipPaymentPage(),
    (page) => ({})
);

export const membershipReapplyPageRoute = membershipRoute.addRoute<
    MembershipReapplyPage
    >(
    '/reapply',
    MembershipReapplyPage,
    (membership) => membership.showChildPage(new MembershipReapplyPage(membership)),
    (page) => ({})
);

export const membershipApplicationRoute = membershipRoute.addRoute<
    MembershipApplication
>(
    '/application',
    MembershipApplication,
    (membership) => membership.showApplicationPage(),
    (page) => ({})
);

export const invoicesRoute = homeRoute.addRoute<Invoices>(
    '/invoices',
    Invoices,
    (home) => home.showInvoicesPage(),
    (page) => ({})
);

export const confirmInvitation = homeRoute.addRoute<AcceptInvitationPage>(
    '/acceptinvitation',
    AcceptInvitationPage,
    (home) => home.showChildPage(new AcceptInvitationPage()),
    (page) => ({})
);

//Submissions
export const submissionsRoute = homeRoute.addRoute<Submissions>(
    '/submissions',
    Submissions,
    (homePage) => homePage.showSubmissionPage(),
    (page) => ({})
);

export const handelkurvRute = submissionsRoute.addRoute<Handelkurv>(
    '/handelkurv',
    Handelkurv,
    (submissionsRoute) => submissionsRoute.showHandelkurvPage(),
    (page) => ({})
);


export const submissionsMyPagePaymentRoute = handelkurvRute.addRoute<SubmissionsMyPagePayment>(
    '/payment',
    SubmissionsMyPagePayment,
    (handelkurvRute) => handelkurvRute.showPayment(),
    (page) => ({})
)


//Competitions
export const competitionsListPageRoute = homeRoute.addRoute<
    CompetitionsListPage
>(
    '/competitions',
    CompetitionsListPage,
    (homePage) => homePage.showCompetitionsListPage(),
    (page) => ({})
);

export const competitionRoute = competitionsListPageRoute.addRoute<
    Competition,
    { competitionId: string }
>(
    '/:competitionId',
    Competition,
    (competitions, params) =>
        competitions.showCompetitionPage(Number(params.competitionId)),
    (page) => ({ competitionId: page.competitionId.toString() })
);
export const submissionFormRoute = competitionRoute.addRoute<
    SubmissionFormPage,
    { categoryId: string; entryId: string }
>(
    '/categories/:categoryId/:entryId',
    SubmissionFormPage,
    (competitions, params) =>
        competitions.showFormPage(
            Number(params.categoryId),
            Number(params.entryId)
        ),
    (page) => ({
        categoryId: page.categoryId.toString(),
        entryId: page.entryId.toString()
    })
);
export const submissionFormPreview = submissionFormRoute.addRoute<
    SubmissionFormPreviewPage
>(
    '/preview',
    SubmissionFormPreviewPage,
    (submissionFormPage, params) => submissionFormPage.showFormPreviewPage(),
    (page) => ({})
);

export const submissionPaymentRoute = submissionFormPreview.addRoute<
    SubmissionPayment
>(
    '/payment',
    SubmissionPayment,
    (submissionPreview) => submissionPreview.showSubmissionPaymentPage(),
    (page) => ({})
);

//Judging
export const judgingCompetitionsRoute = homeRoute.addRoute<JudgingCompetitions>(
    '/judging-competitions',
    JudgingCompetitions,
    (judgingLanding) => judgingLanding.showChildPage(new JudgingCompetitions()),
    () => ({})
);

export const judgingIntroRoute = judgingCompetitionsRoute.addRoute<
    JudgingIntroPage,
    { competitionId: string }
>(
    '/:competitionId',
    JudgingIntroPage,
    (judgingCompetitions, params) =>
        judgingCompetitions.showJudgingIntroductionPage(
            Number(params.competitionId)
        ),
    (page) => ({ competitionId: page.competitionId.toString() })
);

export const judgingCategoryPageRoute = judgingIntroRoute.addRoute<
    JudgingCategoryPage,
    { competitionId: string }
>(
    '/categories',
    JudgingCategoryPage,
    (judgingIntro, params) => judgingIntro.showJudgingCategoryPage(),
    (page) => ({ competitionId: page.competitionId.toString() })
);

export const judgingEntriesPageRoute = judgingCategoryPageRoute.addRoute<
    JudgingEntriesPage,
    { categoryId: string }
>(
    '/:categoryId',
    JudgingEntriesPage,
    (judgingCategories, params) =>
        judgingCategories.showJudgingEntriesPage(Number(params.categoryId)),
    (page) => ({ categoryId: page.categoryId.toString() })
);

export const judgingEntryDetailsRoute = judgingEntriesPageRoute.addRoute<
    JudgingEntryDetails,
    { entryId: string }
>(
    '/:entryId',
    JudgingEntryDetails,
    (judgingEntries, params) =>
        judgingEntries.showJudgingEntryDetails(Number(params.entryId)),
    (page) => ({ entryId: page.entryId.toString() })
);

export const pendingPaymentCallback = homeRoute.addRoute<OnlinePaymentStatusPage>(
    '/payment/authorized',
    OnlinePaymentStatusPage,
    (home) => home.showChildPage(new OnlinePaymentStatusPage(home, PaymentStatus.pending)),
    (page) => ({})
);

export const paidPaymentCallback = homeRoute.addRoute<OnlinePaymentStatusPage>(
  '/payment/captured',
  OnlinePaymentStatusPage,
  (home) => home.showChildPage(new OnlinePaymentStatusPage(home, PaymentStatus.paid)),
  (page) => ({})
);

export const declinedPaymentCallback = homeRoute.addRoute<
    OnlinePaymentStatusPage
>(
    '/payment/declined',
    OnlinePaymentStatusPage,
    (home) => home.showChildPage(new OnlinePaymentStatusPage(home, PaymentStatus.failed)),
    (page) => ({})
);

export const invoicePaymentStatusCallback = homeRoute.addRoute<
    InvoicePaymentStatusPage
>(
    '/invoicepaymentstatus',
    InvoicePaymentStatusPage,
    (home) => home.showChildPage(new InvoicePaymentStatusPage(home)),
    (page) => ({})
);

export const confirmPhoneNumber = createAccountRoute.addRoute<
    ConfirmPhoneNumberPage
>(
    '/confirmPhoneNumber',
    ConfirmPhoneNumberPage,
    (createAccountPage) =>
        createAccountPage.showChildPage(new ConfirmPhoneNumberPage('')),
    (page) => ({})
);
