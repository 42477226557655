import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {PaymentConfirmationResponse} from "./Model/PaymentConfirmationResponse"

export interface GetPaymentDetailsRequest {
    orderId: string
}
export const getPaymentDetails = (getPaymentDetailsRequest: GetPaymentDetailsRequest): Promise<PaymentConfirmationResponse> => {
    return send("GetPaymentDetails", getPaymentDetailsRequest)
}
