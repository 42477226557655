import CheckboxField from 'common/components/SubmissionsAnswers/CheckboxField';

import * as React from 'react';
import { QuestionType } from 'model/Externals';
import style from './SubmissionsAnswers.module.scss';
import File from './File';
import Text from './Text';
import { QuestionsWithAnswers } from 'web/screen/SubmissionFormPreview/SubmissionFormPreviewPage';
import { Collapse } from 'antd';
import DateQuestion from './DateQuestion';
import { AttachmentResponse } from 'model/Api/Submission/Model/AttachmentResponse';
import { AnswerDto } from 'model/Api/Submission/Model/AnswerDto';
import ContributorsFields from './ContributorsFields';
import SpecialUrls from './SpecialUrls';
import MultiSelectField from './MultiSelectField';
import SingleSelectField from './SingleSelectField';
import classNames from 'classnames';
import VideoLinks from 'common/components/SubmissionsAnswers/VideoLinks';
import CustomEmpty from "web/components/CustomEmpty";

const {Panel} = Collapse;

interface SubmissionsAnswersProps {
  answer: QuestionsWithAnswers | AnswerDto;
  attachments: AttachmentResponse[];
  mode?: 'form' | 'preview';
  onFileDialogOpen?: (
    images: AttachmentResponse[],
    startIndex?: number
  ) => void;
}

const checkIfAnswerIsEmpty = (value: any) => {
  const parsedValue = JSON.parse(value);
  if (Array.isArray(parsedValue)) {
    if (parsedValue.every((v) => !v)) {
      return <CustomEmpty isBottomMargin={true}/>;
    }
  }
}

const renderAnswer = (
  type: QuestionType,
  value: string | undefined,
  attachments: AttachmentResponse[],
  mode: 'form' | 'preview',
  onFileDialogOpen?: (
    images: AttachmentResponse[],
    startIndex?: number
  ) => void,
  questionTitle?: string
) => {
  if (!value || !value.length) {
    if(type === QuestionType.checkbox) {
      return <CheckboxField value={value ?? ''} title={questionTitle ?? ''}/>;
    }
    
    return <CustomEmpty isBottomMargin={true}/>;
  }

  switch (type) {
    case QuestionType.date:
      return <DateQuestion value={value}/>;
    case QuestionType.file:
    case QuestionType.multiFile:
      return checkIfAnswerIsEmpty(value)
        ? <CustomEmpty isBottomMargin={true}/>
        : (
          <File
            value={value}
            attachments={attachments}
            mode={mode}
            onFileDialogOpen={onFileDialogOpen}
          />
        );
    case QuestionType.roleInProject:
      return <ContributorsFields data={value}/>;
    case QuestionType.url:
      return <SpecialUrls data={value}/>;
    case QuestionType.checkbox:
      return <CheckboxField value={value} title={questionTitle ?? ''}/>;
    case QuestionType.singleSelect:
      return <SingleSelectField value={value}/>;
    case QuestionType.multiSelect:
      return <MultiSelectField value={value}/>;
    case QuestionType.videoLink:
      return checkIfAnswerIsEmpty(value)
        ? <CustomEmpty isBottomMargin={true}/>
        : <VideoLinks multipleVideo={value}/>;
    default:
      return <Text value={value}/>;
  }
};

const SubmissionsAnswers: React.FC<SubmissionsAnswersProps> = ({
                                                                 answer,
                                                                 attachments,
                                                                 mode = 'form',
                                                                 onFileDialogOpen
                                                               }) => {
  const boxStyle = classNames(
    {[style.boxForm]: mode === 'form'},
    {[style.boxPreview]: mode === 'preview'}
  );

  const answerStyle = classNames(style.answer, {
    [style.answerPreview]: mode === 'preview'
  });

  if (answer.questionType === QuestionType.checkbox) {
    return (
      <div className={boxStyle}>
        {renderAnswer(
          answer.questionType,
          answer.value,
          attachments,
          mode,
          onFileDialogOpen,
          answer.label
        )}
      </div>
    );
  }

  return (
    <Collapse
      defaultActiveKey={[answer.questionId.toString()]}
      ghost={true}
      expandIconPosition={'right'}>
      <Panel key={answer.questionId.toString()} header={answer.label}>
        <div className={answerStyle}>
          {mode === 'form' && (
            <small className={style.answerHelp}>
              {answer.helpText}
            </small>
          )}
          <div className={boxStyle}>
            {renderAnswer(
              answer.questionType,
              answer.value,
              attachments,
              mode,
              onFileDialogOpen,
              answer.label
            )}
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};

export default SubmissionsAnswers;
