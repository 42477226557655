import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface PasswordResetByEmailRequest {
    email: string
}
export const passwordResetByEmail = (passwordResetByEmailRequest: PasswordResetByEmailRequest): Promise<any> => {
    return send("PasswordResetByEmail", passwordResetByEmailRequest)
}
