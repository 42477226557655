import * as React from 'react';
import ProfessionalInfo from 'web/screen/Membership/ProfessionalInfo/ProfessionalInfo';
import { useTranslation } from 'react-i18next';
import style from 'web/screen/Membership/Membership.module.scss';
import { Button, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PageLoader from 'common/components/PageLoader';
import AntForm from 'common/components/antHelpers/AntForm';
import MembershipApplicationInterestsView from 'web/screen/MembershipApplication/FormStep/ProfessionalForm/MembershipApplicationInterestsView';
import Flex from 'common/components/Flex';
import { observer } from 'mobx-react';
import InfoRow from 'common/components/InfoRow/InfoRow';

interface MyInterestsViewProps {
    model: ProfessionalInfo;
}

const MyInterestsView: React.FC<MyInterestsViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['membership', 'common']);

        return (
            <div className={style.dataContent}>
                <div className={style.title}>
                    <span className={style.titleTxt}>
                        {t('membership:moreAboutMe')}
                    </span>
                    {!model.isMyInterestsEdited && (
                        <Button
                            type={'text'}
                            size={'small'}
                            className={style.titleIcon}
                            onClick={() => model.onMyInterestsState(true)}>
                            <EditOutlined />
                        </Button>
                    )}
                </div>
                <PageLoader loader={{ isLoading: model.isMyInterestsLoaded }}>
                    {model.isMyInterestsEdited && model.myInterestsForm ? (
                        <>
                            <AntForm
                                form={model.myInterestsForm}
                                layout={'vertical'}>
                                {(fields) => (
                                    <div className={style.box}>
                                        <MembershipApplicationInterestsView
                                            field={
                                                fields.interests
                                            }
                                            showLabel={false}
                                        />
                                    </div>
                                )}
                            </AntForm>
                            <Flex justifyContent={'flex-end'}>
                                <Space>
                                    <Button
                                        className={style.btn}
                                        type={'text'}
                                        onClick={() =>
                                            model.onMyInterestsState(false)
                                        }>
                                        {t('common:cancel')}
                                    </Button>
                                    <Button
                                        type={'primary'}
                                        onClick={model.onSave}>
                                        {t('common:save')}
                                    </Button>
                                </Space>
                            </Flex>
                        </>
                    ) : (
                        <InfoRow title={''}>
                            {!!model.member?.interests && model.member.interests.length > 0
                                ? model.member.interests.map(
                                      (item, index) => (
                                          <span
                                              key={`interests__${index}`}>{`${item}, `}</span>
                                      )
                                  )
                                : '-'}
                        </InfoRow>
                    )}
                </PageLoader>
            </div>
        );
    }
);

export default MyInterestsView;
