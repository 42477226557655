import {useTranslation} from "react-i18next";

import * as React from 'react';
import UrlField from './UrlField';
import { Button, Space } from 'antd';
import { Form } from 'react-mvvm/forms';
import AntField from 'common/components/antHelpers/AntField';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import { observer } from 'mobx-react';
import style from './UrlField.module.scss';
import classNames from 'classnames';

interface UrlFieldViewProps {
    model: UrlField;
    label: string;
    helpText: string;
    disabled?: boolean;
    isRequired?: boolean;
}

const regex = new RegExp('https?://');

const UrlRow: React.FC<{
    form: Form<any>;
    index: number;
    onRemove: (i: number) => void;
    disabled:boolean;
}> = observer(({ form, index, onRemove , disabled}) => {
    return (
        <Space align={'start'} className={style.row}>
            <AntField field={form.fields['url']}>
                {(props) => (
                    <AntTextInput
                        {...props}
                        addonBefore={'https://'}
                        value={
                            !props.value ? '' : props.value.replace(regex, '')
                        }
                        disabled={disabled}
                    />
                )}
            </AntField>
            {index > 0 && (
                <Button type={'text'} onClick={() => onRemove(index)} disabled={disabled}>
                    <DeleteOutlined />
                </Button>
            )}
        </Space>
    );
});

const UrlFieldView: React.FC<UrlFieldViewProps> = observer(
    ({ model, label, helpText, disabled= false, isRequired = false}) => {
        const { t } = useTranslation('common');
        const title = classNames(style.title, {
            [style.required]:
                isRequired
        });
        return (
            <div>
                <span className={title}>{label}</span>
                <small className={style.small}>{helpText}</small>
                {model.urls &&
                    model.urls.map((urlForm, index) => (
                        <UrlRow
                            key={`url_row_${index}`}
                            form={urlForm}
                            index={index}
                            onRemove={model.removeUrl}
                            disabled={disabled}
                        />
                    ))}
                <Button
                    disabled={disabled}
                    size={"small"}
                    type={'text'}
                    className={`ant-btn-add ${style.addButton}`}
                    onClick={() => model.addUrl.execute()}>
                    <PlusOutlined />{t('addLink')}
                </Button>
            </div>
        );
    }
);

export default UrlFieldView;
