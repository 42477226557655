import * as React from "react";
import OnBackToSubmissionModal from "web/screen/SubmissionFormPage/OnBackToSubmissionModal/OnBackToSubmissionModal";
import {useTranslation} from "react-i18next";
import AntModal from "common/components/antHelpers/AntModal";
import {Button} from "antd";

interface OnBackToSubmissionModalViewProps {
    dialog: OnBackToSubmissionModal;
}

const OnBackToSubmissionModalView: React.FC<OnBackToSubmissionModalViewProps> = ({dialog}) => {
    const { confirm, cancel, onCancelClick } = dialog;
    const { t } = useTranslation('competitions');

    return <AntModal cancel={cancel} okCommand={confirm} title={t('goToSubmissionModalTitle')}
                     footer={[<Button onClick={() => onCancelClick.execute()}>{t('cancel')}</Button>, <Button key="submit" type="primary" onClick={() =>confirm.execute()}>{t('goAndSave')}</Button>]}>
        {t('goToSubmissionModalText')}
    </AntModal>;
};

export default OnBackToSubmissionModalView;
