import { Page } from 'react-mvvm';
import { action, computed, observable } from 'mobx';
import JudgingEntriesPage from 'web/screen/JudgingEntriesPage/JudgingEntriesPage';
import { CompetitionEntryEvaluationResponse } from 'model/Api/Evaluation/Model/CompetitionEntryEvaluationResponse';
import { CompetitionEntryStatus, CompetitionStatus, PhaseOneEvaluation } from 'model/Externals';
import loader from 'react-mvvm/loading/loader';
import { getCompetitionEntryEvaluationDetails } from 'model/Api/Evaluation/GetCompetitionEntryEvaluationDetailsRequest';
import { setPhaseOneEvaluation } from 'model/Api/Evaluation/SetPhaseOneEvaluationRequest';
import { setPhaseTwoEvaluation } from 'model/Api/Evaluation/SetPhaseTwoEvaluationRequest';
import { setPrivateNote } from 'model/Api/Evaluation/SetPrivateNoteRequest';
import { setPublicNote } from 'model/Api/Evaluation/SetPublicNoteRequest';
import ImageDialog from 'web/components/ImageDialog/ImageDialog';
import { AttachmentResponse } from 'model/Api/Submission/Model/AttachmentResponse';
import { CompetitionEntryListItemResponse } from "model/Api/Evaluation/Model/CompetitionEntryListItemDto";

class JudgingEntryDetails extends Page<ImageDialog> {
  @observable entryId: number;
  @observable parent: JudgingEntriesPage;
  @observable entry: CompetitionEntryEvaluationResponse = {
    competitionEntryStatus: CompetitionEntryStatus.created,
    answers: [],
    attachments: [],
    publicNote: '',
    privateNote: '',
    title: '',
    hasMovedOn: false,
    coverImage: {
      uid: '0',
      type: '',
      size: 0,
      name: '',
      url: '',
      mdThumbUrl: '',
      thumbUrl: '',
      width: 0,
      height: 0
    }
  };
  @observable isSavedPublicNotes = false;
  @observable isSavePrivateNotes = false;

  constructor(entryId: number, parent: JudgingEntriesPage) {
    super();

    this.entryId = entryId;
    this.parent = parent;
  }

  @computed get pageName() {
    return this.entry.title;
  }

  @computed get competitionStatus() {
    return this.parent.competitionStatus;
  }

  @computed get evaluationResults() {
    return this.parent.evaluationResults;
  }

  @computed get evaluationStatus() {
    return this.parent.categoryEvaluationStatus;
  }

  @computed get isEditable(): boolean {
    return (
      this.competitionStatus ===
      CompetitionStatus.phaseOneJudgingInProgress ||
      this.competitionStatus ===
      CompetitionStatus.phaseTwoJudgingInProgress
    );
  }

  @computed get coverImgIdx(): number {
    let index = 0;
    if (!this.entry.attachments.length && !!this.entry.coverImage) {
      this.entry.attachments.find((a, idx) => {
        if (a.uid === this.entry.coverImage.uid) {
          index = idx;
        }
      });
    }

    return index;
  }

  @computed get sortedAttachments(): AttachmentResponse[] {
    if (!this.entry.attachments.length) {
      return [];
    }
    const {attachments, coverImage, answers} = this.entry;

    const sortedAtts: AttachmentResponse[] = [];

    if (!!coverImage && coverImage.uid !== '0') {
      sortedAtts.push(coverImage);
    }

    answers.forEach(answer => {
      if (answer.questionType !== 'file' && answer.questionType !== 'multiFile' && answer.questionType !== 'videoLink') {
        return;
      }

      if (answer.questionType === 'videoLink') {
        const videoAttachment: AttachmentResponse = {
          uid: answer.id.toString(),
          name: '',
          size: 0,
          type: 'videoLink/mp4',
          url: answer.value,
          thumbUrl: '',
          mdThumbUrl: '',
          width: 0,
          height: 0,
        };
        sortedAtts.push(videoAttachment);
        return;
      }

      const fileIds: string[] = answer.value === "" ? [] : JSON.parse(answer.value);
      const files = fileIds.reduce<AttachmentResponse[]>((prev, current) => {
        const f: AttachmentResponse | undefined = attachments.find(at => at.uid === current);
        if (!!f) {
          prev.push(f);
        }
        return prev
      }, []);

      if (!files.length) {
        return;
      }

      sortedAtts.push(...files);
    });

    return sortedAtts;
  }

  @computed get sortedEntry(): CompetitionEntryListItemResponse[] {
    const {entryList, getGroupPhaseEntryList} = this.parent;
    if (this.competitionStatus === CompetitionStatus.phaseTwoJudgingInProgress || this.competitionStatus === CompetitionStatus.phaseTwoJudgingClosed) {
      const isPromoted = getGroupPhaseEntryList.promoted.find(e => e.id === this.entryId);
      const isNotPromoted = getGroupPhaseEntryList.notPromoted && getGroupPhaseEntryList.notPromoted.find(e => e.id === this.entryId);

      return isPromoted ? getGroupPhaseEntryList.promoted : isNotPromoted && getGroupPhaseEntryList.notPromoted ? getGroupPhaseEntryList.notPromoted : [];
    }

    return entryList;
  }

  api = loader({
    getEvaluationEntryDetails: async (competitionEntryRef: number) =>
      await getCompetitionEntryEvaluationDetails({competitionEntryRef})
  });

  apiEvaluation = loader(
    {
      setPhaseOneEvaluation: async (evaluation: PhaseOneEvaluation) => {
        await setPhaseOneEvaluation({
          competitionEntryRef: this.entryId,
          evaluation
        });
        this.entry.phaseOneEvaluation = evaluation;
      },
      setPhaseTwoEvaluation: async (evaluationRef: number) => {
        this.entry.phaseTwoEvaluation = await setPhaseTwoEvaluation({
          competitionEntryRef: this.entryId,
          evaluationRef
        });
      }
    },
    {
      setPhaseOneEvaluation:
        'Something went wrong, we can not set your evaluation',
      setPhaseTwoEvaluation:
        'Something went wrong, we can not set your evaluation'
    }
  );

  apiNotes = loader({
    savePrivateNotes: async (note: string) =>
      await setPrivateNote({
        competitionEntryRef: this.entryId,
        privateNote: note
      }),
    savePublicNotes: async (note: string) =>
      await setPublicNote({
        competitionEntryRef: this.entryId,
        publicNote: note
      })
  });


  @action
  onSubmissionChange = (moveBy: -1 | 1) => {
    const {showJudgingEntryDetails} = this.parent;

    if ((moveBy === -1 && this.entryId === this.sortedEntry[0]?.id) || (moveBy === 1 && this.entryId === this.sortedEntry[this.sortedEntry.length - 1]?.id)) return;
    const currentEntryIdx = this.sortedEntry.findIndex((e) => e.id === this.entryId);
    showJudgingEntryDetails(this.sortedEntry[currentEntryIdx + moveBy].id);
  };

  @action
  onPublicNotesSave = async (note: string) => {
    await this.apiNotes.savePublicNotes(note);
    this.isSavedPublicNotes = true;
    setTimeout(() => {
      this.isSavedPublicNotes = false;
    }, 3000);
  };

  @action
  onPrivateNotesSave = async (note: string) => {
    await this.apiNotes.savePrivateNotes(note);
    this.isSavePrivateNotes = true;
    setTimeout(() => {
      this.isSavePrivateNotes = false;
    }, 3000);
  };

  showImageDialog = async (
    images: AttachmentResponse[],
    startIndex?: number
  ) => {
    await this.showModal<ImageDialog, boolean>(
      (close) => new ImageDialog(close, images, startIndex)
    );
  };

  protected async onActivated(): Promise<any> {
    this.entry = await this.api.getEvaluationEntryDetails(this.entryId);
  }
}

export default JudgingEntryDetails;
