import * as React from 'react';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import ConfirmationModalView from 'common/components/ConfirmationModal/ConfirmationModalView';
import Party from 'common/components/UserAddresses/Party';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import AddressDialogView from '../AddressDialog/AddressDialogView';
import AddressDialog from '../AddressDialog/AddressDialog';

interface SingleUserAddressesDialogsViewProps {
    model: UserAddresses | undefined;
}

const SingleUserAddressesDialogsView: React.FC<SingleUserAddressesDialogsViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['userAddresses']);
        if (!model) {
            return null;
        }
        return (
            <>
                {model.page.dialog instanceof AddressDialog && (
                    <AddressDialogView dialog={model.page.dialog} />
                )}
                {model.page.dialog instanceof ConfirmationModal && (
                    <ConfirmationModalView
                        dialog={model.page.dialog}
                        title={t('dialogDelete')}>
                        <span>{t('deleteConfirmation')}</span>
                        {!!model.deleteParty && (
                            <Party party={model.deleteParty} />
                        )}
                    </ConfirmationModalView>
                )}
            </>
        );
    }
);

export default SingleUserAddressesDialogsView;
