import * as React from 'react';
import style from './MembershipTitle.module.scss';
import { Typography } from 'antd';

interface MembershipTitleProps {
    title: string;
    intro?: string;
    description?:string;
}

const MembershipTitle: React.FC<MembershipTitleProps> = ({ title, intro, description }) => {
    return (
        <div>
            {!!intro && (
                <Typography.Text className={style.appUserName}>
                    {intro}
                </Typography.Text>
            )}
            <Typography.Title level={2} className={style.appTitle}>
                {title}
            </Typography.Title>
            {!!description && (
                <Typography.Text className={style.dsc}>
                    {description}
                </Typography.Text>
            )}
        </div>
    );
};

export default MembershipTitle;
