import BaseDialog from 'admin/helpers/BaseDialog';
import { asyncCommand } from 'react-mvvm/commands';

class UpdateCartInfoDialog extends BaseDialog<boolean> {
    constructor(
        private onConfirm: () => void,
        close: (v: boolean | undefined) => void
    ) {
        super(close);
    }

    confirm = asyncCommand(async () => {
        await this.onConfirm();
        this.close(true);
    });

    cancel = () => this.close(false);

    activate(): Promise<void> {
        return Promise.resolve();
    }
}

export default UpdateCartInfoDialog;
