import * as React from 'react';
import HomePage from 'web/screen/HomePage/HomePage';
import { observer } from 'mobx-react';
import { ContentView } from 'react-mvvm';
import PageWrapper from 'web/components/PageWrapper/PageWrapper';
import CompetitionHeader from 'web/components/CompetitionHeader';
import style from './HomePage.module.scss';
import MemberMenu from 'web/screen/HomePage/MemberMenu';
import AskForPasswordResetPage from 'web/screen/AskForPasswordResetPage/AskForPasswordResetPage';
import CreateAccountPage from 'web/screen/CreateAccountPage/CreateAccountPage';
import LoginPage from 'web/screen/LoginPage/LoginPage';
import UserInfoView from 'web/screen/HomePage/UserInfo/UserInfoView';
import OnlinePaymentStatusPage from '../SubmissionStatusPage/OnlinePaymentStatusPage/OnlinePaymentStatusPage';
import HeaderBreadcrumbs from 'web/components/HeaderBreadcrumbs';
import Invoices from 'web/screen/Invoices/Invoices';
import AcceptInvitationPage from 'web/screen/AcceptInvitation/AcceptInvitationPage';
import ResetPasswordByEmailPage from '../ResetPasswordPage/ResetPasswordByEmailPage/ResetPasswordByEmailPage';
import ResetPasswordByPhonePage from '../ResetPasswordPage/ResetPasswordByPhonePage/ResetPasswordByPhonePage';
import Submissions from 'web/screen/Submissions/Submissions';

interface HomePageViewProps {
    model: HomePage;
}

const HomePageView: React.FC<HomePageViewProps> = observer(({ model }) => {
    const gotToLoginPage = (e: any) => {
        e.preventDefault();
        model.navigation.items.loginPage.activate();
    };

    return (
        <div className={style.page}>
            {model.childPage instanceof AskForPasswordResetPage ||
            model.childPage instanceof ResetPasswordByEmailPage ||
            model.childPage instanceof ResetPasswordByPhonePage ||
            model.childPage instanceof CreateAccountPage ||
            model.childPage instanceof OnlinePaymentStatusPage ||
            model.childPage instanceof AcceptInvitationPage ||
            model.childPage instanceof LoginPage ? null : (
                <CompetitionHeader
                    logoClick={() => model.navigation.items.home.activate()}
                    helpClick={() => {}}
                    signoutClick={model.signOutClicked}
                    breadcrumbs={
                        !!model.breadcrumbs ? (
                            <HeaderBreadcrumbs list={model.breadcrumbs} />
                        ) : null
                    }
                />
            )}
            {!model.childPage ||
            model.childPage instanceof Invoices ||
            model.childPage instanceof Submissions ? (
                <MemberMenu model={model} />
            ) : null}
            <ContentView content={model.childPage}>
                <PageWrapper>
                    <div className={style.container}>
                        <UserInfoView model={model.userInfo} />
                    </div>
                </PageWrapper>
            </ContentView>
        </div>
    );
});

export default HomePageView;
