import BaseDialog from 'admin/helpers/BaseDialog';
import { asyncCommand } from 'react-mvvm/commands';
import { PartySnapshotDto } from 'model/Api/Parties/Model/PartySnapshotDto';
import { Page } from 'react-mvvm';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';
import { action, observable } from 'mobx';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import PrivateParty from "../PrivateParty/PrivateParty";
import CompanyParty from "../CompanyParty/CompanyParty";

class ChangeBillingAddressDialog extends BaseDialog<PartyDto[]> {
    page: Page;
    @observable userAddresses: UserAddresses;
    @observable isNewAddressVisible: boolean = false;

    privateParty: PrivateParty;
    companyParty?: CompanyParty;

    constructor(
        private onConfirm: (ref: number) => Promise<any>,
        close: (v: PartyDto[] | undefined) => void,
        page: Page,
        private partySnapshot: PartySnapshotDto | undefined,
        private userId?: number
    ) {
        super(close);
        this.page = page;
        this.userAddresses = new UserAddresses(page, true, true);

        this.privateParty = new PrivateParty(this.onSaveNewAddress, undefined, userId);
        this.companyParty = new CompanyParty(this.onSaveNewAddress, undefined, userId);
    }

    confirm = asyncCommand(async () => {
        if (!!this.userAddresses.selectedParty) {
            const party: PartyDto[] = await this.onConfirm(
                this.userAddresses.selectedParty
            );
            this.close(party);
        }
    });

    cancel = () => this.close(undefined);

    @action
    setNewAddressVisible = (val: boolean) => {
        this.isNewAddressVisible = val;
    };

    async activate(): Promise<void> {
        await this.userAddresses.init(this.userId);
        if (
            !this.userAddresses.parties.find(
                (party) =>
                    !!this.partySnapshot && party.id === this.partySnapshot.id
            )
        ) {
            this.userAddresses.parties.push(this.partySnapshot as PartyDto);
        }
        if (!!this.partySnapshot)
            this.userAddresses.selectedParty = this.partySnapshot.id;
        return Promise.resolve();
    }

    onSaveNewAddress = async (savedParty?: PartyDto) => {
        if (!!savedParty) {
            await this.userAddresses.parties.push(savedParty);
            this.userAddresses.selectedParty = savedParty.id;
            this.setNewAddressVisible(false);
        }
    };
}

export default ChangeBillingAddressDialog;
