import * as React from 'react';
import style from '../Membership.module.scss';
import PersonalData from './PersonalData';
import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import Form from './Form';
import Info from './Info';
import Flex from 'common/components/Flex';
import PageLoader from 'common/components/PageLoader';
import SingleUserAddressesDialogsView from 'common/components/UserAddresses/SingleUserAddressesDialogsView';

interface PersonalDataViewProps {
    model: PersonalData;
}

const PersonalDataView: React.FC<PersonalDataViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['membership', 'common']);

        return (
            <div className={style.dataContent}>
                <div className={style.title}>
                    <span className={style.titleTxt}>
                        {t('membership:personalData')}
                    </span>
                    {!model.isEdit && (
                        <Button
                            type={'text'}
                            size={'small'}
                            className={style.titleIcon}
                            onClick={model.onEdit}>
                            <EditOutlined />
                        </Button>
                    )}
                </div>
                <PageLoader loader={model.parent.apiSaveMembership}>
                    {model.isEdit ? (
                        <div>
                            <Form model={model} />
                            <Flex justifyContent={'flex-end'}>
                                <Space>
                                    <Button
                                        className={style.btn}
                                        type={'text'}
                                        onClick={model.onCancel}>
                                        {t('common:cancel')}
                                    </Button>
                                    <Button
                                        type={'primary'}
                                        onClick={model.onSavePersonalData}>
                                        {t('common:save')}
                                    </Button>
                                </Space>
                            </Flex>
                        </div>
                    ) : (
                        <Info model={model} />
                    )}
                </PageLoader>
                <SingleUserAddressesDialogsView model={model.userAddresses} />
            </div>
        );
    }
);

export default PersonalDataView;
