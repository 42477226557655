import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


import {PaymentListItemDto} from "./Model/PaymentListItemDto"

export interface GetPaymentsRequest {
    userId?: number
    type?: FilterPaymentByType
}
export enum FilterPaymentByType {
    membership = "membership",
    competitionEntries = "competitionEntries",
}
export const getPayments = (getPaymentsRequest: GetPaymentsRequest): Promise<PaymentListItemDto[]> => {
    return send("GetPayments", getPaymentsRequest)
}
