import * as React from 'react';
import SubmissionPayment from './SubmissionPayment';
import { observer } from 'mobx-react';
import style from './SubmissionPayment.module.scss';
import PaymentMethodSelectorView from 'web/components/PaymentMethodSelector/PaymentMethodSelectorView';
import { useTranslation } from 'react-i18next';
import PageTitle from 'web/components/PageTitle';
import SubmissionsNextBtn from 'web/components/SubmissionsNextBtn';
import PageLoader from 'common/components/PageLoader';
import AllUsersAddressDropdown from 'common/components/UserAddresses/AllUsersAddressDropdown';
import PaymentTermsAndConditions from 'web/components/PaymentTermsAndConditions';

interface SubmissionPaymentViewProps {
    model: SubmissionPayment;
}

const SubmissionPaymentView: React.FC<SubmissionPaymentViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['competitions', 'common']);
        return (
            <div className={style.container}>
                <PageTitle
                    title={t('competitions:totalPaymentTitle', {
                        price: model.totalPrice
                    })}
                    headerNumber={2}
                />
                <PageLoader loader={model.api}>
                    <div className={style.content}>
                        <div className={style.row}>
                            <span className={style.rowTitle}>
                                {t('competitions:selectAddress')}
                            </span>
                            <AllUsersAddressDropdown
                                model={model.userAddresses}
                            />
                        </div>
                        <div className={style.row}>
                            <span className={style.rowTitle}>
                                {t('competitions:paymentMethod')}
                            </span>
                            {!!model.product && !!model.invoiceFeeProduct && (
                                <PaymentMethodSelectorView
                                    model={model.paymentMethod}
                                    price={model.product.priceIncVat}
                                    invoicePrice={
                                        model.invoiceFeeProduct.priceIncVat
                                    }
                                />
                            )}
                        </div>
                        <PaymentTermsAndConditions
                            onSelect={model.onAcceptTerms}
                            selected={model.isPaymentTermsAccepted}
                        />
                        <SubmissionsNextBtn
                            onNextClick={model.onPay}
                            competitionStatus={model.competitionStatus}
                            label={t('common:pay')}
                            isDisable={
                                !model.paymentMethod.selected ||
                                !model.userAddresses.selectedParty ||
                                !model.isPaymentTermsAccepted
                            }
                        />
                    </div>
                </PageLoader>
            </div>
        );
    }
);

export default SubmissionPaymentView;
