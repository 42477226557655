import * as React from 'react';
import AssociateForm from './AssociateForm';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import FieldPolicy from 'web/screen/MembershipApplication/FormStep/FieldPolicy';
import GenderField from 'common/components/antHelpers/GenderField/GenderField';
import { useTranslation } from 'react-i18next';
import SingleUserAddressesDialogsView from 'common/components/UserAddresses/SingleUserAddressesDialogsView';
import SingleUsersAddressDropdown from 'common/components/SignleUserAddressDropdown/SingleUsersAddressDropdown';
import BirthDatePicker from 'common/components/BirthDatePicker/BirthDatePicker';
import BirthDatePickerView from 'common/components/BirthDatePicker/BirthDatePickerView';
import style from '../FormStep.module.scss';
import { Form } from 'react-mvvm/forms';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';

interface AssociateFormViewProps {
    form: Form<any>;
    userAddresses: UserAddresses | undefined;
    isEditView?: boolean;
}

const AssociateFormView: React.FC<AssociateFormViewProps> = ({
    form,
    userAddresses,
    isEditView = false
}) => {
    const { t } = useTranslation('membership');
    return (
        <AntForm form={form} layout={'vertical'}>
            {(fields) => {
                return (
                    <>
                        <div className={style.row}>
                            <BirthDatePickerView
                                model={fields.birthDate as BirthDatePicker}
                            />
                        </div>
                        {!!fields.gender && (
                            <GenderField field={fields.gender} />
                        )}
                        <AntField
                            field={fields.privatePartyId}
                            label={t('selectPrivateAddress')}>
                            {(props) => {
                                return (
                                    <SingleUsersAddressDropdown
                                        model={userAddresses}
                                        type={'private'}
                                    />
                                );
                            }}
                        </AntField>
                        <AntField
                            field={fields.employerPartyId}
                            label={t('selectCompanyAddress')}>
                            {(props) => {
                                return (
                                    <SingleUsersAddressDropdown
                                        model={userAddresses}
                                        type={'company'}
                                    />
                                );
                            }}
                        </AntField>
                        {!!fields.workPosition && (
                            <AntField
                                field={fields.workPosition}
                                label={t('workPosition')}>
                                {(props) => <AntTextInput {...props} />}
                            </AntField>
                        )}
                        {!isEditView && (
                            <FieldPolicy
                                field={fields.acceptPrivacyPolicy}
                                offset={6}
                            />
                        )}
                        <SingleUserAddressesDialogsView model={userAddresses} />
                    </>
                );
            }}
        </AntForm>
    );
};

export default AssociateFormView;
