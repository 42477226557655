import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {CompetitionEntryEvaluationResponse} from "./Model/CompetitionEntryEvaluationResponse"

export interface GetCompetitionEntryEvaluationDetailsRequest {
    competitionEntryRef: number
}
export const getCompetitionEntryEvaluationDetails = (getCompetitionEntryEvaluationDetailsRequest: GetCompetitionEntryEvaluationDetailsRequest): Promise<CompetitionEntryEvaluationResponse> => {
    return send("GetCompetitionEntryEvaluationDetails", getCompetitionEntryEvaluationDetailsRequest)
}
