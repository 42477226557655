import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import style from './AntCheckbox.module.scss';

type Props = {
    content: string | undefined;
    value: boolean | undefined;
    onChange: (value: boolean) => void;
    isRequired?: boolean;
    description?: string;
};

const AntCheckbox = ({
    content,
    value,
    onChange,
    isRequired,
    description,
    ...props
}: Props & Omit<CheckboxProps, 'checked' | 'onChange'>) =>  {
    return (
      <>
          <Checkbox
            className={isRequired ? style.required : ''}
            {...props}
            checked={typeof value !== "boolean" ? value as string | undefined === 'true' : value}
            onChange={(e) => onChange && onChange(e.target.checked)}>
              {content}
          </Checkbox>
          {description && (
            <span className={style.small}>{description}</span>
          )}
      </>
    );
}

export default AntCheckbox;
