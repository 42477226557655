import {
    Form,
    FormField,
    FormFieldValidator,
    FormState,
    NullValidator,
    RequiredPostalCodeValidator,
} from 'react-mvvm/forms';
import { AddressDto } from 'model/Api/Parties/Model/AddressDto';
import { computed, observable } from 'mobx';

class AddressField extends FormField<Partial<AddressDto> | undefined> {
    @observable address: Form<any>;
    private isErrorState = false;
    private isValidatingProcess = false;
    readonly fieldValidator: FormFieldValidator<string>;

    constructor(
        private dto: AddressDto | undefined,
        validator: FormFieldValidator<string>
    ) {
        super(dto, validator);
        this.fieldValidator = validator;

        this.address = new Form({
            line: new FormField<AddressDto['line']>(dto?.line, NullValidator),
            postalCode: new FormField<AddressDto['postalCode']>(
                dto?.postalCode,
                RequiredPostalCodeValidator
            ),
            city: new FormField<AddressDto['city']>(
                dto?.city,
                this.fieldValidator
            ),
            country: new FormField<AddressDto['country']>(
                dto?.country,
                NullValidator
            )
        });

        if (!!dto) this.value = dto;
    }

    @computed get value(): Partial<AddressDto> | undefined {
        if (!this.address) {
            return undefined;
        }
        const { fields } = this.address;

        return {
            line: fields.line.value,
            postalCode: fields.postalCode.value,
            city: fields.city.value,
            country: fields.country.value
        };
    }

    set value(data: Partial<AddressDto> | undefined) {
        if (!!data && !!this.address) {
            this.address.fields.line.value = data.line;
            this.address.fields.postalCode.value = data.postalCode;
            this.address.fields.city.value = data.city;
            this.address.fields.country.value = data.country;
        }
    }

    @computed get isPristine() {
        return this.address.isPristine;
    }

    @computed get state(): FormState {
        if (this.isErrorState) {
            return FormState.Invalid;
        }

        if (this.isValidatingProcess) {
            return FormState.Pending;
        }

        return FormState.Valid;
    }

    async validate(): Promise<true | readonly string[]> {
        let isValid = true;
        this.isErrorState = false;
        this.isValidatingProcess = true;

        for (const field in this.address.fields) {
            const validation = await this.address.fields[
                field as string
            ].validate();
            if (!validation || Array.isArray(validation)) {
                isValid = false;
                this.isErrorState = true;
            }
        }

        this.isValidatingProcess = false;
        return isValid || [];
    }
}

export default AddressField;
