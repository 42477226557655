import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {ListOptions} from "../GenericList/ListOptions"

import {CompetitionEntryListItemDto} from "./Model/CompetitionEntryListItemDto"

export interface GetUserCompetitionEntriesRequest {
    itemId?: number
    options?: ListOptions
    filter?: any
}
export const getUserCompetitionEntries = (getUserCompetitionEntriesRequest: GetUserCompetitionEntriesRequest): Promise<CompetitionEntryListItemDto[]> => {
    return send("GetUserCompetitionEntries", getUserCompetitionEntriesRequest)
}
