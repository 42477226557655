import * as React from 'react';
import {Space, Typography} from "antd";
import AntCheckbox from "common/components/antHelpers/AntCheckbox";

interface CheckboxFieldProps {
    value: string;
    title: string;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({value, title}) => {
    return <Space>
        <AntCheckbox
            value={value === 'true'}
            content={''}
            onChange={() => {}}
            disabled={true}
        />
        <Typography.Text>{title}</Typography.Text>
    </Space>
};

export default CheckboxField;
