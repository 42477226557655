import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {UserInfoDto} from "./Model/UserInfoDto"

export interface UpdateUserInfoRequest {
    ticketRef: number
    pin: string
}
export const updateUserInfo = (updateUserInfoRequest: UpdateUserInfoRequest): Promise<UserInfoDto> => {
    return send("UpdateUserInfo", updateUserInfoRequest)
}
