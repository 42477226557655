import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {SubscriptionType} from "../../Externals"

import {OnlinePaymentTokenDto} from "../Payments/Model/OnlinePaymentTokenDto"

export interface ReapplyForMembershipAndPayOnlineRequest {
    subscriptionType: SubscriptionType
    partyId: number
}
export const reapplyForMembershipAndPayOnline = (reapplyForMembershipAndPayOnlineRequest: ReapplyForMembershipAndPayOnlineRequest): Promise<OnlinePaymentTokenDto> => {
    return send("ReapplyForMembershipAndPayOnline", reapplyForMembershipAndPayOnlineRequest)
}
