import { EvaluationResultDto } from 'model/Api/Competitions/Model/EvaluationResultDto';
import { ReactComponent as PublicNote } from 'common/icons/comment_icn.svg';
import { ReactComponent as PrivateNote } from 'common/icons/note_icn.svg';
import * as React from 'react';
import { Select } from 'antd';
import style from './ActionPhaseSecond.module.scss';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import {useTranslation} from "react-i18next";

interface ActionsPhaseSecondProps {
    evaluationValue: EvaluationResultDto | undefined;
    evaluationResults: EvaluationResultDto[];
    onEvaluationPhaseTwo: (evaluationRef: number) => void;
    isEditable: boolean;

    isList?: boolean;

    isPrivateNote?: boolean;
    privateNoteActive?: boolean;
    onSetPrivateNoteState?: () => void;
    isPublicNote?: boolean;
    publicNoteActive?: boolean;
    onSetPublicNoteState?: () => void;
}

const ActionsPhaseSecond: React.FC<ActionsPhaseSecondProps> = observer(
    ({
        evaluationValue,
        evaluationResults,
        onEvaluationPhaseTwo,
        isEditable,
        isList,
        isPrivateNote,
        privateNoteActive,
        onSetPrivateNoteState,
        isPublicNote,
        publicNoteActive,
        onSetPublicNoteState
    }) => {
        const { t } = useTranslation('judging');
        const containerStyle = classNames(style.container, {
            [style.list]: isList
        });
        const privateIconStyle = classNames(style.icon, {
            [style.iconActive]: privateNoteActive
        });
        const publicNoteStyle = classNames(style.icon, {
            [style.iconActive]: publicNoteActive
        });

        return (
            <div className={containerStyle}>
                <Select
                    allowClear={true}
                    disabled={!isEditable}
                    className={style.select}
                    placeholder={t('yourNote')}
                    value={!evaluationValue ? undefined : evaluationValue.id}
                    onChange={(value: number) => onEvaluationPhaseTwo(value)}>
                    {evaluationResults.map((el) => {
                        return (
                            <Select.Option
                                value={el.id}
                                key={`${el.value}_${el.id}`}>
                                {el.value}
                            </Select.Option>
                        );
                    })}
                </Select>
                {isPrivateNote && onSetPrivateNoteState && (
                    <PrivateNote
                        className={privateIconStyle}
                        onClick={onSetPrivateNoteState}
                    />
                )}
                {isPublicNote && onSetPublicNoteState && (
                    <PublicNote
                        className={publicNoteStyle}
                        onClick={onSetPublicNoteState}
                    />
                )}
            </div>
        );
    }
);

export default ActionsPhaseSecond;
