import { Page } from 'react-mvvm';
import { computed, observable, runInAction } from 'mobx';
import JudgingIntroPage from 'web/screen/JudgingIntroPage/JudgingIntroPage';
import { CategoryEvaluationStatus, CompetitionStatus } from 'model/Externals';
import loader from 'react-mvvm/loading/loader';
import { ListOptions } from 'model/Api/GenericList/ListOptions';
import { getEvaluationCompetitionDetails } from 'model/Api/Evaluation/GetEvaluationCompetitionDetailsRequest';
import { EvaluationCategoryListItemDto } from 'model/Api/Evaluation/Model/EvaluationCategoryListItemDto';
import { setCategoryEvaluationStatus } from 'model/Api/Evaluation/SetCategoryEvaluationStatusRequest';
import { SortDirection } from 'model/Api/GenericList/SortDirection';
import JudgingEntriesPage from 'web/screen/JudgingEntriesPage/JudgingEntriesPage';
import { EvaluationCompetitionListItemDto } from 'model/Api/Evaluation/Model/EvaluationCompetitionListItemDto';

export enum JudgingCategorySorting {
    name = 'name',
    assessedSubmissions = 'assessedSubmissions'
}

class JudgingCategoryPage extends Page {
    @observable competitionId: number;
    @observable patent: JudgingIntroPage;
    @observable categories: EvaluationCategoryListItemDto[] = [];
    @observable sortingValue: JudgingCategorySorting =
        JudgingCategorySorting.name;

    constructor(competitionId: number, parent: JudgingIntroPage) {
        super();

        this.competitionId = competitionId;
        this.patent = parent;
    }

    @computed get competitionListItem():
        | EvaluationCompetitionListItemDto
        | undefined {
        return !!this.patent.competitionListItem
            ? this.patent.competitionListItem
            : undefined;
    }

    @computed get competitionStatus(): CompetitionStatus {
        return !!this.competitionListItem
            ? this.competitionListItem.competitionStatus
            : CompetitionStatus.draft;
    }

    @computed get pageName(): string {
        return !this.patent.competitionListItem
            ? ''
            : this.patent.competitionListItem.name;
    }

    api = loader({
        getCompetitionDetails: async (options?: ListOptions) =>
            await getEvaluationCompetitionDetails({
                competitionRef: this.competitionId,
                options
            })
    });

    getCategories = async () => {
        this.categories = await this.api.getCompetitionDetails({
            page: 1,
            pageSize: 1000,
            sorting: {
                field: 'name',
                sortDirection: SortDirection.ascending
            }
        });
    }

    protected async onActivated(): Promise<any> {
        if (!this.categories.length) {
            await this.getCategories();
        }
    }

    protected async onLeafFocus(): Promise<any> {
        await this.getCategories();
    }

    onSortChange = async (prop: JudgingCategorySorting): Promise<void> => {
        this.sortingValue = prop;
        this.categories = await this.api.getCompetitionDetails({
            page: 1,
            pageSize: 1000,
            sorting: {
                field: prop,
                sortDirection:
                    prop === JudgingCategorySorting.name
                        ? SortDirection.ascending
                        : SortDirection.descending
            }
        });
    };

    showJudgingEntriesPage = async (categoryId: number) => {
        return await this.showChildPage(
            new JudgingEntriesPage(categoryId, this)
        );
    };
}

export default JudgingCategoryPage;
