import * as React from 'react';
import SubmissionApprovedModal from './SubmissionApprovedModal';
import AntModal from 'common/components/antHelpers/AntModal';
import {useTranslation} from "react-i18next";

interface SubmissionApprovedModalViewProps {
    dialog: SubmissionApprovedModal;
}

const SubmissionApprovedModalView: React.FC<SubmissionApprovedModalViewProps> = ({
    dialog
}) => {
    const { confirm, cancel } = dialog;
    const { t } = useTranslation('competitions');

    return <AntModal cancel={cancel} okCommand={confirm} title={''} okText={t('sendIn')}>
        Alt klart? Innsendte skjema kan ikke endres.
    </AntModal>;
};

export default SubmissionApprovedModalView;
