import { IActivatable } from 'react-mvvm';
import { AttachmentResponse } from 'model/Api/Submission/Model/AttachmentResponse';

class ImageDialog implements IActivatable {
    images: AttachmentResponse[];
    startIndex: number | undefined;

    constructor(
        private close: (r: boolean) => void,
        images: AttachmentResponse[],
        startIndex?: number
    ) {
        this.images = images;
        this.startIndex = startIndex;
    }

    cancel = () => this.close(false);

    activate(): Promise<void> {
        return Promise.resolve();
    }
}

export default ImageDialog;
