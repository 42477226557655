import {
    Form,
    FormField,
    FormFields,
    FormState,
    IFormField,
    NullValidator,
    RequiredFieldValidator,
    RequiredUrlValidator,
    RequiredVideoLinkValidator,
    UrlValidator,
    VideoLinkValidator
} from 'react-mvvm';
import {action, computed, observable, runInAction} from 'mobx';
import {CompetitionEntryDto} from 'model/Api/Submission/Model/CompetitionEntryDto';
import {CompetitionEntryStatus, CompetitionStatus, QuestionType} from 'model/Externals';
import {SubmissionQuestionDto} from 'model/Api/Submission/Model/SubmissionQuestionDto';
import {RcFile, UploadChangeParam, UploadFile} from 'antd/lib/upload/interface';
import {UploadRequestOption as RcCustomRequestOptions} from 'rc-upload/lib/interface';
import loader from 'react-mvvm/loading/loader';
import {getSubmissionQuestionnaire} from 'model/Api/Submission/GetSubmissionQuestionnaireRequest';
import {getCompetitionEntryDetails} from 'model/Api/Submission/GetCompetitionEntryDetailsRequest';
import {deleteAttachment} from 'model/Api/Submission/DeleteAttachmentRequest';
import {updateCompetitionEntry} from 'model/Api/Submission/UpdateCompetitionEntryRequest';
import {ProductDto} from 'model/Api/Competitions/Model/ProductDto';
import {AnswerDto} from 'model/Api/Submission/Model/AnswerDto';
import UrlField from 'web/screen/SubmissionFormPage/UrlField/UrlField';
import ContributorsField from 'web/screen/SubmissionFormPage/ContributorsField/ContributorsField';
import moment, {Moment} from 'moment';
import {notification} from 'antd';
import {api as axiosWeb} from 'web/api/config';
import {api as axiosAdmin} from 'admin/api/config';
import {AttachmentResponse} from 'model/Api/Submission/Model/AttachmentResponse';
import {getSubmissionDetails} from 'model/Api/Competitions/GetSubmissionDetailsRequest';
import {adminUpdateCompetitionEntry} from 'model/Api/Submission/AdminUpdateCompetitionEntryRequest';
import EnterSubmissionForm from 'admin/screen/EnterSubmissionForm/EnterSubmissionForm';
import SubmissionFormPage from 'web/screen/SubmissionFormPage/SubmissionFormPage';
import {computedFn} from "mobx-utils";
import customMessage from "web/components/CustomMessage/CustomMessage";

export enum SubmissionMode {
    admin = 'admin',
    web = 'web'
}

export enum FileMoveDirection {
    up = -1,
    down = 1
}

export type LoadingFileProgress = {
    uid:string,
    name: string,
    percent: number
}

class SubmissionForm {
    mode: SubmissionMode;
    @observable isAdminEditDisable: boolean = true;
    @observable parent: EnterSubmissionForm | SubmissionFormPage;
    @observable categoryId: number;
    @observable entryId: number;

    @observable competitionEntryDto: CompetitionEntryDto;
    @observable competitionEntryForm: Form<any>;

    @observable isFormChanged: boolean = false;
    @observable questions: SubmissionQuestionDto[] = [];

    @observable files: { [id: string]: UploadFile<any>[] } = {};
    @observable multiFiles: { [id: string]: UploadFile<any>[] } = {};
    @observable formErrorFields: IFormField[] = [];

    @observable coverImageQuestionRef: number | undefined;
    @observable titleQuestionRef: number | undefined;
    @observable entryPreviewPageDescription: string | undefined;
    @observable product: ProductDto | undefined;
    @observable invoiceFeeProduct: ProductDto | undefined;
    @observable multiFileLoadingProgress: {[id: number]:LoadingFileProgress[]} = {};
    
    private autoSave: number | null = null;

    constructor(
        parent: EnterSubmissionForm | SubmissionFormPage,
        categoryId: number,
        entryId: number,
        mode: SubmissionMode
    ) {
        this.parent = parent;
        this.categoryId = categoryId;
        this.entryId = entryId;
        this.mode = mode;

        this.competitionEntryDto = this.parent.competitionEntryDto ?? {
            answers: [],
            attachments: [],
            competitionEntryStatus: CompetitionEntryStatus.created,
            id: 0,
            version: 0
        };
        this.competitionEntryForm = new Form([]);
    }

    init = async () => {
        const questionnaire = await this.api.getSubmissionFormQuestions();

        this.coverImageQuestionRef = questionnaire.coverImageQuestionRef;
        this.titleQuestionRef = questionnaire.titleQuestionRef;
        this.entryPreviewPageDescription =
            questionnaire.entryPreviewPageDescription;
        this.product = questionnaire.product;
        this.invoiceFeeProduct = questionnaire.invoiceFeeProduct;

        this.questions = questionnaire.questions;

        if (!this.parent.competitionEntryDto) {
            if (this.mode === SubmissionMode.web) {
                this.competitionEntryDto = await this.api.getCompetitionEntry(
                    this.entryId
                );
            } else if (this.mode === SubmissionMode.admin) {
                this.competitionEntryDto = await this.api.getCompetitionEntryAdmin(
                    this.entryId
                );
            }
        }

        this.competitionEntryForm = await this.buildCompetitionSubmissionForm();
        this.isAdminEditDisable =
            this.competitionEntryDto.competitionEntryStatus !==
            CompetitionEntryStatus.draft;
    };
    
    onAutoSave = async () => {
        if(this.competitionEntryDto.competitionEntryStatus !== CompetitionEntryStatus.created && this.competitionEntryForm.isDirty && this.isFormChanged) {
            await this.updateCompetitionEntryDto();
            if (this.mode === SubmissionMode.web) {
                this.competitionEntryDto = await this.apiAutoSave.saveEntry(
                    this.competitionEntryDto
                );
            } else if (this.mode === SubmissionMode.admin) {
                this.competitionEntryDto = await this.apiAutoSave.saveEntryAdmin(
                    this.competitionEntryDto
                );
            }
            
            await customMessage('Lagring')
            runInAction(() => (this.isFormChanged = false));
        }
    }

    onLeafFocus = async () => {
        window.scrollTo({ top: 0 });
        this.autoSave = window.setInterval(async () => {
            if (this.competitionEntryDto.competitionEntryStatus !== CompetitionEntryStatus.created && this.competitionEntryForm.isDirty && this.isFormChanged) {
                await this.updateCompetitionEntryDto();
                if (this.mode === SubmissionMode.web) {
                    this.competitionEntryDto = await this.apiAutoSave.saveEntry(
                        this.competitionEntryDto
                    );
                } else if (this.mode === SubmissionMode.admin) {
                    this.competitionEntryDto = await this.apiAutoSave.saveEntryAdmin(
                        this.competitionEntryDto
                    );
                }

                await customMessage('Lagring')
                runInAction(() => (this.isFormChanged = false));
            }
        }, 30000);
    };

    onSaveAsDraftClicked = async () => {
        if (this.competitionEntryForm.isDirty && this.isFormChanged) {
            await this.updateCompetitionEntryDto();
            if (this.mode === SubmissionMode.web) {
                this.competitionEntryDto = await this.apiAutoSave.saveEntry(
                    this.competitionEntryDto
                );
            } else if (this.mode === SubmissionMode.admin) {
                this.competitionEntryDto = await this.apiAutoSave.saveEntryAdmin(
                    this.competitionEntryDto
                );
            }
            await customMessage('Lagring')

            runInAction(() => (this.isFormChanged = false));
        }
    };

    onLeafBlur = async () => {
        if (!!this.autoSave) {
            window.clearInterval(this.autoSave);
        }
    };

    api = loader({
        getSubmissionFormQuestions: async () =>
            getSubmissionQuestionnaire({
                categoryRef: this.categoryId
            }),
        getCompetitionEntry: async (entryId: number) =>
            getCompetitionEntryDetails({ competitionEntryRef: entryId }),
        getCompetitionEntryAdmin: async (competitionEntryRef: number) =>
            getSubmissionDetails({ competitionEntryRef })
    });

    apiAttachments = loader({
        removeAttachment: async (uid: number) =>
            await deleteAttachment({ attachmentRef: uid })
    });

    apiAutoSave = loader({
        saveEntry: async (entry: CompetitionEntryDto) =>
            await updateCompetitionEntry({ competitionEntryDto: entry }),
        saveEntryAdmin: async (competitionEntryDto: CompetitionEntryDto) =>
            await adminUpdateCompetitionEntry({ competitionEntryDto })
    });

    @action
    onAdminEdit = () => {
        this.isAdminEditDisable = !this.isAdminEditDisable;
    };

    @action
    onFormChange = () => {
        if (!this.isFormChanged) {
            this.isFormChanged = true;
        }
    };
    
    @computed get competitionStatus() {
        return !!this.parent
            ? this.parent.competitionStatus
            : CompetitionStatus.draft;
    }

    @computed get isFormDisabled() {
        if (this.mode === SubmissionMode.admin) {
            return this.isAdminEditDisable;
        }
        return (
            this.competitionEntryDto.competitionEntryStatus ===
                CompetitionEntryStatus.submitted ||
            this.competitionStatus !== CompetitionStatus.submissionOpen
        );
    }

    buildCompetitionSubmissionForm = (): Form<any> => {
        const formFields: FormFields<any> = {};

        for (let idx = 0; idx < this.questions.length; idx++) {
            const question = this.questions[idx];
            const questionId: string = `${idx}_${question.id}`;
            let answer: AnswerDto | undefined;

            if (this.competitionEntryDto.answers.length > 0) {
                answer = this.competitionEntryDto.answers.find(
                    (a) => a.questionId === question.id
                );
            }

            if (!answer) {
                this.competitionEntryDto.answers.push({
                    questionId: question.id,
                    value: '',
                    questionType: question.questionType,
                    id: 0,
                    version: 0
                });
            }
            switch (question.questionType) {
                case QuestionType.url:
                    formFields[questionId] = new UrlField(
                        !!answer ? answer.value : undefined,
                        question.isRequired
                            ? RequiredUrlValidator
                            : UrlValidator
                    );
                    break;
                case QuestionType.videoLink:
                    formFields[questionId] = new UrlField(
                        !!answer ? answer.value : undefined,
                        question.isRequired
                            ? RequiredVideoLinkValidator
                            : VideoLinkValidator
                    );
                    break;
                case QuestionType.roleInProject:
                    formFields[questionId] = new ContributorsField(
                        !!answer && !!answer.value ? answer.value : undefined,
                        question.isRequired
                            ? RequiredFieldValidator
                            : NullValidator,
                        question.configuration
                    );
                    break;
                case QuestionType.date:
                    formFields[questionId] = new FormField(
                        !!answer && !!answer.value
                            ? moment(new Date(answer.value))
                            : undefined,
                        question.isRequired
                            ? RequiredFieldValidator
                            : NullValidator
                    );
                    break;
                case QuestionType.multiSelect:
                case QuestionType.singleSelect:
                    formFields[questionId] = new FormField(
                        !!answer && !!answer.value
                            ? JSON.parse(answer.value)
                            : undefined,
                        question.isRequired
                            ? RequiredFieldValidator
                            : NullValidator
                    );
                    break;
                default:
                    formFields[questionId] = new FormField(
                        !!answer ? answer.value : undefined,
                        question.isRequired
                            ? RequiredFieldValidator
                            : NullValidator
                    );
                    break;
            }
        }

        return new Form<any>(formFields, this.onFormChange);
    };

    private updateCompetitionEntryDto = async () => {
        await Object.keys(this.competitionEntryForm.fields).map((f) => {
            const realId = f.split('_')[1];
            const idx = parseInt(realId, 10);

            const value = this.competitionEntryForm.fields[f].value;
            const answer = this.competitionEntryDto.answers.find(
                (a) => a.questionId === idx
            );
            if (!answer) {
                return null;
            }

            switch (answer.questionType) {
                case QuestionType.date:
                    answer.value = !value
                        ? ''
                        : (value as Moment).toISOString();
                    break;
                case QuestionType.singleSelect:
                case QuestionType.multiSelect:
                    answer.value = !value ? '[]' : JSON.stringify(value);
                    break;
                default:
                    answer.value =
                        typeof value === 'string'
                            ? value
                            : JSON.stringify(value);
                    break;
            }
        });
    };

    onFormSubmit = async (firstErrorRef: any): Promise<boolean> => {
        await this.competitionEntryForm.validate();
        this.formErrorFields = await this.competitionEntryForm.gerErrorFields();
        if (!!firstErrorRef && firstErrorRef.current) {
            window.scrollTo({
                top: firstErrorRef.current.offsetTop ?? 0,
                left: 0,
                behavior: 'smooth'
            });
        }

        if (this.competitionEntryForm.state === FormState.Valid) {
            await this.updateCompetitionEntryDto();
            if (
                this.competitionEntryDto.competitionEntryStatus ===
                    CompetitionEntryStatus.draft ||
                this.mode === SubmissionMode.admin
            ) {
                if (this.mode === SubmissionMode.web) {
                    this.competitionEntryDto = await this.apiAutoSave.saveEntry(
                        this.competitionEntryDto
                    );
                } else if (this.mode === SubmissionMode.admin) {
                    this.competitionEntryDto.competitionEntryStatus =
                        CompetitionEntryStatus.submitted;
                    this.competitionEntryDto = await this.apiAutoSave.saveEntryAdmin(
                        this.competitionEntryDto
                    );
                }

                await customMessage('Lagring')
            }

            return true;
        }

        return false;
    };

    //helper functions
    getSelectDataSource = (
        array: string[],
        hasEmpty: boolean = false
    ): { id: string; value: string }[] => {
        let result = array.map((val, index) => ({
            id: index.toString(),
            value: val
        }));
        if (hasEmpty) result.unshift({ id: '-1', value: '' });
        return result;
    };

    getFileList(idx: number, field: FormField<string>) {
        if (!this.files[idx]) {
            this.files[idx] = !field.value
                ? []
                : JSON.parse(field.value).reduce(
                      (prev: UploadFile<any>[], current: string) => {
                          const attachment = this.competitionEntryDto.attachments.find(
                              (a) => a.uid.toString() === current
                          );
                          if (!!attachment) {
                              prev.push(attachment);
                          }
                          return prev;
                      },
                      []
                  );
        }
        return this.files[idx];
    }

    @action
    getMultiFilesList = (idx: number, field: FormField<string>) => {
        if (!this.multiFiles[idx]) {
            this.multiFiles[idx] = !field.value
                ? []
                : JSON.parse(field.value).reduce(
                      (prev: any[], current: string) => {
                          const attachment = this.competitionEntryDto.attachments.find(
                              (a) => a.uid.toString() === current
                          );
                          if (!!attachment) {
                              prev.push(attachment);
                          }
                          return prev;
                      },
                      []
                  );
        }
        return this.multiFiles[idx];
    }
    multiFileListOptions = computedFn((field: FormField<string>) => {
        return !field.value
            ? []
            : JSON.parse(field.value).reduce(
                (prev: any[], current: string) => {
                    const attachment = this.competitionEntryDto.attachments.find(
                        (a) => a.uid.toString() === current
                    );
                    if (!!attachment) {
                        prev.push(attachment);
                    }
                    return prev;
                },
                []
            );
    })
    @action
    updateMultiFilesList = (idx: number, newFieldValue: string[]) => {
        this.multiFiles[idx] = newFieldValue.reduce((prev: any[], current: string) => {
            const attachment = this.competitionEntryDto.attachments.find(
                (a) => a.uid.toString() === current
            );
            if (!!attachment) {
                prev.push(attachment);
            }
            return prev;
        }, []);
    };

    
    onFileChangeOrder = (idx: number, field: FormField<string>): (file: UploadFile, direction: FileMoveDirection) => void => {
        return (file: UploadFile, direction: FileMoveDirection) => {
            if(!!field.value){
                const data:string[] = JSON.parse(field.value);
                const fieldIndex = data.findIndex(id => id === file.uid);
                if(fieldIndex !== undefined){
                    const temp = data[fieldIndex];
                    data[fieldIndex] = data[fieldIndex + direction];
                    data[fieldIndex + direction] = temp;
                }
                
                field.value = JSON.stringify(data);

                this.updateMultiFilesList(idx, data);
            }
        }
    }

    handleSingleFileChange(
        formField: FormField<string>,
        idx: number
    ): (info: UploadChangeParam<UploadFile<any>>) => void {
        return (info: UploadChangeParam<UploadFile<any>>): void => {
            if ((info.file as any).flag) {
                return;
            }

            if (!info.file.status) {
                info.fileList.forEach((f) => {
                    if (info.file.uid === f.uid) {
                        f.status = 'error';
                    }
                });
            }

            let fileList = [...info.fileList];

            fileList = fileList.slice(-1);

            fileList = fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.thumbUrl = file.response.thumbUrl;
                    formField.value = JSON.stringify([file.response.uid]);
                } else {
                    formField.value = '';
                }
                return file;
            });

            this.files[idx] = fileList;
        };
    }

    onRemoveAttachment = async (uid: number, name: string | undefined) => {
        const remove = await this.apiAttachments.removeAttachment(uid);

        if (remove) {
            notification.info({
                message: 'Delete attachment',
                description: !name
                    ? ''
                    : `File: ${name} have been successful removed`,
                duration: 2000
            });
        }
    };

    onRemoveFile(
        formField: FormField<string>
    ): (file: UploadFile<any>) => boolean {
        return (file: UploadFile<any>) => {
            const tempUid = file.response ? file.response.uid : file.uid;
            const index = this.competitionEntryDto.attachments.findIndex(
                (e) => e.uid === tempUid
            );
            if (index >= 0 && !!formField.value) {
                this.competitionEntryDto.attachments.splice(index, 1);
                let files: string[] = JSON.parse(formField.value);
                files = files.reduce<string[]>((p, c) => {
                    if (c !== tempUid.toString()) {
                        p.push(c);
                    }
                    return p;
                }, []);
                formField.value = JSON.stringify(files);
                this.onRemoveAttachment(tempUid, file.fileName);
            }
            return true;
        };
    }
    
    

    getFileUploadRequest(
        formField: FormField<string>,
        idx?: number
    ): (options: RcCustomRequestOptions) => void {
        return async (options: any) => {
            const { onSuccess, onError, file, onProgress } = options;
            const formData = new FormData();
            formData.append('attachmentRequest.file', file, file.name);
            try {
                let axios;
                if (this.mode === SubmissionMode.web) {
                    axios = axiosWeb();
                } else {
                    axios = axiosAdmin();
                }
                if(!!idx) {
                    this.multiFileLoadingProgress[idx] = [];
                }
                const attachmentResponse = await axios.post<AttachmentResponse>(
                    `/CreateAttachment?competitionEntryRef=${this.competitionEntryDto.id.toString()}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: (e: any) => {
                            const percentage = Math.round((e.loaded / e.total) * 100);
                            onProgress(
                                { percent: percentage},
                                file
                            );
                            if(!!idx){
                                const isLoaded = this.multiFileLoadingProgress[idx].find(f => {
                                    if(f.uid === file.uid){
                                        f.percent = percentage;
                                        return true
                                    }
                                    return false;
                                });
                                if(!!isLoaded) {
                                    return;
                                }
                                this.multiFileLoadingProgress[idx].push({
                                    uid: file.uid,
                                    name: file.name,
                                    percent: percentage
                                })
                            }
                            
                        }
                    }
                ).then((data) => {
                    let fieldList: string[] = [];
                    if (!!formField.value) {
                        fieldList = [...JSON.parse(formField.value)];
                    }
                    fieldList.push(data.uid.toString());
                    formField.value = JSON.stringify(fieldList);
                    onSuccess({ ...data }, file);
                    this.competitionEntryDto.attachments.push(data);
                    
                    return data;
                }).then((data) => {
                    if (!!idx) {
                        this.multiFileLoadingProgress = Object.keys(this.multiFileLoadingProgress)
                            .reduce<{[id: number]: LoadingFileProgress[]}>((prev, current) => {
                                const id = parseInt(current);
                                if(id === idx) {
                                    const addedFiles = this.multiFileLoadingProgress[id];
                                    if (!addedFiles.length) {
                                        return prev;
                                    }

                                    prev[id] = addedFiles.reduce<LoadingFileProgress[]>((p,c) => {
                                        if(c.name !== data.name) {
                                            p.push(c);
                                        }
                                        return p;
                                    }, []);
                                    
                                    return prev
                                }

                                prev[id] = this.multiFileLoadingProgress[id];
                                return prev
                            }, {});
                    }
                    return data;
                });
            } catch (error) {
                onError(error);
            }
        };
    }

    validateFileBeforeUpload = (file: RcFile): boolean => {
        const isLt500M = file.size < 524288000;
        if (!isLt500M) {
            notification.error({
                message: 'Uploading Error',
                description:
                    'Attachment size exceeds the allowable limit (500MB)',
                duration: 0
            });
            (file as any).flag = true;
            return false;
        }

        return true;
    };
}

export default SubmissionForm;
