import * as React from 'react';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';

type Props = {
    value: Moment | undefined;
    onChange?: (value: Moment | undefined) => void;
    onCommit?: () => void;
};

const AntDatePicker = ({
    value,
    onChange,
    onCommit,
    ...props
}: Props & Omit<PickerDateProps<Moment>, 'value' | 'onChange' | 'onBlur'>) => {
    return (
        <DatePicker
            {...props}
            value={value}
            onChange={(v, ds) => onChange && onChange(v ? v : undefined)}
            onBlur={() => onCommit && onCommit()}
        />
    );
};

export default AntDatePicker;
