import {
    ConditionalFieldValidator,
    Form,
    FormField,
    FormFields,
    FormFieldValidator,
    FormState,
    NullValidator
} from 'react-mvvm/forms';
import { action, computed, observable } from 'mobx';
import { command } from 'react-mvvm/commands';

export type ContributorsForm = {
    name: string;
    role: string;
    company: string;
};

class ContributorsField extends FormField<any> {
    @observable contributors: Form<ContributorsForm>[] = [];
    readonly fieldValidator: FormFieldValidator<string>;
    readonly configuration: string[];
    private isErrorState = false;
    private isValidatingProcess = false;
    @observable errors: string[] = [];

    constructor(
        value: string | undefined,
        validator: FormFieldValidator<string>,
        configuration: string[]
    ) {
        super(value, validator);
        this.fieldValidator = validator;
        this.configuration = configuration;

        if (!value) {
            this.addRow.execute();
            return;
        }

        this.value = value;
    }

    getAllConfiguration = (role: string | undefined): string[] => {
        if (!role) {
            return this.configuration;
        }
        const isRoleInConfig = this.configuration.find((c) => c === role);
        return !isRoleInConfig
            ? [...this.configuration, role]
            : this.configuration;
    };

    @computed get value(): string | undefined {
        let isEmpty = false;
        const formValue = this.contributors.map((c, index) => {
            isEmpty =
                index === 0 &&
                !c.fields['role'].value &&
                !c.fields['name'].value &&
                !c.fields['company'].value;
            return {
                name: c.fields['name'].value,
                role: c.fields['role'].value,
                company: c.fields['company'].value
            };
        });
        return isEmpty ? '' : JSON.stringify(formValue);
    }

    @computed get isPristine() {
        return this.contributors.some((form) => form.isPristine);
    }

    @computed get state(): FormState {
        if (this.isErrorState) {
            return FormState.Invalid;
        }

        if (this.isValidatingProcess) {
            return FormState.Pending;
        }

        return FormState.Valid;
    }

    set value(data: string | undefined) {
        if (!!data) {
            const formData: any[] = JSON.parse(data);
            this.contributors = formData.map((row) => {
                return this.buildRow(row.name, row.role, row.company);
            });
        }
    }

    addRow = command(() => this.contributors.push(this.buildRow()));

    removeRow = (index: number) => {
        this.contributors.splice(index, 1);
    };

    async validate(): Promise<true | readonly string[]> {
        let isValid = true;
        this.errors = [];
        this.isErrorState = false;
        this.isValidatingProcess = true;
        for (const form of this.contributors) {
            const validation = await form.validate();
            if (!validation) {
                for (const field of Object.values(form.fields)) {
                    if (field.errors.length > 0) {
                        this.errors = [...this.errors, ...field.errors];
                    }
                }
                isValid = false;
                this.isErrorState = true;
            }
        }

        this.isValidatingProcess = false;
        return isValid || [];
    }

    @action
    addCustomRole = (value: string) => {
        this.configuration.push(value);
    };

    buildRow(
        name = undefined,
        role = undefined,
        company = undefined
    ): Form<any> {
        const fields: FormFields<any> = {
            role: new FormField(
                role,
                !!this.fieldValidator &&
                this.fieldValidator.name === NullValidator.name
                    ? (v) =>
                          ConditionalFieldValidator(
                              v,
                              fields.name.value,
                              'Name'
                          )
                    : this.fieldValidator
            ),
            name: new FormField(
                name,
                !!this.fieldValidator &&
                this.fieldValidator.name === NullValidator.name
                    ? (v) =>
                          ConditionalFieldValidator(
                              v,
                              fields.role.value,
                              'Role'
                          )
                    : this.fieldValidator
            ),
            company: new FormField(company, NullValidator)
        };

        return new Form<any>(fields);
    }
}

export default ContributorsField;
