import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface SetPublicNoteRequest {
    competitionEntryRef: number
    publicNote: string
}
export const setPublicNote = (setPublicNoteRequest: SetPublicNoteRequest): Promise<any> => {
    return send("SetPublicNote", setPublicNoteRequest)
}
