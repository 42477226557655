import loader from 'react-mvvm/loading/loader';
import { computed, observable } from 'mobx';
import HomePage from 'web/screen/HomePage/HomePage';
import UserInfoEditDialog from 'web/screen/HomePage/UserInfoEditDialog/UserInfoEditDialog';
import { updateUserInfo } from 'model/Api/Individuals/UpdateUserInfoRequest';
import { updateUserInfoTicket } from 'model/Api/Individuals/UpdateUserInfoTicketRequest';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import { deleteAccountRequest } from 'model/Api/Account/DeleteAccountRequestRequest';
import DeleteAccountDialog from 'web/screen/HomePage/UserInfo/DeleteAccountDialog/DeleteAccountDialog';
import { UserInfoDto } from 'model/Api/Individuals/Model/UserInfoDto';
import { MemberDto } from 'model/Api/Members/Model/MemberDto';
import { getMember } from 'model/Api/Members/GetMemberRequest';

class UserInfo {
    @observable member: MemberDto | undefined;
    @observable userAddress: UserAddresses;
    parent: HomePage;

    constructor(parent: HomePage) {
        this.parent = parent;
        this.userAddress = new UserAddresses(this.parent, false, false);
    }

    @computed get fullName() {
        return `${this.dto.given} ${this.dto.family}`;
    }

    @computed get dto() {
        return this.parent.userDto;
    }

    api = loader({
        updateUserInfo: async (ticketRef: number, pin: string) =>
            updateUserInfo({ ticketRef, pin })
    });

    apiTicket = loader({
        updateUserInfoTicket: async (user: UserInfoDto) =>
            updateUserInfoTicket({ user })
    });

    apiMembership = loader({
        getMember: async () => getMember({})
    });

    apiRequestDeletion = loader({
        deleteAccountRequest: async () => deleteAccountRequest({})
    });

    showEditUser = async () => {
        const userData = await this.parent.showModal<
            UserInfoEditDialog,
            UserInfoDto | undefined
        >(
            (close) =>
                new UserInfoEditDialog(
                    close,
                    this.apiTicket.updateUserInfoTicket,
                    this.api.updateUserInfo,
                    this.dto
                )
        );

        if (!!userData) {
            this.parent.userDto = userData;
        }
    };

    showDeleteAccountModal = async () => {
        const isDeleteRequested = await this.parent.showModal<
            DeleteAccountDialog,
            boolean | undefined
        >(
            (close) =>
                new DeleteAccountDialog(
                    () => this.apiRequestDeletion.deleteAccountRequest(),
                    close
                )
        );

        if (isDeleteRequested) {
            this.parent.userDto.deletionRequested = true;
        }
    };

    init = async () => {
        const membership:
            | MemberDto
            | undefined = await this.apiMembership.getMember();
        this.member = !!membership ? membership : undefined;
    };
}

export default UserInfo;
