import { Page } from 'react-mvvm';
import { computed, observable } from 'mobx';
import SubmissionFormPreviewPage from '../SubmissionFormPreview/SubmissionFormPreviewPage';
import Competition from '../Competition/Competition';
import { SubmissionCategoryDto } from 'model/Api/Submission/Model/SubmissionCategoryDto';
import { homeRoute } from 'web/routes';
import SubmissionForm, {
    SubmissionMode
} from 'common/components/SubmissionForm/SubmissionForm';
import OnBackToSubmissionModal from 'web/screen/SubmissionFormPage/OnBackToSubmissionModal/OnBackToSubmissionModal';

class SubmissionFormPage extends Page<OnBackToSubmissionModal> {
    @observable submissionForm: SubmissionForm;
    @observable selectedCategory: SubmissionCategoryDto | undefined;
    @observable isLoading: boolean = false;

    constructor(
        public competitionId: number,
        public categoryId: number,
        public entryId: number,
        private parent: Competition
    ) {
        super();
        this.submissionForm = new SubmissionForm(
            this,
            categoryId,
            entryId,
            SubmissionMode.web
        );
    }
    @computed get competition() {
        return this.parent.competition;
    }

    @computed get competitionEntryDto() {
        return this.parent.competitionEntryDto;
    }

    @computed get competitionStatus() {
        return this.parent.competition.competitionStatus;
    }

    @computed get pageName() {
        if (
            !!this.parent.selectedCategory &&
            !!this.parent.selectedCategoryName
        ) {
            return this.parent.selectedCategoryName;
        }
        return '';
    }

    protected onActivated = async () => {
        await this.submissionForm.init();
    };

    protected async onLeafFocus(): Promise<any> {
        await this.submissionForm.onLeafFocus();
    }

    protected async onLeafBlur(): Promise<any> {
        await this.submissionForm.onLeafBlur();
    }

    onSaveAsDraftClicked = async () => {
        await this.submissionForm.onSaveAsDraftClicked();
    };

    onContinueClick = async (errorReference: any) => {
        this.isLoading = true;
        if (await this.submissionForm.onFormSubmit(errorReference)) {
            await this.submissionForm.onSaveAsDraftClicked();

            homeRoute.historyPush(`/submissions`, { edited: this.entryId });
        } else {
            this.isLoading = false;
        }
    };

    onBackToSubmission = async () => {
        if (
            this.submissionForm.competitionEntryForm.isDirty &&
            this.submissionForm.isFormChanged
        ) {
            await this.showModal<OnBackToSubmissionModal, boolean>(
                (close) =>
                    new OnBackToSubmissionModal(
                        async () => {
                            await this.submissionForm.onSaveAsDraftClicked();
                            homeRoute.historyPush(`/submissions`, {
                                edited: this.entryId
                            });
                        },
                        close,
                        () => {
                            homeRoute.historyPush(`/submissions`);
                        }
                    )
            );

            return;
        }

        homeRoute.historyPush(`/submissions`);
    };

    showFormPreviewPage = async () => {
        return await this.showChildPage(
            new SubmissionFormPreviewPage(this, false)
        );
    };

    onBack = async () => {
        const state: any | undefined = homeRoute.getHistoryState();
        if (!!state && !!state.backUrl) {
            homeRoute.historyPush(state.backUrl);
        }
        await this.parent.removeChildPage();
    };
}

export default SubmissionFormPage;
