import * as React from 'react';
import UserInfoEditDialog from './UserInfoEditDialog';
import AntModal from 'common/components/antHelpers/AntModal';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import { useTranslation } from 'react-i18next';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

interface UserInfoEditDialogViewProps {
    dialog: UserInfoEditDialog;
}

const UserInfoEditDialogView: React.FC<UserInfoEditDialogViewProps> = observer(
    ({ dialog }) => {
        const { t } = useTranslation(['homePage', 'common']);
        const formLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
        };
        return (
            <>
                {!dialog.confirmTicket && (
                    <AntModal
                        width={650}
                        cancel={dialog.cancel}
                        okCommand={dialog.save}
                        title={t('homePage:editUserTitle')}
                        okText={t('common:submit')}
                        okButtonProps={{
                            htmlType: 'submit',
                            form: 'userInfoEditForm'
                        }}>
                        <AntForm
                            form={dialog.userInfoForm}
                            id={'userInfoEditForm'}
                            {...formLayout}>
                            {(fields) => {
                                return (
                                    <>
                                        <AntField
                                            field={fields.given}
                                            label={t('homePage:name')}>
                                            {(props) => (
                                                <AntTextInput
                                                    {...props}
                                                    autoFocus
                                                />
                                            )}
                                        </AntField>
                                        <AntField
                                            field={fields.family}
                                            label={t('homePage:surname')}>
                                            {(props) => (
                                                <AntTextInput {...props} />
                                            )}
                                        </AntField>
                                        <AntField
                                            field={fields.email}
                                            label={t('homePage:email')}>
                                            {(props) => (
                                                <AntTextInput {...props} />
                                            )}
                                        </AntField>
                                        <AntField
                                            field={fields.phoneNumber}
                                            label={t('homePage:mobile')}>
                                            {(props) => (
                                                <AntTextInput {...props} />
                                            )}
                                        </AntField>
                                    </>
                                );
                            }}
                        </AntForm>
                    </AntModal>
                )}
                {dialog.confirmTicket && (
                    <AntModal
                        width={650}
                        cancel={dialog.cancel}
                        okCommand={dialog.confirm}
                        title={t('homePage:editUserTitle')}
                        okText={t('common:submit')}
                        okButtonProps={{
                            htmlType: 'submit',
                            form: 'userInfoConfirmationForm'
                        }}>
                        <AntForm
                            form={dialog.userInfoConfirmationForm}
                            id={'userInfoConfirmationForm'}
                            {...formLayout}>
                            {(fields) => {
                                return (
                                    <>
                                        <AntField
                                            field={fields.pin}
                                            label={t('common:code')}>
                                            {(props) => (
                                                <AntTextInput
                                                    {...props}
                                                    autoFocus={true}
                                                />
                                            )}
                                        </AntField>
                                    </>
                                );
                            }}
                        </AntForm>
                    </AntModal>
                )}
            </>
        );
    }
);

export default UserInfoEditDialogView;
