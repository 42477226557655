import * as React from "react";
import { CheckOutlined } from '@ant-design/icons';
import {message} from "antd";

const customMessage = async (msg: string, duration?: number) => {
    await message.open({
        type: "success",
        content: msg,
        className: 'custom-notification',
        duration: duration,
        icon: <CheckOutlined />,
    })
}

export default customMessage;