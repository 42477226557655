import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {TokenResult} from "./Model/TokenResult"

export interface SetPasswordByPhoneRequest {
    userId: string
    pin: string
    password: string
    confirmPassword: string
}
export const setPasswordByPhone = (setPasswordByPhoneRequest: SetPasswordByPhoneRequest): Promise<TokenResult> => {
    return send("SetPasswordByPhone", setPasswordByPhoneRequest)
}
