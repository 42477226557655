import { observable } from 'mobx';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';
import {
    Form,
    FormField,
    NullValidator,
    RequiredFieldValidator
} from 'react-mvvm/forms';
import loader from 'react-mvvm/loading/loader';
import { createExternalParty } from 'model/Api/Parties/CreateExternalPartyRequest';
import { updateExternalParty } from 'model/Api/Parties/UpdateExternalPartyRequest';
import AddressField from '../AddressField/AddressField';

class PrivateParty {
    @observable party: Partial<PartyDto> = {};
    @observable partyForm: Form<any>;
    isEdited: boolean;
    userId?: number

    constructor(private onSave?: (p?: PartyDto) => void, initParty?: PartyDto, userId?: number) {
        if (!!initParty) {
            this.party = initParty;
        }
        this.isEdited = !!initParty;
        this.partyForm = this.buildForm();
        this.userId = userId;
    }

    api = loader({
        createParty: async (party: PartyDto, userId?: number) => createExternalParty({ party, userRef: userId }),
        editParty: async (party: PartyDto, userId?: number) => updateExternalParty({ party, userRef: userId })
    });

    buildForm = () => {
        return new Form({
            careOf: new FormField<string>(this.party.careOf, NullValidator),
            address: new AddressField(
                this.party.address,
                RequiredFieldValidator
            )
        });
    };

    save = async () => {
        if (await this.partyForm.validate()) {
            this.party = {
                ...this.party,
                careOf: this.partyForm.fields.careOf.value,
                address: this.partyForm.fields.address.value
            };

            let savedParty: PartyDto;
            if (this.isEdited) {
                savedParty = await this.api.editParty(this.party as PartyDto, this.userId);
            } else {
                savedParty = await this.api.createParty(this.party as PartyDto, this.userId);
            }

            !!this.onSave && this.onSave(savedParty as PartyDto);
        }
    };
}

export default PrivateParty;
