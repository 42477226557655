import { FormField, FormState, IFormField } from 'react-mvvm/forms';
import { default as React, ReactElement } from 'react';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { bindTo, property } from 'react-mvvm';
import { Form as TheForm } from 'antd';
import { observer } from 'mobx-react';
import style from './AntField.module.scss';

export type FieldInputProps<T> = {
    value: T | undefined;
    onChange: (value: T | undefined) => void;
    onCommit: () => void;
};

function getValidationStatus(field: IFormField) {
    if (field.state === FormState.Invalid) return 'error';
    if (field.isValidating) return 'validating';
    if (field.state === FormState.Valid) return 'success';
    return '';
}

type ChildrenType<T> = (inputProps: FieldInputProps<T>) => ReactElement;

function fieldImpl<T>(
    props: {
        field: FormField<T>;
        children: ChildrenType<T>;
        description?: string;
        dscextend?: ReactElement;
    } & Omit<FormItemProps, 'children'>
) {
    const field = props.field;
    const inputProps = {
        ...bindTo(property(field, 'value')),
        onCommit: () => field.commit()
    };

    return (
        <TheForm.Item
            {...props}
            required={field.isRequired}
            validateStatus={getValidationStatus(field)}
            help={field.errors.length > 0 ? field.errors.join('\n') : false}
            hasFeedback={field.state === FormState.Invalid}>
            {props.description && (
                <span className={style.small}>{props.description}</span>
            )}
            {!!props.dscextend && (
                <span className={style.dscExtend}>{props.dscextend}</span>
            )}
            {props.children(inputProps)}
        </TheForm.Item>
    );
}

const AntField = observer(fieldImpl);

export default AntField;
