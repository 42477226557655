import * as React from "react";
import Handelkurv from "web/screen/Handelkurv/Handelkurv";
import PageWrapper from "web/components/PageWrapper";
import style from './Handelkurv.module.scss';
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import EntriesTable from "web/screen/Submissions/EntriesTable";
import { observer } from 'mobx-react';
import Flex from "common/components/Flex";
import {ContentView} from "react-mvvm";
import { ReactComponent as X } from 'common/icons/X.svg';

interface HandelkurvViewProps {
    model: Handelkurv;
} 

const HandelkurvView: React.FC<HandelkurvViewProps> = observer(({model}) => {
    const { t } = useTranslation('competitions');
    
    React.useEffect(() => {
        if(!model.selectedEntries.length) {
            model.parent.onRemoveChildPage();
        }
    }, [model.selectedEntries])
    
    return <ContentView content={model.childPage}><div className={style.container}>
        <h1 className={style.title}><span>{t('basket')}</span></h1>
        <PageWrapper>
            {model.selectedEntries.length > 0 &&
                <EntriesTable entries={model.selectedEntries} withSelection={false} withEditLink={false} actionButtons={(record) => {
                    return <>
                        <Flex justifyContent={"flex-end"} alignItems={"center"} customClass={style.remove}>
                            <Button size={"small"} icon={<X/>} onClick={() => model.onRemoveSelectedEntry(record.id)} />
                        </Flex>
                    </>
                }} pagination={false}/>
            }
        </PageWrapper>
        <div className={style.footer}>
            <Flex justifyContent={"space-between"} alignItems={"center"} customClass={style.footerContent}>
                <Button size={"small"} type={"link"} onClick={() => model.parent.onRemoveChildPage()}>{t('backToAllSubmissions')}</Button>
                <Button type={"primary"} size={"small"} onClick={() => model.onPayOrSubmit()} >{t('sendIn')}</Button>
            </Flex>
        </div>
    </div>
    </ContentView>
});

export default HandelkurvView;
