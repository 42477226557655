import loader from 'react-mvvm/loading/loader';
import {getUserCompetitionEntries} from 'model/Api/Submission/GetUserCompetitionEntriesRequest';
import {CompetitionEntryListItemDto} from 'model/Api/Submission/Model/CompetitionEntryListItemDto';
import {action, computed, observable} from 'mobx';
import {CompetitionEntryStatus, CompetitionStatus} from 'model/Externals';
import {Page} from 'react-mvvm';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import {deleteCompetitionEntry} from 'model/Api/Submission/DeleteCompetitionEntryRequest';
import SubmissionApprovedModal from 'common/components/SubmissionApprovedModal/SubmissionApprovedModal';
import {submitCompetitionEntry} from 'model/Api/Submission/SubmitCompetitionEntryRequest';
import Handelkurv from "web/screen/Handelkurv/Handelkurv";
import {homeRoute} from "web/routes";

type SortedEntryByCompetitionStatus = {
    open: CompetitionEntryListItemDto[];
    past: CompetitionEntryListItemDto[];
}

class Submissions extends Page<ConfirmationModal | SubmissionApprovedModal> {
    @observable entriesList: CompetitionEntryListItemDto[] = [];
    @observable selectedEntries: number[] = [];
    @observable lastEditedEntry: number | undefined;

    api = loader({
        getUserEntries: async () => getUserCompetitionEntries({}),
        submitCompetitionEntry: async (competitionEntryId: number) =>
            submitCompetitionEntry({
                competitionEntryId
            })
    });

    apiSubmission = loader({
        delete: async (competitionEntryRef: number) =>
            deleteCompetitionEntry({ competitionEntryRef })
    });

    async loadUserEntries() {
      this.entriesList = await this.api.getUserEntries();
    }

    protected async onActivated(): Promise<any> {
        this.selectedEntries = [];
        
        const formState = homeRoute.getHistoryState();
        this.lastEditedEntry = formState ? (formState as any)?.edited : undefined;
        await this.loadUserEntries();
        setTimeout(()=> {
            this.lastEditedEntry = undefined;
            window.history.replaceState({}, document.title)
        },5000)
    }

    @computed get sortedEntry(): SortedEntryByCompetitionStatus {
        const list: SortedEntryByCompetitionStatus = {
            open: [],
            past: []
        }

        this.entriesList.forEach(e => {
            if ((e.competition.competitionStatus === CompetitionStatus.closed || e.competition.competitionStatus === CompetitionStatus.archived) && e.competitionEntryStatus === CompetitionEntryStatus.submitted) {
                list.past.push(e);
                return
            }

            if ((e.competitionEntryStatus === CompetitionEntryStatus.draft || 
                e.competitionEntryStatus === CompetitionEntryStatus.submitted) && 
                (e.competition.competitionStatus !== CompetitionStatus.closed && 
                    e.competition.competitionStatus !== CompetitionStatus.archived && 
                    e.competition.competitionStatus !== CompetitionStatus.draft)) {
                list.open.push(e);
                return;
            }
            return;
        })
        return list;

    }

    @action
    onSelectRecord = (
        record: CompetitionEntryListItemDto,
        selected: boolean,
        selectedRows: any[]
    ) => {
        if (selected) {
            this.selectedEntries.push(record.id);
            return;
        }

        this.selectedEntries._remove(record.id);
    };

    @action
    onSelectAllRecords = (
        selected: boolean,
        selectedRows: CompetitionEntryListItemDto[],
        changeRows: CompetitionEntryListItemDto[]
    ) => {
        if (selected) {
            this.selectedEntries.push(...changeRows.map((entry) => entry.id));
            return;
        }

        changeRows.map((entry) => this.selectedEntries._remove(entry.id));
    };
    
    @action
    onSubmitSingleEntry = (id:number) => {
        this.selectedEntries = [id];
        return this.showHandelkurvPage();
    }

    onRemoveEntry = async (entry: CompetitionEntryListItemDto) => {
        const confirmation = await this.showModal<ConfirmationModal, boolean>(
            (close) =>
                new ConfirmationModal(
                    async () => this.apiSubmission.delete(entry.id),
                    close,
                    entry.title
                )
        );

        if (confirmation) {
            this.entriesList._remove(entry);
        }
    };
    
    showHandelkurvPage = async () => {
        return this.showChildPage(new Handelkurv(this));
    }

    onApprove = async (record: CompetitionEntryListItemDto) => {
        if (record.isReadyToApprove) {
            const result = await this.showModal<
                SubmissionApprovedModal,
                boolean
            >(
                (close) =>
                    new SubmissionApprovedModal(
                        async () => {
                            await this.api.submitCompetitionEntry(record.id);
                        },
                        close,
                        ''
                    )
            );

            if (result) {
                this.entriesList.find((el) => {
                    if (el.id === record.id) {
                        el.competitionEntryStatus =
                            CompetitionEntryStatus.submitted;
                        return true;
                    }
                    return false;
                });
            }
        }
    };

    onRemoveChildPage = async () => {
        this.selectedEntries = [];
        await this.removeChildPage();
        this.onActivated();
    };
}

export default Submissions;
