import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {TokenInfo} from "../Account/Model/TokenInfo"

export interface GetDownloadInvoiceTokenRequest {
}
export const getDownloadInvoiceToken = (getDownloadInvoiceTokenRequest: GetDownloadInvoiceTokenRequest): Promise<TokenInfo> => {
    return send("GetDownloadInvoiceToken", getDownloadInvoiceTokenRequest)
}
