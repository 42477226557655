import * as React from 'react';
import style from './InfoRow.module.scss';
import { ReactElement } from 'react';

interface InfoRowProps {
    title?: string;
    margin?: string;
    titleRow?: ReactElement;
}

const InfoRow: React.FC<InfoRowProps> = ({
    title,
    margin,
    titleRow,
    children
}) => {
    return (
        <div
            className={style.infoRow}
            style={{ margin: !!margin ? margin : 0 }}>
            <span className={style.infoRowTitle}>
                {!!title && `${title}:`}
                {!!titleRow && titleRow}
            </span>
            {children}
        </div>
    );
};

export default InfoRow;
