import * as React from 'react';
import style from './SubmissionsHelpText.module.scss';
import { CompetitionStatus } from 'model/Externals';
import { Typography } from 'antd';

interface SubmissionsHelpTextProps {
    status: CompetitionStatus;
    accentColor?: boolean;
}

const SubmissionsHelpText: React.FC<SubmissionsHelpTextProps> = ({
    status,
    accentColor
}) => {
    const colorText = !accentColor ? style.text : style.accent;

    switch (status) {
        case CompetitionStatus.published:
            return (
                <Typography.Text className={colorText}>
                    Det åpnes for innsending av arbeider snart
                </Typography.Text>
            );
        case CompetitionStatus.submissionClosed:
        case CompetitionStatus.phaseOneJudgingInProgress:
        case CompetitionStatus.phaseOneJudgingClosed:
        case CompetitionStatus.phaseTwoJudgingInProgress:
        case CompetitionStatus.phaseTwoJudgingClosed:
            return (
                <Typography.Text className={colorText}>
                    Påmeldingsfristen har gått ut. Vi er i gang med å vurdere de
                    mottatte arbeidene.
                </Typography.Text>
            );
        default:
            return null;
    }
};

export default SubmissionsHelpText;
