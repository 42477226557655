import {
    Page,
    RequiredFieldValidator,
    bindableForm,
    asyncCommand
} from 'react-mvvm';
import { observable } from 'mobx';
import queryString from 'query-string';
import {
    ConfirmPhoneNumberRequest,
    confirmPhoneNumber
} from 'model/Api/Account/ConfirmPhoneNumberRequest';
import hello from 'hellojs';
import { homeRoute } from 'web/routes';

class ConfirmPhoneNumberPage extends Page {
    @observable confirmPhone: ConfirmPhoneNumberRequest;
    @observable errorMessage: string = '';
    @observable isLoading: boolean = false;

    constructor(private phone: string) {
        super();

        this.confirmPhone = {
            phoneNumber: phone,
            pin: '',
            password: '',
            confirmPassword: ''
        };
    }

    confirmPhoneForm = bindableForm<ConfirmPhoneNumberRequest>()
        .addField('phoneNumber', { validator: RequiredFieldValidator })
        .addField('pin', { validator: RequiredFieldValidator })
        .addField('password', { validator: RequiredFieldValidator })
        .addField('confirmPassword', { validator: RequiredFieldValidator })
        .bindTo(() => this.confirmPhone);

    resetErrors = () => {
        this.errorMessage = '';
    };

    onSubmit = asyncCommand(
        async () => {
            this.isLoading = true;
            this.errorMessage = '';
            if (!(await this.confirmPhoneForm.validate())) {
                this.isLoading = false;
                return;
            }

            this.confirmPhoneForm.commit();

            try {
                const token = await confirmPhoneNumber(this.confirmPhone);
                this.isLoading = false;

                if ('accessToken' in token) {
                    hello.utils.store('customJWT', {
                        access_token: token.accessToken.token,
                        refresh_token: token.refreshToken.token
                    });

                    homeRoute.historyPush(homeRoute.getPath({}));
                }
            } catch (error) {
                if (error.response.status === 400) {
                    (error.response.data.errors as string[]).some((e) => {
                        this.errorMessage = e;
                    });
                }
                this.isLoading = false;
            }
        },
        () => this.confirmPhoneForm.isDirty
    );
}

export default ConfirmPhoneNumberPage;
