import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {SubmissionCompetitionDto} from "./Model/SubmissionCompetitionDto"

export interface GetSubmissionCompetitionDetailsRequest {
    id: number
}
export const getSubmissionCompetitionDetails = (getSubmissionCompetitionDetailsRequest: GetSubmissionCompetitionDetailsRequest): Promise<SubmissionCompetitionDto> => {
    return send("GetSubmissionCompetitionDetails", getSubmissionCompetitionDetailsRequest)
}
