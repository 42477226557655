import { useTranslation } from 'react-i18next';

import * as React from 'react';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';
import style from './UserAddresses.module.scss';
import Party from './Party';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Flex from 'common/components/Flex';
import { Button } from 'antd';

interface AddressBoxProps {
    party: PartyDto;
    onSelect: ((id: number) => void) | undefined;
    selected: number | undefined;
    onEdit: (party: PartyDto) => void;
    onDelete: (partyRef: number) => void;
}

const AddressBox: React.FC<AddressBoxProps> = observer(
    ({ party, onSelect, selected, onEdit, onDelete }) => {
        const boxStyle = classNames(style.partyBox, {
            [style.partyBoxSelected]: party.id === selected
        });
        const { t } = useTranslation(['common']);

        return (
            <div className={boxStyle}>
                <div
                    className={style.partyBoxAddress}
                    onClick={() => !!onSelect && onSelect(party.id)}>
                    <Party party={party} />
                </div>
                <Flex justifyContent={'space-between'}>
                    <Button
                        type={'text'}
                        onClick={() => onEdit(party)}
                        size={'small'}
                        className={style.btn}>
                        {t('common:edit')}
                    </Button>
                    <Button
                        type={'text'}
                        onClick={() => onDelete(party.id)}
                        size={'small'}
                        className={style.btn}>
                        {t('common:delete')}
                    </Button>
                </Flex>
            </div>
        );
    }
);

export default AddressBox;
