import { useTranslation } from 'react-i18next';

import * as React from 'react';
import { EvaluationCompetitionListItemDto } from 'model/Api/Evaluation/Model/EvaluationCompetitionListItemDto';
import Link from 'web/components/Link';
import style from './Competition.module.scss';
import ProgressBar from 'web/components/ProgressBar';
import Arrow from 'web/components/Arrow';

interface CompetitionProps {
    competition: EvaluationCompetitionListItemDto;
    onClick: () => void;
}

const Competition: React.FC<CompetitionProps> = ({ competition, onClick }) => {
    const { t } = useTranslation('judging');
    return (
        <div className={style.box} onClick={onClick}>
            <ProgressBar percentage={competition.assessedSubmissions} />
            <div className={style.content}>
                <div className={style.icon}>
                    <Arrow direction={'right'} customStyle={style.arrowIcon} />
                </div>
                <div className={style.title}>{competition.name}</div>
                <div className={style.stats}>
                    <span className={style.items}>{`${competition.categories} ${t(
                        'subcategories'
                    )}`}</span>
                    <span className={style.items}>{`${competition.submissions} ${t(
                        'entries'
                    )}`}</span>
                </div>
            </div>
        </div>
    );
};

export default Competition;
