import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {CompetitionEntryDto} from "./Model/CompetitionEntryDto"


export interface UpdateCompetitionEntryRequest {
    competitionEntryDto: CompetitionEntryDto
}
export const updateCompetitionEntry = (updateCompetitionEntryRequest: UpdateCompetitionEntryRequest): Promise<CompetitionEntryDto> => {
    return send("UpdateCompetitionEntry", updateCompetitionEntryRequest)
}
