import * as React from 'react';
import style from './MembershipPaymentPage.module.scss';
import MembershipPaymentPage from './MembershipPaymentPage';
import { useTranslation } from 'react-i18next';
import Flex from 'common/components/Flex';
import PaymentCheckbox from 'web/components/PaymentCheckbox';
import { PaymentMethod, SubscriptionType } from 'model/Externals';
import { Space, Typography, Button } from 'antd';
import PaymentTermsAndConditions from 'web/components/PaymentTermsAndConditions';
import { observer } from 'mobx-react';
import AllUsersAddressDropdown from 'common/components/UserAddresses/AllUsersAddressDropdown';
import PageLoader from 'common/components/PageLoader';
import MembershipTitle from 'web/components/MembershipTitle';
import PageWrapper from 'web/components/PageWrapper';
import {formatPrice} from "../../../common/utils";

interface MembershipPaymentPageViewProps {
    model: MembershipPaymentPage;
}

const MembershipPaymentPageView: React.FC<MembershipPaymentPageViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['membership', 'common']);

        return (
            <PageLoader loader={model.api}>
                <PageWrapper>
                    <div className={style.pageTitle}>
                        <MembershipTitle
                            title={t('membership:changePaymentSettings')}
                            intro={t('membership:myMembership')}
                            description={t('membership:changePaymentSettingsDescription')}
                        />
                    </div>
                </PageWrapper>
                <div className={style.container}>
                    <div className={style.row}>
                        <Typography.Text className={style.rowTitle}>
                            {t('membership:paymentTitle')}
                        </Typography.Text>
                        <div className={style.formBox}>
                            <Flex
                                justifyContent={'flex-start'}
                                alignItems={'stretch'}>
                                <PaymentCheckbox
                                    selected={
                                        model.subscriptionType ===
                                        SubscriptionType.annual
                                    }
                                    onSelect={() =>
                                        model.onSubscriptionCheck(
                                            SubscriptionType.annual
                                        )
                                    }
                                    price={`kr. ${formatPrice(model.membershipPrice?.annualMembershipPrice)}`}
                                    title={t('membership:annual')}
                                    dsc={t('membership:annualTitle')}
                                />
                                <PaymentCheckbox
                                    selected={
                                        model.subscriptionType ===
                                        SubscriptionType.monthly
                                    }
                                    onSelect={() =>
                                        model.onSubscriptionCheck(
                                            SubscriptionType.monthly
                                        )
                                    }
                                    price={`kr. ${formatPrice(model.membershipPrice?.monthlyMembershipPrice)}`}
                                    priceDsc={'/ hver måned'}
                                    title={t('membership:monthly')}
                                    dsc={t('membership:changeToMonthlyDescription')}
                                />
                            </Flex>
                        </div>
                        <div className={style.row}>
                            <Typography.Text className={style.rowTitle}>
                                {t('membership:howToPay')}
                            </Typography.Text>
                            <Flex
                                justifyContent={'flex-start'}
                                alignItems={'stretch'}>
                                <PaymentCheckbox
                                    selected={
                                        model.paymentMethod ===
                                        PaymentMethod.bambora
                                    }
                                    onSelect={() =>
                                        model.onPaymentMethodCheck(
                                            PaymentMethod.bambora
                                        )
                                    }
                                    title={t('membership:payCard')}
                                    dsc={t('membership:changeToCardDsc')}
                                    isBorder={false}
                                    isDisabled={
                                        process.env.REACT_APP_BAMBORA_OFF ===
                                        'true' || !model.subscriptionType
                                    }
                                />
                                <PaymentCheckbox
                                    selected={
                                        model.paymentMethod ===
                                        PaymentMethod.invoice
                                    }
                                    onSelect={() =>
                                        model.onPaymentMethodCheck(
                                            PaymentMethod.invoice
                                        )
                                    }
                                    title={t('membership:payInvoice')}
                                    priceDsc={model.membershipPrice?.invoiceFeePrice && t('membership:payInvoiceTxt', { amount: model.membershipPrice.invoiceFeePrice}) || ''}
                                    dsc={t('membership:payInvoiceDsc')}
                                    isBorder={false}
                                    isDisabled={model.subscriptionType !== SubscriptionType.annual}
                                />
                            </Flex>
                        </div>
                        <div className={style.row}>
                            <Typography.Text className={style.rowTitle}>
                                {t('membership:selectAddressTitle')}
                            </Typography.Text>
                            <AllUsersAddressDropdown
                                model={model.userAddresses}
                            />
                        </div>
                        <PaymentTermsAndConditions
                            onSelect={model.onTermsCheck}
                            selected={model.isPaymentTermsAccepted}
                        />
                    </div>
                    <Flex
                        justifyContent={'flex-end'}
                        customClass={style.footer}>
                        <Space>
                            <Button
                                type={'link'}
                                onClick={model.onBck}
                                className={style.backBtn}>
                                {t('common:back')}
                            </Button>
                            <Button
                                type={'primary'}
                                onClick={model.onChangeMembershipSubscription}
                                disabled={model.isPaymentDisable}>
                                {t('common:save')}
                            </Button>
                        </Space>
                    </Flex>
                </div>
            </PageLoader>
        );
    }
);

export default MembershipPaymentPageView;
