import * as React from 'react';
import style from './PaymentTermsAndConditions.module.scss';
import AntCheckbox from 'common/components/antHelpers/AntCheckbox';
import Link from 'web/components/Link';
import { useTranslation } from 'react-i18next';

interface PaymentTermsAndConditionsProps {
    selected: boolean;
    onSelect: (v: boolean) => void;
}

const PaymentTermsAndConditions: React.FC<PaymentTermsAndConditionsProps> = ({
    selected,
    onSelect
}) => {
    const { t } = useTranslation('membership');
    return (
        <div className={style.row}>
            <AntCheckbox
                value={selected}
                onChange={(e) => onSelect(e)}
                content={t('paymentConditions')}
            />
            <Link
                arrowIcon={'right'}
                size={'small'}
                target={'_blank'}
                href={'https://www.grafill.no/kjopsvilkar'}>
                {t('kjøpsvilkår')}
            </Link>
        </div>
    );
};

export default PaymentTermsAndConditions;
