import * as React from 'react';
import style from './SubmissionForm.module.scss';
import SubmissionForm from './SubmissionForm';
import { UploadFile } from 'antd/lib/upload/interface';
import {
    FilePdfOutlined,
    SoundOutlined,
    VideoCameraOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import LoaderDataContainer from 'admin/components/LoaderDataContainer';
import SubmissionsHelpText from 'web/components/SubmissionsHelpText';
import AntForm from 'common/components/antHelpers/AntForm';
import { QuestionType } from 'model/Externals';
import AntField from 'common/components/antHelpers/AntField';
import { FieldInputProps } from 'common/components/antHelpers/AntField/AntField';
import AntTextArea from 'common/components/antHelpers/AntTextArea';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import UrlFieldView from 'web/screen/SubmissionFormPage/UrlField/UrlFieldView';
import UrlField from 'web/screen/SubmissionFormPage/UrlField/UrlField';
import { Moment } from 'moment';
import AntDatePicker from 'common/components/antHelpers/AntDatePicker';
import AntUploadSingleFile from 'common/components/antHelpers/AntUploadSingleFile';
import AntUploadFile from 'common/components/antHelpers/AntUploadFile';
import AntWideNumberInput from 'common/components/antHelpers/AntWideNumberInput';
import AntCheckbox from 'common/components/antHelpers/AntCheckbox';
import {
    AntMultiSelect,
    AntSingleSelect
} from 'common/components/antHelpers/AntSelect/AntSelect';
import ContributorsFieldView from 'web/screen/SubmissionFormPage/ContributorsField/ContributorsFieldView';
import ContributorsField from 'web/screen/SubmissionFormPage/ContributorsField/ContributorsField';
import { observer } from 'mobx-react';

interface SubmissionFormViewProps {
    model: SubmissionForm;
    errorReference: any;
}

const customIconRender = (file: UploadFile<any>) => {
    switch (file.type) {
        case 'application/pdf':
            return <FilePdfOutlined />;
        case 'audio/mpeg': //mp3
            return <SoundOutlined />;
        case 'video/mp4':
            return <VideoCameraOutlined />;
        default:
            return undefined;
    }
};

const SubmissionFormView: React.FC<SubmissionFormViewProps> = observer(
    ({ model, errorReference }) => {
        const { t } = useTranslation('competitions');
        const { isFormDisabled } = model;

        return (
            <LoaderDataContainer loader={model.api} customStyle={style.loader}>
                {model.isFormDisabled && (
                    <div className={style.help}>
                        <SubmissionsHelpText
                            status={model.competitionStatus}
                            accentColor={true}
                        />
                    </div>
                )}
                <div className={style.content}>
                    <AntForm
                        id={'form1'}
                        form={model.competitionEntryForm}
                        className={style.formContainer}
                        layout={'vertical'}>
                        {(fields) => {
                            return Object.keys(fields).map((key) => {
                                const realKey = key.split('_')[1];
                                const field = fields[key];
                                const idx = parseInt(realKey, 10);
                                const question = model.questions.find(
                                    (q) => q.id === idx
                                );
                                if (!question) {
                                    return null;
                                }
                                const label = question.label;
                                const helpText = question.helpText || '';
                                const config = question.configuration;
                                const id = question.id;

                                const isFirstError: boolean =
                                    !!model.formErrorFields.length &&
                                    field === model.formErrorFields[0];

                                switch (question.questionType) {
                                    case QuestionType.multiLine:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <AntField
                                                    field={field}
                                                    label={label}
                                                    description={helpText}
                                                    className={style.multilineField}>
                                                    {(
                                                        props: FieldInputProps<
                                                            string
                                                        >
                                                    ) => {
                                                        return (
                                                            <AntTextArea
                                                                {...props}
                                                                maxLength={1000}
                                                                disabled={
                                                                    isFormDisabled
                                                                }
                                                            />
                                                        );
                                                    }}
                                                </AntField>
                                            </div>
                                        );
                                    case QuestionType.singleLine:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <AntField
                                                    field={field}
                                                    description={helpText}
                                                    label={label}>
                                                    {(
                                                        props: FieldInputProps<
                                                            string
                                                        >
                                                    ) => {
                                                        return (
                                                            <AntTextInput
                                                                {...props}
                                                                disabled={
                                                                    isFormDisabled
                                                                }
                                                            />
                                                        );
                                                    }}
                                                </AntField>
                                            </div>
                                        );
                                    case QuestionType.url:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <UrlFieldView
                                                    model={field as UrlField}
                                                    label={label}
                                                    helpText={helpText}
                                                    disabled={isFormDisabled}
                                                />
                                            </div>
                                        );
                                    case QuestionType.date:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <AntField
                                                    field={field}
                                                    description={helpText}
                                                    label={label}>
                                                    {(
                                                        props: FieldInputProps<
                                                            Moment
                                                        >
                                                    ) => {
                                                        return (
                                                            <AntDatePicker
                                                                {...props}
                                                                disabled={
                                                                    isFormDisabled
                                                                }
                                                            />
                                                        );
                                                    }}
                                                </AntField>
                                            </div>
                                        );
                                    case QuestionType.file:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <AntField
                                                    field={field}
                                                    description={helpText}
                                                    label={label}>
                                                    {(
                                                        props: FieldInputProps<
                                                            string
                                                        >
                                                    ) => {
                                                        return (
                                                            <AntUploadSingleFile
                                                                fileList={model.getFileList(
                                                                    idx,
                                                                    field
                                                                )}
                                                                onRemove={model.onRemoveFile(
                                                                    field
                                                                )}
                                                                customRequest={model.getFileUploadRequest(
                                                                    field
                                                                )}
                                                                onChange={model.handleSingleFileChange(
                                                                    field,
                                                                    idx
                                                                )}
                                                                multiple={false}
                                                                iconRender={
                                                                    customIconRender
                                                                }
                                                                onlyImages={
                                                                    model.coverImageQuestionRef ===
                                                                    id
                                                                }
                                                                beforeUpload={
                                                                    model.validateFileBeforeUpload
                                                                }
                                                                disabled={
                                                                    isFormDisabled
                                                                }
                                                            />
                                                        );
                                                    }}
                                                </AntField>
                                            </div>
                                        );
                                    case QuestionType.multiFile:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <AntField
                                                    field={field}
                                                    description={helpText}
                                                    label={label}>
                                                    {(
                                                        props: FieldInputProps<
                                                            string
                                                        >
                                                    ) => {
                                                        return (
                                                            <AntUploadFile
                                                                defaultFileList={model.getMultiFilesList(
                                                                    idx,
                                                                    field
                                                                )}
                                                                fileList={model.multiFileListOptions(field)}
                                                                onRemove={model.onRemoveFile(
                                                                    field
                                                                )}
                                                                customRequest={model.getFileUploadRequest(
                                                                    field,
                                                                    idx
                                                                )}
                                                                multiple={true}
                                                                iconRender={
                                                                    customIconRender
                                                                }
                                                                beforeUpload={
                                                                    model.validateFileBeforeUpload
                                                                }
                                                                disabled={
                                                                    isFormDisabled
                                                                }
                                                                multiFileLoadingProgress={model.multiFileLoadingProgress[idx]}
                                                                onFileOrderChange={model.onFileChangeOrder(idx, field)}
                                                            />
                                                        );
                                                    }}
                                                </AntField>
                                            </div>
                                        );
                                    case QuestionType.number:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <AntField
                                                    field={field}
                                                    description={helpText}
                                                    label={label}>
                                                    {(
                                                        props: FieldInputProps<
                                                            string | number
                                                        >
                                                    ) => {
                                                        return (
                                                            <AntWideNumberInput
                                                                {...props}
                                                                disabled={
                                                                    isFormDisabled
                                                                }
                                                            />
                                                        );
                                                    }}
                                                </AntField>
                                            </div>
                                        );
                                    case QuestionType.checkbox:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <AntField field={field} className={style.checkboxField}>
                                                    {(
                                                        props: FieldInputProps<
                                                            boolean
                                                        >
                                                    ) => {
                                                        return (
                                                            <AntCheckbox
                                                                content={label}
                                                                isRequired={
                                                                    field.isRequired
                                                                }
                                                                {...props}
                                                                disabled={
                                                                    isFormDisabled
                                                                }
                                                                description={
                                                                    helpText
                                                                }
                                                            />
                                                        );
                                                    }}
                                                </AntField>
                                            </div>
                                        );
                                    case QuestionType.singleSelect:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <AntField
                                                    field={field}
                                                    label={label}
                                                    description={helpText}>
                                                    {(
                                                        props: FieldInputProps<{
                                                            id: React.ReactText;
                                                            value: string;
                                                        }>
                                                    ) => {
                                                        return (
                                                            <AntSingleSelect
                                                                {...props}
                                                                allowClear={
                                                                    !question.isRequired
                                                                }
                                                                dataSource={model.getSelectDataSource(
                                                                    config
                                                                )}
                                                                optionText={(
                                                                    el
                                                                ) => el.value}
                                                                disabled={
                                                                    isFormDisabled
                                                                }
                                                            />
                                                        );
                                                    }}
                                                </AntField>
                                            </div>
                                        );
                                    case QuestionType.multiSelect:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <AntField
                                                    field={field}
                                                    label={label}
                                                    description={helpText}>
                                                    {(
                                                        props: FieldInputProps<
                                                            readonly {
                                                                id: React.ReactText;
                                                                value: string;
                                                            }[]
                                                        >
                                                    ) => {
                                                        return (
                                                            <AntMultiSelect
                                                                {...props}
                                                                dataSource={model.getSelectDataSource(
                                                                    config
                                                                )}
                                                                optionText={(
                                                                    el
                                                                ) => el.value}
                                                                disabled={
                                                                    isFormDisabled
                                                                }
                                                            />
                                                        );
                                                    }}
                                                </AntField>
                                            </div>
                                        );
                                    case QuestionType.roleInProject:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <ContributorsFieldView
                                                    model={
                                                        field as ContributorsField
                                                    }
                                                    label={label}
                                                    helpText={helpText}
                                                    disabled={isFormDisabled}
                                                />
                                            </div>
                                        );
                                    case QuestionType.videoLink:
                                        return (
                                            <div
                                                className={style.questionRow}
                                                key={key}
                                                ref={
                                                    isFirstError
                                                        ? errorReference
                                                        : null
                                                }>
                                                <UrlFieldView
                                                    model={
                                                        field as UrlField
                                                    }
                                                    label={label}
                                                    helpText={helpText}
                                                    isRequired={field.isRequired}
                                                    disabled={isFormDisabled}
                                                />
                                            </div>
                                        );
                                    default:
                                        break;
                                }
                            });
                        }}
                    </AntForm>
                    {!!model.formErrorFields.length && <div className={style.errorsInForm}>{t('errorsInApplicationForm')}</div>}
                </div>
            </LoaderDataContainer>
        );
    }
);

export default SubmissionFormView;
