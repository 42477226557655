import { action, computed, observable, reaction } from 'mobx';
import i18next from 'i18next';

export enum FormState {
    Pending,
    Valid,
    Invalid
}

export interface IFormField {
    readonly isValidating: boolean;
    readonly isPristine: boolean;
    readonly state: FormState;

    validate(): Promise<readonly string[] | true>;

    commit(): Promise<void>;

    onValueChange?(value: any): void;
}

export class FormField<T> implements IFormField {
    @observable.ref value: T | undefined;

    get isValidating(): boolean {
        return !!this._currentValidation;
    }

    @computed get isPristine() {
        return this.areEqual(this.value, this._pristineValue);
    }

    get isRequired() {
        return (
            this.validator.name === RequiredFieldValidator.name ||
            this.validator.name === RequiredEmailValidator.name ||
            this.validator.name === RequiredUrlValidator.name ||
            this.validator.name === RequiredPostalCodeValidator.name ||
            this.validator.name === RequiredVideoLinkValidator.name ||
            this.validator.name === RequiredOrganisationNumberValidator.name
        );
    }

    get isDirty() {
        return !this.isPristine;
    }

    @computed get state(): FormState {
        if (this._isPendingValidation) return FormState.Pending;
        if (this._currentValidation) return FormState.Pending;
        return this.errors.length === 0 ? FormState.Valid : FormState.Invalid;
    }

    @observable private _isPendingValidation = true;
    @observable.ref private _errors: ReadonlyArray<string> = [];

    get errors(): ReadonlyArray<string> {
        return this._errors;
    }

    @observable.ref private _pristineValue: T | undefined;
    @observable private _valueHasBeenCommitted: boolean = false;
    @observable.ref private _currentValidation:
        | Promise<readonly string[] | true>
        | undefined;
    private _needsValidation: boolean = false;

    constructor(
        value: T | undefined,
        public validator: FormFieldValidator<T>,
        private comparer?: (a: T, b: T) => boolean,
        public onValueChange?: (value: any) => void
    ) {
        this.value = value;
        this._pristineValue = value;

        reaction(
            () => this.value,
            (value) => {
                if (this._valueHasBeenCommitted || !this._isPendingValidation) {
                    this.validate();
                }
                if (this.onValueChange) this.onValueChange(value);
            },
            {
                delay: 250
            }
        );
    }

    validate(): Promise<readonly string[] | true> {
        this._isPendingValidation = false;
        if (!this._currentValidation) {
            this._currentValidation = this._validate();
        }
        return this._currentValidation;
    }

    @action
    reset(pristineValue?: T) {
        if (pristineValue !== undefined) {
            this._pristineValue = pristineValue;
        }

        this.value = this._pristineValue;
        this._isPendingValidation = true;
        this._errors = [];
        this._valueHasBeenCommitted = false;
    }

    async commit() {
        if (this.state !== FormState.Invalid) await this.validate();
        this._valueHasBeenCommitted = true;
    }

    private async _validate() {
        try {
            do {
                this._needsValidation = false;
                const result = await this.validator(this.value);
                if (result !== true) {
                    this._errors = result;
                    return result;
                }
            } while (this._needsValidation);

            this._errors = [];
            return true;
        } finally {
            this._currentValidation = undefined;
        }
    }

    private areEqual(a: T | undefined, b: T | undefined) {
        if (a === b) return true;
        if (a === undefined || b === undefined) return false;
        if (this.comparer) return this.comparer(a, b);
        return false;
    }
}

export type FormFieldValidator<T> = <T>(
    value: T | undefined
) => (true | string[]) | PromiseLike<true | string[]>;

export const NullValidator = (value: any): true => true;

function isEmpty(value: {} | string | number | undefined | null) {
    if (!value) return true;

    if (Array.isArray(value)) return value.length === 0;

    return value.toString().length === 0;
}

export function RequiredFieldValidator(
    value: any | undefined
): true | string[] {
    if (!isEmpty(value)) {
        return true;
    } else {
        return [i18next.t('form:requiredField')];
    }
}

function validateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLocaleLowerCase());
}

export function RequiredEmailValidator(
    value: any | undefined
): true | string[] {
    if (!isEmpty(value) && validateEmail(value)) {
        return true;
    } else {
        return [i18next.t('form:requiredFieldAndEmail')];
    }
}

function validateUrl(url: string): boolean {
    const re = /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,16}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    return re.test(url.toLocaleLowerCase());
}

export function RequiredUrlValidator(value: any | undefined): true | string[] {
    if (!isEmpty(value) && validateUrl(value)) {
        return true;
    } else {
        return [i18next.t('form:requiredUrl')];
    }
}

export function UrlValidator(value: any | undefined): true | string[] {
    if (isEmpty(value) || validateUrl(value)) {
        return true;
    } else {
        return [i18next.t('form:validUrl')];
    }
}

function validateYoutubeUrl(url: string): boolean {
    const re = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/;
    return re.test(url.toLocaleLowerCase());
}

function validateVimeoUrl(url: string): boolean {
    const re = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
    return re.test(url.toLocaleLowerCase());
}

function organisationNumberValidator(value: any | undefined): boolean {
    const re = /^[0-9]{9}$/;
    return re.test(value);
}

export function RequiredOrganisationNumberValidator(
    value: any | undefined
): true | string[] {
    if (!isEmpty(value) && organisationNumberValidator(value)) {
        return true;
    } else {
        return [i18next.t('form:requiredField')];
    }
}

export function VideoLinkValidator(value: any | undefined): true | string[] {
    if (
        isEmpty(value) ||
        (validateUrl(value) &&
            (validateYoutubeUrl(value) || validateVimeoUrl(value)))
    ) {
        return true;
    } else {
        return [i18next.t('form:vimeoYoutubeValidator')];
    }
}

export function RequiredVideoLinkValidator(
    value: any | undefined
): true | string[] {
    if (
        !isEmpty(value) &&
        validateUrl(value) &&
        (validateYoutubeUrl(value) || validateVimeoUrl(value))
    ) {
        return true;
    } else {
        return [i18next.t('form:vimeoYoutubeRequired')];
    }
}

function validatePostalCode(text: string): boolean {
    const re = /[a-z0-9-]{1,8}$/;

    return re.test(text.toLocaleLowerCase());
}

export function RequiredPostalCodeValidator(
    value: any | undefined
): true | string[] {
    if (!isEmpty(value) && validatePostalCode(value)) {
        return true;
    } else {
        return [i18next.t('form:postalCodeRequired')];
    }
}

export function ConditionalFieldValidator(
    value: any | undefined,
    conditionalValue: any | undefined,
    conditionalFieldName: string
): true | string[] {
    if (!isEmpty(conditionalValue) && isEmpty(value)) {
        return [
            i18next.t('form:conditionalRequired', { conditionalFieldName })
        ];
    }

    return true;
}
