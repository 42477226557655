import axios, { AxiosRequestConfig } from 'axios';
import hello from 'hellojs';
import { axiosOptions } from 'auth/Authentication';
import { refreshToken } from 'model/Api/Account/RefreshTokenRequest';

function createApi(options?: AxiosRequestConfig) {
    const cachedToken = hello.utils.store('customJWT');

    const axiosInstance = axios.create(options);

    if (cachedToken) {
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${cachedToken.access_token}`;
    }

    axiosInstance.interceptors.response.use(
        (response) => response && response.data,
        (err) => {
            const originalRequest = err.config;
            if (
                err.response.status === 401 &&
                err.response.config &&
                !err.response.config.__isRetryRequest
            ) {
                const ct = hello.utils.store('customJWT');
                if (!ct) {
                    window.location.href = `/login?redirect_uri=${encodeURIComponent(
                        window.location.href
                    )}`;
                }

                err.response.config.__isRetryRequest = true;

                return refreshToken({ refreshToken: ct.refresh_token })
                    .then((token) => {
                        hello.utils.store('customJWT', {
                            access_token: token.accessToken.token,
                            refresh_token: ct.refresh_token
                        });
                        axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken.token}`;
                        err.config.headers.Authorization = `Bearer ${token.accessToken.token}`;
                        return axiosInstance(originalRequest);
                    })
                    .catch((reason) => {
                        window.location.href = `/login?redirect_uri=${encodeURIComponent(
                            window.location.href
                        )}`;
                    });
            } else {
                return Promise.reject(err);
            }
        }
    );

    return {
        post<K>(
            url: string,
            data: any,
            config?: AxiosRequestConfig
        ): Promise<K> {
            return axiosInstance.post(url, data, config);
        }
    };
}

let _api: ReturnType<typeof createApi> | undefined;

export const api = () => (_api ? _api : (_api = createApi(axiosOptions)));
