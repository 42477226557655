import * as React from 'react';
import style from './Membership.module.scss';
import Membership from './Membership';
import PageLoader from 'common/components/PageLoader';
import { observer } from 'mobx-react';
import { ContentView } from 'react-mvvm';
import PageWrapper from 'web/components/PageWrapper';
import { Button, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import MemberMenu from 'web/screen/HomePage/MemberMenu';
import MembershipPaymentPage from 'web/screen/MembershipPaymentPage/MembershipPaymentPage';
import MembershipTitle from 'web/components/MembershipTitle/MembershipTitle';
import Subscription from 'web/screen/Membership/Subscription';
import CancelMembershipDialog from 'web/screen/Membership/CancelMembershipDialog/CancelMembershipDialog';
import CancelMembershipDialogView from 'web/screen/Membership/CancelMembershipDialog/CancelMembershipDialogView';
import PaymentInfo from 'web/screen/Membership/PaymentInfo';
import PersonalDataView from 'web/screen/Membership/PersonalData/PersonalDataView';
import SchoolInfoView from 'web/screen/Membership/SchoolInfo/SchoolInfoView';
import EmployeeDataView from 'web/screen/Membership/EmployeeData/EmployeeDataView';
import ProfessionalInfoView from 'web/screen/Membership/ProfessionalInfo/ProfessionalInfoView';
import UpdateCartInfoDialog from 'web/screen/Membership/UpdateCartInfoDialog/UpdateCartInfoDialog';
import UpdateCartInfoDialogView from 'web/screen/Membership/UpdateCartInfoDialog/UpdateCartInfoDialogView';
import AddCartInformationDialog from 'web/screen/Membership/AddCartInformationDialog/AddCartInformationDialog';
import AddCartInformationDialogView from 'web/screen/Membership/AddCartInformationDialog/AddCartInformationDialogView';
import AlmostMemberView from 'web/screen/Membership/AlmostMember/AlmostMemberView';
import ChangeBillingAddressDialog
    from "../../../common/components/ChangeBillingAddressDialog/ChangeBillingAddressDialog";
import ChangeBillingAddressDialogView from 'common/components/ChangeBillingAddressDialog/ChangeBillingAddressDialogView';

interface MembershipViewProps {
    model: Membership;
}

const MembershipView: React.FC<MembershipViewProps> = observer(({ model }) => {
    const { t } = useTranslation(['membership', 'common']);
    const { member } = model;

    return (
        <div>
            {!model.childPage ||
            model.childPage instanceof MembershipPaymentPage ? (
                <MemberMenu model={model.parent} />
            ) : null}
            <ContentView content={model.childPage}>
                <PageLoader loader={model.api}>
                    <PageWrapper>
                        {!model.user?.isMember &&
                        !!model.user?.hasPendingApplication ? (
                            <AlmostMemberView model={model.almostMember} />
                        ) : !model.isMember || !member ? (
                            <Empty
                                description={t('membership:noMembershipInfo')}
                                className={style.empty}>
                                <Button
                                    type={'primary'}
                                    onClick={model.showApplicationPage}>
                                    {t('membership:becomeMember')}
                                </Button>
                            </Empty>
                        ) : (
                            <>
                                <div className={style.pageTitle}>
                                    <MembershipTitle
                                        title={model.userName ?? ''}
                                        intro={t('membership:myAccount')}
                                    />
                                </div>
                                {!!member && (
                                    <>
                                        <Subscription
                                            isActive={member.isActive}
                                            member={member}
                                            onStopRenewalSubscription={
                                                model.onStopRenewalSubscription
                                            }
                                            onReapplyForMembership={
                                                model.onReapplyForMembership
                                            }
                                            onUpgradeMembership={
                                                model.onUpgradeMembership
                                            }
                                            canUpgrade={model.membershipOptions.length > 0}
                                        />

                                        {model.dialog instanceof
                                            CancelMembershipDialog && (
                                            <CancelMembershipDialogView
                                                dialog={model.dialog}
                                            />
                                        )}
                                    </>
                                )}
                                <div>
                                    <PaymentInfo
                                        isActive={member.isActive}
                                        membership={member.currentMembership}
                                        updateCreditCart={model.onUpdateCart}
                                        onChangeSubscription={
                                            model.onChangeSubscriptionData
                                        }
                                        onChangeBillingAddress={
                                            model.onChangeBillingAddress
                                        }
                                        onAddCreditCart={model.onAddCart}
                                    />
                                    {model.dialog instanceof
                                        ChangeBillingAddressDialog && (
                                        <ChangeBillingAddressDialogView
                                            dialog={model.dialog}
                                        />
                                    )}
                                    {model.dialog instanceof
                                        UpdateCartInfoDialog && (
                                        <UpdateCartInfoDialogView
                                            dialog={model.dialog}
                                        />
                                    )}
                                    {model.dialog instanceof
                                        AddCartInformationDialog && (
                                        <AddCartInformationDialogView
                                            dialog={model.dialog}
                                        />
                                    )}
                                </div>

                                {!!model.personaData && (
                                    <PersonalDataView
                                        model={model.personaData}
                                    />
                                )}
                                {!!model.schoolInfo && (
                                    <SchoolInfoView model={model.schoolInfo} />
                                )}
                                {!!model.employeeInfo && (
                                    <EmployeeDataView
                                        model={model.employeeInfo}
                                    />
                                )}
                                {!!model.professionalInfo && (
                                    <ProfessionalInfoView
                                        model={model.professionalInfo}
                                    />
                                )}
                            </>
                        )}
                    </PageWrapper>
                </PageLoader>
            </ContentView>
        </div>
    );
});

export default MembershipView;
