import * as React from 'react';
import style from './ActionPhaseFirst.module.scss';
import classNames from 'classnames';
import { Radio } from 'antd';
import { ReactComponent as PrivateNote } from 'common/icons/note_icn.svg';
import { PhaseOneEvaluation } from 'model/Externals';
import { RadioChangeEvent } from 'antd/lib/radio';
import { observer } from 'mobx-react';
import {useTranslation} from "react-i18next";

interface ActionPhaseFirstProps {
    evaluationValue: PhaseOneEvaluation | undefined;
    onEvaluationValueChange?: (value: PhaseOneEvaluation) => void;
    isEditable: boolean;
    privateNoteActive?: boolean;
    onSetPrivateNoteState?: () => void;
    isPrivateNote?: boolean;
    isList?: boolean;
}

const ActionPhaseFirst: React.FC<ActionPhaseFirstProps> = observer(
    ({
        evaluationValue,
        onEvaluationValueChange,
        isEditable,
        privateNoteActive,
        isPrivateNote,
        onSetPrivateNoteState,
        isList
    }) => {
        const { t } = useTranslation('judging');
        const notesStyle = classNames(style.judgeIcn, {
            [style.judgeIcnActive]: privateNoteActive
        });
        const containerStyle = classNames(style.judging, {
            [style.list]: isList
        });

        return (
            <div className={containerStyle}>
                <Radio.Group
                    disabled={!isEditable}
                    className={style.judgeBtnGroup}
                    value={evaluationValue}
                    onChange={(e: RadioChangeEvent) =>
                        onEvaluationValueChange && onEvaluationValueChange(
                            e.target.value as PhaseOneEvaluation
                        )
                    }>
                    <Radio.Button
                        value={PhaseOneEvaluation.zero}
                        className={style.judgeBtn}>
                        0
                    </Radio.Button>
                    <Radio.Button
                        value={PhaseOneEvaluation.one}
                        className={style.judgeBtn}>
                        1
                    </Radio.Button>
                    <Radio.Button
                        value={PhaseOneEvaluation.two}
                        className={style.judgeBtn}>
                        2
                    </Radio.Button>
                    <Radio.Button
                        value={PhaseOneEvaluation.four}
                        className={style.judgeBtn}>
                        4
                    </Radio.Button>
                    <Radio.Button
                        value={PhaseOneEvaluation.excluded}
                        className={style.judgeBtn}>
                        {t('inhabil')}
                    </Radio.Button>
                </Radio.Group>
                {onSetPrivateNoteState && isPrivateNote && (
                    <PrivateNote
                        className={notesStyle}
                        onClick={onSetPrivateNoteState}
                    />
                )}
            </div>
        );
    }
);

export default ActionPhaseFirst;
