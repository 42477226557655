import * as React from 'react';
import style from '../Membership.module.scss';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import SingleUsersAddressDropdown from 'common/components/SignleUserAddressDropdown/SingleUsersAddressDropdown';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import EmployeeData from 'web/screen/Membership/EmployeeData/EmployeeData';
import AntTextInput from 'common/components/antHelpers/AntTextInput';

interface FormProps {
    model: EmployeeData;
}

const Form: React.FC<FormProps> = observer(({ model }) => {
    const { t } = useTranslation('membership');
    if (!model.form) {
        return null;
    }
    return (
        <AntForm form={model.form} layout={'vertical'}>
            {(fields) => {
                return (
                    <div className={style.personalData}>
                        <AntField
                            field={fields.employerPartyId}
                            label={t('selectCompanyAddress')}>
                            {(props) => {
                                return (
                                    <SingleUsersAddressDropdown
                                        model={model.userAddresses}
                                        type={'company'}
                                        witheClear={true}
                                    />
                                );
                            }}
                        </AntField>
                        {!!fields.workPosition && (
                            <AntField
                                field={fields.workPosition}
                                label={t('workPosition')}
                                description={
                                    'Dersom du ikke har en offisiell stillingstittel, oppgi en beskrivelse av hva du arbeider\n' +
                                    'med, f.eks illustratør, grafisk designer, e.l.'
                                }>
                                {(props) => <AntTextInput {...props} />}
                            </AntField>
                        )}
                    </div>
                );
            }}
        </AntForm>
    );
});

export default Form;
