import { IPage, navigation, Page } from 'react-mvvm';
import LoginPage from 'web/screen/LoginPage/LoginPage';
import loader from 'react-mvvm/loading/loader';
import hello from 'hellojs';
import { computed, observable } from 'mobx';
import UserInfo from './UserInfo/UserInfo';
import Membership from '../Membership/Membership';
import Invoices from 'web/screen/Invoices/Invoices';
import UserInfoEditDialog from 'web/screen/HomePage/UserInfoEditDialog/UserInfoEditDialog';
import { getUserInfo } from 'model/Api/Individuals/GetUserInfoRequest';
import { UserInfoDto } from 'model/Api/Individuals/Model/UserInfoDto';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import CompetitionsListPage from 'web/screen/CompetitionsListPage/CompetitionsListPage';
import { HeaderBreadcrumbsData } from 'web/components/HeaderBreadcrumbs/HeaderBreadcrumbs';
import JudgingCompetitions from 'web/screen/JudgingCompetitions/JudgingCompetitions';
import Submissions from 'web/screen/Submissions/Submissions';
import { validateToken } from 'model/Api/Account/ValidateTokenRequest';
import DeleteAccountDialog from 'web/screen/HomePage/UserInfo/DeleteAccountDialog/DeleteAccountDialog';
import AddressDialog from "../../../common/components/AddressDialog/AddressDialog";

class HomePage extends Page<
    UserInfoEditDialog | AddressDialog | ConfirmationModal | DeleteAccountDialog
> {
    @observable userInfo: UserInfo;
    @observable userDto: UserInfoDto = {
        family: '',
        given: '',
        email: '',
        phoneNumber: '',
        isJudge: false,
        isMember: undefined,
        id: 0,
        version: 0,
        deletionRequested: false
    };
    membershipPage: Membership;
    submissions: Submissions;
    competitionList: CompetitionsListPage;

    constructor() {
        super();
        this.userInfo = new UserInfo(this);
        this.membershipPage = new Membership(this);
        this.submissions = new Submissions();
        this.competitionList = new CompetitionsListPage();
    }

    navigation = navigation(this, {
        home: undefined,
        loginPage: LoginPage
    });

    api = loader({
        getUserInfo: async () => await getUserInfo({})
    });

    apiToken = loader({
        validateToken: async (accessToken: string) =>
            await validateToken({ accessToken })
    });

    signOutClicked = () => {
        hello.logout('customJWT');
        hello.utils.store('customJWT', '');
        window.location.href = `/login?redirect_uri=${encodeURIComponent(
            window.location.href
        )}`;
    };

    showMembershipPage = async () => {
        await this.membershipPage.removeChildPage();
        return this.showChildPage(this.membershipPage);
    };

    showMembershipApplication = async () => {
        await this.showMembershipPage();
        await this.membershipPage.showApplicationPage();
    };

    showInvoicesPage = async () => {
        return this.showChildPage(new Invoices());
    };

    showSubmissionPage = async () => {
        await this.submissions.removeChildPage();
        return this.showChildPage(this.submissions);
    };

    showCompetitionsListPage = async () => {
        return this.showChildPage(this.competitionList);
    };

    showJudgePage = async () => {
        return this.showChildPage(new JudgingCompetitions());
    };

    showSelectedCompetitions = async (id: number) => {
        await this.showCompetitionsListPage();
        return this.competitionList.showCompetitionPage(id);
    };

    @computed get breadcrumbs(): HeaderBreadcrumbsData[] | undefined {
        if (
            !!this.childPage &&
            (this.childPage instanceof CompetitionsListPage ||
                this.childPage instanceof JudgingCompetitions)
        ) {
            return this.childPage.breadcrumbs;
        }
        return undefined;
    }

    async show<T extends IPage>(model: T) {
        await this.showChildPage(model);
        return model;
    }

    protected async onLeafFocus() {
        if (!this.childPage) {
            const ct = hello.utils.store('customJWT');
            if (!ct) {
                this.navigation.items.loginPage.activate();
                return;
            }

            this.userDto = await this.api.getUserInfo();
            await this.userInfo.init();
            await this.userInfo.userAddress.init();
            await this.competitionList.competitions.getList();
        }
    }
}

export default HomePage;
