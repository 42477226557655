import { FormField } from 'react-mvvm/forms';
import {
    RcFile,
    UploadFile
} from 'antd/lib/upload/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import loader from 'react-mvvm/loading/loader';
import { notification } from 'antd';
import { api as axios } from 'web/api/config';
import { observable } from 'mobx';
import { MembershipApplicationAttachmentDto } from 'model/Api/Members/Model/MembershipApplicationAttachmentDto';
import { deleteDocumentAttachment } from 'model/Api/Individuals/DeleteDocumentAttachmentRequest';

class FileUpload {
    @observable filesField: FormField<
        MembershipApplicationAttachmentDto[] | undefined
    >;

    constructor(
        files: FormField<MembershipApplicationAttachmentDto[] | undefined>,
        private membershipApplicationId: number
    ) {
        this.filesField = files;
    }

    api = loader({
        removeAttachment: async (uid: number) =>
            deleteDocumentAttachment({ documentAttachmentRef: uid })
    });

    onRemoveAttachment = async (uid: number, name: string | undefined) => {
        if (!isNaN(Number(uid))) {
            const remove = await this.api.removeAttachment(uid);
            if (remove) {
                notification.info({
                    message: 'Delete attachment',
                    description: !name
                        ? ''
                        : `File: ${name} have been successful removed`,
                    duration: 2000
                });
            }
        }
    };

    onRemoveFile(
        formField: FormField<MembershipApplicationAttachmentDto[] | undefined>
    ): (file: UploadFile<any>) => boolean {
        return (file: UploadFile<any>) => {
            if (!formField.value) {
                return false;
            }
            const tempUid = file.response ? file.response.uid : file.uid;

            formField.value = formField.value.reduce<
                MembershipApplicationAttachmentDto[]
            >((prev, current) => {
                if (current.uid !== tempUid) {
                    prev.push(current);
                }
                return prev;
            }, []);
            this.onRemoveAttachment(tempUid, file.fileName);

            return true;
        };
    }

    getFileUploadRequest(
        formField: FormField<MembershipApplicationAttachmentDto[] | undefined>
    ): (options: RcCustomRequestOptions) => void {
        return async (options: any) => {
            const { onSuccess, onError, file, onProgress } = options;

            let fieldList: MembershipApplicationAttachmentDto[] = [];
            if (!!formField.value) {
                fieldList = [...formField.value];
            }
            const formData = new FormData();
            formData.append('createDocumentAttachment.file', file, (file).name);
            try {
                const attachmentResponse = await axios().post<
                    MembershipApplicationAttachmentDto
                >(
                    `/CreateDocumentAttachment?membershipApplicationRef=${this.membershipApplicationId.toString()}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: (e) => {
                            onProgress(
                                { percent: (e.loaded / e.total) * 100 },
                                file
                            );
                        }
                    }
                );
                fieldList.push(attachmentResponse);
                formField.value = fieldList;
                onSuccess({ ...attachmentResponse }, file);
            } catch (e) {
                onError(e);
            }
        };
    }

    validateFileBeforeUpload = (file: RcFile): boolean => {
        const isLt500M = file.size < 524288000;
        if (!isLt500M) {
            notification.error({
                message: 'Uploading Error',
                description:
                    'Attachment size exceeds the allowable limit (500MB)',
                duration: 0
            });
            (file as any).flag = true;
            return false;
        }

        return true;
    };
}

export default FileUpload;
