import { History } from 'history';
import { IRoutedPage, IHomeRoute } from './index';
import { RouteBinding } from './RouteBinding';
import { RouteDefinitionBase } from './RouteDefinition';

export class HomeRouteDefinition<
    TModel extends IRoutedPage & { activate: () => void }
> extends RouteDefinitionBase<TModel, {}, {}> implements IHomeRoute<TModel> {
    model: TModel | null = null;

    history?: History<any>;

    constructor(
        public path: string,
        public modelFactory: () => Promise<TModel> | TModel
    ) {
        super();
    }

    historyPush(path: string, state?: object | null): void {
        if (this.history) {
            this.history.push(path, state);
        }
    }

    historyReplace(path: string): void {
        if (this.history) {
            this.history.replace(path);
        }
    }

    getHistoryState(): object | undefined {
        if (this.history) {
            return this.history.location.state;
        }

        return undefined;
    }

    async bind(history: History<any>) {
        this.history = history;

        let updatingLocation = false;
        let updatingBindings = false;

        const updateLocation = () => {
            if (updatingBindings) return;

            let newLocation = binding.getLocation();
            if (!newLocation.path.startsWith('/'))
                newLocation.path = '/' + newLocation.path;

            if (
                history.location.pathname.toLowerCase() !=
                newLocation.path.toLowerCase()
            ) {
                updatingLocation = true;
                try {
                    history.push({
                        pathname: newLocation.path,
                        hash: newLocation.hash
                    });
                } finally {
                    updatingLocation = false;
                }
            } else if (history.location.hash != newLocation.hash) {
                updatingLocation = true;
                try {
                    history.replace({
                        pathname: newLocation.path,
                        hash: newLocation.hash
                    });
                } finally {
                    updatingLocation = false;
                }
            }
        };

        const model = await this.modelFactory();
        const childBinding = await this.bindFirstMatchingChildToPath(
            updateLocation,
            model,
            history.location.pathname.substring(this.path.length),
            history.location.hash
        );
        const binding = new RouteBinding<TModel>(
            updateLocation,
            this,
            this.path,
            model,
            childBinding
        );

        const unlisten = history.listen(async (location, action) => {
            if (updatingLocation) return;
            updatingBindings = true;
            try {
                await binding.update(
                    location.pathname.substring(this.path.length),
                    location.hash.substr(1)
                );
            } finally {
                updatingBindings = false;
            }
        });

        model.activate();

        return {
            page: model,
            destroy: () => {
                unlisten();
                binding.destroy();
            }
        };
    }

    getPath(params: {}) {
        return this.path;
    }
}
