import React, {useState} from "react";
import style from './PdfLoader.module.scss';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {Spin} from "antd";
import LazyLoad from 'react-lazyload';

export const VIEWER_MAX_WIDTH = 950;


interface PdfLoaderProps {
    url: string;
    width?: number;
}

const PdfLoader: React.FC<PdfLoaderProps> = ({url, width}) => {
    const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }


    return <LazyLoad height={300} offset={100}>
        <div className={style.Loader__container__load}>
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}
                      loading={<div className={style.contentLoader}>
                          <Spin size={"default"}/>
                          <p>Pdf file is loading</p>
                      </div>}>
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={!width ? VIEWER_MAX_WIDTH : width}/>
                ))}
            </Document>
        </div>
    </LazyLoad>;
};

export default PdfLoader;