import * as React from 'react';
import ProfessionalInfo from './ProfessionalInfo';
import { observer } from 'mobx-react';
import MyInterests from './MyInterestsView';

interface ProfessionalInfoViewProps {
    model: ProfessionalInfo;
}

const ProfessionalInfoView: React.FC<ProfessionalInfoViewProps> = observer(
    ({ model }) => {
        return <MyInterests model={model} />;
    }
);

export default ProfessionalInfoView;
