import * as React from 'react';
import style from './PaymentMethodSelector.module.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PaymentMethodSelector from './PaymentMethodSelector';
import PaymentCheckbox from 'web/components/PaymentCheckbox';
import { PaymentMethod } from 'model/Externals';

interface PaymentMethodSelectorViewProps {
    model: PaymentMethodSelector;
    price: number | undefined;
    invoicePrice: number | undefined;
}

const PaymentMethodSelectorView: React.FC<PaymentMethodSelectorViewProps> = observer(
    ({ model, price, invoicePrice }) => {
        const { t } = useTranslation('competitions');
        return (
            <div>
                <div className={style.content}>
                    <PaymentCheckbox
                        selected={model.selected === PaymentMethod.bambora}
                        onSelect={() =>
                            model.onPaymentSelect(PaymentMethod.bambora)
                        }
                        title={t('payNow')}
                        price={price !== undefined ? `${price} NOK` : undefined}
                        dsc={t('payNow')}
                        isDisabled={
                            process.env.REACT_APP_BAMBORA_OFF === 'true'
                        }
                    />

                    <PaymentCheckbox
                        selected={model.selected === PaymentMethod.invoice}
                        onSelect={() =>
                            model.onPaymentSelect(PaymentMethod.invoice)
                        }
                        title={t('payWithInvoice')}
                        price={
                            price !== undefined && invoicePrice !== undefined
                                ? `${price + invoicePrice} NOK`
                                : undefined
                        }
                        dsc={
                            invoicePrice !== undefined && invoicePrice !== 0
                                ? `kr. ${price} + kr. ${invoicePrice} NOK ${t(
                                      'invoice'
                                  )}`
                                : 'Få tilsendt faktura per epost, og betal innen 14 dager.'
                        }
                    />
                </div>
            </div>
        );
    }
);

export default PaymentMethodSelectorView;
