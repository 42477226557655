import { action, computed, observable } from 'mobx';
import Membership from 'web/screen/Membership/Membership';
import {
    Form,
    FormField,
    NullValidator,
    RequiredFieldValidator
} from 'react-mvvm/forms';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import { MemberDto } from 'model/Api/Members/Model/MemberDto';

class EmployeeData {
    parent: Membership;
    form: Form<any> | undefined;
    @observable isEdit: boolean;
    @observable userAddresses: UserAddresses | undefined;

    constructor(parent: Membership) {
        this.parent = parent;
        this.isEdit = false;
    }

    @computed get member(): MemberDto | undefined {
        return this.parent.member;
    }
    
    onInit = async () => {
        this.userAddresses = new UserAddresses(
            this.parent,
            true,
            true,
            this.onSelectAddress,
            true
        );
        await this.userAddresses.init();

        if (!!this.member) {
            this.form = await this.buildForm(this.member);
        }
    };

    buildForm = async (member: MemberDto) => {
        if (!!member.employerPartyId && !!this.userAddresses) {
            this.userAddresses.onSelectAddress(member.employerPartyId);
        }

        return new Form({
            employerPartyId: new FormField(
                member.employerPartyId,
                NullValidator
            ),
            workPosition: new FormField(
                member.workPosition,
                RequiredFieldValidator
            )
        });
    };

    onSelectAddress = () => {
        if (!!this.userAddresses && !!this.form) {
            const { doubleSelectedParty } = this.userAddresses;
            this.form.fields.employerPartyId.value =
                doubleSelectedParty.company;
        }
    };

    onSave = async () => {
        if (!!this.form && this.member) {
             const { fields } = this.form;
             await this.parent.api.updateMemberEmploymentData(fields.workPosition.value, fields.employerPartyId.value);
             this.parent.member = await this.parent.api.getMember();
             this.parent.userParties = await this.parent.api.getUserParty();
             this.isEdit = false;
        }
    };

    @action
    onEdit = () => {
        this.isEdit = true;
    };

    @action
    onCancel = async () => {
        this.isEdit = false;
        if (!!this.member) {
            this.form = await this.buildForm(this.member);
        }
    };
}


export default EmployeeData;
