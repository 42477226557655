import moment from 'moment';

import * as React from 'react';
import { CompetitionListItemDto } from 'model/Api/Competitions/Model/CompetitionListItemDto';
import Arrow from 'web/components/Arrow';
import { observer } from 'mobx-react';
import style from './List.module.scss';
import { Empty } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export enum CompetitionListItemType {
    future = 'future',
    current = 'current',
    past = 'past'
}

interface ListProps {
    title: string;
    competitions: CompetitionListItemDto[];
    type: CompetitionListItemType;
    onDetails: (id: number) => void;
}

const List: React.FC<ListProps> = observer(
    ({ title, competitions, onDetails, type }) => {
        const { t } = useTranslation('competitions');
        const titleStyle = classNames(style.rowTitle, {
            [style.rowTitlePast]: type === CompetitionListItemType.past,
            [style.rowTitleFuture]: type === CompetitionListItemType.future
        });
        return (
            <ul className={style.ul}>
                <li className={titleStyle}>{title}</li>
                {!competitions.length && (
                    <li className={style.empty}>
                        <Empty description={t('emptyList', { type: type })} />
                    </li>
                )}
                {competitions.map((competition) => (
                    <li
                        className={style.row}
                        key={competition.id}
                        onClick={() => onDetails(competition.id)}>
                        <div className={style.content}>
                            <div className={style.icon}>
                                <Arrow
                                    direction={'right'}
                                    customStyle={style.arrowIcon}
                                />
                            </div>
                            <span className={style.title}>
                                {competition.name}
                            </span>
                            <span className={style.date}>
                                {`${moment(
                                    competition.submissionsDeadlineStart
                                ).format('DD. MMM YYYY')} - ${moment(
                                    competition.submissionsDeadlineEnd
                                ).format('DD. MMM YYYY')}`}
                            </span>
                            {/*<span className={style.dsc}>
                                {competition.description}
                            </span>*/}
                        </div>
                    </li>
                ))}
            </ul>
        );
    }
);

export default List;
