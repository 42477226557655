import * as React from 'react';
import style from '../Membership.module.scss';
import PersonalData from 'web/screen/Membership/PersonalData/PersonalData';
import AntForm from 'common/components/antHelpers/AntForm';
import BirthDatePickerView from 'common/components/BirthDatePicker/BirthDatePickerView';
import BirthDatePicker from 'common/components/BirthDatePicker/BirthDatePicker';
import AntField from 'common/components/antHelpers/AntField';
import SingleUsersAddressDropdown from 'common/components/SignleUserAddressDropdown/SingleUsersAddressDropdown';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import GenderField from 'common/components/antHelpers/GenderField';

interface FormProps {
    model: PersonalData;
}

const Form: React.FC<FormProps> = observer(({ model }) => {
    const { t } = useTranslation('membership');
    if (!model.form) {
        return null;
    }
    return (
        <AntForm form={model.form} layout={'vertical'}>
            {(fields) => {
                return (
                    <div className={style.personalData}>
                        <BirthDatePickerView
                            model={fields.birthDate as BirthDatePicker}
                        />
                        {!!fields.gender && (
                            <GenderField field={fields.gender} />
                        )}
                        <AntField
                            field={fields.privatePartyId}
                            label={t('selectPrivateAddress')}>
                            {(props) => {
                                return (
                                    <SingleUsersAddressDropdown
                                        model={model.userAddresses}
                                        type={'private'}
                                    />
                                );
                            }}
                        </AntField>
                    </div>
                );
            }}
        </AntForm>
    );
});

export default Form;
