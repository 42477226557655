import * as React from 'react';
import { Button } from 'antd';
import style from './BackButton.module.scss';
import { useTranslation } from 'react-i18next';

interface BackButtonProps {
    onClick: () => void;
    title?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick, title }) => {
    const { t } = useTranslation('common');
    return (
        <Button
            size={'small'}
            type={'text'}
            className={style.btn}
            onClick={onClick}>
            {!title ? t('back') : title}
        </Button>
    );
};

export default BackButton;
