import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {MembershipApplicationDto} from "./Model/MembershipApplicationDto"


export interface CreateOrUpdateMembershipApplicationRequest {
    membershipApplication?: MembershipApplicationDto
}
export const createOrUpdateMembershipApplication = (createOrUpdateMembershipApplicationRequest: CreateOrUpdateMembershipApplicationRequest): Promise<MembershipApplicationDto> => {
    return send("CreateOrUpdateMembershipApplication", createOrUpdateMembershipApplicationRequest)
}
