import { Page } from 'react-mvvm';
import { action, computed, observable } from 'mobx';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import loader from 'react-mvvm/loading/loader';
import { homeRoute } from 'web/routes';
import PaymentMethodSelector from 'web/components/PaymentMethodSelector/PaymentMethodSelector';
import { PaymentMethod } from 'model/Externals';
import { payForCompetitionEntriesWithInvoice } from "../../../model/Api/Payments/PayForCompetitionEntriesWithInvoiceRequest";
import { payForCompetitionEntriesOnline } from "../../../model/Api/Payments/PayForCompetitionEntriesOnlineRequest";
import Handelkurv from "web/screen/Handelkurv/Handelkurv";
import ConfirmationModal from "common/components/ConfirmationModal/ConfirmationModal";

class SubmissionsMyPagePayment extends Page<ConfirmationModal> {
    parent: Handelkurv;
    @observable userAddresses: UserAddresses;
    @observable paymentMethod: PaymentMethodSelector;
    @observable isPaymentTermsAccepted: boolean = false;

    constructor(parent: Handelkurv) {
        super();
        this.parent = parent;
        this.userAddresses = new UserAddresses(this, true, true);
        this.paymentMethod = new PaymentMethodSelector();
    }

    api = loader({
        invoicePayment: async (
          competitionEntriesRef: number[],
          payingPartyRef: number,
          isPaymentTermsAccepted: boolean
        ) => await payForCompetitionEntriesWithInvoice({
            competitionEntriesRef,
            payingPartyRef,
            isPaymentTermsAccepted
        }),
        createTransaction: async (
          competitionEntriesRef: number[],
          payingPartyRef: number,
          isPaymentTermsAccepted: boolean
        ) => await payForCompetitionEntriesOnline({
            competitionEntriesRef,
            payingPartyRef,
            isPaymentTermsAccepted
        })
    });

    protected async onActivated(): Promise<any> {
        if (!this.parent.selectedEntries.length) {
            await this.parent.onRemoveChildPage();
            return;
        }
        await this.userAddresses.init();
        window.scrollTo({ top: 0 });
    }

    @action
    onAcceptTerms = (value: boolean) => {
        this.isPaymentTermsAccepted = value;
    };

    @computed get selectedEntries() {
        return this.parent.selectedEntries;
    }

    @computed get totalPrice() {
        let price = 0;
        let invoiceFeePrice = 0;
        this.selectedEntries.forEach((entry) => {
            if (!!entry) {
                price = price + entry.product.priceIncVat;
                invoiceFeePrice =
                    invoiceFeePrice + entry.invoiceFeeProduct.priceIncVat;
            }
        });

        return { price, invoiceFeePrice };
    }
    onPay = async () => {
        if (!this.userAddresses.selectedParty || !this.isPaymentTermsAccepted) {
            return;
        }
        if (this.paymentMethod.selected === PaymentMethod.bambora) {
            const result = await this.api.createTransaction(
                this.parent.selectedEntries.map(e => e.id),
                this.userAddresses.selectedParty,
                this.isPaymentTermsAccepted
            );

            window.location.href = result.url;
        } else if (this.paymentMethod.selected === PaymentMethod.invoice) {
            const result = await this.api.invoicePayment(
                this.parent.selectedEntries.map(e => e.id),
                this.userAddresses.selectedParty,
                this.isPaymentTermsAccepted
            );
            homeRoute.historyPush(
                `/invoicepaymentstatus?status=${result.status}&orderId=${result.orderId}`
            );
        }
    };
    
    onPaymentClick = async () => {
        await this.showModal<ConfirmationModal, boolean>(
            (close) => new ConfirmationModal(
                async () => {
                    await this.onPay();
                },
                close
            )
        )
    }

    onRemoveSelectedEntry = (id: number) => this.parent.onRemoveSelectedEntry(id)

    onBack = async () => this.parent.onRemoveChildPage();
}

export default SubmissionsMyPagePayment;
