import { Page } from 'react-mvvm';
import loader from 'react-mvvm/loading/loader';
import { PaymentListItemDto } from 'model/Api/Payments/Model/PaymentListItemDto';
import { List } from 'react-mvvm/lists';
import { getPayments } from 'model/Api/Payments/GetPaymentsRequest';
import {getDownloadInvoiceToken} from "model/Api/Payments/GetDownloadInvoiceTokenRequest";
import {action, observable} from "mobx";


async function delay(ms: number) {
    return new Promise(r => setTimeout(r, ms));
}

class Invoices extends Page {
    paymentsList: List<PaymentListItemDto> = new List();

    @observable token: string = '';
    @observable isLoading: boolean = false;
    
    api = loader({
        getPayments: async () => await getPayments({}),
        getToken: async() => await getDownloadInvoiceToken({})
    });
    
    protected async onActivated(): Promise<any> {
        this.token = (await this.api.getToken()).token;
        const payment: PaymentListItemDto[] = await this.api.getPayments();
        payment.map((item) => this.paymentsList.push(item));
    }

    @action
    onDownloadClicked = async () : Promise<void> => {
        this.isLoading = true;
        await delay(5000);
        this.isLoading = false;
    }
}

export default Invoices;
