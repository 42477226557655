import * as React from "react";
import style from './ProgressBar.module.scss';

interface ProgressBarProps {
    percentage: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({percentage}) => {
    return <div className={style.progress} style={{width: `${percentage}%`}}>
        {`${percentage}%`}
    </div>
};

export default ProgressBar