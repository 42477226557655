import * as React from 'react';
import PrivateParty from './PrivateParty';
import style from './PrivateParty.module.scss';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import Flex from 'common/components/Flex';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import AddressFieldView from '../AddressField/AddressFieldView';
import AddressField from '../AddressField/AddressField';

interface PrivatePartyViewProps {
    model: PrivateParty;
}

const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
};

const PrivatePartyView: React.FC<PrivatePartyViewProps> = ({ model }) => {
    const { t } = useTranslation('common');
    return (
        <AntForm form={model.partyForm} {...formLayout}>
            {(fields) => (
                <>
                    <div className={style.fields}>
                        <AntField
                            field={fields.careOf}
                            label={t('common:name')}>
                            {(props) => <AntTextInput {...props} />}
                        </AntField>
                        <AddressFieldView
                            model={fields.address as AddressField}
                        />
                    </div>
                    <Flex justifyContent={'flex-end'}>
                        <Button
                            className={style.btn}
                            type={'primary'}
                            onClick={() => model.save()}
                            disabled={model.partyForm.isPristine}>
                            {t('common:save')}
                        </Button>
                    </Flex>
                </>
            )}
        </AntForm>
    );
};

export default PrivatePartyView;
