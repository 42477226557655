import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface SubmitCompetitionEntryRequest {
    competitionEntryId: number
}
export const submitCompetitionEntry = (submitCompetitionEntryRequest: SubmitCompetitionEntryRequest): Promise<any> => {
    return send("SubmitCompetitionEntry", submitCompetitionEntryRequest)
}
