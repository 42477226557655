import {
    Form,
    FormField,
    FormFieldValidator,
    FormState
} from 'react-mvvm/forms';
import { computed, observable } from 'mobx';
import moment, { Moment } from 'moment';

class BirthDatePicker extends FormField<string | undefined> {
    @observable dateForm: Form<any>;
    @observable errors: string[] = [];

    monthList: { index: number; name: string }[];

    private isErrorState = false;
    private isValidatingProcess = false;
    fieldValidator: FormFieldValidator<string>;

    constructor(
        value: string | undefined,
        validator: FormFieldValidator<string>
    ) {
        super(value, validator);
        this.monthList = this.getMonthList();

        this.fieldValidator = validator;

        const data = moment(value);
        
        this.dateForm = new Form<any>({
            day: new FormField<string>(
                !!data && !!value ? data.date().toString() : undefined,
                this.fieldValidator
            ),
            month: new FormField<string>(
                !!data && !!value ? data.month().toString() : undefined,
                this.fieldValidator,
                undefined,
                (value) => {
                    if (
                        !this.days.find(
                            (d) => d === this.dateForm.fields.day.value
                        )
                    ) {
                        this.dateForm.fields.day.value = undefined;
                    }
                }
            ),
            year: new FormField<Moment>(
                !!data && !!value
                    ? moment(new Date()).set('year', data.year())
                    : undefined,
                this.fieldValidator,
                undefined,
                (value) => {
                    if (
                        !this.days.find(
                            (d) => d === this.dateForm.fields.day.value
                        )
                    ) {
                        this.dateForm.fields.day.value = undefined;
                    }
                }
            )
        });
        this.value = value;
    }

    @computed get value() {
        if (!this.dateForm) {
            return undefined;
        }
        const { fields } = this.dateForm;
        if (!fields.day.value || !fields.month.value || !fields.year.value) {
            return undefined;
        }
        const birthDate = moment(new Date());
        
        birthDate
            .year(fields.year.value.year())
            .month(fields.month.value - 1)
            .date(fields.day.value);

        return birthDate.toISOString();
    }
    set value(dataValue) {
        if (!dataValue || !this.dateForm) {
            return;
        }
        const data = moment(dataValue);
        const { fields } = this.dateForm;
        
        if (!!data) {
            fields.day.value = data.date();
            fields.month.value = data.month() + 1;
            fields.year.value = moment(new Date()).set('year', data.year());
        }
    }

    @computed get state(): FormState {
        if (this.isErrorState) {
            return FormState.Invalid;
        }

        if (this.isValidatingProcess) {
            return FormState.Pending;
        }

        return FormState.Valid;
    }
    @computed get isPristine() {
        return this.dateForm.isPristine;
    }

    async validate(): Promise<true | readonly string[]> {
        let isValid = true;
        this.errors = [];
        this.isErrorState = false;
        this.isValidatingProcess = true;

        if (!(await this.dateForm.validate())) {
            for (const field of Object.values(this.dateForm.fields)) {
                if (field.errors.length > 0) {
                    this.errors = [...this.errors, ...field.errors];
                }
            }
            isValid = false;
            this.isErrorState = true;
        }

        this.isValidatingProcess = false;
        return isValid || [];
    }

    getMonthList = (): { index: number; name: string }[] => {
        const date = moment(new Date());
        return Array.apply(0, Array(12)).map((i, index) => {
            return {
                index: index + 1,
                name: date.month(index).format('MMMM')
            };
        });
    };

    @computed get days() {
        let date = moment(new Date());
        if (!!this.dateForm.fields.month.value) {
            date.set('month', this.dateForm.fields.month.value - 1);
        }

        if (!!this.dateForm.fields.year.value) {
            date.set('year', this.dateForm.fields.year.value.year());
        }
        return [...Array(date.daysInMonth())].map((e, i) => i + 1);
    }
}

export default BirthDatePicker;
