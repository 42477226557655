import {
    Page,
    bindableForm,
    asyncCommand,
    RequiredFieldValidator
} from 'react-mvvm';
import { passwordResetByEmail } from 'model/Api/Account/PasswordResetByEmailRequest';
import { observable } from 'mobx';
import LoginPage from 'web/screen/LoginPage/LoginPage';
import { homeRoute } from 'web/routes';
import { ResetPasswordResult } from 'model/Api/Account/Model/ResetPasswordResult';
import { passwordResetByPhone } from 'model/Api/Account/PasswordResetByPhoneRequest';
import i18next from 'i18next';

class AskForPasswordResetPage extends Page {
    @observable.ref passwordResetModel: PasswordResetFormModel;
    @observable message: string;
    @observable isLoading: boolean;
    @observable errorMsg: string;

    constructor(private parent: LoginPage) {
        super();
        this.message = '';
        this.errorMsg = '';
        this.isLoading = false;
        this.passwordResetModel = {
            login: ''
        };
    }

    askForPasswordResetForm = bindableForm<PasswordResetFormModel>()
        .addField('login', { validator: RequiredFieldValidator })
        .bindTo(() => this.passwordResetModel);

    onSubmit = asyncCommand(
        async () => {
            if (!(await this.askForPasswordResetForm.validate())) return;
            this.message = '';
            this.errorMsg = '';
            this.isLoading = true;

            this.askForPasswordResetForm.commit();

            if (this.passwordResetModel.login.indexOf('@') > -1) {
                try {
                    await passwordResetByEmail({
                        email: this.passwordResetModel.login
                    });
                    this.message = i18next.t('login:emailSentTo', {
                        email: this.passwordResetModel.login
                    });
                    this.isLoading = false;
                } catch (e) {
                    this.isLoading = false;
                    if (
                        e.response.data.errors &&
                        e.response.data.errors.length != 0
                    ) {
                        this.errorMsg = e.response.data.errors.join(', ');
                    }
                }
            } else {
                try {
                    const result: ResetPasswordResult = await passwordResetByPhone(
                        {
                            phoneNumber: this.passwordResetModel.login
                        }
                    );
                    window.location.href = `/setnewpasswordbyphone?userId=${result.userId}`;
                    // homeRoute.historyPush(
                    //     `/setnewpasswordbyphone?userId=${result.userId}`
                    // );
                } catch (e) {
                    this.isLoading = false;
                    if (
                        e.response.data.errors &&
                        e.response.data.errors.length != 0
                    ) {
                        this.errorMsg = e.response.data.errors.join(', ');
                    }
                }
            }
        },
        () => this.askForPasswordResetForm.isDirty
    );

    goBack = async () => {
        await this.parent.removeChildPage();
    };
}

class PasswordResetFormModel {
    login: string = '';
}

export default AskForPasswordResetPage;
