import * as React from "react";
import { Typography } from "antd";

interface TextProps {
    value: string
}

const Text: React.FC<TextProps> = ({value}) => {
    return <Typography.Text>{value}</Typography.Text>
}

export default Text;