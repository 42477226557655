import * as React from 'react';
import style from './PageLoader.module.scss';
import { Spin } from 'antd';
import { ILoader } from 'react-mvvm/loading/loader';
import { observer } from 'mobx-react';

interface PageLoaderProps {
    loader: ILoader;
}

const PageLoader: React.FC<PageLoaderProps> = observer(
    ({ loader, children }) => {
        if (loader.isLoading) {
            return (
                <div className={style.container}>
                    <Spin size={'large'} />
                </div>
            );
        }

        return <>{children}</>;
    }
);

export default PageLoader;
