import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {SubscriptionType} from "../../Externals"

import {InvoicePaymentDto} from "../Payments/Model/InvoicePaymentDto"

export interface ReapplyForMembershipAndPayWithInvoiceRequest {
    subscriptionType: SubscriptionType
    partyId: number
}
export const reapplyForMembershipAndPayWithInvoice = (reapplyForMembershipAndPayWithInvoiceRequest: ReapplyForMembershipAndPayWithInvoiceRequest): Promise<InvoicePaymentDto> => {
    return send("ReapplyForMembershipAndPayWithInvoice", reapplyForMembershipAndPayWithInvoiceRequest)
}
