import { default as React, ReactNode } from "react";
import { FormFields } from "react-mvvm";
import { Form as TheForm } from "antd";
import { observer } from "mobx-react";
import { FormProps } from "antd/lib/form";

interface AntFormParams<T> {
    form: { fields: FormFields<T> };
    children: (fields: FormFields<T>) => ReactNode | ReactNode[];
}

function antFormImpl<T>(props : AntFormParams<T> & Omit<FormProps, "form" | "children">) {
    return (
        <TheForm {...props} form={undefined}>
            { props.children(props.form.fields) }
        </TheForm>);
}

const AntForm = observer(antFormImpl);
export default AntForm;