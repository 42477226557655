import * as React from 'react';
import { CompetitionStatus } from 'model/Externals';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import SubmissionsHelpText from 'web/components/SubmissionsHelpText';
import style from './SubmissionsNextBtn.module.scss';
import classNames from 'classnames';
import { ReactElement } from 'react';
import SaveDraftButton from '../SaveDraftButton';

interface SubmissionsNextBtnProps {
    onNextClick: () => void;
    onSaveDraftClick?: () => void;
    isSaveDraftEnabled?: boolean;
    competitionStatus: CompetitionStatus;
    label?: string;
    isVisible?: boolean;
    isDisable?: boolean;
    isPreview?: boolean;
    backBtn?: ReactElement;
    containerStyle?: string;
}

const SubmissionsNextBtn: React.FC<SubmissionsNextBtnProps> = ({
    onNextClick,
    onSaveDraftClick,
    isSaveDraftEnabled = false,
    competitionStatus,
    label,
    isPreview = false,
    isDisable = false,
    isVisible = true,
    containerStyle,
    backBtn,
    children
}) => {
    const { t } = useTranslation('common');

    const container = classNames(style.container, {
        [containerStyle as string]: !!containerStyle
    });
    return (
        <div className={container}>
            <SubmissionsHelpText
                status={competitionStatus}
                accentColor={true}
            />
            {!!backBtn && <div className={style.back}>{backBtn}</div>}
            {!isPreview && (
                <>
                    {isVisible &&
                        competitionStatus ===
                            CompetitionStatus.submissionOpen && (
                            <>
                                {!!onSaveDraftClick && (
                                    <div className={style.saveDraft}>
                                        <SaveDraftButton
                                            onClick={onSaveDraftClick}
                                            isDisabled={
                                                isDisable || isSaveDraftEnabled
                                            }
                                        />
                                    </div>
                                )}
                                <Button
                                    type={'primary'}
                                    disabled={isDisable}
                                    onClick={onNextClick}>
                                    {!label ? t('next') : label}
                                </Button>
                            </>
                        )}
                    {!!children && children}
                </>
            )}
        </div>
    );
};

export default SubmissionsNextBtn;
