import * as React from 'react';
import SubscriptionStep from './SubscriptionStep';
import { Radio, Typography } from 'antd';
import style from './SubscriptionStep.module.scss';
import rootStyle from '../MembershipApplication.module.scss';
import { observer } from 'mobx-react';
import PageLoader from 'common/components/PageLoader';
import { useTranslation } from 'react-i18next';
import Link from 'web/components/Link';
import FormName from '../FormName';

interface SubscriptionStepViewProps {
    model: SubscriptionStep;
}

const SubscriptionStepView: React.FC<SubscriptionStepViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['membership', 'common']);
        return (
            <PageLoader loader={model.api}>
                <FormName
                    helpElement={
                        <Typography.Text className={style.help}>
                            {t('membership:stepOneHelpText')}{' '}
                            <Link
                                style={style.link}
                                href={
                                    'https://www.grafill.no/medlem/sok-medlemskap'
                                }>
                                {t('membership:stepOneHelpUrl')}
                            </Link>
                            .
                        </Typography.Text>
                    }
                    title={t('membership:stepOneTitle')}
                />
                <div className={rootStyle.formBox}>
                    <Radio.Group
                        onChange={model.onChangeSubscription}
                        value={model.subscription}>
                        {model.membershipTypes.map((item) => {
                            return (
                                <Radio
                                    value={item}
                                    className={style.radio}
                                    key={item.id}>
                                    <span className={style.radioName}>
                                        {`${item.name}`}
                                        <span className={style.radioPrice}>
                                            {` - kr. ${item.annualPriceIncVat},-`}
                                        </span>
                                    </span>
                                </Radio>
                            );
                        })}
                    </Radio.Group>
                </div>
            </PageLoader>
        );
    }
);

export default SubscriptionStepView;
