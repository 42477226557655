import { action, observable } from 'mobx';
import { PaymentMethod } from 'model/Externals';

class PaymentMethodSelector {
    @observable selected: PaymentMethod | undefined;

    @action
    onPaymentSelect = (v: PaymentMethod) => (this.selected = v);
}

export default PaymentMethodSelector;
