import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {ListOptions} from "../GenericList/ListOptions"

import {EvaluationCategoryListItemDto} from "./Model/EvaluationCategoryListItemDto"

export interface GetEvaluationCompetitionDetailsRequest {
    competitionRef: number
    itemId?: number
    options?: ListOptions
    filter?: any
}
export const getEvaluationCompetitionDetails = (getEvaluationCompetitionDetailsRequest: GetEvaluationCompetitionDetailsRequest): Promise<EvaluationCategoryListItemDto[]> => {
    return send("GetEvaluationCompetitionDetails", getEvaluationCompetitionDetailsRequest)
}
