import * as React from 'react';
import style from './Membership.module.scss';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';

interface PartyInfoProps {
    party: PartyDto | undefined;
}

const PartyInfo: React.FC<PartyInfoProps> = ({ party }) => {
    if(!party) {
        return <span className={style.partyRow}>-</span>
    }
    return (
        <>
            <span className={style.partyRow}>{party.name}</span>
            <span className={style.partyRow}>{`${party.address.line}`}</span>
            <span
                className={
                    style.partyRow
                }>{`${party.address.postalCode} ${party.address.city}`}</span>
            <span className={style.partyRow}>{party.address.country}</span>
        </>
    );
};

export default PartyInfo;
