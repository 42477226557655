import {
    Form,
    FormField,
    FormFieldValidator,
    FormState
} from 'react-mvvm/forms';
import { computed, observable } from 'mobx';
import { command } from 'react-mvvm/commands';
import { MembershipApplicationLinkDto } from 'model/Api/Members/Model/MembershipApplicationLinkDto';

const buildLink = (
    validator: FormFieldValidator<string>,
    link: MembershipApplicationLinkDto | undefined = {
        value: '',
        id: 0,
        version: 0
    }
): UrlFieldRowLink => {
    return {
        id: !link ? 0 : link.id,
        version: !link ? 0 : link.version,
        value: new Form({
            url: new FormField(link?.value, validator)
        })
    };
};

export type UrlFieldRowLink = {
    id: number;
    version: number;
    value: Form<any>;
};

class UrlField extends FormField<MembershipApplicationLinkDto[] | undefined> {
    @observable links: UrlFieldRowLink[] = [];
    public fieldValidator: FormFieldValidator<string>;
    private isErrorState = false;
    private isValidatingProcess = false;

    constructor(
        value: MembershipApplicationLinkDto[] | undefined,
        validator: FormFieldValidator<string>
    ) {
        super(value, validator);

        this.fieldValidator = validator;
        if (!value?.length) {
            this.addLink.execute();
            return;
        }

        this.value = value;
    }

    addLink = command(() => this.links.push(buildLink(this.fieldValidator)));
    removeLink = (index: number) => this.links.splice(index, 1);

    @computed get value(): MembershipApplicationLinkDto[] | undefined {
        if (this.links.length === 1 && !this.links[0].value) {
            return [];
        }
        return this.links.map((link) => {
            return {
                id: link.id,
                version: link.version,
                value: link.value.fields.url.value
            };
        });
    }

    set value(data: MembershipApplicationLinkDto[] | undefined) {
        if (!!data) {
            this.links = data.map((row) => buildLink(this.fieldValidator, row));
        }
    }

    @computed get state(): FormState {
        if (this.isErrorState) {
            return FormState.Invalid;
        }

        if (this.isValidatingProcess) {
            return FormState.Pending;
        }

        return FormState.Valid;
    }

    @computed get isPristine() {
        return this.links.some((row) => row.value.isPristine);
    }

    async validate(): Promise<true | readonly string[]> {
        let isValid = true;
        this.isErrorState = false;
        this.isValidatingProcess = true;

        for (const row of this.links) {
            const validation = row.value.validate();
            if (!validation) {
                isValid = false;
                this.isErrorState = true;
            }
        }

        this.isValidatingProcess = false;
        return isValid || [];
    }
}

export default UrlField;
