import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface DeletePartyRequest {
    partyRef: number
    userRef?: number
}
export const deleteParty = (deletePartyRequest: DeletePartyRequest): Promise<any> => {
    return send("DeleteParty", deletePartyRequest)
}
