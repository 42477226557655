import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {CompetitionEntryDto} from "./Model/CompetitionEntryDto"

export interface GetCompetitionEntryDetailsRequest {
    competitionEntryRef: number
}
export const getCompetitionEntryDetails = (getCompetitionEntryDetailsRequest: GetCompetitionEntryDetailsRequest): Promise<CompetitionEntryDto> => {
    return send("GetCompetitionEntryDetails", getCompetitionEntryDetailsRequest)
}
