import {Page} from "../../../react-mvvm";
import {action, computed, observable} from "mobx";
import Membership from "../Membership/Membership";
import UserAddresses from "../../../common/components/UserAddresses/UserAddresses";
import {PaymentMethod, SubscriptionType} from "../../../model/Externals";
import {MembershipPriceResponse} from "../../../model/Api/Members/Model/MembershipPriceResponse";
import loader from "../../../react-mvvm/loading/loader";
import {getMembershipPrice} from "../../../model/Api/Members/GetMembershipPriceRequest";
import {reapplyForMembershipAndPayOnline} from "../../../model/Api/Members/ReapplyForMembershipAndPayOnlineRequest";
import {homeRoute} from "../../routes";
import {reapplyForMembershipAndPayWithInvoice} from "../../../model/Api/Members/ReapplyForMembershipAndPayWithInvoiceRequest";

class MembershipReapplyPage extends Page {
    
    @observable parent: Membership;
    @observable userAddresses: UserAddresses;
    @observable subscriptionType: SubscriptionType | undefined;
    @observable paymentMethod: PaymentMethod | undefined;
    @observable isPaymentTermsAccepted: boolean = false;
    @observable membershipPrice: MembershipPriceResponse | undefined;

    constructor(parent: Membership) {
        super();

        this.parent = parent;
        this.userAddresses = new UserAddresses(this, true, true);
    }

    protected async onActivated(): Promise<any> {
        if (!this.parent.member) {
            await this.parent.removeAllChildPages(this.parent as Page);
            await this.parent.activate();
        }
        window.scrollTo({ top: 0 });

        if (this.parent.membershipType) {
            this.membershipPrice = await this.api.getMembershipPrice(
                this.parent.membershipType.id
            );
        }
        await this.userAddresses.init();
        if (this.userAddresses.parties.length > 0)
            this.userAddresses.selectedParty = this.userAddresses.parties[0].id;
    }

    api = loader({
        reapplyAndPayOnline: async (partyId: number, subscriptionType: SubscriptionType ) => 
            reapplyForMembershipAndPayOnline({
                partyId,
                subscriptionType
            }),
        reapplyAndPayWithInvoice: async (partyId: number, subscriptionType: SubscriptionType) => 
            reapplyForMembershipAndPayWithInvoice({
                partyId,
                subscriptionType 
        }),
        getMembershipPrice: (typeId: number) =>
            getMembershipPrice({
                typeId
            })
    });

    @computed get membership() {
        return this.parent.member;
    }

    @action
    onSubscriptionCheck = (value: SubscriptionType) => {
        this.subscriptionType = value;
    };

    @action
    onTermsCheck = (value: boolean) => {
        this.isPaymentTermsAccepted = value;
    };

    @action
    onPaymentMethodCheck = (values: PaymentMethod) => {
        this.paymentMethod = values;
    };

    @computed get subscription() {
        return this.parent.membershipType;
    }

    onChangeMembershipSubscription = async () => {
        if (!!this.subscriptionType && !!this.paymentMethod) {
            if(this.paymentMethod === PaymentMethod.bambora) {
                const onlinePaymentToken = await this.api.reapplyAndPayOnline(
                    this.userAddresses.selectedParty ?? 0,
                    this.subscriptionType
                );
                
                window.location.href = onlinePaymentToken.url;
            } else {
                const invoicePayment = await this.api.reapplyAndPayWithInvoice(
                    this.userAddresses.selectedParty ?? 0, this.subscriptionType);
                
                homeRoute.historyPush(
                    `/invoicepaymentstatus?status=${invoicePayment.status}&orderId=${invoicePayment.orderId}`
                );
            }
        }
    };

    @computed get userName() {
        return this.parent.userName
    }

    @computed get isPaymentDisable() {
        return (
            !this.subscriptionType ||
            !this.paymentMethod ||
            !this.isPaymentTermsAccepted
        );
    }

    onBck = async () => {
        await this.parent.removeAllChildPages(this.parent as Page);
        await this.parent.activate();
    };
}

export default MembershipReapplyPage;