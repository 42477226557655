import { computed, observable } from 'mobx';
import { IRoutedPage } from './routing';
import { IActivatable } from './index';

export interface IPage extends IRoutedPage, IActivatable {
    deactivate(): Promise<void>;
    identity: string;
    pageName?: string;
}

export abstract class Page<TDialog extends IActivatable = never>
    implements IPage {
    @observable.ref dialog: TDialog | undefined;

    @computed get childPage(): IPage | undefined {
        return this._childPage;
    }

    @observable.ref _pageName: string = '';

    identity = this.constructor.name;
    @computed get pageName() {
        return this._pageName;
    }
    set pageName(name) {
        this._pageName = name;
    }

    @observable.ref private _childPage: IPage | undefined = undefined;

    private isActive: boolean = false;
    private isLeaf: boolean = false;

    async activate() {
        this.isActive = true;
        if (this._childPage) {
            await this._childPage.activate();
            this.isLeaf = false;
            await this.onLeafBlur();
        } else {
            this.isLeaf = true;
            await this.onLeafFocus();
        }
        this.onActivated();
    }

    async deactivate() {
        if (this._childPage) await this._childPage.deactivate();
        this.isActive = false;
        this.isLeaf = false;
        await this.onLeafBlur();
        await this.onDeactivated();
    }

    async removeChildPage() {
        if (this._childPage && this.isActive)
            await this._childPage.deactivate();
        this._childPage = undefined;
        this.isLeaf = true;
        await this.onLeafFocus();
    }

    async showChildPage<T extends IPage>(newPage: T) {
        if (this._childPage && this.isActive)
            await this._childPage.deactivate();
        this._childPage = newPage;
        this.isLeaf = false;
        await this.onLeafBlur();
        console.log(this, 'Changed current page');
        if (this._childPage && this.isActive) await this._childPage.activate();
        return newPage;
    }

    async showModal<TModal extends IActivatable, TResult>(
        createDialog: (close: (result: TResult) => void) => TDialog
    ): Promise<TResult> {
        return new Promise<TResult>((resolve) => {
            this.dialog = createDialog((result) => {
                this.dialog = undefined;
                resolve(result);
            });

            this.dialog.activate();
        });
    }

    async removeAllChildPages(page: Page) {
        const allChild: IPage[] = [page];

        const checkChild = async (p: IPage) => {
            const child = p.childPage;
            if (!child) {
                return;
            }
            allChild.push(child as IPage);
            await checkChild(child as IPage);
        };
        await checkChild(page);
        await allChild
            .reverse()
            .forEach(async (page) => await page.removeChildPage());
    }

    protected async onActivated() {}

    protected async onDeactivated() {}

    protected async onLeafFocus() {}

    protected async onLeafBlur() {}
}
