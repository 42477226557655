import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {MembershipPriceResponse} from "./Model/MembershipPriceResponse"

export interface GetMembershipPriceRequest {
    typeId: number
}
export const getMembershipPrice = (getMembershipPriceRequest: GetMembershipPriceRequest): Promise<MembershipPriceResponse> => {
    return send("GetMembershipPrice", getMembershipPriceRequest)
}
