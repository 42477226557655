import * as React from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import style from './PageTitle.module.scss';

interface PageTitleProps {
    title: string;
    headerNumber?: 1 | 2 | 3 | 4;
    size?: 'xsmall' | 'small' | 'normal' | 'big';
    marginBottom?: 'onlyBottom' | 'small' | 'middle' | 'big' | 'default';
}

const PageTitle: React.FC<PageTitleProps> = ({
    title,
    headerNumber = 1,
    size = 'normal',
    marginBottom = 'default'
}) => {
    const headerStyle = classNames(
        style.header,
        { [style.xsmall]: size === 'xsmall' },
        { [style.small]: size === 'small' },
        { [style.normal]: size === 'normal' },
        { [style.big]: size === 'big' },
        { [style.onlyBottom]: marginBottom === 'onlyBottom' },
        { [style.marginSmall]: marginBottom === 'small' },
        { [style.marginMiddle]: marginBottom === 'middle' },
        { [style.marginBig]: marginBottom === 'big' }
    );

    return (
        <Typography.Title level={headerNumber} className={headerStyle}>
            {title}
        </Typography.Title>
    );
};

export default PageTitle;
