import * as React from 'react';
import style from './UserAddresses.module.scss';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import { observer } from 'mobx-react';
import { Button, Menu } from 'antd';
import Flex from 'common/components/Flex';
import Link from 'web/components/Link';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';

interface MenuDropdownProps {
    model: UserAddresses;
    data: PartyDto[];
    setVisible: (v: boolean) => void;
    type?: 'private' | 'company';
    selectedId?: string;
    userId?: number;
}

const MenuDropdown: React.FC<MenuDropdownProps> = observer(
    ({ model, data, setVisible, type, userId, selectedId = '' }) => {
        const { t } = useTranslation(['userAddresses', 'common']);
        const [selected, setSelected] = React.useState<string>(selectedId);

        if (!data.length) {
            return (
                <Menu
                    onClick={() => {
                        setVisible(false);
                        model.onCreateAddress(type, userId);
                    }}
                    className={style.dropdownMenu}>
                    <Menu.Item key={'empty'} className={style.dropdownItem}>
                        <PlusOutlined /> {t('userAddresses:addAddress')}
                    </Menu.Item>
                </Menu>
            );
        }

        return (
            <Menu
                onClick={(e) => {
                    setSelected(e.key as string);
                    setVisible(false);
                    model.onSelectAddress(Number(e.key));
                }}
                className={style.dropdownMenu}>
                {data.map((party) => {
                    return (
                        <Menu.Item
                            key={party.id}
                            title={party.name}
                            className={style.dropdownItem}>
                            <Flex
                                justifyContent={'space-between'}
                                alignItems={'center'}>
                                <span className={style.dropdownText}>
                                    {!!party.name && `${party.name}, `}
                                    {!!party.careOf && `${!!party.orgNo ? 'att. ' : 'c/o '} ${party.careOf}, `}
                                    {!!party.address.line &&
                                        `${party.address.line}, `}
                                    {`${party.address.postalCode} ${party.address.city}`}
                                </span>
                                <div>
                                    {!!type &&
                                        type === 'private' &&
                                        selected === party.id.toString() && (
                                            <Link
                                                onClick={() =>
                                                    model.onEdit(party)
                                                }
                                                style={style.dropdownLink}>
                                                {t('common:edit')}
                                            </Link>
                                        )}
                                    {selected === party.id.toString() && (
                                        <CheckOutlined
                                            className={style.dropdownIcon}
                                        />
                                    )}
                                </div>
                            </Flex>
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
    }
);

export default MenuDropdown;
