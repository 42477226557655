import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {SubmissionQuestionnaireDto} from "./Model/SubmissionQuestionnaireDto"

export interface GetSubmissionQuestionnaireRequest {
    categoryRef: number
}
export const getSubmissionQuestionnaire = (getSubmissionQuestionnaireRequest: GetSubmissionQuestionnaireRequest): Promise<SubmissionQuestionnaireDto> => {
    return send("GetSubmissionQuestionnaire", getSubmissionQuestionnaireRequest)
}
