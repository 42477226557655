﻿import * as React from "react";
import TextArea from "antd/es/input/TextArea";
import {debounce} from "debounce";
import {ChangeEvent} from "react";
import {observer} from "mobx-react";

interface NoteProps {
    note: string,
    onSaveNote: (value: string) => void
}

const Note: React.FC<NoteProps> = observer(({note, onSaveNote}) => {
    const [noteValue, setNote] = React.useState<string>('');
    const noteRef = React.useRef(null);
    const saveComments = React.useRef(debounce(onSaveNote, 1000)).current;
    React.useEffect(() => {
        setNote(note);
    }, [note])
        
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setNote(e.target.value);
        saveComments(e.target.value);
    }
    
    return <TextArea ref={noteRef} onChange={onChange} value={noteValue} style={{minHeight: '210px'}} />
})

export default Note