import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {UserInfoDto} from "./Model/UserInfoDto"

import {TicketDto} from "../Tickets/Model/TicketDto"

export interface UpdateUserInfoTicketRequest {
    user: UserInfoDto
}
export const updateUserInfoTicket = (updateUserInfoTicketRequest: UpdateUserInfoTicketRequest): Promise<TicketDto> => {
    return send("UpdateUserInfoTicket", updateUserInfoTicketRequest)
}
