import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {LoginUserDto} from "./Model/LoginUserDto"

import {TokenResult} from "./Model/TokenResult"

export interface AuthenticateUserRequest {
    login: LoginUserDto
}
export const authenticateUser = (authenticateUserRequest: AuthenticateUserRequest): Promise<TokenResult> => {
    return send("AuthenticateUser", authenticateUserRequest)
}
