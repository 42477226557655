import * as React from 'react';
import { observer } from 'mobx-react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import style from './CaptchaFieldView.module.scss';
import CaptchaField from './CaptchaField';
import { FormField } from '../../../../react-mvvm/forms/formField';
import AntField from '../AntField';

interface CaptchaFieldViewProps {
  model: CaptchaField;
  field: FormField<string>;
}

const UrlFieldView: React.FC<CaptchaFieldViewProps> = observer(
    ({ model, field }) => {
      return (
        <AntField field={field} className={style.container}>
          {(props) => (
            <HCaptcha sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY || ''} onVerify={model.onVerifyCaptcha} {...props} />
          )}
        </AntField>
      )
    }
);

export default UrlFieldView;
