import * as React from 'react';
import Link from 'web/components/Link';
import AntField from 'common/components/antHelpers/AntField';
import { FormField } from 'react-mvvm/forms';
import { observer } from 'mobx-react';
import AntCheckbox from 'common/components/antHelpers/AntCheckbox';
import { useTranslation } from 'react-i18next';
import style from './FormStep.module.scss';

interface FieldPolicyProps {
    field: FormField<boolean>;
    offset?: number;
}

const FieldPolicy: React.FC<FieldPolicyProps> = observer(
    ({ field, offset = 5 }) => {
        const { t } = useTranslation('membership');
        return (
            <AntField field={field}>
                {(props) => (
                    <div className={style.policyFieldRequired}>
                        <AntCheckbox
                            {...props}
                            content={t('termsAndConditionText')}
                        />
                        <Link
                            arrowIcon={'right'}
                            size={'small'}
                            target={'_blank'}
                            href={
                                'https://s3-eu-west-1.amazonaws.com/grafill-files/documents/240530_Vedtekter_Grafill.pdf'
                            }>
                            {t('termsLink')}
                        </Link>
                    </div>
                )}
            </AntField>
        );
    }
);

export default FieldPolicy;
