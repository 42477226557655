import Membership from 'web/screen/Membership/Membership';
import { Form, FormField, RequiredFieldValidator } from 'react-mvvm';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import { observable, computed, action } from 'mobx';
import BirthDatePicker from 'common/components/BirthDatePicker/BirthDatePicker';
import { MemberDto } from 'model/Api/Members/Model/MemberDto';

class PersonalData {
    parent: Membership;
    form: Form<any> | undefined;
    @observable isEdit: boolean;
    @observable userAddresses: UserAddresses | undefined;

    constructor(parent: Membership) {
        this.parent = parent;
        this.isEdit = false;
    }

    @computed get member(): MemberDto | undefined {
        return this.parent.member;
    }
    
    onInit = async () => {
        this.userAddresses = new UserAddresses(
            this.parent,
            true,
            true,
            this.onSelectAddress,
            true
        );
        await this.userAddresses.init();

        if (!!this.member) {
            this.form = await this.buildForm(this.member);
        }
    };

    buildForm = async (member: MemberDto) => {
        if (!!member.privatePartyId && !!this.userAddresses) {
            this.userAddresses.onSelectAddress(member.privatePartyId);
        }

        return new Form({
            birthDate: new BirthDatePicker(
                member.birthDate,
                RequiredFieldValidator
            ),
            gender: new FormField(member.gender, RequiredFieldValidator),
            privatePartyId: new FormField(
                member.privatePartyId,
                RequiredFieldValidator
            )
        });
    };

    private onSelectAddress = () => {
        if (!!this.userAddresses && !!this.form) {
            const { doubleSelectedParty } = this.userAddresses;
            this.form.fields.privatePartyId.value =
                doubleSelectedParty.private;
        }
    };

    onSavePersonalData = async () => {
        if (!!this.member && !!this.form) {
            const { fields } = this.form;
            await this.parent.api.updateMemberPersonalData(fields.birthDate.value, 
                fields.gender.value, fields.privatePartyId.value);
            this.parent.member = await this.parent.api.getMember();
            this.parent.userParties = await this.parent.api.getUserParty();
            this.isEdit = false;
        }
    };

    @action
    onEdit = () => {
        this.isEdit = true;
    };

    @action
    onCancel = () => {
        this.isEdit = false;
    };
}

export default PersonalData;
