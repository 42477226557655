import * as React from 'react';
import Flex from "common/components/Flex";
import PageWrapper from "web/components/PageWrapper";
import {Button} from "antd";
import style from './CompetitionIntro.module.scss';

interface CompetitionIntroProps {
    onBackBtn: () => void;
    backBtnText: string;
    categoryId?: number;
}

const CompetitionIntro: React.FC<CompetitionIntroProps> = ({onBackBtn, backBtnText}) => {
    return <div className={style.root}>
        <PageWrapper>
            <Flex justifyContent={"flex-start"} alignItems={"flex-start"}>
                <Button size={'small'}
                        type={"link"} onClick={onBackBtn}>{backBtnText}</Button>
            </Flex>   
        </PageWrapper>
    </div>
};

export default CompetitionIntro;
