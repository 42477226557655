import * as React from 'react';

interface FlexProps {
    flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
    justifyContent?:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'start'
        | 'end'
        | 'left'
        | 'right'
        | 'unsafe';
    alignItems?:
        | 'stretch'
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'baseline'
        | 'first baseline'
        | 'last baseline'
        | 'start'
        | 'end'
        | 'self-start'
        | 'self-end'
        | 'unsafe';
    alignContent?:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'stretch'
        | 'start'
        | 'end'
        | 'baseline'
        | 'first baseline'
        | 'last baseline'
        | 'unsafe';
    customClass?: string;
}

const Flex: React.FunctionComponent<FlexProps> = ({
    children,
    flexDirection = 'row',
    flexWrap = 'nowrap',
    justifyContent = 'flex-start',
    alignItems = 'stretch',
    alignContent = 'flex-start',
    customClass = ''
}) => {
    return (
        <div
            className={customClass}
            style={{
                display: 'flex',
                flexDirection: flexDirection,
                flexWrap: flexWrap,
                justifyContent: justifyContent,
                alignItems: alignItems,
                alignContent: alignContent
            }}>
            {children}
        </div>
    );
};

export default Flex;
