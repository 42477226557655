import * as React from 'react';
import { FormField } from 'react-mvvm/forms';
import { Gender } from 'model/Externals';
import { Radio } from 'antd';
import AntField from 'common/components/antHelpers/AntField';
import style from './GenderField.module.scss';
import { useTranslation } from 'react-i18next';

interface GenderFieldProps {
    field: FormField<Gender | undefined>;
    disabled?: boolean;
    className?: string;
}
const GenderField: React.FC<GenderFieldProps> = ({ field, disabled, className }) => {
    const { t } = useTranslation('membership');
    return (
        <AntField className={className} field={field} label={t('gender')}>
            {(props) => (
                <Radio.Group
                    disabled={disabled}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}>
                    <Radio value={Gender.female} className={style.radio}>
                        {t('genderFemale')}
                    </Radio>
                    <Radio value={Gender.male} className={style.radio}>
                        {t('genderMale')}
                    </Radio>
                    <Radio value={Gender.other} className={style.radio}>
                        {t('genderOther')}
                    </Radio>
                </Radio.Group>
            )}
        </AntField>
    );
};

export default GenderField;
