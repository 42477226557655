import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface UpdateMemberEducationDataRequest {
    expectedGraduationDate?: string
    schoolName: string
}
export const updateMemberEducationData = (updateMemberEducationDataRequest: UpdateMemberEducationDataRequest): Promise<any> => {
    return send("UpdateMemberEducationData", updateMemberEducationDataRequest)
}
