import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {AcceptInvitationDto} from "./Model/AcceptInvitationDto"

import {TokenResult} from "./Model/TokenResult"

export interface AcceptInvitationRequest {
    acceptInvitation: AcceptInvitationDto
}
export const acceptInvitation = (acceptInvitationRequest: AcceptInvitationRequest): Promise<TokenResult> => {
    return send("AcceptInvitation", acceptInvitationRequest)
}
