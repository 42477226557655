import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {ListOptions} from "../GenericList/ListOptions"

import {CompetitionStatus} from "../../Externals"

import {CompetitionListItemDto} from "../Competitions/Model/CompetitionListItemDto"

export interface GetPublishedCompetitionsRequest {
    itemId?: number
    options?: ListOptions
    filter?: StatusFilter
}
export interface StatusFilter {
    includeCompetitionStatuses: CompetitionStatus[]
}
export const getPublishedCompetitions = (getPublishedCompetitionsRequest: GetPublishedCompetitionsRequest): Promise<CompetitionListItemDto[]> => {
    return send("GetPublishedCompetitions", getPublishedCompetitionsRequest)
}
