import * as React from 'react';
import style from './SubmissionCard.module.scss';
import ProgressBar from 'web/components/ProgressBar';
import classNames from 'classnames';
import { EvaluationCategoryListItemDto } from 'model/Api/Evaluation/Model/EvaluationCategoryListItemDto';
import { observer } from 'mobx-react';

interface SubmissionCardProp {
    type: 'tiles' | 'list';
    onSelect: () => void;
    evaluatedCategory: EvaluationCategoryListItemDto;
    isEvaluationButton: boolean;
}

const SubmissionCard: React.FC<SubmissionCardProp> = observer(
    ({
        type,
        onSelect,
        evaluatedCategory,
        isEvaluationButton
    }) => {
        const box = classNames(
            style.box,
            type === 'tiles' ? style.tiles : style.list
        );
        const content = classNames(
            type === 'tiles' ? style.tilesContent : style.listContent
        );

        return (
            <div className={box} onClick={() => onSelect()}>
                {isEvaluationButton && (
                    <ProgressBar
                        percentage={evaluatedCategory.assessedSubmissions}
                    />
                )}
                <div className={content}>
                    <div className={style.title}>
                        <span className={style.link}>
                            {evaluatedCategory.name}
                        </span>
                    </div>
                    <span className={style.submission}>
                        {evaluatedCategory.submissions}
                    </span>
                </div>
            </div>
        );
    }
);

export default SubmissionCard;
