import * as React from 'react';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import AntModal from 'common/components/antHelpers/AntModal';
import { observer } from 'mobx-react';

interface ConfirmationModalViewProps {
    dialog: ConfirmationModal;
    title: string;
    okText?: string
}

const ConfirmationModalView: React.FC<ConfirmationModalViewProps> = observer(({
    dialog,
    title,
    children,
    okText
}) => {
    const { confirm, cancel } = dialog;
    return (
        <AntModal cancel={cancel} okCommand={confirm} title={title} okText={okText ? okText : 'Ok'}>
            <p>{children}</p>
        </AntModal>
    );
});

export default ConfirmationModalView;
