import { IPage, Page } from 'react-mvvm';
import loader from 'react-mvvm/loading/loader';
import { getSubmissionCompetitionDetails } from 'model/Api/Submission/GetSubmissionCompetitionDetailsRequest';
import { computed, observable } from 'mobx';
import { SubmissionCompetitionDto } from 'model/Api/Submission/Model/SubmissionCompetitionDto';
import CompetitionsListPage from 'web/screen/CompetitionsListPage/CompetitionsListPage';
import SubmissionFormPage from '../SubmissionFormPage/SubmissionFormPage';
import { createCompetitionEntry } from 'model/Api/Submission/CreateCompetitionEntryRequest';
import { CompetitionEntryStatus, CompetitionStatus } from 'model/Externals';
import { CompetitionEntryDto } from 'model/Api/Submission/Model/CompetitionEntryDto';
import { homeRoute } from 'web/routes';
import { SubmissionCategoryDto } from 'model/Api/Submission/Model/SubmissionCategoryDto';
import { HeaderBreadcrumbsData } from 'web/components/HeaderBreadcrumbs/HeaderBreadcrumbs';

class Competition extends Page {
    @observable competition: SubmissionCompetitionDto = {
        name: '',
        description: '',
        categories: [],
        competitionStatus: CompetitionStatus.draft,
        id: 0,
        version: 0
    };
    @observable selectedCat: number | undefined;
    @observable competitionEntryDto: CompetitionEntryDto | undefined;
    @observable showHelpText: boolean = false;

    constructor(
        public competitionId: number,
        private parent: CompetitionsListPage
    ) {
        super();
    }

    api = loader({
        getDetails: async (id: number) =>
            await getSubmissionCompetitionDetails({ id })
    });

    apiEntry = loader({
        createEntry: async (categoryId: number) => {
            const entry = await createCompetitionEntry({
                categoryRef: categoryId,
                competitionEntryDto: {
                    answers: [],
                    attachments: [],
                    competitionEntryStatus: CompetitionEntryStatus.created,
                    id: 0,
                    version: 0
                }
            });
            return entry
        }
            
    });

    @computed get isSubmissionsOpen(): boolean {
        return (
            this.competition.competitionStatus ===
            CompetitionStatus.submissionOpen
        );
    }

    @computed get selectedCategory(): SubmissionCategoryDto | undefined {
        if (!this.selectedCat) {
            return undefined;
        }
        return this.competition.categories.find((cat) => {
            if (cat.id === this.selectedCat) {
                return cat;
            }
            let subCat;
            cat.subCategories.find((sub) => {
                if (sub.id === this.selectedCat) {
                    subCat = sub;
                }
            });
            return subCat;
        });
    }

    @computed get selectedCategoryName(): string | undefined {
        if (!this.selectedCat) {
            return undefined;
        }
        let name = '';
        this.competition.categories.find((cat) => {
            if (cat.id === this.selectedCat) {
                name = cat.name;
                return true;
            }

            cat.subCategories.find((subCat) => {
                if (subCat.id === this.selectedCat) {
                    name = `${cat.name} / ${subCat.name}`;
                    return true;
                }
            });
        });
        return name;
    }

    @computed get pageName() {
        if (!!this.parent.selectedCompetition) {
            return this.parent.selectedCompetition.name;
        }
        return '';
    }

    activateChildPage = async (p: Page) => {
        await this.removeAllChildPages(p);
        await p.activate();
    };

    @computed get breadcrumbs(): HeaderBreadcrumbsData[] {
        const breadcrumbsPages: IPage[] = [this];
        let currentPage: Page = this;
        while (!!currentPage.childPage && breadcrumbsPages.length < 3) {
            breadcrumbsPages.push(currentPage.childPage);
            currentPage = currentPage.childPage as Page;
        }

        return breadcrumbsPages.map((page, index) => {
            if (breadcrumbsPages.length - 1 === index) {
                return { name: page.pageName ?? '' };
            }

            return {
                name: page.pageName ?? '',
                onClick: () => {
                    this.activateChildPage(page as Page);
                }
            };
        });
    }

    onActivated = async () => {
        this.competition = await this.api.getDetails(this.competitionId);
    };

    onGoBack = async () => {
        await homeRoute.historyPush('/');
    };

    onCategorySet = async (categoryId: number) => {
        if (!this.isSubmissionsOpen) {
            this.showHelpText = true;
            return;
        }

        this.selectedCat = categoryId;
        this.competitionEntryDto = await this.apiEntry.createEntry(
            this.selectedCat
        );
        await this.showFormPage(this.selectedCat, this.competitionEntryDto.id);
    };

    showFormPage(categoryId: number, entryId: number) {
        this.selectedCat = categoryId;
        return this.showChildPage(
            new SubmissionFormPage(
                this.competitionId,
                categoryId,
                entryId,
                this
            )
        );
    }
}

export default Competition;
