import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface CancelMembershipApplicationRequest {
    membershipApplicationPaymentId: number
}
export const cancelMembershipApplication = (cancelMembershipApplicationRequest: CancelMembershipApplicationRequest): Promise<any> => {
    return send("CancelMembershipApplication", cancelMembershipApplicationRequest)
}
