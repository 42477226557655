import { Page } from 'react-mvvm';
import Submissions from 'web/screen/Submissions/Submissions';
import { computed } from 'mobx';
import { CompetitionEntryListItemDto } from 'model/Api/Submission/Model/CompetitionEntryListItemDto';
import SubmissionsMyPagePayment from 'web/screen/SubmissionsMyPagePayment/SubmissionsMyPagePayment';
import loader from 'react-mvvm/loading/loader';
import { CompetitionEntryStatus } from 'model/Externals';
import { updateCompetitionEntry } from 'model/Api/Submission/UpdateCompetitionEntryRequest';
import { CompetitionEntryDto } from 'model/Api/Submission/Model/CompetitionEntryDto';
import { getCompetitionEntryDetails } from 'model/Api/Submission/GetCompetitionEntryDetailsRequest';

class Handelkurv extends Page {
    api = loader({
        submitCompetitionEntry: async (entry: CompetitionEntryDto) =>
            await updateCompetitionEntry({ competitionEntryDto: entry }),
        getCompetitionEntryDetails: async (entryId: number) =>
            await getCompetitionEntryDetails({ competitionEntryRef: entryId })
    });
    parent: Submissions;

    constructor(parent: Submissions) {
        super();
        this.parent = parent;
    }

    @computed get selectedEntries(): CompetitionEntryListItemDto[] {
        if (!this.parent.selectedEntries.length) {
            return [];
        }

        return this.parent.selectedEntries.reduce<
            CompetitionEntryListItemDto[]
        >((prev, current) => {
            const entry = this.parent.entriesList.find((e) => e.id === current);
            if (!!entry) {
                prev.push(entry);
            }

            return prev;
        }, []);
    }

    onPayOrSubmit = async () => {
        if (this.selectedEntries.some((e) => e.product?.priceIncVat > 0)) {
            await this.showPayment();
            return;
        }
        
        this.selectedEntries.forEach(async (entry) => {
            const entryDetails = await this.api.getCompetitionEntryDetails(
                entry.id
            );
            entryDetails.competitionEntryStatus =
                CompetitionEntryStatus.submitted;
            await this.api.submitCompetitionEntry(entryDetails);
        });
        await this.parent.loadUserEntries();
        await this.onRemoveChildPage();
    };

    showPayment = async () => {
        return this.showChildPage(new SubmissionsMyPagePayment(this));
    };

    onRemoveSelectedEntry = (id: number) => {
        if (!id) {
            return;
        }
        this.parent.selectedEntries = this.parent.selectedEntries.reduce<
            number[]
        >((prev, current) => {
            if (current !== id) {
                prev.push(current);
            }
            return prev;
        }, []);
    };

    onRemoveChildPage = async () => {
        await this.parent.onRemoveChildPage();
    };
}

export default Handelkurv;
