import * as React from 'react';
import style from './JudgingCompetitionsView.module.scss';
import JudgingCompetitions from './JudgingCompetitions';
import { observer } from 'mobx-react';
import { ContentView } from 'react-mvvm';
import PageLoader from 'common/components/PageLoader';
import Competition from './Competition';
import { Typography } from 'antd';
import PageWrapper from 'web/components/PageWrapper';
import { useTranslation } from 'react-i18next';

interface JudgingCompetitionsViewProps {
    model: JudgingCompetitions;
}

const JudgingCompetitionsView: React.FC<JudgingCompetitionsViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('judging');
        return (
            <>
                <ContentView content={model.childPage}>
                    <PageLoader loader={model.api}>
                        <div className={style.container}>
                            <PageWrapper>
                                <Typography.Title level={2}>
                                    {t('judgingPageTitle')}
                                </Typography.Title>
                                <div className={style.containerBox}>
                                    {model.evaluations.map((competition) => {
                                        return (
                                            <Competition
                                                competition={competition}
                                                key={competition.id}
                                                onClick={() => {
                                                    model.showJudgingIntroductionPage(
                                                        competition.id
                                                    );
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            </PageWrapper>
                        </div>
                    </PageLoader>
                </ContentView>
            </>
        );
    }
);

export default JudgingCompetitionsView;
