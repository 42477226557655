import * as React from 'react';
import JudgingIntroPage from 'web/screen/JudgingIntroPage/JudgingIntroPage';
import { ContentView, Page } from 'react-mvvm';
import PageWrapper from 'web/components/PageWrapper';
import PageLoader from 'common/components/PageLoader';
import Introduction from 'web/screen/JudgingIntroPage/Introduction';
import { observer } from 'mobx-react';
import CompetitionHeader from 'web/components/CompetitionHeader';
import HeaderBreadcrumbs, {
    HeaderBreadcrumbsData
} from 'web/components/HeaderBreadcrumbs/HeaderBreadcrumbs';

interface JudgingIntroPageViewProps {
    model: JudgingIntroPage;
}

const JudgingIntroPageView: React.FC<JudgingIntroPageViewProps> = observer(
    ({ model }) => {
        return (
            <PageWrapper>
                <ContentView content={model.childPage}>
                    <PageLoader loader={model.parent.api}>
                        {!!model.competitionListItem && (
                            <Introduction
                                onStart={model.showJudgingCategoryPage}
                                competitionListItem={model.competitionListItem}
                            />
                        )}
                    </PageLoader>
                </ContentView>
            </PageWrapper>
        );
    }
);

export default JudgingIntroPageView;
