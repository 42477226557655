import * as React from 'react';
import SubmissionFormPreviewPage from './SubmissionFormPreviewPage';
import style from './SubmissionFormPreview.module.scss';
import { CompetitionEntryStatus } from 'model/Externals';
import { Button } from 'antd';
import SubmissionsNextBtn from 'web/components/SubmissionsNextBtn';
import { useTranslation } from 'react-i18next';
import BackButton from 'web/components/BackButton';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';

interface SentButtonsProps {
    model: SubmissionFormPreviewPage;
    align: 'right' | 'left';
    isBack: boolean;
}

const SentButtons: React.FC<SentButtonsProps> = ({ model, align, isBack }) => {
    const { t } = useTranslation(['competitions', 'common']);
    return (
        <SubmissionsNextBtn
            onNextClick={model.onSend}
            competitionStatus={model.competitionStatus}
            label={
                model.product &&
                model.product.priceIncVat > 0.001
                    ? t('competitions:finalizeAndPayLater')
                    : t('competitions:sendIn')
            }
            isPreview={model.status === 'paid'}
            isVisible={
                model.competitionEntryDto?.competitionEntryStatus !==
                CompetitionEntryStatus.submitted
            }
            backBtn={isBack ? <BackButton onClick={model.onBack} /> : undefined}
            containerStyle={align === 'left' ? style.buttonLeft : ''}>
            {model &&
                model.product &&
                model.product.priceIncVat > 0.001 && (
                    <Button
                        className={style.payBtn}
                        type={'primary'}
                        onClick={model.showSubmissionPaymentPageWithModal}>
                        {model.competitionEntryDto?.competitionEntryStatus !==
                        CompetitionEntryStatus.submitted
                            ? `${t('common:sendIn')} og ${t(
                                  'common:pay'
                              ).toLocaleLowerCase()}`
                            : t('common:pay')}
                    </Button>
                )}
        </SubmissionsNextBtn>
    );
};

export default SentButtons;
