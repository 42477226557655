import { Page } from 'react-mvvm';
import SubmissionFormPreviewPage from 'web/screen/SubmissionFormPreview/SubmissionFormPreviewPage';
import { action, computed, observable } from 'mobx';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import loader from 'react-mvvm/loading/loader';
import PaymentMethodSelector from 'web/components/PaymentMethodSelector/PaymentMethodSelector';
import { PaymentMethod } from 'model/Externals';
import { payForCompetitionEntriesWithInvoice } from "../../../model/Api/Payments/PayForCompetitionEntriesWithInvoiceRequest";
import { payForCompetitionEntriesOnline } from "../../../model/Api/Payments/PayForCompetitionEntriesOnlineRequest";
import { homeRoute } from "../../routes";
import AddressDialog from "../../../common/components/AddressDialog/AddressDialog";

class SubmissionPayment extends Page<AddressDialog | ConfirmationModal> {
    parent: SubmissionFormPreviewPage;
    @observable userAddresses: UserAddresses;
    @observable paymentMethod: PaymentMethodSelector;
    @observable isPaymentTermsAccepted: boolean = false;

    constructor(parent: SubmissionFormPreviewPage) {
        super();

        this.parent = parent;
        this.userAddresses = new UserAddresses(this, true, true);
        this.paymentMethod = new PaymentMethodSelector();
    }

    api = loader({
        invoicePayment: async (
            competitionEntriesRef: number[],
            payingPartyRef: number,
            isPaymentTermsAccepted: boolean
        ) => await payForCompetitionEntriesWithInvoice({
             competitionEntriesRef,
             payingPartyRef,
             isPaymentTermsAccepted
         }),
        createTransaction: async (
            competitionEntriesRef: number[],
            payingPartyRef: number,
            isPaymentTermsAccepted: boolean
        ) => await payForCompetitionEntriesOnline({
                competitionEntriesRef,
                payingPartyRef,
                isPaymentTermsAccepted
            })
    });
    protected async onActivated(): Promise<any> {
        if (this.parent.competitionEntryDto.answers.length === 0) {
            return this.parent.goToFormPage();
        }
        await this.userAddresses.init();
    }

    protected async onLeafFocus(): Promise<any> {
        window.scrollTo({ top: 0 });
    }

    @action
    onAcceptTerms = (value: boolean) => {
        this.isPaymentTermsAccepted = value;
    };

    @computed get competitionStatus() {
        return this.parent.competitionStatus;
    }

    @computed get entryId() {
        return this.parent.entryId;
    }

    @computed get product() {
        return this.parent.product;
    }

    @computed get invoiceFeeProduct() {
        return this.parent.invoiceFeeProduct;
    }

    @computed get totalPrice() {
        if (!this.product || !this.invoiceFeeProduct) {
            return 0;
        }
        let price = this.product.priceIncVat;
        if (this.paymentMethod.selected === PaymentMethod.invoice) {
            price = price + this.invoiceFeeProduct.priceIncVat;
        }

        return price;
    }

    onPay = async () => {
        if (!this.userAddresses.selectedParty || !this.isPaymentTermsAccepted) {
            return;
        }
        if (this.paymentMethod.selected === PaymentMethod.bambora) {
            const result = await this.api.createTransaction(
                [this.entryId],
                this.userAddresses.selectedParty,
                this.isPaymentTermsAccepted
            );

            window.location.href = result.url;
        } else if (this.paymentMethod.selected === PaymentMethod.invoice) {
            const result = await this.api.invoicePayment(
                [this.entryId],
                this.userAddresses.selectedParty,
                this.isPaymentTermsAccepted
            );

            homeRoute.historyPush(
                `/invoicepaymentstatus?status=${result.status}&orderId=${result.orderId}`
            );
        }
    };

    onBack = async () => this.parent.removeChildPage();
}

export default SubmissionPayment;
