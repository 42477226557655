import * as React from 'react';
import style from './ImageDialog.module.scss';
import AntModal from 'common/components/antHelpers/AntModal';
import ImageDialog from './ImageDialog';
import { Button, Carousel } from 'antd';
import ImageGallery from './ImageGallery';

interface ImageDialogProps {
    dialog: ImageDialog;
}

const ImageDialogView: React.FC<ImageDialogProps> = ({ dialog }) => {
    const { images, startIndex } = dialog;

    return (
        <AntModal
            cancel={dialog.cancel}
            className={style.dialog}
            footer={null}
            centered={true}
            closable={false}
            wrapClassName={style.wrapDialog}
            width={'100%'}>
            <Button
                type={'text'}
                onClick={dialog.cancel}
                className={style.closeIcon}>
                <span className={style.icon} />
            </Button>
            <div className={style.imageContainer}>
                <ImageGallery images={images} startIndex={startIndex} />
            </div>
        </AntModal>
    );
};

export default ImageDialogView;
