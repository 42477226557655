import React from 'react';
import {
    RcFile,
    UploadChangeParam,
    UploadProps,
    UploadFile
} from 'antd/lib/upload/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { action } from 'mobx';
import style from './Upload.module.scss';
import { ReactComponent as UploadIcon } from 'common/icons/upload_icon.svg';
import { Upload } from 'antd';
import { observer } from 'mobx-react';

type Props = {
    action?: (file: RcFile) => string;
    customRequest: (options: RcCustomRequestOptions) => void;
    multiple: boolean;
    onChange?: (file: UploadChangeParam<UploadFile<any>>) => void;
    onlyImages?: boolean;
    beforeUpload: (file: RcFile, FileList: RcFile[]) => boolean;
};

const onlyImagesRegex = RegExp(/.(jpg|jpeg|png|gif)$/i);

const AntUploadSingleFile = observer(
    ({
        customRequest,
        multiple,
        onChange,
        onlyImages = false,
        beforeUpload,
        ...props
    }: Props &
        Omit<UploadProps, 'onChange' | 'multiple' | 'customRequest'>) => (
        <Upload.Dragger
            {...props}
            accept={
                onlyImages
                    ? '.png,image/png,.gif,image/gif,.jpg,.jpeg,image/jpeg'
                    : undefined
            }
            className={style.dragger}
            customRequest={customRequest}
            action={action}
            onChange={onChange}
            listType={'picture'}
            multiple={multiple}
            beforeUpload={(file: RcFile, fileList: RcFile[]) => {
                let result = beforeUpload(file, fileList);
                if (onlyImages) {
                    result = onlyImagesRegex.test(file.name);
                }
                return result;
            }}>
            <div className={style.container}>
                <UploadIcon width={40} height={40} />
                <span className={style.text}>
                    Dra filer hit eller{' '}
                    <span className={style.link}>klikk her</span>
                </span>
                <span className={style.hint}>{`JPG, GIF, PNG${
                    onlyImages ? '' : ', PDF, MP3, MP4'
                }`}</span>
                <small className={style.hint}>
                    Enkel fillaster, fil erstattes ved opplastning av ny fil
                </small>
            </div>
        </Upload.Dragger>
    )
);

export default AntUploadSingleFile;
