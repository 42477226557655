import * as React from 'react';
import {CompetitionEntryListItemDto} from "model/Api/Submission/Model/CompetitionEntryListItemDto";
import {useTranslation} from "react-i18next";
import {Button, Table} from "antd";
import style from './EntriesTable.module.scss';
import {AttachmentResponse} from "model/Api/Submission/Model/AttachmentResponse";
import classNames from "classnames";
import WebTag from "web/components/WebTag";
import {FileImageOutlined} from "@ant-design/icons";
import {CompetitionEntryStatus, CompetitionStatus, PaymentMethod, PaymentStatus} from "model/Externals";
import {CategoryInfoResponse} from "model/Api/Submission/Model/CategoryInfoResponse";
import {CompetitionInfoResponse} from "model/Api/Submission/Model/CompetitionInfoResponse";
import {homeRoute, submissionFormPreview, submissionFormRoute} from 'web/routes';
import {ReactElement} from "react";
import {observer} from "mobx-react";
import EditLinkWrapper from "web/screen/Submissions/EntriesTable/EditLinkWrapper";
import Flex from "common/components/Flex";

interface EntriesTableProps {
    entries: CompetitionEntryListItemDto[];
    withSelection: boolean;
    withEditLink: boolean;
    actionButtons: (entry: CompetitionEntryListItemDto) => ReactElement;
    title?: string; 
    onSelect?: (
        record: CompetitionEntryListItemDto,
        selected: boolean,
        selectedRows: any[]
    ) => void;
    onAllSelect?: (
        selected: boolean,
        selectedRows: CompetitionEntryListItemDto[],
        changeRows: CompetitionEntryListItemDto[]
    ) => void;
    selectedRows?: number[];
    pagination?: boolean;
    onSubmitAll?: () =>void;
    lastEditedEntry?: number;
}

const getStatusTag = (m: CompetitionEntryListItemDto, t: any) => {
    if(m.competitionEntryStatus === CompetitionEntryStatus.draft) {
        return <WebTag
            text={t('draft')}
            customStyle={`${style.tag} ${style.draft}`}
        />;
    }
    
    if(m.competitionEntryStatus === CompetitionEntryStatus.submitted) {
        return <WebTag
            text={t('submitted')}
            customStyle={style.tag}
        />;
    }
    
    return null;
}

const getColumns = (
    t: any,
    withSelections: boolean,
    withEditLink: boolean,
    title: string | undefined,
    actionButtons: (entry: CompetitionEntryListItemDto) => ReactElement,
    selectedRows?: number[] | undefined,
    onSubmitAll?: () => void
) => [
    {
        dataIndex: 'coverImage',
        title: !title ? null : <span className={style.title}>{title}</span>,
        colSpan: 4,
        width: 195,
        render: (
            coverImage: AttachmentResponse,
            model: CompetitionEntryListItemDto
        ) => {
            const bg = {
                backgroundImage: !model.coverImage
                    ? ''
                    : `url('${model.coverImage.thumbUrl}')`
            };
            const coverImgStyle = classNames(style.image, {[style.noImg]: !model.coverImage});
            
            return (
                <EditLinkWrapper withEditLink={withEditLink} onClick={() => {
                    model.paymentStatus === PaymentStatus.paid ||
                    (model.paymentStatus === PaymentStatus.pending && model.paymentMethod === PaymentMethod.invoice)
                        ? homeRoute.historyPush(
                            `${submissionFormPreview.getPath({
                                competitionId: model.competition.id.toString(),
                                categoryId: model.category.id.toString(),
                                entryId: model.id.toString()
                            })}`,
                            { status: 'paid', backUrl: '/submissions' }
                        )
                        : homeRoute.historyPush(
                            `${submissionFormRoute.getPath({
                                competitionId: model.competition.id.toString(),
                                categoryId: model.category.id.toString(),
                                entryId: model.id.toString()
                            })}`,
                            { backUrl: '/submissions' }
                        );
                }}>
                    <div className={coverImgStyle}>
                        {getStatusTag(model, t)}
                        {!model.coverImage ? (
                            <FileImageOutlined className={style.icoImg} />
                        ) : (
                            <div className={style.img} style={bg} />
                        )}
                    </div>
                </EditLinkWrapper>
            );
        }
    },
    {
        dataIndex: 'title',
        colSpan: 0,
        width: 300,
        className: style.borderCell,
        render: (title: string, model: CompetitionEntryListItemDto) => {
            return <EditLinkWrapper withEditLink={withEditLink} onClick={() => {
                model.paymentStatus === PaymentStatus.paid ||
                (model.paymentStatus === PaymentStatus.pending && model.paymentMethod === PaymentMethod.invoice)
                    ? homeRoute.historyPush(
                        `${submissionFormPreview.getPath({
                            competitionId: model.competition.id.toString(),
                            categoryId: model.category.id.toString(),
                            entryId: model.id.toString()
                        })}`,
                        { status: 'paid', backUrl: '/submissions' }
                    )
                    : homeRoute.historyPush(
                        `${submissionFormRoute.getPath({
                            competitionId: model.competition.id.toString(),
                            categoryId: model.category.id.toString(),
                            entryId: model.id.toString()
                        })}`,
                        { backUrl: '/submissions' }
                    );
            }} className={style.titleLink}>
                {title}
            </EditLinkWrapper>;
        }
    },
    {
        dataIndex: 'id',
        colSpan: 0,
        width: 105,
        render: (id: number) => <span className={style.id}>{`ID: ${id}`}</span>
    },
    {
        dataIndex: 'competition',
        colSpan: 0,
        //width: 180,
        render: (
            competition: CompetitionInfoResponse,
            model: CompetitionEntryListItemDto
        ) => {
            return ( 
                <span className={style.category}>{competition.name}</span>
            );
        }
    },
    {
        dataIndex: 'category',
        colSpan: 0,
        //width: 250,
        className: style.borderCell,
        render: (category: CategoryInfoResponse) => <span className={style.category}>{`${category.parent.name} / ${category.name}`}</span>
    },
    {
        colSpan: 3,
        title: !onSubmitAll ? '' : <Flex justifyContent={"flex-end"}>
            <Button onClick={onSubmitAll} size={"small"} disabled={!selectedRows?.length}>{t('submitAll')}</Button>
        </Flex> ,
        render: (item: null, record: CompetitionEntryListItemDto) => {
            return actionButtons(record);
        }
    }
];

const EntriesTable: React.FC<EntriesTableProps> = observer(({
                                                                entries,
                                                                withSelection,
                                                                withEditLink,
                                                                actionButtons,
                                                                pagination = true,
                                                                onSubmitAll,
                                                                selectedRows,
                                                                onAllSelect,
                                                                onSelect, 
                                                                lastEditedEntry, 
                                                                title
                                                            }) => {
    

    const {t} = useTranslation('competitions');

    return <Table className={style.table}
                  columns={getColumns(t, withSelection, withEditLink, title, actionButtons, selectedRows, onSubmitAll)}
                  dataSource={entries}
                  rowKey={(record: CompetitionEntryListItemDto) => record.id}
                  pagination={pagination ? {position: ['bottomRight']} : false}
                  rowClassName={(record) => {
                      return record.id === lastEditedEntry ? style.highlite : ''
                  }}
                  rowSelection={withSelection ?
                      {
                          type: 'checkbox',
                          onSelect: onSelect,
                          onSelectAll: onAllSelect,
                          selectedRowKeys: !!selectedRows
                              ? [...selectedRows]
                              : [],
                          getCheckboxProps: (record) => ({
                              disabled: !record.isReadyToApprove || record.competitionEntryStatus === CompetitionEntryStatus.submitted || record.competition.competitionStatus !== CompetitionStatus.submissionOpen
                          })
                      } :
                      undefined}/>;

});

export default EntriesTable;
