import axios, { AxiosRequestConfig } from 'axios';
import { authContext, adalConfig, axiosOptions } from 'auth/Authentication';

function createApi(options?: AxiosRequestConfig) {
    const cachedToken = authContext.getCachedToken(adalConfig.clientId);

    if (!cachedToken || !authContext.getCachedUser()) {
        authContext.login();
    }

    const axiosInstance = axios.create(options);

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${cachedToken}`;

    axiosInstance.interceptors.response.use(
        (response) => response && response.data,
        (err) => {
            if (
                err.response.status === 401 &&
                err.response.config &&
                !err.response.config.__isRetryRequest
            ) {
                err.response.config.__isRetryRequest = true;

                return new Promise((resolve, reject) => {
                    authContext.acquireToken(
                        adalConfig.clientId,
                        (_message, token) => {
                            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                            err.config.headers.Authorization = `Bearer ${token}`;
                            axios(err.config).then(resolve, reject);
                        }
                    );
                });
            }
            throw err;
        }
    );

    authContext.handleWindowCallback();

    return {
        post<K>(url: string, ...props: any): Promise<K> {
            return axiosInstance.post(url, ...props);
        }
    };
}

let _api: ReturnType<typeof createApi> | undefined;

export const api = () => (_api ? _api : (_api = createApi(axiosOptions)));
