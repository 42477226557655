import * as React from 'react';
import style from 'web/screen/Membership/Membership.module.scss';
import MembershipTitle from 'web/components/MembershipTitle';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import AlmostMember from "./AlmostMember";
import Link from "../../../components/Link";
import Flex from 'common/components/Flex';

interface AlmostMemberProps {
    model: AlmostMember
}

const AlmostMemberView: React.FC<AlmostMemberProps> = ({model}) => {
    const { t } = useTranslation(['membership']);
    return (
        <div>
            <div className={style.pageTitle}>
                <MembershipTitle
                    title={model.name}
                    intro={t('membership:myAccount')}
                />
            </div>
            <Empty
                description={model.hasBamboraPaymentFailed ? t('membership:bamboraPaymentFailed') : t('membership:alreadyMember')}
                className={style.empty}>
                <Flex flexDirection="column">
                    <Link onClick={model.onCancelApplicationClicked} arrowIcon="right">{t('membership:deleteAndCreateNewApplication')}</Link>
                    {model.hasBamboraPaymentFailed && <Link onClick={model.onRetryPaymentClicked} arrowIcon="right">{t('membership:retryPaymentForApplication')}</Link>}     
                </Flex>
            </Empty>
        </div>
    );
};

export default AlmostMemberView;
