import {
    Page,
    RequiredFieldValidator,
    asyncCommand,
    RequiredEmailValidator,
    FormField
} from 'react-mvvm';
import { CreateAccountDto } from 'model/Api/Account/Model/CreateAccountDto';
import { observable } from 'mobx';
import { createAccount } from 'model/Api/Account/CreateAccountRequest';
import LoginPage from '../LoginPage/LoginPage';
import ConfirmPhoneNumberPage from '../ConfirmPhoneNumberPage/ConfirmPhoneNumberPage';
import { Form } from '../../../react-mvvm/forms/form';
import { FormFields } from '../../../react-mvvm/forms/form';
import CaptchaField from 'common/components/antHelpers/CaptchaField/CaptchaField';

class CreateAccountPage extends Page {
    @observable createAccountDto: CreateAccountDto;
    @observable createAccountForm: Form<any>;
    @observable accountExistsError: boolean = false;
    @observable errorMessage: string = '';
    @observable isLoading: boolean = false;

    constructor(private parent: LoginPage) {
        super();

        this.createAccountDto = {
            id: 0,
            version: 0,
            email: '',
            given: '',
            family: '',
            phoneNumber: '',
            acceptPrivacyPolicy: false,
            captchaToken: '',
        };

        this.createAccountForm = this.createForm()
    }

    createForm = (): Form<any> => {
        const formFields: FormFields<any> = {};
        formFields['email'] = new FormField('', RequiredEmailValidator);
        formFields['given'] = new FormField('', RequiredFieldValidator);
        formFields['family'] = new FormField('', RequiredFieldValidator);
        formFields['phoneNumber'] = new FormField('', RequiredFieldValidator);
        formFields['acceptPrivacyPolicy'] = new FormField(false, RequiredFieldValidator);
        formFields['captchaToken'] = new CaptchaField('', RequiredFieldValidator);
        return new Form<any>(formFields);
    }

    onSubmit = asyncCommand(
        async () => {
            if (!(await this.createAccountForm.validate())) return;
            this.isLoading = true;
            this.accountExistsError = false;
            this.errorMessage = '';

            const { fields } = this.createAccountForm;
            this.createAccountDto = {
                ...this.createAccountDto,
                email: fields.email.value,
                given: fields.given.value,
                family: fields.family.value,
                phoneNumber: fields.phoneNumber.value,
                acceptPrivacyPolicy: fields.acceptPrivacyPolicy.value,
                captchaToken: fields.captchaToken.value,
            };

            try {
                await createAccount({
                    account: this.createAccountDto
                });
                this.isLoading = false;
                this.showPhoneNumberConfirmationPage(
                    this.createAccountDto.phoneNumber
                );
            } catch (error) {
                if (error.response.status === 400) {
                    (error.response.data.errors as string[]).some((e) => {
                        switch (e) {
                            case 'account already exists':
                                this.accountExistsError = true;
                                break;
                            default:
                                this.errorMessage = e;
                                break;
                        }
                    });
                }
                this.isLoading = false;
            }
        },
        () => this.createAccountForm.isDirty
    );

    onRemoveChildPage = async () => {
        await this.parent.removeChildPage();
    };

    showPhoneNumberConfirmationPage = async (phone: string) =>
        this.showChildPage(new ConfirmPhoneNumberPage(phone));

    resetErrors = () => {
        this.errorMessage = '';
        this.accountExistsError = false;
    };

    goBack = async () => {
        await this.parent.removeChildPage();
    };
}

export default CreateAccountPage;
