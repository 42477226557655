import * as React from 'react';
import Competition from 'web/screen/Competition/Competition';
import { observer } from 'mobx-react';
import { Typography } from 'antd';
import CategoryList from 'web/screen/Competition/CategoryList';
import PageLoader from 'common/components/PageLoader';
import { ContentView } from 'react-mvvm';
import style from './Competition.module.scss';
import PageWrapper from 'web/components/PageWrapper';
import { useTranslation } from 'react-i18next';
import PageTitle from 'web/components/PageTitle';
import BackButton from 'web/components/BackButton';
import SubmissionsHelpText from 'web/components/SubmissionsHelpText';

interface CompetitionViewProps {
    model: Competition;
}

const CompetitionView: React.FC<CompetitionViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['competitions']);
        return (
            <ContentView content={model.childPage}>
                <PageLoader loader={model.api}>
                    <div className={style.container}>
                        <PageWrapper>
                            <BackButton onClick={model.onGoBack} />
                            <PageTitle
                                title={t('selectCategory')}
                                headerNumber={2}
                            />
                            <Typography.Paragraph className={style.dsc}>
                                {model.competition.description}
                            </Typography.Paragraph>
                        </PageWrapper>
                        <CategoryList
                            onCategorySet={model.onCategorySet}
                            competition={model.competition}
                        />
                        {model.showHelpText && (
                            <div className={style.footer}>
                                <SubmissionsHelpText
                                    accentColor={true}
                                    status={model.competition.competitionStatus}
                                />
                            </div>
                        )}
                    </div>
                </PageLoader>
            </ContentView>
        );
    }
);

export default CompetitionView;
