import * as React from 'react';
import JudgingEntryDetails from './JudgingEntryDetails';
import { Button, Col, Collapse, Row, Typography } from 'antd';
import SubmissionsAnswers from 'common/components/SubmissionsAnswers';
import { CategoryEvaluationStatus, CompetitionStatus } from 'model/Externals';
import ActionsPhaseFirst from 'web/components/ActionsPhaseFirst/ActionPhaseFirst';
import ActionsPhaseSecond from 'web/components/ActionsPhaseSecond';
import Sidebar from './Sidebar';
import { observer } from 'mobx-react';
import RenderPreview from 'common/components/Preview/RenderPreview';
import style from './JudgingEntryDetails.module.scss';
import ImageDialog from 'web/components/ImageDialog/ImageDialog';
import ImageDialogView from 'web/components/ImageDialog/ImageDialogView';
import PageLoader from 'common/components/PageLoader';
import { useTranslation } from 'react-i18next';
import Flex from 'common/components/Flex';
import NextPrevNavigation from "./NextPrevNavigation";

const { Panel } = Collapse;

interface JudgingEntryDetailsViewProps {
    model: JudgingEntryDetails;
}

const JudgingEntryDetailsView: React.FC<JudgingEntryDetailsViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['judging', 'common']);
        const { entry, coverImgIdx, sortedAttachments, entryId, onSubmissionChange } = model;
        const { coverImage } = entry;
        const { entryList } = model.parent;

        return (
            <PageLoader loader={model.api}>
                {!model.api.error ? 
                <div className={style.container}>
                    {entryList.length > 0 && <NextPrevNavigation model={model}/> }
                    <Typography.Title level={2} className={style.title}>{entry.title}</Typography.Title>
                    <Row gutter={20}>
                        <Col span={18}>
                            <div className={style.image}>
                                {coverImage && (
                                    <RenderPreview
                                        file={coverImage}
                                        onPreview={() => model.showImageDialog(sortedAttachments, coverImgIdx)}
                                    />
                                )}
                            </div>
                            <div className={style.answerBox}>
                                <Collapse
                                    defaultActiveKey={[model.entryId]}
                                    ghost={true}
                                    expandIconPosition={'right'}>
                                    <Panel key={model.entryId} header={'Id'}>
                                        <span className={style.id}>{model.entryId}</span>
                                    </Panel>
                                </Collapse>
                                {entry.answers.map((answer) => (
                                    <SubmissionsAnswers
                                        answer={answer}
                                        attachments={sortedAttachments}
                                        mode={'preview'}
                                        onFileDialogOpen={model.showImageDialog}
                                        key={answer.id}
                                    />
                                ))}
                            </div>
                            {model.dialog instanceof ImageDialog && (
                                <ImageDialogView dialog={model.dialog} />
                            )}
                        </Col>
                        <Col span={6}>
                            <div className={style.sidebarContainer}>
                                <Typography.Title
                                    level={4}
                                    className={style.sidebarTitle}>{t('judging:point')}
                                </Typography.Title>
                                {(model.competitionStatus ===
                                    CompetitionStatus.phaseOneJudgingInProgress ||
                                    model.competitionStatus ===
                                    CompetitionStatus.phaseOneJudgingClosed) && (
                                    <ActionsPhaseFirst
                                        onEvaluationValueChange={
                                            model.apiEvaluation
                                                .setPhaseOneEvaluation
                                        }
                                        evaluationValue={
                                            entry.phaseOneEvaluation
                                        }
                                        isEditable={
                                            model.evaluationStatus ===
                                            CategoryEvaluationStatus.pending &&
                                            model.isEditable
                                        }
                                    />
                                )}
                                {(model.competitionStatus ===
                                    CompetitionStatus.phaseTwoJudgingInProgress ||
                                    model.competitionStatus ===
                                    CompetitionStatus.phaseTwoJudgingClosed) && (
                                    <ActionsPhaseSecond
                                        evaluationValue={
                                            entry.phaseTwoEvaluation
                                        }
                                        evaluationResults={
                                            model.evaluationResults
                                        }
                                        onEvaluationPhaseTwo={
                                            model.apiEvaluation
                                                .setPhaseTwoEvaluation
                                        }
                                        isEditable={model.isEditable}
                                    />
                                )}
                                {model.competitionStatus ===
                                CompetitionStatus.phaseTwoJudgingInProgress ||
                                model.competitionStatus ===
                                CompetitionStatus.phaseTwoJudgingClosed ? (
                                    <Sidebar
                                        privateNotes={entry.privateNote}
                                        publicNotes={entry.publicNote}
                                        onSavePublicNotes={
                                            model.onPublicNotesSave
                                        }
                                        onSavePrivateNotes={
                                            model.onPrivateNotesSave
                                        }
                                        publicNotesSaveStatus={
                                            model.isSavedPublicNotes
                                        }
                                        privateNoteSaveStatus={
                                            model.isSavePrivateNotes
                                        }
                                    />
                                ) : (
                                    <Sidebar
                                        privateNotes={entry.privateNote}
                                        onSavePrivateNotes={
                                            model.onPrivateNotesSave
                                        }
                                        privateNoteSaveStatus={
                                            model.isSavePrivateNotes
                                        }
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                    {entryList.length > 0 && <NextPrevNavigation model={model} withTopBorder /> }
                </div> : null}
                
            </PageLoader>
        );
    }
);

export default JudgingEntryDetailsView;
