import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {Gender} from "../../Externals"


export interface UpdateMemberPersonalDataRequest {
    birthDate: string
    gender: Gender
    privatePartyId: number
}
export const updateMemberPersonalData = (updateMemberPersonalDataRequest: UpdateMemberPersonalDataRequest): Promise<any> => {
    return send("UpdateMemberPersonalData", updateMemberPersonalDataRequest)
}
