import MembershipApplication, {
    MembershipWizardStep
} from 'web/screen/MembershipApplication/MembershipApplication';
import { computed, observable } from 'mobx';
import { MembershipKind } from 'model/Externals';
import { BindableForm, Form } from 'react-mvvm/forms';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import StudentForm from 'web/screen/MembershipApplication/FormStep/StudentForm/StudentForm';
import ProfessionalForm from 'web/screen/MembershipApplication/FormStep/ProfessionalForm/ProfessionalForm';
import AssociateForm from 'web/screen/MembershipApplication/FormStep/AssociateForm/AssociateForm';
import { MembershipApplicationDto } from 'model/Api/Members/Model/MembershipApplicationDto';

export interface MembershipWizardForm extends MembershipWizardStep {
    onSelectAddress: () => void;
    form: BindableForm<MembershipApplicationDto, any> | Form<any>;
    formValues: Partial<MembershipApplicationDto>;
}

class FormStep implements MembershipWizardStep {
    parent: MembershipApplication;
    @observable userAddresses: UserAddresses | undefined;
    @observable applicationForm: MembershipWizardForm | undefined;
    dateFormat: string = 'DD-MM-YYYY';

    constructor(parent: MembershipApplication) {
        this.parent = parent;
    }

    onActivation = async () => {
        switch (this.subscriptionType) {
            case MembershipKind.student:
                this.applicationForm = new StudentForm(this);
                break;
            case MembershipKind.professional:
                this.applicationForm = new ProfessionalForm(this);
                break;
            case MembershipKind.associate:
                this.applicationForm = new AssociateForm(this);
                break;
            default:
                break;
        }
        this.userAddresses = new UserAddresses(
            this.parent,
            true,
            true,
            this.applicationForm?.onSelectAddress,
            true
        );
        await this.userAddresses.init();
        window.scrollTo({ top: 0 });
    };

    @computed get subscriptionType(): MembershipKind | undefined {
        return this.parent?.subscriptionStep.subscription?.membershipKind;
    }

    @computed get isNextDisable(): boolean {
        return !this.applicationForm
            ? true
            : this.applicationForm.isNextDisable;
    }

    @computed get membershipAppId(): number {
        return !this.parent.initApplication
            ? 0
            : this.parent.initApplication.id;
    }

    @computed get application(): Partial<MembershipApplicationDto> {
        return !this.applicationForm ? {} : this.applicationForm?.formValues;
    }

    onNext = async () => {
        if (
            !!this.parent.initApplication &&
            !!this.applicationForm &&
            (await this.applicationForm.onNext())
        ) {
            this.parent.initApplication = {
                ...this.parent.initApplication,
                ...this.application
            };
            await this.parent.paymentStep.onActivation();
            return true;
        }
        return false;
    };
}

export default FormStep;
