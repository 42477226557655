import React from "react";
import {Button} from "antd";
import style from "./JudgingEntryDetails.module.scss";
import Flex from "../../../common/components/Flex";
import JudgingEntryDetails from "./JudgingEntryDetails";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

interface NextPrevNavigationProps {
    model: JudgingEntryDetails;
    withTopBorder?: boolean;
}

const NextPrevNavigation:React.FC<NextPrevNavigationProps> = ({model, withTopBorder}) => {
    const { entryId, onSubmissionChange, sortedEntry } = model;
    const { t } = useTranslation(['common']);
    const borderStyle = classNames({[style.topBorder]: withTopBorder})
 
    return <Flex justifyContent={'flex-end'} customClass={borderStyle}>
        <Button
            type={'text'}
            onClick={() => onSubmissionChange(-1)}
            className={`${style.navBtn} ${style.navPrevBtn}`}
            disabled={entryId === sortedEntry[0]?.id}
        >
            {t('common:prev')}
        </Button>
        <Button
            type={'text'}
            onClick={() => onSubmissionChange(1)}
            className={style.navBtn}
            disabled={entryId === sortedEntry[sortedEntry.length - 1]?.id}
        >
            {t('common:next')}
        </Button>
    </Flex>;
}

export default NextPrevNavigation