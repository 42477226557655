import * as React from 'react';
import classNames from 'classnames';
import componentStyle from './Link.module.scss';
import { ReactComponent as Next } from 'common/icons/next_icn.svg';
import { ReactComponent as Prev } from 'common/icons/navigation_previous.svg';

interface LinkProps {
    href?: string;
    title?: string;
    size?: 'large' | 'default' | 'small' | 'huge';
    type?: 'plain' | 'default';
    style?: string;
    onClick?: () => void;
    arrowIcon?: 'left' | 'right';
    target?: '_blank' | '_self' | '_parent' | '_top';
    negative?: boolean
}

const Link: React.FC<LinkProps> = ({
    href,
    title = '',
    size = 'default',
    type = 'default',
    style,
    onClick,
    children,
    arrowIcon,
    target,
    negative = false
}) => {
    const linkStyle = classNames(
        `${componentStyle.link}`,
        {[componentStyle.linkNegative]: negative},
        {
            [`${componentStyle.huge}`]: size === 'huge',
            [`${componentStyle.large}`]: size === 'large',
            [`${componentStyle.small}`]: size === 'small',
            [`${componentStyle.plain}`]: type === 'plain'
        },
        !!style && !arrowIcon ? style : ''
    );

    const containerStyle = classNames(
        componentStyle.container,
        !!arrowIcon ? style : ''
    );
    const hrefLink = !!href ? { href } : null;
    const targetLink = !!target ? {target: target} : null; 
    const link = (
        <a
            {...hrefLink}
            {...targetLink}
            title={title}
            className={linkStyle}
            onClick={() => onClick && onClick()}>
            {children}
        </a>
    );
    return !!arrowIcon ? (
        <div className={containerStyle}>
            {!!arrowIcon && arrowIcon === 'left' ? (
                <Prev className={componentStyle.icon} />
            ) : null}
            {link}
            {!!arrowIcon && arrowIcon === 'right' ? (
                <Next className={componentStyle.icon} />
            ) : null}
        </div>
    ) : (
        link
    );
};

export default Link;
