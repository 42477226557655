import * as React from 'react';
import AddressField from './AddressField';
import AntField from 'common/components/antHelpers/AntField';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

interface AddressFieldViewProps {
    model: AddressField | undefined;
}
const regex = new RegExp('https?://');

const AddressFieldView: React.FC<AddressFieldViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['addressField']);
        if (!model) {
            return null;
        }
        return (
            <>
                <AntField
                    field={model.address.fields['line']}
                    label={t('address')}>
                    {(props) => <AntTextInput {...props} />}
                </AntField>
                <AntField
                    field={model.address.fields['postalCode']}
                    label={t('postalCode')}>
                    {(props) => <AntTextInput maxLength={9} {...props} />}
                </AntField>
                <AntField
                    field={model.address.fields['city']}
                    label={t('city')}>
                    {(props) => <AntTextInput {...props} />}
                </AntField>
                <AntField
                    field={model.address.fields['country']}
                    label={t('country')}>
                    {(props) => <AntTextInput {...props} />}
                </AntField>
            </>
        );
    }
);

export default AddressFieldView;
