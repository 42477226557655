import * as React from 'react';
import LoginBox from 'web/screen/LoginPage/LoginBox';
import LoginPage from 'web/screen/LoginPage/LoginPage';
import { observer } from 'mobx-react';
import style from './LoginPage.module.scss';
import { ContentView } from 'react-mvvm';

interface LoginPageProps {
    model: LoginPage;
}

const LoginPageView: React.FC<LoginPageProps> = observer(({ model }) => {
    return (
        <ContentView content={model.childPage}>
            <div className={style.container}>
                <div className={style.content}>
                    <LoginBox
                        state={model}
                        onResetPasswordClick={model.showPasswordResetPage}
                        onCreateAccount={model.showCreateAccountPage}
                    />
                </div>
            </div>
        </ContentView>
    );
});

export default LoginPageView;
