import React  from 'react';
import { InboxOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import styles from './CustomEmpty.module.scss';
import classNames from "classnames";

interface CustomEmptyProps {
  isBottomMargin?: boolean
}

const CustomEmpty: React.FC<CustomEmptyProps> = ({isBottomMargin}) => {
  return <Empty className={classNames(styles.root, {[styles.bottomM]: isBottomMargin})} image={<InboxOutlined className={styles.icon} />} />
};

export default CustomEmpty;
