import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface ValidateTokenRequest {
    accessToken: string
}
export const validateToken = (validateTokenRequest: ValidateTokenRequest): Promise<boolean> => {
    return send("ValidateToken", validateTokenRequest)
}
