import * as React from 'react';
import Link from 'web/components/Link';
import style from './SpecialUrls.module.scss';
import CustomEmpty from "web/components/CustomEmpty";

interface SpecialUrlsProps {
    data: string;
}

const SpecialUrls: React.FC<SpecialUrlsProps> = ({ data }) => {
    const links: string[] = JSON.parse(data);
    const [isEmpty, setIsEmpty] = React.useState(false);
    
    React.useEffect(() => {
        if(links.find(link => link !== '')){
            setIsEmpty(false);
            return
        }
        
        setIsEmpty(true);
    }, [data])

    if(isEmpty) {
        return <CustomEmpty isBottomMargin={true}/>
    }
    
    return (
        <>
            {links.map((link, index) => {
                const regex = new RegExp('https?://');
                const fixedLink = link.replace(regex, '');

                return !!link ? (
                    <Link
                        key={`${link}_${index}`}
                        href={`https://${fixedLink}`}
                        title={link}
                        target={'_blank'}
                        arrowIcon={'right'}
                        style={style.link}>
                        {link}
                    </Link>
                ) : null;
            })}
        </>
    );
};

export default SpecialUrls;
