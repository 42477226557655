import FormStep, {MembershipWizardForm} from 'web/screen/MembershipApplication/FormStep/FormStep';
import {computed, observable} from 'mobx';
import {
    Form,
    FormField,
    NullValidator,
    RequiredFieldValidator
} from 'react-mvvm/forms';
import UrlField from 'common/components/antHelpers/UrlField/UrlFied';
import BirthDatePicker from 'common/components/BirthDatePicker/BirthDatePicker';
import {MembershipApplicationDto} from 'model/Api/Members/Model/MembershipApplicationDto';
import i18next from "i18next";
import {MembershipApplicationLinkDto} from "../../../../../model/Api/Members/Model/MembershipApplicationLinkDto";
import {MembershipApplicationAttachmentDto} from "../../../../../model/Api/Members/Model/MembershipApplicationAttachmentDto";

class ProfessionalForm implements MembershipWizardForm {
    parent: FormStep;
    @observable form: Form<any>;
    @observable formValues: Partial<MembershipApplicationDto> = {};

    constructor(parent: FormStep) {
        this.parent = parent;
        this.form = this.buildMembershipForm();
    }

    @computed get userAddresses() {
        return this.parent.userAddresses;
    }

    @computed get application() {
        return this.parent.application;
    }

    buildMembershipForm = () => {
 
        const linkFormField = new UrlField(
            this.application.membershipApplicationLinks,
            NullValidator
        );
        
        const attachmentField = new FormField(
            this.application?.attachments,
            NullValidator
        );

        for (let link of linkFormField.links) {
            for (let val of link.value.getFields()) {
                const x = val as FormField<MembershipApplicationLinkDto>;
                x.validator = this.attachmentOrLinkShouldBeProvided(linkFormField, attachmentField, true);
            }
        }
        
        attachmentField.validator = this.attachmentOrLinkShouldBeProvided(linkFormField, attachmentField, false);
        
        return new Form({
            birthDate: new BirthDatePicker(
                this.application.birthDate,
                RequiredFieldValidator
            ),
            gender: new FormField(
                this.application.gender,
                RequiredFieldValidator
            ),
            privatePartyId: new FormField(
                this.application.privatePartyId,
                RequiredFieldValidator
            ),
            employerPartyId: new FormField(
                this.application.employerPartyId,
                NullValidator
            ),
            workPosition: new FormField(
                this.application.workPosition,
                RequiredFieldValidator
            ),
            experienceConfirmation: new FormField(
                this.application.experienceConfirmation,
                RequiredFieldValidator
            ),
            membershipApplicationInterests: new FormField(
                this.application.membershipApplicationInterests,
                RequiredFieldValidator
            ),
            membershipApplicationLinks: linkFormField,
            attachments: attachmentField,
            acceptPrivacyPolicy: new FormField(
                this.application.acceptPrivacyPolicy,
                RequiredFieldValidator
            )
        });
    };

    onSaveForm = async (): Promise<boolean> => {
        const validation = await this.form.validate();
        if (validation) {
            const { fields } = this.form;
            this.formValues = {
                ...this.formValues,
                birthDate: fields.birthDate.value,
                gender: fields.gender.value,
                privatePartyId: fields.privatePartyId.value,
                employerPartyId: fields.employerPartyId.value,
                workPosition: fields.workPosition.value,
                experienceConfirmation: fields.experienceConfirmation.value,
                membershipApplicationInterests:
                    fields.membershipApplicationInterests.value,
                membershipApplicationLinks:
                    fields.membershipApplicationLinks.value,
                attachments: fields.attachments.value,
                acceptPrivacyPolicy: fields.acceptPrivacyPolicy.value
            };
            return true;
        }

        return false;
    };
    
    private attachmentOrLinkShouldBeProvided = <T>(linkFormField: UrlField,
                                                   attachments: FormField<MembershipApplicationAttachmentDto[]>, showErrorMessage: boolean): (_: T) => true | string[] => {

        return (_ : T) => {
            const v1 = (linkFormField?.value && linkFormField.value.length > 0 && RequiredFieldValidator(linkFormField.value[0].value)) === true;
            const v2 = RequiredFieldValidator(attachments.value) === true;

            if(v1 || v2) {
                
                for (let link of linkFormField.links) {
                    for (let val of link.value.getFields()) {
                        const x = val as FormField<MembershipApplicationLinkDto>;
                        x.reset(x.value);
                    }
                }
            
                attachments.reset(attachments.value);
            
                return true;
            }

            return showErrorMessage ? [i18next.t('form:oneOfManyRequired')] : [''];
        }
    }

    onNext = async () => {
        return this.onSaveForm();
    };

    @computed get isNextDisable() {
        return (
            this.form.isPristine &&
            !this.userAddresses?.doubleSelectedParty.private &&
            !this.userAddresses?.doubleSelectedParty.company
        );
    }

    onSelectAddress = async () => {
        if (!!this.userAddresses) {
            const { doubleSelectedParty } = this.userAddresses;
            this.form.fields.privatePartyId.value = doubleSelectedParty.private;
            this.form.fields.employerPartyId.value =
                doubleSelectedParty.company;
        }
    };
}

export default ProfessionalForm;
