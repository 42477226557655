import * as React from 'react';
import { observer } from 'mobx-react';
import { SubmissionCompetitionDto } from 'model/Api/Submission/Model/SubmissionCompetitionDto';
import { Collapse } from 'antd';
import style from './CategoryList.module.scss';
import { ReactComponent as ArrowIcon } from 'common/icons/next_icn.svg';
import CategoryListHeader from 'web/screen/Competition/CategoryList/CategoryListHeader';
import PageWrapper from 'web/components/PageWrapper';
import Arrow from 'web/components/Arrow';
import Flex from 'common/components/Flex';

interface CategoryListProps {
    competition: SubmissionCompetitionDto;

    onCategorySet: (id: number) => void;
}

const CategoryList: React.FC<CategoryListProps> = observer(
    ({ competition, onCategorySet }) => {
        return (
            <Collapse
                className={style.collapse}
                accordion
                expandIconPosition={'right'}
                expandIcon={() => (
                    <span>
                        <ArrowIcon />
                    </span>
                )}>
                {competition.categories.map((category) => {
                    return category.subCategories.length > 0 ? (
                        <Collapse.Panel
                            key={category.id}
                            header={
                                <CategoryListHeader
                                    title={category.name}
                                    customStyle={style.collapseWidth}
                                />
                            }>
                            <PageWrapper>
                                <span className={style.categoryDsc}>
                                    {category.description}
                                </span>
                            </PageWrapper>
                            <ul className={style.ul}>
                                {category.subCategories.map((subcategory) => {
                                    return (
                                        <li
                                            className={style.subcategory}
                                            key={subcategory.id}
                                            onClick={() =>
                                                onCategorySet(subcategory.id)
                                            }>
                                            <PageWrapper>
                                                <Flex
                                                    justifyContent={
                                                        'flex-start'
                                                    }
                                                    alignItems={'center'}>
                                                    <span
                                                        className={
                                                            style.collapseIcon
                                                        }>
                                                        <Arrow />
                                                    </span>
                                                    <span
                                                        className={style.title}>
                                                        {subcategory.name}
                                                    </span>
                                                    <span className={style.dsc}>
                                                        {
                                                            subcategory.description
                                                        }
                                                    </span>
                                                </Flex>
                                            </PageWrapper>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Collapse.Panel>
                    ) : (
                        <CategoryListHeader
                            key={category.id}
                            title={category.name}
                            description={category.description}
                            onClick={() => onCategorySet(category.id)}
                            customStyle={style.category}
                        />
                    );
                })}
            </Collapse>
        );
    }
);

export default CategoryList;
