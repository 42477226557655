import { Page } from 'react-mvvm';
import { action, computed, observable } from 'mobx';
import Membership from 'web/screen/Membership/Membership';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import loader from 'react-mvvm/loading/loader';
import { PaymentMethod, SubscriptionType } from 'model/Externals';
import { getMembershipPrice } from 'model/Api/Members/GetMembershipPriceRequest';
import { MembershipPriceResponse } from 'model/Api/Members/Model/MembershipPriceResponse';
import { changeMembershipSubscription } from 'model/Api/Members/ChangeMembershipSubscriptionRequest';

class MembershipPaymentPage extends Page {
    @observable parent: Membership;
    @observable userAddresses: UserAddresses;
    @observable subscriptionType: SubscriptionType | undefined;
    @observable paymentMethod: PaymentMethod | undefined;
    @observable isPaymentTermsAccepted: boolean = false;
    @observable membershipPrice: MembershipPriceResponse | undefined;

    constructor(parent: Membership) {
        super();

        this.parent = parent;
        this.userAddresses = new UserAddresses(this, true, true);
    }

    protected async onActivated(): Promise<any> {
        if (!this.parent.member) {
            await this.parent.removeAllChildPages(this.parent as Page);
            await this.parent.activate();
        }
        window.scrollTo({ top: 0 });

        if (this.parent.membershipType) {
            this.membershipPrice = await this.api.getMembershipPrice(
                this.parent.membershipType.id
            );
        }
        await this.userAddresses.init();
        if (this.userAddresses.parties.length > 0)
            this.userAddresses.selectedParty = this.userAddresses.parties[0].id;
    }

    api = loader({
        changeMembershipSubscription: (
            paymentMethod: PaymentMethod,
            subscriptionType: SubscriptionType,
            paymentTermsAccepted: boolean,
            payingPartyId: number
        ) =>
            changeMembershipSubscription({
                paymentMethod,
                subscriptionType,
                paymentTermsAccepted,
                payingPartyId
            }),
        getMembershipPrice: (typeId: number) =>
            getMembershipPrice({
                typeId
            })
    });

    @computed get membership() {
        return this.parent.member;
    }

    @action
    onSubscriptionCheck = (value: SubscriptionType) => {
        this.subscriptionType = value;
    };

    @action
    onTermsCheck = (value: boolean) => {
        this.isPaymentTermsAccepted = value;
    };

    @action
    onPaymentMethodCheck = (values: PaymentMethod) => {
        this.paymentMethod = values;
    };

    @computed get subscription() {
        return this.parent.membershipType;
    }

    onChangeMembershipSubscription = async () => {
        if (!!this.subscriptionType && !!this.paymentMethod) {
            const result = await this.api.changeMembershipSubscription(
                this.paymentMethod,
                this.subscriptionType,
                this.isPaymentTermsAccepted,
                this.userAddresses.selectedParty ?? 0
            );

            if (
                result.currentSubscription.paymentMethod ===
                    PaymentMethod.bambora &&
                result.token?.url
            ) {
                window.location.href = result.token.url;
            } else {
                if (this.parent.member) {
                    this.parent.member.currentMembership.currentSubscription =
                        result.currentSubscription;
                }

                await this.parent.removeChildPage();
            }
        }
    };
    
    @computed get userName() {
        return this.parent.userName
    }

    @computed get isPaymentDisable() {
        return (
            !this.subscriptionType ||
            !this.paymentMethod ||
            !this.isPaymentTermsAccepted
        );
    }

    onBck = async () => {
        await this.parent.removeAllChildPages(this.parent as Page);
        await this.parent.activate();
    };
}

export default MembershipPaymentPage;
