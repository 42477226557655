import * as React from 'react';
import AntModal from 'common/components/antHelpers/AntModal';
import { useTranslation } from 'react-i18next';
import UpdateCartInfoDialog from './UpdateCartInfoDialog';

interface UpdateCartInfoDialogViewProps {
    dialog: UpdateCartInfoDialog;
}

const UpdateCartInfoDialogView: React.FC<UpdateCartInfoDialogViewProps> = ({
    dialog
}) => {
    const { t } = useTranslation(['membership']);
    return (
        <AntModal
            cancel={dialog.cancel}
            okCommand={dialog.confirm}
            title={t('updateCartTitle')}>
            <span>{t('updateCart')}</span>
        </AntModal>
    );
};

export default UpdateCartInfoDialogView;
