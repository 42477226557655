import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from 'antd';
import style from './SaveDraftButton.module.scss';
import { useTranslation } from 'react-i18next';
import {ButtonType} from "antd/lib/button/button";

interface SaveDraftButtonProps {
    onClick: () => void;
    isDisabled?: boolean;
    label?: string;
    type?: ButtonType
}

const SaveDraftButton: React.FC<SaveDraftButtonProps> = observer(
    ({ onClick, isDisabled = false, label, type= 'default' }) => {
        const { t } = useTranslation('common');
        return (
            <Button
                className={style.saveAsDraft}
                type={type}
                disabled={isDisabled}
                onClick={onClick}>
                {!label ? t('saveAsDraft') : label}
            </Button>
        );
    }
);

export default SaveDraftButton;
