import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface DeleteAttachmentRequest {
    attachmentRef: number
}
export const deleteAttachment = (deleteAttachmentRequest: DeleteAttachmentRequest): Promise<any> => {
    return send("DeleteAttachment", deleteAttachmentRequest)
}
