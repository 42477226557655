import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface NominateCompetitionEntryRequest {
    competitionEntryRef: number
}
export const nominateCompetitionEntry = (nominateCompetitionEntryRequest: NominateCompetitionEntryRequest): Promise<any> => {
    return send("NominateCompetitionEntry", nominateCompetitionEntryRequest)
}
