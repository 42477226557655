import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {PaymentDto} from "../Payments/Model/PaymentDto"

export interface GetMembershipApplicationPaymentRequest {
}
export const getMembershipApplicationPayment = (getMembershipApplicationPaymentRequest: GetMembershipApplicationPaymentRequest): Promise<PaymentDto> => {
    return send("GetMembershipApplicationPayment", getMembershipApplicationPaymentRequest)
}
