import * as React from 'react';
import style from './BirthDatePicker.module.scss';
import BirthDatePicker from './BirthDatePicker';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import { Col, DatePicker, Row, Select } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import {useTranslation} from "react-i18next";

interface BirthDatePickerViewProps {
    model: BirthDatePicker;
}

const BirthDatePickerView: React.FC<BirthDatePickerViewProps> = observer(
    ({ model }) => {
        const { fields } = model.dateForm;
        const { t } = useTranslation('common');
        return (
            <div>
                <span className={style.label}>{t('birthDate')}</span>
                <Row gutter={10}>
                    <Col span={8}>
                        <AntField field={fields.month} label={'Måned'}>
                            {(props) => {
                                return (
                                    <Select
                                        placeholder={'Måned'}
                                        value={props.value}
                                        onChange={(e) => props.onChange(e)}>
                                        {model.monthList.map((item) => (
                                            <Select.Option
                                                value={item.index}
                                                key={`monthItem__${item.index}`}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                );
                            }}
                        </AntField>
                    </Col>
                    <Col span={8}>
                        <AntField field={fields.day} label={'Dag'}>
                            {(props) => {
                                return (
                                    <Select
                                        placeholder={'Dag'}
                                        value={props.value}
                                        onChange={(e) => props.onChange(e)}>
                                        {model.days.map((item) => (
                                            <Select.Option
                                                value={item}
                                                key={`day__${item}`}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                );
                            }}
                        </AntField>
                    </Col>
                    <Col span={8}>
                        <AntField field={fields.year} label={'År'}>
                            {(props) => (
                                <DatePicker
                                    style={{ width: '100%' }}
                                    value={props.value}
                                    defaultPickerValue={moment(new Date()).add(
                                        -18,
                                        'year'
                                    )}
                                    onChange={props.onChange}
                                    picker='year'
                                    disabledDate={(current) =>
                                        current &&
                                        current >
                                            moment(new Date()).add(-18, 'year')
                                    }
                                />
                            )}
                        </AntField>
                    </Col>
                </Row>
            </div>
        );
    }
);

export default BirthDatePickerView;
