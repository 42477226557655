import * as React from 'react';
import CompetitionsList from './CompetitionsList';
import PageLoader from 'common/components/PageLoader';
import List from './List';
import { useTranslation } from 'react-i18next';
import { CompetitionListItemType } from 'web/components/CompetitionsList/List/List';
import { observer } from 'mobx-react';

interface CompetitionsListViewProps {
    model: CompetitionsList;
    onDetails?: (id: number) => void;
}

const CompetitionsListView: React.FC<CompetitionsListViewProps> = observer(
    ({ model, onDetails }) => {
        const { t } = useTranslation('competitions');

        return (
            <PageLoader loader={model.api}>
                <List
                    title={t('currentCompetitions')}
                    competitions={model.sorted.current}
                    type={CompetitionListItemType.current}
                    onDetails={(id) =>
                        !!onDetails ? onDetails(id) : model.onDetails(id)
                    }
                />
                {!!model.sorted.future.length && (
                    <List
                        title={t('futureCompetitions')}
                        competitions={model.sorted.future}
                        type={CompetitionListItemType.future}
                        onDetails={(id) =>
                            !!onDetails ? onDetails(id) : model.onDetails(id)
                        }
                    />
                )}
            </PageLoader>
        );
    }
);

export default CompetitionsListView;
