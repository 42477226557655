import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {OnlinePaymentTokenDto} from "../Payments/Model/OnlinePaymentTokenDto"

export interface RetryOnlinePaymentRequest {
}
export const retryOnlinePayment = (retryOnlinePaymentRequest: RetryOnlinePaymentRequest): Promise<OnlinePaymentTokenDto> => {
    return send("RetryOnlinePayment", retryOnlinePaymentRequest)
}
