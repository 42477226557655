import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface SetPrivateNoteRequest {
    competitionEntryRef: number
    privateNote: string
}
export const setPrivateNote = (setPrivateNoteRequest: SetPrivateNoteRequest): Promise<any> => {
    return send("SetPrivateNote", setPrivateNoteRequest)
}
