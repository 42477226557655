﻿import * as React from 'react';
import moment from 'moment';
import { Typography } from 'antd';

interface DateQuestionProps {
    value: string;
}

const DateQuestion: React.FC<DateQuestionProps> = ({ value }) => {
    const date = !!value ? moment(new Date(value)) : undefined;
    return <Typography.Text>{!date ? '-' : date.format('DD MMMM YYYY')}</Typography.Text>;
};

export default DateQuestion;
