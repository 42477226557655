import * as React from 'react';
import MembershipApplication from 'web/screen/MembershipApplication/MembershipApplication';
import { useTranslation } from 'react-i18next';
import style from './MembershipApplication.module.scss';
import { observer } from 'mobx-react';
import { PaymentMethod, SubscriptionType } from 'model/Externals';
import { formatPrice } from "../../../common/utils";

interface PaymentSummaryProps {
    model: MembershipApplication;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = observer(({ model }) => {
    const { t } = useTranslation('membership');

    return (
        <div className={style.summary}>
            <span className={style.summaryTitle}>
                {t('paymentPeriod', {
                    type:
                        model.paymentStep.subscriptionType ===
                        SubscriptionType.annual
                            ? t('annual')
                            : t('monthly')
                })}
            </span>
            <span className={style.summaryPrice}>
                {`kr. ${formatPrice(
                    model.paymentStep.subscriptionType ===
                    SubscriptionType.annual
                        ? model.paymentStep.membershipPrice
                              ?.annualMembershipPriceIncInvoiceFee
                        : model.paymentStep.membershipPrice
                              ?.monthlyMembershipPriceIncInvoiceFee)
                }`}
            </span>

            {!!model.subscriptionStep &&
                model.paymentStep.paymentMethod === PaymentMethod.invoice &&
                !!model.subscriptionStep.subscription
                    ?.invoiceFeePriceIncVat && (
                    <span className={style.summaryInfo}>{`(kr. ${
                        model.paymentStep.subscriptionType ===
                        SubscriptionType.annual
                            ? model.paymentStep.membershipPrice
                                  ?.annualMembershipPrice
                            : model.paymentStep.membershipPrice
                                  ?.monthlyMembershipPrice
                    } + kr. ${
                        model.subscriptionStep.subscription
                            ?.invoiceFeePriceIncVat
                    },- med faktura)`}</span>
                )}

            {model.paymentStep.subscriptionType ===
                SubscriptionType.monthly && (
                <span className={style.summaryInfo}> / hver måned</span>
            )}
        </div>
    );
});

export default PaymentSummary;
