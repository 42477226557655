import {
    FormField,
    Form,
    FormFieldValidator,
    FormState
} from 'react-mvvm/forms';
import { observable, computed } from 'mobx';
import { command } from 'react-mvvm/commands';
import { buildUrl } from 'common/utils';

class UrlField extends FormField<any> {
    @observable urls: Form<any>[] = [];
    readonly fieldValidator: FormFieldValidator<string>;
    private isErrorState = false;
    private isValidatingProcess = false;
    @observable errors: string[] = [];

    constructor(
        value: string | undefined,
        validator: FormFieldValidator<string>
    ) {
        super(value, validator);

        this.fieldValidator = validator;

        if (!value) {
            this.addUrl.execute();
            return;
        }

        this.value = value;
    }

    @computed get value() {
        const allUrls = this.urls.map((url) =>
            !url.fields['url'].value ? '' : url.fields['url'].value
        );

        return JSON.stringify(allUrls);
    }

    set value(data: string | undefined) {
        if (!!data) {
            const urlForms: string[] = JSON.parse(data);
            this.urls = urlForms.map((row) =>
                buildUrl(this.fieldValidator, !row ? undefined : row)
            );
        }
    }

    @computed get state(): FormState {
        if (this.isErrorState) {
            return FormState.Invalid;
        }

        if (this.isValidatingProcess) {
            return FormState.Pending;
        }

        return FormState.Valid;
    }

    @computed get isPristine() {
        return this.urls.some((form) => form.isPristine);
    }

    addUrl = command(() => this.urls.push(buildUrl(this.fieldValidator)));

    removeUrl = (index: number) => this.urls.splice(index, 1);

    async validate(): Promise<true | readonly string[]> {
        let isValid = true;
        this.errors = [];
        this.isErrorState = false;
        this.isValidatingProcess = true;

        for (const url of this.urls) {
            const validation = await url.validate();
            if (!validation) {
                if(!!url.fields.url.errors.length) {
                    this.errors = [...this.errors, ...url.fields.url.errors];
                }
                isValid = false;
                this.isErrorState = true;
            }
        }

        this.isValidatingProcess = false;
        return isValid || [];
    }
}

export default UrlField;
