import Membership from 'web/screen/Membership/Membership';
import { action, computed, observable } from 'mobx';
import {
    Form,
    FormField,
    RequiredFieldValidator
} from 'react-mvvm/forms';
import { MemberDto } from 'model/Api/Members/Model/MemberDto';

class ProfessionalInfo {
    parent: Membership;
    myInterestsForm: Form | undefined;

    @observable isMyInterestsLoaded: boolean = false;
    @observable isMyInterestsEdited: boolean = false;

    constructor(parent: Membership) {
        this.parent = parent;
    }

    @computed get member(): MemberDto | undefined {
        return this.parent.member;
    }
    
    onInit = async () => {
        if (!!this.member) {

            this.myInterestsForm = new Form<any>({
                interests: new FormField(
                    this.member.interests,
                    RequiredFieldValidator
                )
            });
        }
    };

    onSave = async () => {
        if (!!this.myInterestsForm && !!this.member) {
            this.isMyInterestsLoaded = true;
            const { fields } = this.myInterestsForm;
            
            await this.parent.api.updateMemberInterests(fields.interests.value)
            this.parent.member = await this.parent.api.getMember();
            
            this.onMyInterestsState(false);
            this.isMyInterestsLoaded = false;
        }
    };

    @action
    onMyInterestsState = (state: boolean) => (this.isMyInterestsEdited = state);
}

export default ProfessionalInfo;
