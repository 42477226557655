import React from 'react';
import FilePdfIcon from '../FilePdfIcon';
import SoundIcon from '../SoundIcon';
import VideoCameraIcon from '../VideoCameraIcon';

interface RenderIconProps {
    url: string;
    fileName?: string;
    type: string;
}

const RenderIcon: React.FC<RenderIconProps> = ({ url, type, fileName }) => {
    switch (type) {
        case 'application/pdf':
            return <FilePdfIcon />;
        case 'audio/mpeg': //mp3
            return <SoundIcon />;
        case 'video/mp4':
            return <VideoCameraIcon />;
        default:
            return <img src={url} alt={fileName ?? ''} />;
    }
};

export default RenderIcon;
