import * as React from 'react';
import style from './Arrow.module.scss';
import classNames from 'classnames';

interface ArrowProps {
    direction?: 'left' | 'right';
    color?: string;
    customStyle?: string;
}

const Arrow: React.FC<ArrowProps> = ({
    direction = 'right',
    color = '#979797',
    customStyle
}) => {
    const pointUpper = classNames(style.point, {
        [style.leftUpper]: direction === 'left',
        [style.rightUpper]: direction === 'right'
    });
    const pointLower = classNames(style.point, {
        [style.leftLower]: direction === 'left',
        [style.rightLower]: direction === 'right'
    });
    const bgColor = { color: color };
    
    const arrow = classNames(style.arrow, !!customStyle && customStyle);

    return (
        <span className={arrow} style={bgColor}>
            <span className={pointUpper} />
            <span className={style.line} />
            <span className={pointLower} />
        </span>
    );
};

export default Arrow;
