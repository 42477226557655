import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {CompetitionEntryDto} from "./Model/CompetitionEntryDto"


export interface CreateCompetitionEntryRequest {
    categoryRef: number
    competitionEntryDto: CompetitionEntryDto
}
export const createCompetitionEntry = (createCompetitionEntryRequest: CreateCompetitionEntryRequest): Promise<CompetitionEntryDto> => {
    return send("CreateCompetitionEntry", createCompetitionEntryRequest)
}
