import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {ListOptions} from "../GenericList/ListOptions"

import {CategoryCompetitionEntriesResponse} from "./Model/CategoryCompetitionEntriesResponse"

export interface GetCompetitionEntriesRequest {
    categoryRef: number
    options?: ListOptions
}
export const getCompetitionEntries = (getCompetitionEntriesRequest: GetCompetitionEntriesRequest): Promise<CategoryCompetitionEntriesResponse> => {
    return send("GetCompetitionEntries", getCompetitionEntriesRequest)
}
