﻿import * as React from 'react';
import { Collapse, Space } from 'antd';
import { observer } from 'mobx-react';
import Note from './Note';
import { CheckCircleOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";

const { Panel } = Collapse;

interface SidebarProps {
    publicNotes?: string;
    privateNotes: string;
    onSavePublicNotes?: (value: string) => void;
    onSavePrivateNotes: (value: string) => void;
    publicNotesSaveStatus?: boolean;
    privateNoteSaveStatus: boolean;
}

const Sidebar: React.FC<SidebarProps> = observer(
    ({
        publicNotes,
        privateNotes,
        onSavePublicNotes,
        onSavePrivateNotes,
        publicNotesSaveStatus,
        privateNoteSaveStatus
    }) => {
        const { t } = useTranslation(['judging', 'common']);
        return (
            <>
                <Collapse
                    defaultActiveKey={[1, 2]}
                    expandIconPosition={'right'}>
                    {onSavePublicNotes && (
                        <Panel key={1} header={t('judging:juryComments')}>
                            <Note
                                note={publicNotes ?? ''}
                                onSaveNote={onSavePublicNotes}
                            />
                            {!!publicNotesSaveStatus && (
                                <Space>
                                    <CheckCircleOutlined />
                                    Saved
                                </Space>
                            )}
                        </Panel>
                    )}
                    <Panel key={2} header={t('judging:myComments')}>
                        <Note
                            note={privateNotes}
                            onSaveNote={onSavePrivateNotes}
                        />
                        {privateNoteSaveStatus && (
                            <Space>
                                <CheckCircleOutlined />
                                {t('common:save')}
                            </Space>
                        )}
                    </Panel>
                </Collapse>
            </>
        );
    }
);

export default Sidebar;
