import * as React from 'react';
import PaymentStep from './PaymentStep';
import { observer } from 'mobx-react';
import { PaymentMethod, SubscriptionType } from 'model/Externals';
import style from './PaymentStep.module.scss';
import rootStyle from '../MembershipApplication.module.scss';
import { useTranslation } from 'react-i18next';
import AllUsersAddressDropdown from 'common/components/UserAddresses/AllUsersAddressDropdown';
import FormName from 'web/screen/MembershipApplication/FormName';
import PaymentCheckbox from 'web/components/PaymentCheckbox';
import Flex from 'common/components/Flex';
import { Typography } from 'antd';
import PaymentTermsAndConditions from 'web/components/PaymentTermsAndConditions';
import { formatPrice } from "../../../../common/utils";
import PageLoader from "common/components/PageLoader";

interface PaymentStepViewProps {
    model: PaymentStep;
}

const PaymentStepView: React.FC<PaymentStepViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['membership', 'common']);

        return (
            <PageLoader loader={model.api}>
                <FormName title={t('membership:paymentTitle')} />
                <div className={rootStyle.formBox}>
                    <Flex
                        justifyContent={'flex-start'}
                        alignItems={'stretch'}
                        customClass={style.row}>
                        <PaymentCheckbox
                            selected={
                                model.subscriptionType ===
                                SubscriptionType.annual
                            }
                            onSelect={() =>
                                model.onSubscriptionCheck(
                                    SubscriptionType.annual
                                )
                            }
                            price={`kr. ${formatPrice(model.membershipPrice?.annualMembershipPrice)}`}
                            title={t('membership:annual')}
                            dsc={t('membership:annualTitle')}
                        />
                        <PaymentCheckbox
                            selected={
                                model.subscriptionType ===
                                SubscriptionType.monthly
                            }
                            onSelect={() =>
                                model.onSubscriptionCheck(
                                    SubscriptionType.monthly
                                )
                            }
                            price={`kr. ${formatPrice(model.membershipPrice?.monthlyMembershipPrice)}`}
                            priceDsc={'/ hver måned'}
                            title={t('membership:monthly')}
                            dsc={t(model.parent.parent.isMember ? 'membership:monthlyTitleNoBindingYear' : 'membership:monthlyTitle')}
                        />
                    </Flex>
                    <div className={style.row}>
                        <Typography.Text className={style.rowTitle}>
                            {t('membership:paymentMethod')}
                        </Typography.Text>
                        <Flex
                            justifyContent={'flex-start'}
                            alignItems={'stretch'}>
                            <PaymentCheckbox
                                selected={
                                    model.paymentMethod ===
                                    PaymentMethod.bambora
                                }
                                onSelect={() =>
                                    model.onPaymentMethodCheck(
                                        PaymentMethod.bambora
                                    )
                                }
                                title={t('membership:payCard')}
                                dsc={t('membership:payCardDsc')}
                                isBorder={false}
                                isDisabled={
                                    process.env.REACT_APP_BAMBORA_OFF === 'true' ||
                                    !model.subscriptionType
                                }
                            />
                            <PaymentCheckbox
                                selected={
                                    model.paymentMethod ===
                                    PaymentMethod.invoice
                                }
                                onSelect={() =>
                                    model.onPaymentMethodCheck(
                                        PaymentMethod.invoice
                                    )
                                }
                                title={t('membership:payInvoice')}
                                priceDsc={model.membershipPrice?.invoiceFeePrice && t('membership:payInvoiceTxt', { amount: model.membershipPrice.invoiceFeePrice}) || ''}
                                dsc={t('membership:payInvoiceDsc')}
                                isBorder={false}
                                isDisabled={model.subscriptionType !== SubscriptionType.annual}
                            />
                        </Flex>
                    </div>
                    <div className={style.row}>
                        <Typography.Text className={style.rowTitle}>
                            {t('membership:selectAddressTitle')}
                        </Typography.Text>
                        <AllUsersAddressDropdown model={model.userAddresses} />
                    </div>
                    <PaymentTermsAndConditions
                        onSelect={model.onTermsCheck}
                        selected={model.isPaymentTermsAccepted}
                    />
                </div>
            </PageLoader>
        );
    }
);

export default PaymentStepView;
