import * as React from 'react';
import ReactDOM from 'react-dom';
//[TODO] check import of global array extenstions in admin and web
import 'react-mvvm/arrayExtensions';
import { homeRoute } from 'web/routes';
import { createBrowserHistory } from 'history';
import { ContentView, registerContentView } from 'react-mvvm';
import HomePage from 'web/screen/HomePage/HomePage';
import HomePageView from 'web/screen/HomePage/HomePageView';
import LoginPage from 'web/screen/LoginPage/LoginPage';
import LoginPageView from 'web/screen/LoginPage/LoginPageView';
import { api } from 'web/api/config';
import { init } from 'api';
import AcceptInvitationPage from 'web/screen/AcceptInvitation/AcceptInvitationPage';
import AcceptInvitationPageView from 'web/screen/AcceptInvitation/AcceptInvitationPageView';
import 'normalize.css';
import './webStyle.scss';
import CompetitionsListPage from 'web/screen/CompetitionsListPage/CompetitionsListPage';
import CompetitionsListPageView from 'web/screen/CompetitionsListPage/CompetitionsListPageView';
import Competition from 'web/screen/Competition/Competition';
import CompetitionView from 'web/screen/Competition/CompetitionView';
import SubmissionFormPage from 'web/screen/SubmissionFormPage/SubmissionFormPage';
import SubmissionFormPageView from 'web/screen/SubmissionFormPage/SubmissionFormPageView';
import SubmissionFormPreviewPage from 'web/screen/SubmissionFormPreview/SubmissionFormPreviewPage';
import SubmissionFormPreviewPageView from 'web/screen/SubmissionFormPreview/SubmissionFormPreviewPageView';

import JudgingCompetitionsView from 'web/screen/JudgingCompetitions/JudgingCompetitionsView';
import JudgingCompetitions from 'web/screen/JudgingCompetitions/JudgingCompetitions';
import JudgingIntroPage from 'web/screen/JudgingIntroPage/JudgingIntroPage';
import JudgingIntroPageView from 'web/screen/JudgingIntroPage/JudgingIntroPageView';
import JudgingCategoryPage from 'web/screen/JudgingCategoryPage/JudgingCategoryPage';
import JudgingCategoryPageView from 'web/screen/JudgingCategoryPage/JudgingCategoryPageView';
import JudgingEntriesPage from 'web/screen/JudgingEntriesPage/JudgingEntriesPage';
import JudgingEntriesPageView from 'web/screen/JudgingEntriesPage/JudgingEntriesPageView';
import JudgingEntryDetails from 'web/screen/JudgingEntryDetails/JudgingEntryDetails';
import JudgingEntryDetailsView from 'web/screen/JudgingEntryDetails/JudgingEntryDetailsView';
import AskForPasswordResetPage from 'web/screen/AskForPasswordResetPage/AskForPasswordResetPage';
import AskForPasswordResetPageView from 'web/screen/AskForPasswordResetPage/AskForPasswordResetPageView';
import CreateAccountPage from 'web/screen/CreateAccountPage/CreateAccountPage';
import CreateAccountPageView from 'web/screen/CreateAccountPage/CreateAccountPageView';
import Membership from 'web/screen/Membership/Membership';
import MembershipView from 'web/screen/Membership/MembershipView';
import Invoices from 'web/screen/Invoices/Invoices';
import InvoicesView from 'web/screen/Invoices/InvoicesView';
import OnlinePaymentStatusPage from 'web/screen/SubmissionStatusPage/OnlinePaymentStatusPage/OnlinePaymentStatusPage';
import OnlinePaymentStatusPageView from 'web/screen/SubmissionStatusPage/OnlinePaymentStatusPage/OnlinePaymentStatusPageView';
import SubmissionPayment from 'web/screen/SubmissionPayment/SubmissionPayment';
import SubmissionPaymentView from 'web/screen/SubmissionPayment/SubmissionPaymentView';
import InvoicePaymentStatusPageView from 'web/screen/SubmissionStatusPage/InvoicePaymentStatusPage/InvoicePaymentStatusPageView';
import InvoicePaymentStatusPage from 'web/screen/SubmissionStatusPage/InvoicePaymentStatusPage/InvoicePaymentStatusPage';
import MembershipApplication from 'web/screen/MembershipApplication/MembershipApplication';
import MembershipApplicationView from 'web/screen/MembershipApplication/MembershipApplicationView';
import ConfirmPhoneNumberPage from 'web/screen/ConfirmPhoneNumberPage/ConfirmPhoneNumberPage';
import ConfirmPhoneNumberPageView from 'web/screen/ConfirmPhoneNumberPage/ConfirmPhoneNumberPageView';
import './i18n';
import ResetPasswordByEmailPage from 'web/screen/ResetPasswordPage/ResetPasswordByEmailPage/ResetPasswordByEmailPage';
import ResetPasswordByEmailPageView from 'web/screen/ResetPasswordPage/ResetPasswordByEmailPage/ResetPasswordByEmailPageView';
import ResetPasswordByPhonePage from 'web/screen/ResetPasswordPage/ResetPasswordByPhonePage/ResetPasswordByPhonePage';
import ResetPasswordByPhonePageView from 'web/screen/ResetPasswordPage/ResetPasswordByPhonePage/ResetPasswordByPhonePageView';
import Submissions from 'web/screen/Submissions/Submissions';
import SubmissionsView from 'web/screen/Submissions/SubmissionsView';
import { ConfigProvider } from 'antd';
import nbNO from 'antd/es/locale/nb_NO';
import enGB from 'antd/es/locale/en_GB';
import moment from 'moment';
import 'moment/locale/nb';
import MembershipPaymentPage from 'web/screen/MembershipPaymentPage/MembershipPaymentPage';
import MembershipPaymentPageView from 'web/screen/MembershipPaymentPage/MembershipPaymentPageView';
import MembershipReapplyPage from "./web/screen/MembershipReapplyPage/MembershipReapplyPage";
import MembershipReapplyPageView from "./web/screen/MembershipReapplyPage/MembershipReapplyPageView";
import HandelkurvView from "web/screen/Handelkurv/HandelkurvView";
import Handelkurv from "web/screen/Handelkurv/Handelkurv";
import SubmissionsMyPagePayment from "web/screen/SubmissionsMyPagePayment/SubmissionsMyPagePayment";
import SubmissionsMyPagePaymentView from "web/screen/SubmissionsMyPagePayment/SubmissionsMyPagePaymentView";

// @ts-ignore
import pdfjs from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = "https://d2v5g.csb.app/pdf.worker.js";

init(api);

registerContentView(HomePage, (p) => <HomePageView model={p} />);
registerContentView(LoginPage, (p) => <LoginPageView model={p} />);
registerContentView(Membership, (p) => <MembershipView model={p} />);
registerContentView(MembershipPaymentPage, (p) => (
    <MembershipPaymentPageView model={p} />
));
registerContentView(MembershipReapplyPage, (p) => (
    <MembershipReapplyPageView model={p} />
));

registerContentView(MembershipApplication, (p) => (
    <MembershipApplicationView model={p} />
));
registerContentView(Submissions, (p) => <SubmissionsView model={p} />);

registerContentView(Handelkurv, (p) => <HandelkurvView model={p} />);
registerContentView(SubmissionsMyPagePayment, (p) => <SubmissionsMyPagePaymentView model={p} />);
registerContentView(Invoices, (p) => <InvoicesView model={p} />);
registerContentView(AcceptInvitationPage, (p) => (
    <AcceptInvitationPageView model={p} />
));
registerContentView(CompetitionsListPage, (p) => (
    <CompetitionsListPageView model={p} />
));
registerContentView(Competition, (p) => <CompetitionView model={p} />);
registerContentView(SubmissionFormPage, (p) => (
    <SubmissionFormPageView model={p} />
));
registerContentView(SubmissionFormPreviewPage, (p) => (
    <SubmissionFormPreviewPageView model={p} />
));
registerContentView(SubmissionPayment, (p) => (
    <SubmissionPaymentView model={p} />
));
registerContentView(JudgingCompetitions, (p) => (
    <JudgingCompetitionsView model={p} />
));
registerContentView(JudgingIntroPage, (p) => (
    <JudgingIntroPageView model={p} />
));
registerContentView(JudgingCategoryPage, (p) => (
    <JudgingCategoryPageView model={p} />
));
registerContentView(JudgingEntriesPage, (p) => (
    <JudgingEntriesPageView model={p} />
));
registerContentView(JudgingEntryDetails, (p) => (
    <JudgingEntryDetailsView model={p} />
));
registerContentView(OnlinePaymentStatusPage, (p) => (
    <OnlinePaymentStatusPageView model={p} />
));
registerContentView(AskForPasswordResetPage, (p) => (
    <AskForPasswordResetPageView model={p} />
));
registerContentView(ResetPasswordByEmailPage, (p) => (
    <ResetPasswordByEmailPageView model={p} />
));
registerContentView(ResetPasswordByPhonePage, (p) => (
    <ResetPasswordByPhonePageView model={p} />
));
registerContentView(CreateAccountPage, (p) => (
    <CreateAccountPageView model={p} />
));
registerContentView(InvoicePaymentStatusPage, (p) => (
    <InvoicePaymentStatusPageView model={p} />
));
registerContentView(ConfirmPhoneNumberPage, (p) => (
    <ConfirmPhoneNumberPageView model={p} />
));

moment().locale(process.env.REACT_APP_LANGUAGE === 'en' ? 'en' : 'nb');

(async () => {
    const history = createBrowserHistory();
    const { page } = await homeRoute.bind(history);
    ReactDOM.render(
        <ConfigProvider
            locale={process.env.REACT_APP_LANGUAGE === 'en' ? enGB : nbNO}>
            <ContentView content={page} />
        </ConfigProvider>,
        document.getElementById('root')
    );
})();
