import loader from 'react-mvvm/loading/loader';
import { observable } from 'mobx';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';
import {
    Form,
    FormField,
    NullValidator,
    RequiredFieldValidator,
    RequiredOrganisationNumberValidator
} from 'react-mvvm/forms';
import { createExternalParty } from 'model/Api/Parties/CreateExternalPartyRequest';
import { updateExternalParty } from 'model/Api/Parties/UpdateExternalPartyRequest';
import AddressField from "../AddressField/AddressField";

class CompanyParty {
    @observable party: Partial<PartyDto> = {};
    @observable partyForm: Form<any>;
    isEdited: boolean;
    userId?: number;

    constructor(private onSave?: (p?: PartyDto) => void, initParty?: PartyDto, userId?: number) {
        if (!!initParty) {
            this.party = initParty;
        }
        this.isEdited = !!initParty;
        this.partyForm = this.buildForm();
        this.userId = userId;
    }

    api = loader({
        createParty: async (party: PartyDto, userId?: number) => createExternalParty({ party, userRef: userId }),
        editParty: async (party: PartyDto, userId?: number) => updateExternalParty({ party, userRef: userId })
    });
    
    getOnlyNumbers = (v: string | undefined): string | undefined => {
       if(!v) {
           return undefined;
       }
       const value:string[] = [];
       const re = /^[0-9]/;
       
       v.split('').forEach(item => {
           if(re.test(item)){
               value.push(item);
           }
       })
       return value.join('');
    }

    buildForm = () => {
        return new Form({
            name: new FormField<string>(this.party.name, NullValidator),
            careOf: new FormField<string>(this.party.careOf, NullValidator),
            orgNo: new FormField<string>(
                this.party.orgNo,
              (value) => RequiredOrganisationNumberValidator(this.getOnlyNumbers(`${value}`))
            ),
            address: new AddressField(
                this.party.address,
                RequiredFieldValidator
            )
        });
    };

    save = async () => {
        if (await this.partyForm.validate()) {
            this.party = {
                ...this.party,
                name: this.partyForm.fields.name.value,
                orgNo: this.partyForm.fields.orgNo.value,
                address: this.partyForm.fields.address.value,
                careOf: this.partyForm.fields.careOf.value
            };
            let savedParty: PartyDto;
            if (this.isEdited) {
                savedParty = await this.api.editParty(this.party as PartyDto, this.userId);
            } else {
                savedParty = await this.api.createParty(this.party as PartyDto, this.userId);
            }

            !!this.onSave && this.onSave(savedParty as PartyDto);
        }
    };
}

export default CompanyParty;
