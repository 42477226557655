import * as React from "react";
import Link from "web/components/Link";

interface EditLinkWrapperProps {
    withEditLink: boolean;
    onClick: () => void;
    className?: string;
}

const EditLinkWrapper: React.FC<EditLinkWrapperProps> = ({withEditLink, onClick, className, children}) => {
    if(!withEditLink){
        return <div className={!className ? '' : className}>{children}</div>
    }
    return <Link size={"small"} onClick={onClick} style={!className ? '' : className}>
        {children}
    </Link>
};

export default EditLinkWrapper;
