import * as React from 'react';
import JudgingEntriesPage from 'web/screen/JudgingEntriesPage/JudgingEntriesPage';
import { CompetitionStatus } from 'model/Externals';
import ArtWork from 'web/screen/JudgingEntriesPage/ArtWork';
import { Collapse } from 'antd';
import NotPromotedArtWork from 'web/screen/JudgingEntriesPage/GroupPhase/NotPromotedArtWork';
import style from './GroupPhase.module.scss';
import { observer } from 'mobx-react';
import {useTranslation} from "react-i18next";

interface GroupPhaseProps {
    model: JudgingEntriesPage;
    view: 'tiles' | 'list';
}

const { Panel } = Collapse;

const GroupPhase: React.FC<GroupPhaseProps> = observer(({ model, view }) => {
    const { t } = useTranslation('judging');
    const isEditablePhaseTwo: boolean =
        model.competitionStatus ===
            CompetitionStatus.phaseTwoJudgingInProgress && model.isEditable;
    const { getGroupPhaseEntryList } = model;
    return (
        <>
            {getGroupPhaseEntryList.promoted.map((entry) => (
                <ArtWork
                    key={entry.id}
                    type={view}
                    competitionEntry={entry}
                    seeDetails={() => model.showJudgingEntryDetails(entry.id)}
                    competitionStatus={model.competitionStatus}
                    onEvaluationPhaseTwo={
                        model.apiEvaluation.setEvaluationPhaseTwo
                    }
                    evaluationResults={model.evaluationResults}
                    isEditable={isEditablePhaseTwo}
                />
            ))}
            {!!getGroupPhaseEntryList.notPromoted &&
                getGroupPhaseEntryList.notPromoted.length > 0 && (
                    <Collapse
                        expandIconPosition={'right'}
                        ghost={true}
                        className={style.collapse}>
                        <Panel
                            key={1}
                            header={t('notPromoted')}
                            className={style.panel}>
                            {getGroupPhaseEntryList.notPromoted.map((entry) => (
                                <NotPromotedArtWork
                                    entry={entry}
                                    onDetails={() =>
                                        model.showJudgingEntryDetails(entry.id)
                                    }
                                    onPromote={() =>
                                        model.onPromoteEntry(entry.id)
                                    }
                                />
                            ))}
                        </Panel>
                    </Collapse>
                )}
        </>
    );
});

export default GroupPhase;
