import * as React from 'react';
import style from './WebTag.module.scss';
import classNames from 'classnames';

interface WebTagProps {
    text: string | null;
    customStyle?: string
}

const WebTag: React.FC<WebTagProps> = ({ text, customStyle }) => {
    const tagStyle = classNames(style.tag, {[customStyle as string]: !!customStyle});
    
    if(!text) {
        return null;
    }
    
    return <div className={tagStyle}>{text}</div>;
};

export default WebTag;
