import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface ConfirmPhoneNumberRequest {
    phoneNumber: string
    pin: string
    password: string
    confirmPassword: string
}
export const confirmPhoneNumber = (confirmPhoneNumberRequest: ConfirmPhoneNumberRequest): Promise<any> => {
    return send("ConfirmPhoneNumber", confirmPhoneNumberRequest)
}
