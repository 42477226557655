import * as React from 'react';
import { Tag } from 'antd';

interface MultiSelectFieldProps {
    value: string;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({ value }) => {
    const allValues: { id: number; value: string }[] = !value
        ? []
        : JSON.parse(value);

    return (
        <>
            {allValues.map((element) => (
                <Tag key={element.id}>{element.value}</Tag>
            ))}
        </>
    );
};

export default MultiSelectField;
