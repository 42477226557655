import { Page } from 'react-mvvm';
import { observable } from 'mobx';
import queryString from 'query-string';
import HomePage from 'web/screen/HomePage/HomePage';
import {homeRoute} from "web/routes";

class InvoicePaymentStatusPage extends Page {
    @observable orderId: string;
    @observable status: 'success' | 'error';
    timer: number;

    constructor(private parent: HomePage) {
        super();
        this.timer = 0;
        this.orderId = '';
        this.status = 'success';
    }

    protected async onActivated(): Promise<any> {
        const query = queryString.parse(globalThis.location.search);

        this.orderId = !query.orderId
            ? ''
            : Array.isArray(query.orderId)
            ? query.orderId[0]
            : query.orderId;

        const status = !query.status
            ? 'error'
            : Array.isArray(query.status)
            ? query.status[0]
            : query.status;

        this.status =
            status === 'success' || status === 'pending' ? 'success' : 'error';

        this.timer = (setTimeout(async () => {
            await homeRoute.historyPush('/submissions')
        }, 5000) as unknown) as number;
    }

    onRemoveChildPage = async () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = 0;
        }
        await this.parent.removeChildPage();
    };
}

export default InvoicePaymentStatusPage;
