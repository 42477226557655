import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {EvaluationCompetitionListItemDto} from "./Model/EvaluationCompetitionListItemDto"

export interface GetEvaluationCompetitionsRequest {
}
export const getEvaluationCompetitions = (getEvaluationCompetitionsRequest: GetEvaluationCompetitionsRequest): Promise<EvaluationCompetitionListItemDto[]> => {
    return send("GetEvaluationCompetitions", getEvaluationCompetitionsRequest)
}
