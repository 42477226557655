import Flex from 'common/components/Flex';

import * as React from 'react';
import { AttachmentResponse } from 'model/Api/Submission/Model/AttachmentResponse';
import style from './File.module.scss';
import RenderPreview from 'common/components/Preview/RenderPreview';

interface FileProps {
    value: string;
    attachments: AttachmentResponse[];
    mode: 'form' | 'preview';
    onFileDialogOpen?: (
        images: AttachmentResponse[],
        startIndex?: number
    ) => void;
}

const File: React.FC<FileProps> = ({
    value,
    attachments,
    mode,
    onFileDialogOpen
}) => {
    const filesIds = JSON.parse(value);
    const files: AttachmentResponse[] = filesIds.reduce(
        (prev: AttachmentResponse[], current: string) => {
            const fileData = attachments.find((a) => a.uid === current);
            if (!!fileData) {
                prev.push(fileData);
            }
            return prev;
        },
        []
    );

    return (
        <Flex
            justifyContent={'space-between'}
            flexDirection={'column'}
            flexWrap={'wrap'}>
            {files.map((file, index) => {
                let indexInAttachments = index;
                attachments.find((a, idx) => {
                    if (a.uid === file.uid) {
                        indexInAttachments = idx;
                    }
                });
                return mode === 'form' ? (
                    <div className={style.imgFormContainer} key={file.uid}>
                        <RenderPreview file={file} mode={mode}/>
                    </div>
                ) : (
                    <div className={style.image} key={file.uid}>
                        <RenderPreview
                            file={file}
                            mode={mode}
                            onPreview={() =>
                                !!onFileDialogOpen &&
                                onFileDialogOpen(
                                    attachments,
                                    indexInAttachments
                                )
                            }
                        />
                    </div>
                );
            })}
        </Flex>
    );
};

export default File;
