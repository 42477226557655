import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {ListOptions} from "../GenericList/ListOptions"

import {PartyDto} from "./Model/PartyDto"

export interface GetPartiesRequest {
    userRef?: number
    itemId?: number
    options?: ListOptions
    filter?: any
}
export const getParties = (getPartiesRequest: GetPartiesRequest): Promise<PartyDto[]> => {
    return send("GetParties", getPartiesRequest)
}
