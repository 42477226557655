import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface UpdateMemberInterestsRequest {
    interests: string[]
}
export const updateMemberInterests = (updateMemberInterestsRequest: UpdateMemberInterestsRequest): Promise<any> => {
    return send("UpdateMemberInterests", updateMemberInterestsRequest)
}
