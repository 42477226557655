import * as React from 'react';
import style from './ListOptionsLinks.module.scss';
import { Button } from 'antd';
import { JudgeEntriesSorting } from 'web/screen/JudgingEntriesPage/JudgingEntriesPage';
import { JudgingCategorySorting } from 'web/screen/JudgingCategoryPage/JudgingCategoryPage';
import classNames from 'classnames';

interface ListOptionsLinksProps {
    onChange: (prop: any) => Promise<void>;
    value: JudgeEntriesSorting | JudgingCategorySorting;
    options: { id: string; value: string }[];
}

const ListOptionsLinks: React.FC<ListOptionsLinksProps> = ({
    options,
    value,
    onChange
}) => {
    return (
        <div className={style.list}>
            {options.map((el) => {
                const selected = value === el.id;
                
                return (
                    <Button
                        type={'link'}
                        key={el.id}
                        onClick={() => onChange(el.id)}
                        className={classNames(style.element, {
                            [style.selected]: selected
                        })}>
                        {el.value}
                    </Button>
                );
            })}
        </div>
    );
};

export default ListOptionsLinks;
