import * as React from 'react';
import ReactPlayer from 'react-player';
import style from './VideoLinks.module.scss';
import { ReactComponent as Play } from "../../../icons/play_icn.svg";
import { useTranslation } from "react-i18next";

interface VideoLinksProps {
  multipleVideo?: string;
  singleVideo?: string;
}

const VideoLinks: React.FC<VideoLinksProps> = ({multipleVideo, singleVideo}) => {
  const [errors, setErrors] = React.useState<string[]>([]);
  const {t} = useTranslation('judging');


  if (!!singleVideo) {
    return <div className={style.root}>
      <div className={style.video}>
        {
          errors.length && errors.find(e => e === singleVideo) ?
            <div className={`${style.error} error-video-link`}>
              <span>{t('videoError')}</span>
              <a href={singleVideo} target={'_blank'} rel="noopener noreferrer"
                 className={style.error_link}>{singleVideo}</a>
            </div> : <ReactPlayer url={singleVideo}
                                  light={true}
                                  playIcon={<div className={style.play}>
                                    <Play className={style.playIcon}/>
                                    <span>Run video</span>
                                  </div>}
                                  onError={(error) => {
                                    setErrors(errors.find(e => e === singleVideo) ? [...errors] : [...errors, singleVideo]);
                                  }}
                                  controls={true}/>
        }
      </div>
    </div>
  }
  if (!!multipleVideo) {
    const videos: string[] = JSON.parse(multipleVideo).filter(Boolean);
    return (
      <div className={style.root}>
        {videos.map((video, index) => {
          return (
            <div className={style.video} key={`${video}_${index}`}>
              {
                errors.length && errors.find(e => e === video) ?
                  <div className={`${style.error} error-video-link`}>
                    <span>{t('videoError')}</span>
                    <a href={video} target={'_blank'} rel="noopener noreferrer" className={style.error_link}>{video}</a>
                  </div> : <ReactPlayer
                    url={video}
                    controls={true}
                    onError={(error) => {
                      setErrors(errors.find(e => e === video) ? [...errors] : [...errors, video]);
                    }}
                  />
              }
            </div>
          )
        })}
      </div>
    );
  }
  return null;
};

export default VideoLinks;
