import * as React from 'react';
import CancelMembershipDialog from 'web/screen/Membership/CancelMembershipDialog/CancelMembershipDialog';
import AntModal from 'common/components/antHelpers/AntModal';
import {useTranslation} from "react-i18next";

interface CancelMembershipDialogViewProps {
    dialog: CancelMembershipDialog;
}

const CancelMembershipDialogView: React.FC<CancelMembershipDialogViewProps> = ({
    dialog
}) => {
    const { t } = useTranslation('membership');
    return (
        <AntModal
            cancel={dialog.cancel}
            okCommand={dialog.confirm}
            title={t('stopRenewalSubscription')}>
            <span>{t("stopRenewalSubscriptionMsg")}</span>
        </AntModal>
    );
};

export default CancelMembershipDialogView;
