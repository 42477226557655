import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {PartyDto} from "./Model/PartyDto"


export interface UpdateExternalPartyRequest {
    party: PartyDto
    userRef?: number
}
export const updateExternalParty = (updateExternalPartyRequest: UpdateExternalPartyRequest): Promise<PartyDto> => {
    return send("UpdateExternalParty", updateExternalPartyRequest)
}
