import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {SubscriptionType} from "../../Externals"
import {PaymentMethod} from "../../Externals"

import {ChangeMembershipSubscriptionResponse} from "./Model/ChangeMembershipSubscriptionResponse"

export interface ChangeMembershipSubscriptionRequest {
    subscriptionType: SubscriptionType
    paymentMethod: PaymentMethod
    paymentTermsAccepted: boolean
    payingPartyId?: number
}
export const changeMembershipSubscription = (changeMembershipSubscriptionRequest: ChangeMembershipSubscriptionRequest): Promise<ChangeMembershipSubscriptionResponse> => {
    return send("ChangeMembershipSubscription", changeMembershipSubscriptionRequest)
}
