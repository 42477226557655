import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {CompetitionEntryDto} from "./Model/CompetitionEntryDto"


export interface AdminUpdateCompetitionEntryRequest {
    competitionEntryDto: CompetitionEntryDto
}
export const adminUpdateCompetitionEntry = (adminUpdateCompetitionEntryRequest: AdminUpdateCompetitionEntryRequest): Promise<CompetitionEntryDto> => {
    return send("AdminUpdateCompetitionEntry", adminUpdateCompetitionEntryRequest)
}
