export const init = (
    api: () => {
        post<T, K>(url: string, data: any, config?: any): Promise<K>;
    }
) => {
    _api = api;
};

let _api: () => {
    post<T, K>(url: string, data: any, config?: any): Promise<K>;
};

export const send: any = async (url: string, props: object) => {
    return await _api()
        .post(url, JSON.stringify(props))
        .catch((err: any) => {
            throw err;
        });
};
