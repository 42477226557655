import * as React from 'react';
import { Carousel } from 'antd';
import { AttachmentResponse } from 'model/Api/Submission/Model/AttachmentResponse';
import style from './ImageDialog.module.scss';
import { CarouselRef } from 'antd/lib/carousel';
import VideoLinks from 'common/components/SubmissionsAnswers/VideoLinks';
import useKeypress from 'react-use-keypress';

interface ImageGalleryProps {
    images: AttachmentResponse[];
    startIndex: number | undefined;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images, startIndex }) => {
    let carousel = React.useRef<CarouselRef>(null);

    React.useEffect(() => {
        !!carousel.current && carousel.current.goTo(startIndex ?? 0, false);
        !!carousel.current &&
            carousel.current.innerSlider.list.setAttribute('tabindex', 0);
        !!carousel.current && carousel.current.innerSlider.list.focus();
    }, [carousel]);

    useKeypress(['ArrowLeft', 'ArrowRight'], (event:any) => {
        if (event.key === 'ArrowLeft') {
            !!carousel.current && carousel.current.prev();
        } else {
            !!carousel.current && carousel.current.next();
        }
    });

    const formattedImages: AttachmentResponse[] = images.map((item) => {
        if (item.type === 'videoLink/mp4') {
            const videoUrls = JSON.parse(item.url).filter(Boolean);
            const items = videoUrls.map((url: string, idx: number) => ({
                ...item,
                url: JSON.stringify([url]),
                uid: `${item.uid}_${idx}`,
            }));
            return items;
        }
        return item;
    }).flat();

    return (
        <Carousel
            className={style.carousel}
            arrows={true}
            ref={(node) => {
                (carousel as React.MutableRefObject<any>).current = node;
            }}>
            {formattedImages.filter((file: AttachmentResponse) => file.type !== 'application/pdf').map((file: AttachmentResponse, index: number) => {
                switch (file.type){
                    case 'videoLink/mp4':
                        return (
                            <div key={file.uid} className={style.imageBox}>
                                <VideoLinks multipleVideo={file.url} />
                            </div>
                        );
                    case 'video/mp4':
                        return <div key={file.uid} className={style.imageBox}>
                            <VideoLinks singleVideo={file.url} />
                        </div>
                    default:
                        return (
                            <div key={file.uid} className={style.imageBox}>
                                <img
                                    src={file.url}
                                    alt={file.name}
                                    className={style.image}
                                />
                            </div>
                        );
                }
            })}
        </Carousel>
    );
};

export default ImageGallery;
