import {asyncCommand, IActivatable} from "react-mvvm";

class OnBackToSubmissionModal implements IActivatable {
    constructor(
        private onConfirm: () => void,
        private close: (r: boolean) => void,
        private onNotSave?: () => void,
        private onClose?: () => void,
        public subject?: string
    ) {}

    confirm = asyncCommand(async () => {
        await this.onConfirm();
        this.close(true);
    });

    cancel = async () => {
        if(this.onClose){
            await this.onClose();
        }

        this.close(false);
    }

    activate(): Promise<void> {
        return Promise.resolve();
    }
    
    onCancelClick = asyncCommand(async () => {
        if(this.onNotSave){
            await this.onNotSave();
        }
        this.close(true);
    })
}

export default OnBackToSubmissionModal;