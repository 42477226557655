import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {TokenResult} from "./Model/TokenResult"

export interface SetPasswordByEmailRequest {
    userId: string
    token: string
    password: string
    confirmPassword: string
}
export const setPasswordByEmail = (setPasswordByEmailRequest: SetPasswordByEmailRequest): Promise<TokenResult> => {
    return send("SetPasswordByEmail", setPasswordByEmailRequest)
}
