import * as React from 'react';
import { CompetitionEntryListItemResponse } from 'model/Api/Evaluation/Model/CompetitionEntryListItemDto';
import style from './GroupPhase.module.scss';
import RenderIcon from "common/components/Preview/RenderIcon";
import Link from "web/components/Link";
import { Button } from 'antd';
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";

interface NotPromotedArtWorkProps {
    entry: CompetitionEntryListItemResponse;
    onDetails: () => void;
    onPromote: () => void;
}

const NotPromotedArtWork: React.FC<NotPromotedArtWorkProps> = observer(({ entry, onDetails, onPromote }) => {
    const { t } = useTranslation('judging');
    return <div className={style.ArtworkBox}>
        <div className={style.ArtworkCrop}>
            <RenderIcon
                type={entry.titleThumbnailType}
                url={entry.titleThumbnailUrl}
            />
        </div>
        <div className={style.ArtworkTitle}>
            <Link onClick={onDetails} style={style.ArtworkTitleLink}>{entry.title}</Link>
        </div>
        <Button type={"ghost"} onClick={onPromote} className={style.ArtworkPromote}>{t('Promote')}</Button>
    </div>;
});

export default NotPromotedArtWork;
