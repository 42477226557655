import * as React from 'react';
import AskForPasswordResetPage from './AskForPasswordResetPage';
import { observer } from 'mobx-react';
import { Typography, Button, Col, Row } from 'antd';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import style from './AskForPasswordResetPage.module.scss';
import Flex from 'common/components/Flex';
import { ReactComponent as Prev } from 'common/icons/navigation_previous.svg';
import { CheckCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface AskForPasswordResetPageProps {
    model: AskForPasswordResetPage;
}

const AskForPasswordResetPageView: React.FC<AskForPasswordResetPageProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['login', 'common']);
        return (
            <div className={style.container}>
                <div className={style.content}>
                    <Typography.Title level={3} className={style.title}>
                        {t('login:lostPasswordTitle')}
                    </Typography.Title>
                    <Typography.Paragraph className={style.subTitle}>
                        {t('login:lostPasswordDsc')}
                    </Typography.Paragraph>
                    <AntForm
                        form={model.askForPasswordResetForm}
                        id={'AskForPasswordResetForm'}
                        layout={'vertical'}>
                        {(fields) => {
                            return (
                                <>
                                    <AntField field={fields.login}>
                                        {(props) => (
                                            <AntTextInput
                                                placeholder={t(
                                                    'login:inputPlaceholder'
                                                )}
                                                {...props}
                                            />
                                        )}
                                    </AntField>
                                </>
                            );
                        }}
                    </AntForm>
                    <div className={style.messageBox}>
                        {!!model.errorMsg && (
                            <Typography.Paragraph className={style.errorMsg}>
                                {model.errorMsg}
                            </Typography.Paragraph>
                        )}
                        {!!model.message && (
                            <Typography.Paragraph className={style.message}>
                                <CheckCircleFilled /> {model.message}
                            </Typography.Paragraph>
                        )}
                    </div>
                    <Flex
                        flexDirection={'row'}
                        justifyContent={'space-between'}>
                        <Button
                            type={'link'}
                            onClick={model.goBack}
                            className={style.backButton}>
                            <Prev className={style.backBtnArrow} />
                            {t('common:back')}
                        </Button>
                        <Button
                            loading={model.isLoading}
                            type={'primary'}
                            htmlType={'submit'}
                            onClick={() => model.onSubmit.execute()}>
                            {t('login:resetPassword')}
                        </Button>
                    </Flex>
                </div>
            </div>
        );
    }
);

export default AskForPasswordResetPageView;
