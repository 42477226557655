import {
    Page,
    bindableForm,
    RequiredFieldValidator,
    asyncCommand
} from 'react-mvvm';
import { observable } from 'mobx';
import { RequestStatus } from 'admin/state/common';
import hello, { HelloJSLoginEventArguement } from 'hellojs';
import { AcceptInvitationDto } from 'model/Api/Account/Model/AcceptInvitationDto';
import { acceptInvitation } from 'model/Api/Account/AcceptInvitationRequest';
import queryString from 'query-string';
import { login, store } from 'auth/Authentication';
import { acceptInvitationByExternalAuth } from 'model/Api/Account/AcceptInvitationByExternalAuthRequest';
import { TokenResult } from 'model/Api/Account/Model/TokenResult';
import HomePage from 'web/screen/HomePage/HomePage';
import CreateAccountPage from 'web/screen/CreateAccountPage/CreateAccountPage';
import { homeRoute } from 'web/routes';

export class AcceptInvitationPage extends Page {
    @observable.ref acceptInvitationDto: AcceptInvitationDto;

    constructor() {
        super();
        this.acceptInvitationDto = {
            id: 0,
            version: 0,
            token: '',
            password: '',
            passwordConfirmation: '',
            userId: '',
            given: '',
            family: '',
            pin: ''
        };
    }

    @observable errorMessage: string = '';
    @observable isLoading: boolean = false;
    @observable requestStatus: RequestStatus = RequestStatus.none;

    acceptInvitationForm = bindableForm<AcceptInvitationDto>()
        .addField('pin', { validator: RequiredFieldValidator })
        .addField('password', { validator: RequiredFieldValidator })
        .addField('passwordConfirmation', {
            validator: RequiredFieldValidator
        })
        .addField('token')
        .addField('userId')
        .bindTo(() => this.acceptInvitationDto);

    async onActivated() {
        const params = queryString.parse(globalThis.location.search);
        this.acceptInvitationForm.fields.token.value = !params.token
            ? ''
            : Array.isArray(params.token)
            ? params.token[0]
            : params.token;

        this.acceptInvitationForm.fields.userId.value = !params.userId
            ? ''
            : Array.isArray(params.userId)
            ? params.userId[0]
            : params.userId;
    }

    onSubmit = asyncCommand(
        async () => {
            if (!(await this.acceptInvitationForm.validate())) return;
            this.errorMessage = '';

            this.acceptInvitationForm.commit();
            try {
                const token = await acceptInvitation({
                    acceptInvitation: this.acceptInvitationDto
                });

                if ('accessToken' in token) {
                    hello.utils.store('customJWT', {
                        access_token: token.accessToken.token,
                        refresh_token: token.refreshToken.token
                    });
                    window.location.href = '/';
                }
            } catch (e) {
                this.isLoading = false;
                if (
                    e.response.data.errors &&
                    e.response.data.errors.length != 0
                ) {
                    this.errorMessage = e.response.data.errors.join(', ');
                }
            }
        },
        () => this.acceptInvitationForm.isDirty
    );

    showRegisterPage = async () => {
        homeRoute.historyPush('login/register');
    };

    /*onFacebookLogin = asyncCommand(async () => {
        login('facebook').then(
            (response: HelloJSLoginEventArguement) => {
                if (
                    response &&
                    response.authResponse &&
                    response.authResponse.access_token
                ) {
                    acceptInvitationByExternalAuth({
                        facebook: {
                            accessToken: response.authResponse.access_token,
                            token: this.acceptInvitationForm.fields.token
                                .value!,
                            userId: this.acceptInvitationForm.fields.userId
                                .value!
                        }
                    }).then((value: TokenResult) => {
                        store('customJWT', {
                            access_token: value.accessToken.token,
                            expires_in: value.accessToken.expiresIn,
                            refresh_token: value.refreshToken.token
                        });
                        window.location.href = '/';
                    });
                }
            },
            (reason) => {
                this.errorMessage = 'Facebook authentication was rejected';
            }
        );
    });*/
}

export default AcceptInvitationPage;
