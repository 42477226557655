
import {IActivatable} from "react-mvvm";

class BaseDialog<TData> implements IActivatable {
    constructor(public close: (g: TData | undefined) => void) {
    }

    public activate() {
        return Promise.resolve();
    }
    
    cancel = () => this.close(undefined);
}

export default BaseDialog;
