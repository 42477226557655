import { useTranslation } from 'react-i18next';

import * as React from 'react';
import UrlField, { UrlFieldRowLink } from './UrlFied';
import { Row, Col, Space, Button } from 'antd';
import { observer } from 'mobx-react';
import AntField from 'common/components/antHelpers/AntField';
import { RequiredFieldValidator, RequiredUrlValidator } from 'react-mvvm/forms';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import style from './UrlField.module.scss';
import classNames from 'classnames';

interface UrlFieldViewProps {
    model: UrlField | undefined;
    label: string;
    disabled?: boolean;
}

const regex = new RegExp('https?://');

const LinkRow: React.FC<{
    row: UrlFieldRowLink;
    index: number;
    onRemove: (i: number) => void;
    disabled?: boolean;
}> = observer(({ row, index, onRemove, disabled }) => {
    const { url } = row.value.fields;
    return (
        <div className={style.line}>
            <AntField field={url} className={style.lineInput}>
                {(props) => {
                    return (
                        <AntTextInput
                            {...props}
                            disabled={disabled}
                            addonBefore={'https://'}
                            value={
                                !props.value
                                    ? ''
                                    : props.value.replace(regex, '')
                            }
                        />
                    );
                }}
            </AntField>
            {index > 0 && (
                <Button
                    disabled={disabled}
                    type={'text'}
                    onClick={() => onRemove(index)}
                    className={style.removeButton}>
                    <DeleteOutlined />
                </Button>
            )}
        </div>
    );
});

const UrlFieldView: React.FC<UrlFieldViewProps> = observer(
    ({ model, label, disabled }) => {
        const { t } = useTranslation('common');
        const titleStyle = classNames(style.title, {
            [style.required]:
                !!model?.fieldValidator &&
                (model?.fieldValidator.name === RequiredFieldValidator.name ||
                    model?.fieldValidator.name === RequiredUrlValidator.name)
        });
        return (
            <div className={style.container}>
                <span className={titleStyle}>{label}</span>
                {model?.links &&
                    model.links.map((linksForm, index) => (
                        <LinkRow
                            disabled={disabled}
                            key={`membership-links__${index}`}
                            row={linksForm}
                            index={index}
                            onRemove={model.removeLink}
                        />
                    ))}
                <Button
                    disabled={disabled}
                    type={'text'}
                    size={'small'}
                    onClick={model?.addLink.execute}
                    className={style.addButton}>
                    <PlusOutlined /> {t('addLink')}
                </Button>
            </div>
        );
    }
);

export default UrlFieldView;
