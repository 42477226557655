import { Page } from 'react-mvvm';
import { action, computed, observable } from 'mobx';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';
import loader from 'react-mvvm/loading/loader';
import { getParties } from 'model/Api/Parties/GetPartiesRequest';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import { deleteParty } from 'model/Api/Parties/DeletePartyRequest';
import PrivateParty from "../PrivateParty/PrivateParty";
import CompanyParty from "../CompanyParty/CompanyParty";
import AddressDialog from "../AddressDialog/AddressDialog";

class UserAddresses {
    page: Page<AddressDialog | ConfirmationModal>;
    isSelectable: boolean;
    isExpanded: boolean;
    isDoubleSelection: boolean;
    @observable parties: PartyDto[] = [];
    @observable selectedParty: number | undefined;
    @observable deleteParty: PartyDto | undefined;
    @observable doubleSelectedParty: {
        private: number | undefined;
        company: number | undefined;
    };

    constructor(
        page: Page<any>,
        isSelectable?: boolean,
        isExpanded?: boolean,
        private onSelectAction?: () => void | undefined,
        isDoubleSelection?: boolean | undefined
    ) {
        this.page = page;
        this.isSelectable = isSelectable ?? false;
        this.isExpanded = isExpanded ?? false;
        this.isDoubleSelection = isDoubleSelection ?? false;
        this.doubleSelectedParty = {
            private: undefined,
            company: undefined
        };
    }

    init = async (userRef?: number) => {
        this.parties = await this.api.getParties(userRef);
    };

    @computed get sortedParties(): {
        private: PartyDto[];
        company: PartyDto[];
    } {
        const priv: PartyDto[] = [];
        const company: PartyDto[] = [];

        this.parties.forEach((party) => {
            party.orgNo ? company.push(party) : priv.push(party);
        });

        return {
            private: priv,
            company
        };
    }

    @computed get selectedPartyData(): PartyDto | undefined {
        return this.parties.find((party) => party.id === this.selectedParty);
    }

    @computed get doubleSelectedPartyData(): {
        private: PartyDto | undefined;
        company: PartyDto | undefined;
    } {
        return {
            private: this.parties.find(
                (party) => party.id === this.doubleSelectedParty.private
            ),
            company: this.parties.find(
                (party) => party.id === this.doubleSelectedParty.company
            )
        };
    }

    api = loader({
        getParties: async (userRef?: number) =>
            getParties({ userRef: userRef }),
        deleteParty: async (partyRef: number) => deleteParty({ partyRef })
    });

    onDialogClose = async (
        close: (p: PartyDto | undefined) => void,
        p: PartyDto | undefined
    ) => {
        !!this.page.dialog && (await close(p));
    };

    onCreateAddress = async (type?: 'private' | 'company', userId?: number) => {
        const party = await this.page.showModal<
            AddressDialog,
            PartyDto | undefined
        >(
            (close) =>
                new AddressDialog(
                    close,
                    new PrivateParty((p?: PartyDto) =>
                        this.onDialogClose(close, p)
                    , undefined, userId),
                    new CompanyParty((p?: PartyDto) =>
                        this.onDialogClose(close, p)
                    , undefined, userId),
                    type
                )
        );
        this.parties = await this.api.getParties(userId);

        if (this.isSelectable && !!party) {
            await this.onSelectAddress(party.id);
        }
    };

    onEdit = async (party: PartyDto, userId?: number) => {
        const type: 'private' | 'company' = !!party.orgNo
            ? 'company'
            : 'private';

        await this.page.showModal(
            (close) =>
                new AddressDialog(
                    close,
                    new PrivateParty(
                        (p?: PartyDto) => this.onDialogClose(close, p),
                        party
                    ),
                    new CompanyParty(
                        (p?: PartyDto) => this.onDialogClose(close, p),
                        party
                    ),
                    type
                )
        );
        this.parties = await this.api.getParties(userId);
    };

    onDelete = async (partyRef: number) => {
        this.deleteParty = await this.parties.find(
            (party) => party.id === partyRef
        );
        const isDeleted = await this.page.showModal(
            (close) =>
                new ConfirmationModal(
                    () => this.api.deleteParty(partyRef),
                    close
                )
        );
        if (isDeleted) {
            this.parties = await this.parties.filter(
                (party) => party.id !== partyRef
            );
            this.selectedParty = undefined;
            this.doubleSelectedParty = {
                private: undefined,
                company: undefined
            };
            if (!!this.onSelectAction) {
                this.onSelectAction();
            }
        }
    };

    onClear = () => {
        this.selectedParty = undefined;
        this.doubleSelectedParty = {
            private: undefined,
            company: undefined
        };
        if (!!this.onSelectAction) {
            this.onSelectAction();
        }
    };

    @action
    onSelectAddress = (id: number) => {
        this.selectedParty = id;

        if (this.isDoubleSelection) {
            const selected = this.parties.find((party) => party.id === id);

            this.doubleSelectedParty = {
                private:
                    !!selected && !selected.orgNo
                        ? id
                        : this.doubleSelectedParty.private,
                company:
                    !!selected && selected.orgNo
                        ? id
                        : this.doubleSelectedParty.company
            };
        }

        if (!!this.onSelectAction) {
            this.onSelectAction();
        }
    };
}

export default UserAddresses;
