import * as React from 'react';
import FormStep from './FormStep';
import { MembershipKind } from 'model/Externals';
import StudentFormView from 'web/screen/MembershipApplication/FormStep/StudentForm/StudentFormView';
import StudentForm from './StudentForm/StudentForm';
import AssociateForm from 'web/screen/MembershipApplication/FormStep/AssociateForm/AssociateForm';
import AssociateFormView from 'web/screen/MembershipApplication/FormStep/AssociateForm/AssociateFormView';
import ProfessionalForm from 'web/screen/MembershipApplication/FormStep/ProfessionalForm/ProfessionalForm';
import ProfessionalFormView from 'web/screen/MembershipApplication/FormStep/ProfessionalForm/ProfessionalFormView';
import rootStyle from '../MembershipApplication.module.scss';
import { useTranslation } from 'react-i18next';
import FormName from 'web/screen/MembershipApplication/FormName';

interface FormStepViewProps {
    model: FormStep;
}

const FormStepView: React.FC<FormStepViewProps> = ({ model }) => {
    const { t } = useTranslation(['membership']);
    return (
        <>
            <FormName title={t('membership:stepTwo')} />
            <div className={rootStyle.formBox}>
                {model.subscriptionType === MembershipKind.student &&
                    !!model.applicationForm &&
                    model.applicationForm instanceof StudentForm && (
                        <StudentFormView
                            form={model.applicationForm.form}
                            userAddresses={model.applicationForm.userAddresses}
                        />
                    )}
                {model.subscriptionType === MembershipKind.associate &&
                    !!model.applicationForm &&
                    model.applicationForm instanceof AssociateForm && (
                        <AssociateFormView
                            form={model.applicationForm.form}
                            userAddresses={model.applicationForm.userAddresses}
                        />
                    )}

                {model.subscriptionType === MembershipKind.professional &&
                    !!model.applicationForm &&
                    model.applicationForm instanceof ProfessionalForm && (
                        <ProfessionalFormView
                            form={model.applicationForm.form}
                            userAddresses={model.applicationForm.userAddresses}
                            membershipAppId={
                                model.applicationForm.parent.membershipAppId
                            }
                        />
                    )}
            </div>
        </>
    );
};

export default FormStepView;
