import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {MembershipSubscriptionDto} from "../Members/Model/MembershipSubscriptionDto"

export interface StopAutoRenewalForCurrentSubscriptionRequest {
}
export const stopAutoRenewalForCurrentSubscription = (stopAutoRenewalForCurrentSubscriptionRequest: StopAutoRenewalForCurrentSubscriptionRequest): Promise<MembershipSubscriptionDto> => {
    return send("StopAutoRenewalForCurrentSubscription", stopAutoRenewalForCurrentSubscriptionRequest)
}
