import * as React from 'react';
import ResetPasswordByPhonePage from './ResetPasswordByPhonePage';
import { observer } from 'mobx-react';
import { Typography, Button } from 'antd';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import AntPassword from 'common/components/antHelpers/AntPassword';
import style from './ResetPasswordByPhonePage.module.scss';
import { useTranslation } from 'react-i18next';
import Flex from 'common/components/Flex';

interface ResetPasswordPageProps {
    model: ResetPasswordByPhonePage;
}

const ResetPasswordByPhonePageView: React.FC<ResetPasswordPageProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('login');
        return (
            <div className={style.container}>
                <div className={style.content}>
                    <Typography.Title level={3} className={style.title}>
                        {t('setNewPassword')}
                    </Typography.Title>
                    <AntForm
                        form={model.setPasswordForm}
                        id={'SetPasswordForm'}
                        layout={'vertical'}>
                        {(fields) => {
                            return (
                                <>
                                    <AntField field={fields.pin}>
                                        {(props) => (
                                            <AntPassword
                                                placeholder={t('pin')}
                                                {...props}
                                            />
                                        )}
                                    </AntField>
                                    <AntField field={fields.password}>
                                        {(props) => (
                                            <AntPassword
                                                placeholder={t('password')}
                                                {...props}
                                            />
                                        )}
                                    </AntField>
                                    <AntField field={fields.confirmPassword}>
                                        {(props) => (
                                            <AntPassword
                                                placeholder={t(
                                                    'confirmPassword'
                                                )}
                                                {...props}
                                            />
                                        )}
                                    </AntField>
                                </>
                            );
                        }}
                    </AntForm>
                    <Flex
                        justifyContent={'center'}
                        flexDirection={'column'}
                        alignItems={'center'}>
                        <div className={style.messageBox}>
                            {model.errorMsg && (
                                <Typography.Paragraph
                                    className={style.errorMsg}>
                                    {model.errorMsg}
                                </Typography.Paragraph>
                            )}
                        </div>
                        <Button
                            type={'primary'}
                            htmlType={'submit'}
                            onClick={() => model.onSubmit.execute()}>
                            {t('setPassword')}
                        </Button>
                    </Flex>
                </div>
            </div>
        );
    }
);

export default ResetPasswordByPhonePageView;
