import { AdalConfig, AuthenticationContext } from 'react-adal';
import hello, { HelloJSServiceDef, HelloJSLoginEventArguement } from 'hellojs';
import queryString from 'query-string';
import { AxiosRequestConfig } from 'axios';

export const axiosOptions: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    method: 'post',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
};

export const adalConfig: AdalConfig = {
    tenant: '063afd9e-5fcb-48d2-a769-ca31b0f5b443',
    clientId: 'a65f93fc-7561-4882-ace3-be72f65154ee',
    endpoints: {
        api: 'a65f93fc-7561-4882-ace3-be72f65154ee'
    },
    redirectUri: `${window.location.origin}/admin`,
    cacheLocation: 'sessionStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const customJWTConfig: HelloJSServiceDef = {
    name: 'customJWT',
    oauth: {
        version: 2,
        auth: 'https://grafill-dev.azurewebsites.net/api/authorize',
        grant: 'https://grafill-dev.azurewebsites.net/api/token',
        response_type: 'token'
    },
    scope: {
        basic: 'basic_scope'
    },
    base: 'https://grafill-dev.azurewebsites.net'
};

export const getRedirectUri = (): string => {
    const params = queryString.parse(globalThis.location.search);
    return decodeURIComponent(
        !params.redirect_uri
            ? '/'
            : Array.isArray(params.redirect_uri)
            ? params.redirect_uri[0]
            : params.redirect_uri
    );
};

export const login = (
    network: string
): PromiseLike<HelloJSLoginEventArguement> => {
    hello.init({ customJWT: customJWTConfig });
    /*hello.init(
        { facebook: process.env.REACT_APP_FACEBOOK_CLIENTID ?? '' },
        {
            redirect_uri: window.location.href.split('?')[0]
        }
    );*/
    return hello(network).login({
        scope: 'public_profile,email'
    });
};

export type HelloToken = {
    access_token: string;
    expires_in?: number;
    refresh_token: string;
};

export const store = (
    key: string,
    value?: HelloToken
): HelloToken | undefined => {
    if (!value) {
        return hello.utils.store(key);
    }

    return hello.utils.store(key, value);
};
