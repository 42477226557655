import * as React from 'react';
import style from './ArrowPanel.module.scss';
import Arrow from 'web/components/Arrow';
import { ReactElement } from 'react';
import classNames from 'classnames';

interface ArrowPanelProp {
    contentBox?: ReactElement;
    title?: string;
    desc?: string;
    onClick?: () => void;
    containerStyle?: string;
}

const ArrowPanel: React.FC<ArrowPanelProp> = ({
    contentBox,
    title,
    desc,
    onClick,
    containerStyle
}) => {
    const panelStyle = classNames(style.panel, {[containerStyle as string]: !!containerStyle});

    return (
        <div className={panelStyle} onClick={() => !!onClick && onClick()}>
            <div className={style.arrow}>
                <Arrow direction={'right'} customStyle={style.arrowIcon} />
            </div>
            {!!contentBox && (
                <div className={style.contentEl}>{contentBox}</div>
            )}
            {!!title && !!desc && (
                <div className={style.content}>
                    <span className={style.title}>{title}</span>
                    <span className={style.desc}>{desc}</span>
                </div>
            )}
        </div>
    );
};

export default ArrowPanel;
