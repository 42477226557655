import * as React from "react";
import style from './SubmittedStatus.module.scss';
import {ReactComponent as Done} from "common/icons/done.svg";
import {useTranslation} from "react-i18next";

const SubmittedStatus: React.FC = () => {
    const { t } = useTranslation(['competitions']);
    
    return <span className={style.submitted}><Done />{t('competitions:submittedStatus')}</span>
};

export default SubmittedStatus;
