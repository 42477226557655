import AcceptInvitationPage from './AcceptInvitationPage';
import { observer } from 'mobx-react';
import * as React from 'react';
import style from './AcceptInvitationPage.module.scss';
import Flex from 'common/components/Flex';
import { bindToCommand } from 'react-mvvm';
import { Col, Row, Typography, Button } from 'antd';
import { ReactComponent as FacebookIco } from 'common/icons/facebook.svg';
import { ReactComponent as TwitterIco } from 'common/icons/twitter.svg';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import AntPassword from 'common/components/antHelpers/AntPassword';
import { useTranslation } from 'react-i18next';
import AntTextInput from 'common/components/antHelpers/AntTextInput';

interface AcceptInvitationViewProps {
    model: AcceptInvitationPage;
}

const AcceptInvitationPageView: React.FC<AcceptInvitationViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('login');

        return (
            <div className={style.container}>
                <div className={style.content}>
                    <Row>
                        <Col sm={24}>
                            <div className={style.box}>
                                <Typography.Title
                                    level={3}
                                    className={style.title}>
                                    {t('acceptInvitation')}
                                </Typography.Title>
                                <AntForm
                                    form={model.acceptInvitationForm}
                                    layout={'vertical'}>
                                    {(fields) => {
                                        return (
                                            <>
                                                <AntField field={fields.pin}>
                                                    {(props) => (
                                                        <AntTextInput
                                                            placeholder={t(
                                                                'pin'
                                                            )}
                                                            {...props}
                                                        />
                                                    )}
                                                </AntField>
                                                <AntField
                                                    field={fields.password}>
                                                    {(props) => (
                                                        <AntPassword
                                                            placeholder={t(
                                                                'password'
                                                            )}
                                                            {...props}
                                                        />
                                                    )}
                                                </AntField>
                                                <AntField
                                                    field={
                                                        fields.passwordConfirmation
                                                    }>
                                                    {(props) => (
                                                        <AntPassword
                                                            placeholder={t(
                                                                'confirmPassword'
                                                            )}
                                                            {...props}
                                                        />
                                                    )}
                                                </AntField>
                                            </>
                                        );
                                    }}
                                </AntForm>
                                <div className={style.messageBox}>
                                    {model.errorMessage && (
                                        <Typography.Paragraph
                                            className={style.errorMsg}>
                                            {model.errorMessage}
                                        </Typography.Paragraph>
                                    )}
                                </div>
                                <Flex
                                    justifyContent={'center'}
                                    flexDirection={'column'}
                                    alignItems={'center'}>
                                    <Button
                                        type={'primary'}
                                        htmlType={'submit'}
                                        className={style.btn}
                                        {...bindToCommand(model.onSubmit)}>
                                        {t('acceptInvitationBtn')}
                                    </Button>
                                </Flex>
                            </div>
                        </Col>
                        {/*<Col sm={24} md={12}>
                            <div className={`${style.box} ${style.bg}`}>
                                
                                <Typography.Title
                                    level={3}
                                    className={`${style.title} ${style.
                                    }`}>
                                    Accept Invitation By
                                </Typography.Title>
                                <Flex
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}>
                                    <Button
                                        type={'ghost'}
                                        className={`${style.button} ${style.btn} ${style.google}`}>
                                        Google
                                    </Button>
                                    <Button
                                        type={'ghost'}
                                        className={`${style.button} ${style.btn} ${style.facebook}`}
                                        {...bindToCommand(
                                            model.onFacebookLogin
                                        )}>
                                        <FacebookIco className={style.icon} />{' '}
                                        Facebook
                                    </Button>
                                    <Button
                                        type={'ghost'}
                                        className={`${style.button} ${style.btn} ${style.twitter}`}>
                                        <TwitterIco className={style.icon} />{' '}
                                        Twitter
                                    </Button>
                                </Flex>
                            </div>
                        </Col>*/}
                    </Row>
                </div>
            </div>
        );
    }
);

export default AcceptInvitationPageView;
