import * as React from 'react';
import { ReactComponent as Logo } from './img/logoSmall.svg';
import { Button } from 'antd';
import PageWrapper from 'web/components/PageWrapper';
import style from './CompetitionHeader.module.scss';
import { ReactNode } from 'react';
import { observer } from 'mobx-react';
import hello from 'hellojs';
import {useTranslation} from "react-i18next";

interface CompetitionHeaderProps {
    logoClick: () => void;
    helpClick: () => void;
    signoutClick?: () => void;
    breadcrumbs?: ReactNode;
}

const CompetitionHeader: React.FC<CompetitionHeaderProps> = observer(
    ({ logoClick, helpClick, signoutClick, breadcrumbs }) => {
        const { t } = useTranslation('homePage');
        const onSignOutClicked = () => {
            hello.logout('customJWT');
            hello.utils.store('customJWT', '');
            window.location.href = `/login?redirect_uri=${encodeURIComponent(
                window.location.href
            )}`;
        };
        return (
            <div className={style.content}>
                <PageWrapper>
                    <div className={style.block}>
                        <div className={style.logoBox}>
                            <Logo className={style.logo} onClick={() => logoClick()} />    
                        </div>
                        <div className={style.breadcrumbs}>
                            {!!breadcrumbs && breadcrumbs}    
                        </div>
                        <div className={style.buttonsBox}>
                            <Button
                                className={`${style.button} negative`}
                                type={'link'}
                                onClick={() =>
                                    !!signoutClick ? signoutClick() : onSignOutClicked()
                                }>
                                {t('signOut')}
                            </Button>    
                        </div>                        
                    </div>
                </PageWrapper>
            </div>
        );
    }
);

export default CompetitionHeader;
