import * as React from "react";
import { Typography } from "antd";

interface SingleSelectFieldProps {
    value: string;
}

const SingleSelectField: React.FC<SingleSelectFieldProps> = ({value}) => {
    const data: {id: number, value: string} | undefined = !value ? undefined : JSON.parse(value);
    return <Typography.Text>{!data ? null : data.value}</Typography.Text>
}

export default SingleSelectField;