import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {SubmissionDto} from "./Model/SubmissionDto"

export interface GetSubmissionDetailsRequest {
    competitionEntryRef: number
}
export const getSubmissionDetails = (getSubmissionDetailsRequest: GetSubmissionDetailsRequest): Promise<SubmissionDto> => {
    return send("GetSubmissionDetails", getSubmissionDetailsRequest)
}
