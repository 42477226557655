import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface ChangePayingPartyRequest {
    payingPartyRef: number
    userRef?: number
}
export const changePayingParty = (changePayingPartyRequest: ChangePayingPartyRequest): Promise<any> => {
    return send("ChangePayingParty", changePayingPartyRequest)
}
