import * as React from 'react';
import PartyInfo from 'web/screen/Membership/PartyInfo';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import EmployeeData from 'web/screen/Membership/EmployeeData/EmployeeData';
import InfoRow from "../../../../common/components/InfoRow/InfoRow";

interface InfoProps {
    model: EmployeeData;
}

const Info: React.FC<InfoProps> = observer(({ model }) => {
    const { t } = useTranslation(['membership', 'common', 'userAddresses']);
    if (!model.member) {
        return null;
    }
    return (
        <>
            <InfoRow title={t('userAddresses:companyAddress')}>
                <PartyInfo
                    party={model.parent.getParty(
                        model.member.employerPartyId
                    )}
                />
            </InfoRow>
            <InfoRow title={t('membership:workPosition')}>
                {model.member.workPosition ?? '-'}
            </InfoRow>
        </>
    );
});

export default Info;
