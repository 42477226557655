import * as React from 'react';
import SubmissionsMyPagePayment from './SubmissionsMyPagePayment';
import { Button } from 'antd';
import style from './SubmissionsMyPagePayment.module.scss';
import PaymentMethodSelectorView from 'web/components/PaymentMethodSelector/PaymentMethodSelectorView';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'web/components/PageWrapper';
import Flex from 'common/components/Flex';
import PageLoader from 'common/components/PageLoader';
import AllUsersAddressDropdown from 'common/components/UserAddresses/AllUsersAddressDropdown';
import PaymentTermsAndConditions from 'web/components/PaymentTermsAndConditions';
import EntriesTable from "web/screen/Submissions/EntriesTable";
import { ReactComponent as X } from 'common/icons/X.svg';
import ConfirmationModal from "common/components/ConfirmationModal/ConfirmationModal";
import ConfirmationModalView from "common/components/ConfirmationModal/ConfirmationModalView";

interface SubmissionsMyPagePaymentViewProps {
    model: SubmissionsMyPagePayment;
}

const SubmissionsMyPagePaymentView: React.FC<SubmissionsMyPagePaymentViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['competitions', 'common']);
        return (
            <div className={style.wrapper}>
                <h1 className={style.title}><span>{t('competitions:allSelectedEntry')}</span></h1>
                <PageWrapper>
                    <div className={style.container}>
                        {model.selectedEntries.length > 0 &&
                            <EntriesTable entries={model.selectedEntries} withSelection={false} withEditLink={false} actionButtons={(record) => {
                                return <>
                                    <Flex justifyContent={"flex-end"} alignItems={"center"} customClass={style.remove}>
                                        <Button size={"small"} icon={<X />} onClick={() => model.onRemoveSelectedEntry(record.id)} />

                                    </Flex>
                                </>
                            }} pagination={false}/>
                        }
                        <PageLoader loader={model.api}>
                            <div className={style.content}>
                                <div className={style.row}>
                                <span className={style.rowTitle}>
                                    {t('competitions:selectAddress')}
                                </span>
                                    <AllUsersAddressDropdown
                                        model={model.userAddresses}
                                    />
                                </div>
                                <div className={style.row}>
                                <span className={style.rowTitle}>
                                    {t('competitions:paymentMethod')}
                                </span>
                                    <PaymentMethodSelectorView
                                        price={model.totalPrice.price}
                                        invoicePrice={
                                            model.totalPrice.invoiceFeePrice
                                        }
                                        model={model.paymentMethod}
                                    />
                                </div>
                                <PaymentTermsAndConditions
                                    onSelect={model.onAcceptTerms}
                                    selected={model.isPaymentTermsAccepted}
                                />
                            </div>
                        </PageLoader>
                    </div>
                </PageWrapper>
                <div className={style.footer}>
                    <Flex justifyContent={"space-between"} alignItems={"center"}  customClass={style.footerContent}>
                        <Button size={"small"} type={"link"} onClick={model.onBack}>{t('backToAllSubmissions')}</Button>
                        <Button type={"primary"} size={"small"} onClick={model.onPaymentClick} disabled={
                            !model.paymentMethod.selected ||
                            !model.userAddresses.selectedParty ||
                            !model.isPaymentTermsAccepted
                        }>{t('competitions:pay')}</Button>
                    </Flex>
                </div>
                {model.dialog instanceof ConfirmationModal && <ConfirmationModalView dialog={model.dialog} title={t('competitions:paymentModalTitle')} okText={t('competitions:goAndPay')} >
                    {t('competitions:paymentModalText')}
                </ConfirmationModalView>}
            </div>
        );
    }
);

export default SubmissionsMyPagePaymentView;
