import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {SubscriptionType} from "../../Externals"
import {PaymentMethod} from "../../Externals"

import {OnlinePaymentTokenDto} from "../Payments/Model/OnlinePaymentTokenDto"

export interface SubmitApplicationAndPayOnlineRequest {
    membershipApplicationId: number
    membershipTypeId: number
    subscriptionType: SubscriptionType
    partyId: number
    paymentMethod: PaymentMethod
    paymentTermsAccepted: boolean
}
export const submitApplicationAndPayOnline = (submitApplicationAndPayOnlineRequest: SubmitApplicationAndPayOnlineRequest): Promise<OnlinePaymentTokenDto> => {
    return send("SubmitApplicationAndPayOnline", submitApplicationAndPayOnlineRequest)
}
