import * as React from 'react';
import style from './MemberMenu.module.scss';
import HomePage from '../HomePage';
import PageWrapper from 'web/components/PageWrapper';
import classNames from 'classnames';
import Membership from 'web/screen/Membership/Membership';
import Invoices from 'web/screen/Invoices/Invoices';
import Submissions from 'web/screen/Submissions/Submissions';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

interface MemberMenuProps {
    model: HomePage;
}

const MemberMenu: React.FC<MemberMenuProps> = observer(({ model }) => {
    const { t } = useTranslation('subMenu');
    const {
        showMembershipPage,
        showInvoicesPage,
        showSubmissionPage,
        navigation,
        childPage
    } = model;

    return (
        <div className={style.container}>
            <PageWrapper>
                <ul className={style.list}>
                    <li
                        className={classNames(style.item, {
                            [style.itemActive]: !childPage
                        })}
                        onClick={() => navigation.items.home.activate()}>
                        {t('myPage')}
                    </li>
                    <li
                        className={classNames(style.item, {
                            [style.itemActive]: childPage instanceof Membership
                        })}
                        onClick={() => showMembershipPage()}>
                        {t('membership')}
                    </li>
                    <li
                        className={classNames(style.item, {
                            [style.itemActive]: childPage instanceof Submissions
                        })}
                        onClick={() => showSubmissionPage()}>
                        {t('submissions')}
                    </li>
                    <li
                        className={classNames(style.item, {
                            [style.itemActive]: childPage instanceof Invoices
                        })}
                        onClick={() => showInvoicesPage()}>
                        {t('invoices')}
                    </li>
                </ul>
            </PageWrapper>
        </div>
    );
});

export default MemberMenu;
