import * as React from 'react';
import UserAddresses from './UserAddresses';
import style from './UserAddresses.module.scss';
import AddressBox from './AddressBox';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import ConfirmationModalView from 'common/components/ConfirmationModal/ConfirmationModalView';
import Party from './Party';
import { Button, Collapse } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import Flex from 'common/components/Flex';
import { useTranslation } from 'react-i18next';
import AddressDialogView from '../AddressDialog/AddressDialogView';
import AddressDialog from '../AddressDialog/AddressDialog';

interface UserAddressesViewProps {
    model: UserAddresses;
}

const { Panel } = Collapse;

const UserAddressesView: React.FC<UserAddressesViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['userAddresses']);
        return (
            <>
                <Collapse
                    defaultActiveKey={model.isExpanded ? ['1', '2'] : []}
                    bordered={false}
                    expandIconPosition={'right'}
                    ghost={true}
                    className={style.accordion}>
                    <Panel key={1} header={t('userAddresses:private')}>
                        <div className={style.box}>
                            {model.sortedParties.private.map((party) => (
                                <AddressBox
                                    party={party}
                                    onSelect={
                                        model.isSelectable
                                            ? model.onSelectAddress
                                            : undefined
                                    }
                                    selected={model.selectedParty}
                                    onEdit={model.onEdit}
                                    onDelete={model.onDelete}
                                    key={party.id}
                                />
                            ))}
                        </div>
                    </Panel>
                    <Panel key={2} header={t('userAddresses:company')}>
                        <div className={style.box}>
                            {model.sortedParties.company.map((party) => (
                                <AddressBox
                                    party={party}
                                    onSelect={
                                        model.isSelectable
                                            ? model.onSelectAddress
                                            : undefined
                                    }
                                    selected={model.selectedParty}
                                    onEdit={model.onEdit}
                                    onDelete={model.onDelete}
                                    key={party.id}
                                />
                            ))}
                        </div>
                    </Panel>
                </Collapse>
                {model.page.dialog instanceof AddressDialog && (
                    <AddressDialogView dialog={model.page.dialog} />
                )}
                {model.page.dialog instanceof ConfirmationModal && (
                    <ConfirmationModalView
                        dialog={model.page.dialog}
                        title={t('userAddresses:dialogDelete')}>
                        <span>{t('userAddresses:deleteConfirmation')}</span>
                        {!!model.deleteParty && (
                            <Party party={model.deleteParty} />
                        )}
                    </ConfirmationModalView>
                )}
                <Flex justifyContent={'flex-end'}>
                    <Button
                        type={'text'}
                        size={'small'}
                        onClick={() => model.onCreateAddress()}
                        className={style.btnAdd}>
                        <PlusOutlined /> {t('userAddresses:addAddress')}
                    </Button>
                </Flex>
            </>
        );
    }
);

export default UserAddressesView;
