import { observer } from 'mobx-react';
import SubmissionFormPreviewPage from './SubmissionFormPreviewPage';
import * as React from 'react';
import { Row, Col, Typography, Divider, Button } from 'antd';
import Flex from 'common/components/Flex';
import style from './SubmissionFormPreview.module.scss';
import SubmissionsAnswers from 'common/components/SubmissionsAnswers';
import PageWrapper from 'web/components/PageWrapper';
import { ContentView } from 'react-mvvm';
import { useTranslation } from 'react-i18next';
import PageLoader from 'common/components/PageLoader';
import BackButton from 'web/components/BackButton';
import SentButtons from './SentButtons';
import SubmissionApprovedModalView from 'common/components/SubmissionApprovedModal/SubmissionApprovedModalView';
import SubmissionApprovedModal from "common/components/SubmissionApprovedModal/SubmissionApprovedModal";
interface SubmissionFormPreviewProps {
    model: SubmissionFormPreviewPage;
}

const SubmissionFormPreviewPageView: React.FC<SubmissionFormPreviewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['competitions', 'common']);
        return (
            <div className={style.container}>
                <PageWrapper>
                    <ContentView content={model.childPage}>
                        <Flex
                            customClass={style.header}
                            justifyContent={'flex-end'}>
                            <div className={style.goToSubmissions}>
                                <BackButton
                                    title={t('competitions:backToAllSubmissions')}
                                    onClick={() => {
                                        window.location.href = '/submissions';
                                    }}
                                />
                            </div>
                        </Flex>
                        {/*<PageTitle
                            title={model.parent.submissionForm.product && model.parent.submissionForm.product.priceIncVat < 0.001 ? 
                                t('competitions:previewFormTitleFree') : t('competitions:previewFormTitle')}
                            headerNumber={2}
                        />*/}
                        <PageLoader loader={model.parent.submissionForm.api}>
                            {/*<Row gutter={40}>
                                <Col span='24'>
                                    <Typography.Paragraph
                                        className={style.description}>
                                        {model.entryPreviewPageDescription}
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={'24'}>
                                    <SentButtons
                                        model={model}
                                        align={'left'}
                                        isBack={false}
                                    />
                                </Col>
                            </Row>
                            <Divider />*/}
                            <Row>
                                <Col span='24'>
                                    <Flex
                                        flexDirection='row'
                                        justifyContent={'space-between'}>
                                        <div className={style.info}>
                                            <Typography.Title
                                                level={4}
                                                className={style.infoTitle}>
                                                {t(
                                                    'competitions:previewFormCategory'
                                                )}
                                            </Typography.Title>
                                            <span className={style.infoText}>
                                                {model.categoryName}
                                            </span>
                                        </div>
                                        <div className={style.info}>
                                            <Typography.Title
                                                level={4}
                                                className={style.infoTitle}>
                                                Id
                                            </Typography.Title>
                                            <span className={style.infoText}>
                                                {model.entryId}
                                            </span>
                                        </div>
                                    </Flex>
                                </Col>
                            </Row>
                            <div className={style.content}>
                                <div className={style.answers}>
                                    {model.answers.map((answer) => (
                                        <SubmissionsAnswers
                                            answer={answer}
                                            attachments={
                                                model.competitionEntryDto
                                                    .attachments
                                            }
                                            key={answer.questionId}
                                        />
                                    ))}
                                </div>
                                <SentButtons
                                    model={model}
                                    align={'right'}
                                    isBack={false}
                                />
                            </div>
                            {model.dialog instanceof
                                SubmissionApprovedModal && (
                                <SubmissionApprovedModalView
                                    dialog={model.dialog} />
                            )}
                        </PageLoader>
                    </ContentView>
                </PageWrapper>
                <div className={style.footer}>
                    <Flex justifyContent={"flex-start"} alignItems={"center"} customClass={style.footerContent}>
                        <Button size={"small"} type={"link"} onClick={() => window.location.href = '/submissions'}>{t('competitions:backToAllSubmissions')}</Button>
                    </Flex>
                </div>
            </div>
        );
    }
);

export default SubmissionFormPreviewPageView;
