import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {UserInfoDto} from "./Model/UserInfoDto"

export interface GetUserInfoRequest {
}
export const getUserInfo = (getUserInfoRequest: GetUserInfoRequest): Promise<UserInfoDto> => {
    return send("GetUserInfo", getUserInfoRequest)
}
