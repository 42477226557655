import * as React from 'react';
import AddressDialog from './AddressDialog';
import AntModal from 'common/components/antHelpers/AntModal';
import { Tabs } from 'antd';
import style from './AddressDialog.module.scss';
import { useTranslation } from 'react-i18next';
import CompanyPartyView from 'common/components/CompanyParty/CompanyPartyView';
import PrivatePartyView from 'common/components/PrivateParty/PrivatePartyView';

const { TabPane } = Tabs;

interface AddressDialogViewProps {
    dialog: AddressDialog;
}

const AddressDialogView: React.FC<AddressDialogViewProps> = ({ dialog }) => {
    const { t } = useTranslation('userAddresses');
    return (
        <AntModal
            className={style.dialog}
            cancel={dialog.cancel}
            title={t('dialogAddAddress')}
            footer={null}
            width={800}>
            {!!dialog.companyParty && !dialog.type ? (
                <Tabs defaultActiveKey='1'>
                    <TabPane tab={t('privateAddress')} key='1'>
                        <PrivatePartyView model={dialog.privateParty} />
                    </TabPane>
                    <TabPane tab={t('companyAddress')} key='2'>
                        {!!dialog.companyParty && (
                            <CompanyPartyView model={dialog.companyParty} />
                        )}
                    </TabPane>
                </Tabs>
            ) : dialog.type === 'company' && !!dialog.companyParty ? (
                <CompanyPartyView model={dialog.companyParty} />
            ) : (
                <PrivatePartyView model={dialog.privateParty} />
            )}
        </AntModal>
    );
};

export default AddressDialogView;
