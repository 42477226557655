import InvoicePaymentStatusPage from './InvoicePaymentStatusPage';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';

interface InvoicePaymentStatusPageViewProps {
    model: InvoicePaymentStatusPage;
}

const InvoicePaymentStatusPageView: React.FC<InvoicePaymentStatusPageViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('payment');
        return (
            <Result
                status={model.status}
                title={t('invoiceThankMsg')}
                subTitle={t('invoiceThankDsc', {
                    orderId: model.orderId
                })}
                extra={[
                    <Button
                        type='primary'
                        key='console'
                        onClick={model.onRemoveChildPage}>
                        {t('goToMyPage')}
                    </Button>
                ]}
            />
        );
    }
);

export default InvoicePaymentStatusPageView;
