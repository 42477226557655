import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {PhaseOneEvaluation} from "../../Externals"


export interface SetPhaseOneEvaluationRequest {
    competitionEntryRef: number
    evaluation: PhaseOneEvaluation
}
export const setPhaseOneEvaluation = (setPhaseOneEvaluationRequest: SetPhaseOneEvaluationRequest): Promise<any> => {
    return send("SetPhaseOneEvaluation", setPhaseOneEvaluationRequest)
}
