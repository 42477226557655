import React from 'react';
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import {
    RcFile,
    UploadChangeParam,
    UploadProps,
    UploadFile
} from 'antd/lib/upload/interface';
import {UploadRequestOption as RcCustomRequestOptions} from 'rc-upload/lib/interface';
import {action} from 'mobx';
import style from './Upload.module.scss';
import {ReactComponent as UploadIcon} from 'common/icons/upload_icon.svg';
import {Upload, Tooltip, Progress} from 'antd';
import {observer} from 'mobx-react';
import {FileMoveDirection, LoadingFileProgress} from "common/components/SubmissionForm/SubmissionForm";
import { Button } from 'antd';

type Props = {
    action?: (file: RcFile) => string;
    customRequest: (options: RcCustomRequestOptions) => void;
    multiple: boolean;
    onChange?: (file: UploadChangeParam) => void;
    onlyImages?: boolean;
    beforeUpload: (file: RcFile, FileList: RcFile[]) => boolean;
    onFileOrderChange?: (file: UploadFile, direction: FileMoveDirection) => void
    multiFileLoadingProgress?: LoadingFileProgress[] | undefined;
};

const onlyImagesRegex = RegExp(/.(jpg|jpeg|png|gif)$/i);

const AntUploadFile = observer(({
                                    customRequest,
                                    multiple,
                                    onChange,
                                    onlyImages = false,
                                    beforeUpload,
                                    onFileOrderChange,
                                    multiFileLoadingProgress,
                                    ...props
                                }: Props & Omit<UploadProps, 'onChange' | 'multiple' | 'customRequest'>) => {

    return (
        <div>
            <Upload.Dragger
                {...props}
                accept={
                    onlyImages
                        ? '.png,image/png,.gif,image/gif,.jpg,.jpeg,image/jpeg'
                        : '.png,image/png,.gif,image/gif,.jpg,.jpeg,image/jpeg,.pdf,.mp3,video/mp3,.mp4,video/mp4'
                }
                className={style.dragger}
                customRequest={customRequest}
                action={action}
                onChange={onChange}
                multiple={multiple}
                beforeUpload={(file: RcFile, fileList: RcFile[]) => {
                    let result = beforeUpload(file, fileList);
                    if (onlyImages) {
                        result = onlyImagesRegex.test(file.name);
                    }
                    return result;
                }}
                itemRender={(originNode, file, currFileList) => {
                    const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;

                    return <div key={file.uid} className={style.item}>
                        {!!file.thumbUrl && <div className={style.image}>
                            <img src={file.thumbUrl} className={style.img}/>
                        </div>}
                        <div className={style.file}>
                            {file.status === 'error' ? errorNode : originNode}
                        </div>
                        {(!!currFileList.length && multiple && onFileOrderChange) && <div className={style.buttons}>
                            <Button type={"default"} size={"small"} className={style.btn}
                                    disabled={currFileList[0].uid === file.uid}
                                    onClick={() => onFileOrderChange && onFileOrderChange(file, FileMoveDirection.up)}>
                                <UpOutlined/>
                            </Button>
                            <Button type={"default"} size={"small"} className={style.btn}
                                    disabled={currFileList[currFileList.length - 1].uid === file.uid}
                                    onClick={() => onFileOrderChange && onFileOrderChange(file, FileMoveDirection.down)}>
                                <DownOutlined/>
                            </Button>
                        </div>
                        }
                    </div>
                }}
            >
                <div className={style.container}>
                    <UploadIcon width={40} height={40}/>
                    <span className={style.text}>Dra filer hit eller klikk her</span>
                    <span className={style.hint}>{`JPG, GIF, PNG${
                        onlyImages ? '' : ', PDF, MP3, MP4'
                    }`}</span>
                </div>
            </Upload.Dragger>
            {(!!multiFileLoadingProgress?.length) && <div>
                {multiFileLoadingProgress.map(i => <div className={`${style.item} ${style.itemProgress}`} key={`progress_${i.uid}`}>
                    <div key={`${i.name}_${i.uid}`} className={style.progress}>
                        <span>{i.name}</span>
                        <Progress percent={i.percent}
                                  strokeLinecap="square"
                                  size="small"
                                  strokeColor={'#6feab6'}
                                  trailColor={'#ffffff'}
                                  showInfo={false}
                                  className={style.progressBar}/>
                    </div>
                </div>)}
            </div>}
        </div>
    );
})

export default AntUploadFile;
