import CreateAccountPage from './CreateAccountPage';
import { observer } from 'mobx-react';
import * as React from 'react';
import AntField from 'common/components/antHelpers/AntField';
import AntForm from 'common/components/antHelpers/AntForm/AntForm';
import Typography from 'antd/lib/typography';
import style from './CreateAccountPageView.module.scss';
import { Button, Spin } from 'antd';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import Link from 'web/components/Link';
import Flex from 'common/components/Flex';
import { ReactComponent as Prev } from 'common/icons/navigation_previous.svg';
import { ContentView } from 'react-mvvm';
import { useTranslation } from 'react-i18next';
import FieldPolicy from '../MembershipApplication/FormStep/FieldPolicy';
import CaptchaField from 'common/components/antHelpers/CaptchaField/CaptchaField';
import CaptchaFieldView from 'common/components/antHelpers/CaptchaField/CaptchaFieldView';

interface CreateAccountPageViewProps {
    model: CreateAccountPage;
}

const CreateAccountPageView: React.FC<CreateAccountPageViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('login');
        return (
            <ContentView content={model.childPage}>
                <div className={style.container}>
                    <div className={style.content}>
                        {model.isLoading && (
                            <div className={style.loader}>
                                <Spin />
                            </div>
                        )}
                        <Typography.Title level={3} className={style.title}>
                            {t('createAccount')}
                        </Typography.Title>
                        <AntForm
                            onChange={model.resetErrors}
                            form={model.createAccountForm}
                            id={'CreateAccountForm'}
                            layout={'vertical'}>
                            {(fields) => {
                                return (
                                    <>
                                        <AntField
                                            field={fields.phoneNumber}
                                            name={'phone'}>
                                            {(props) => (
                                                <AntTextInput
                                                    name={'phone'}
                                                    placeholder={t(
                                                        'phoneNumber'
                                                    )}
                                                    {...props}
                                                />
                                            )}
                                        </AntField>
                                        <AntField field={fields.email}>
                                            {(props) => (
                                                <AntTextInput
                                                    name={'email'}
                                                    placeholder={t('email')}
                                                    {...props}
                                                />
                                            )}
                                        </AntField>
                                        <AntField field={fields.given}>
                                            {(props) => (
                                                <AntTextInput
                                                    name={'name'}
                                                    placeholder={t('name')}
                                                    {...props}
                                                />
                                            )}
                                        </AntField>
                                        <AntField field={fields.family}>
                                            {(props) => (
                                                <AntTextInput
                                                    name={'family'}
                                                    placeholder={t('family')}
                                                    {...props}
                                                />
                                            )}
                                        </AntField>
                                        <FieldPolicy
                                            offset={0}
                                            field={fields.acceptPrivacyPolicy}
                                        />
                                        <CaptchaFieldView
                                            model={fields.captchaToken as CaptchaField}
                                            field={fields.captchaToken}
                                        />
                                    </>
                                );
                            }}
                        </AntForm>
                        <div className={style.messageBox}>
                            {model.accountExistsError && (
                                <Typography.Paragraph
                                    className={`${style.message} ${style.messageError}`}>
                                    {t('accountExistMessage', {
                                        email:
                                            model.createAccountForm.fields.email
                                                .value
                                    })}
                                    <Link
                                        onClick={model.goBack}
                                        size={'small'}
                                        style={style.link}>
                                        {t('title')}
                                    </Link>
                                    {t('accessToAccount')}
                                </Typography.Paragraph>
                            )}
                            {model.errorMessage && (
                                <Typography.Paragraph
                                    className={`${style.message} ${style.messageError}`}>
                                    {model.errorMessage}
                                </Typography.Paragraph>
                            )}
                        </div>
                        <Flex
                            flexDirection={'row'}
                            justifyContent={'space-between'}>
                            <Button
                                type={'link'}
                                onClick={model.goBack}
                                className={style.backButton}>
                                <Prev className={style.backBtnArrow} />
                                {t('backBtn')}
                            </Button>
                            <Button
                                type={'primary'}
                                htmlType={'submit'}
                                disabled={!model.createAccountForm.isDirty}
                                onClick={() => model.onSubmit.execute()}>
                                {t('createAccountBtn')}
                            </Button>
                        </Flex>
                    </div>
                </div>
            </ContentView>
        );
    }
);

export default CreateAccountPageView;
