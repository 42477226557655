﻿export enum RequestStatus {
    none = 'none',
    pending = 'pending',
    success = 'success',
    error = 'error'
}

export interface IUpdateRequest {
    status: RequestStatus,
    errorMsg: string
}