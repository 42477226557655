import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {MemberDto} from "./Model/MemberDto"

export interface GetMemberRequest {
}
export const getMember = (getMemberRequest: GetMemberRequest): Promise<MemberDto> => {
    return send("GetMember", getMemberRequest)
}
