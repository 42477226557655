import React from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import { InputProps } from 'antd/lib/input';

type Props = {
    value: string | number | undefined;
    onChange?: (value: string | number | undefined) => void;
    onCommit?: () => void;
};

const AntWideNumberInput = observer(
    ({
        value,
        onChange,
        onCommit,
        ...props
    }: Props & Omit<InputProps, 'onChange' | 'value'>) => (
        <Input
            {...props}
            value={value}
            onChange={(v) => {
                if (!!onChange) {
                    const value = v.target.value;
                    const reg = /^-?\d*(\.\d*)?$/;
                    if (
                        (!isNaN(Number(value)) && reg.test(value)) ||
                        value === '' ||
                        value === '-'
                    ) {
                        onChange(value);
                    }
                }
            }}
            onBlur={() => {
                onCommit && onCommit();
            }}
        />
    )
);

export default AntWideNumberInput;
