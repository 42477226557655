import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {TokenResult} from "./Model/TokenResult"

export interface RefreshTokenRequest {
    refreshToken: string
}
export const refreshToken = (refreshTokenRequest: RefreshTokenRequest): Promise<TokenResult> => {
    return send("RefreshToken", refreshTokenRequest)
}
