import * as React from 'react';
import ContributorsField from './ContributorsField';
import AntField from 'common/components/antHelpers/AntField';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import { Select, Row, Col, Button, Space, Divider, Input } from 'antd';
import style from './ContributorsFieldView.module.scss';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface ContributorsFieldViewProps {
    model: ContributorsField;
    label: string;
    helpText: string;
    disabled?: boolean;
}

const ContributorsFieldView: React.FC<ContributorsFieldViewProps> = observer(
    ({ model, helpText, label, disabled = false }) => {
        const { t } = useTranslation('competitions');
        const titleStyle = classNames(style.title, {
            [style.required]: model.isRequired
        });
        const [customRole, setCustomRole] = React.useState<string>('');

        return (
            <div className={style.container}>
                <span className={titleStyle}>{label}</span>
                <span className={style.small}>{helpText}</span>
                <div className={style.roleContent}>
                    {model.contributors.map((row, index) => {
                        return (
                            <div
                                className={style.roleLine}
                                key={`contributors-field__${index}`}>
                                <Row
                                    className={style.row}
                                    key={`any_${index}`}
                                    gutter={20}>
                                    <Col span={8}>
                                        <AntField
                                            field={row.fields['role']}
                                            label={t('role')}>
                                            {(props) => (
                                                <Select
                                                    value={props.value}
                                                    onChange={props.onChange}
                                                    allowClear={true}
                                                    disabled={disabled}
                                                    dropdownRender={(menu) => {
                                                        return (
                                                            <div>
                                                                {menu}
                                                                <Space
                                                                    className={
                                                                        style.roleContainer
                                                                    }>
                                                                    <Input
                                                                        className={
                                                                            style.roleInput
                                                                        }
                                                                        value={
                                                                            customRole
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            setCustomRole(
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        type={
                                                                            'text'
                                                                        }
                                                                        onClick={() => {
                                                                            if (customRole.length <= 0) return;
                                                                            model.addCustomRole(
                                                                                customRole
                                                                            );
                                                                            setCustomRole(
                                                                                ''
                                                                            );
                                                                            row.fields.role.value = customRole;
                                                                        }}
                                                                        disabled={
                                                                            disabled
                                                                        }>
                                                                        <PlusOutlined />
                                                                    </Button>
                                                                </Space>
                                                            </div>
                                                        );
                                                    }}>
                                                    {model
                                                        .getAllConfiguration(
                                                            row.fields['role']
                                                                .value ??
                                                                undefined
                                                        )
                                                        .map(
                                                            (
                                                                item,
                                                                idxConfig
                                                            ) => {
                                                                return (
                                                                    <Select.Option
                                                                        value={
                                                                            item
                                                                        }
                                                                        key={`configuration_${index}_${idxConfig}`}>
                                                                        {item}
                                                                    </Select.Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            )}
                                        </AntField>
                                    </Col>
                                    <Col span={8}>
                                        <AntField
                                            field={row.fields['name']}
                                            label={t('name')}>
                                            {(props) => (
                                                <AntTextInput
                                                    {...props}
                                                    disabled={disabled}
                                                />
                                            )}
                                        </AntField>
                                    </Col>
                                    <Col span={8}>
                                        <AntField
                                            field={row.fields['company']}
                                            label={t('company')}>
                                            {(props) => (
                                                <AntTextInput
                                                    {...props}
                                                    disabled={disabled}
                                                />
                                            )}
                                        </AntField>
                                    </Col>
                                </Row>
                                {index > 0 && (
                                    <Button
                                        size={'small'}
                                        className={style.button}
                                        type={'text'}
                                        onClick={() => model.removeRow(index)}
                                        disabled={disabled}>
                                        <DeleteOutlined />
                                    </Button>
                                )}
                            </div>
                        );
                    })}
                </div>
                <Button
                    type={'text'}
                    size={'small'}
                    className={`ant-btn-add ${style.addButton}`}
                    onClick={model.addRow.execute}
                    disabled={disabled}>
                    <PlusOutlined />
                    {t('addContributor')}
                </Button>
            </div>
        );
    }
);

export default ContributorsFieldView;
