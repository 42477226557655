import * as React from 'react';
import JudgingEntriesPage from 'web/screen/JudgingEntriesPage/JudgingEntriesPage';
import { CategoryEvaluationStatus, PhaseOneEvaluation } from 'model/Externals';
import ArtWork from 'web/screen/JudgingEntriesPage/ArtWork';
import {observer} from "mobx-react";

interface IndividualPhaseProps {
    model: JudgingEntriesPage;
    view: 'tiles' | 'list';
}

const IndividualPhase: React.FC<IndividualPhaseProps> = observer(({ model, view }) => {
    return (
        <>
            {model.entryList.map((entry) => {
                const isEditablePhaseOne: boolean =
                    model.categoryEvaluationStatus ===
                        CategoryEvaluationStatus.pending && model.isEditable;

                return (
                    <ArtWork
                        key={entry.id}
                        type={view}
                        competitionEntry={entry}
                        seeDetails={() =>
                            model.showJudgingEntryDetails(entry.id)
                        }
                        competitionStatus={model.competitionStatus}
                        onEvaluationPhaseOne={(value: PhaseOneEvaluation) =>
                            model.apiEvaluation.setEvaluationPhaseOne(
                                entry,
                                value
                            )
                        }
                        evaluationResults={model.evaluationResults}
                        isEditable={isEditablePhaseOne}
                    />
                );
            })}
        </>
    );
});

export default IndividualPhase;
