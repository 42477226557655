import * as React from 'react';
import style from '../Membership.module.scss';
import EmployeeData from 'web/screen/Membership/EmployeeData/EmployeeData';
import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import PageLoader from 'common/components/PageLoader';
import { EditOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import Info from './Info';
import Form from './Form';
import Flex from 'common/components/Flex';
import SingleUserAddressesDialogsView from 'common/components/UserAddresses/SingleUserAddressesDialogsView';

interface EmployeeDataViewProps {
    model: EmployeeData;
}

const EmployeeDataView: React.FC<EmployeeDataViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['membership', 'common']);

        return (
            <div className={style.dataContent}>
                <div className={style.title}>
                    <span className={style.titleTxt}>
                        {t('membership:employeeInformation')}
                    </span>
                    {!model.isEdit && (
                        <Button
                            type={'text'}
                            size={'small'}
                            className={style.titleIcon}
                            onClick={model.onEdit}>
                            <EditOutlined />
                        </Button>
                    )}
                </div>
                <PageLoader loader={model.parent.apiSaveMembership}>
                    {model.isEdit ? (
                        <div>
                            <Form model={model} />
                            <Flex justifyContent={'flex-end'}>
                                <Space>
                                    <Button
                                        className={style.btn}
                                        type={'text'}
                                        onClick={model.onCancel}>
                                        {t('common:cancel')}
                                    </Button>
                                    <Button
                                        type={'primary'}
                                        onClick={model.onSave}>
                                        {t('common:save')}
                                    </Button>
                                </Space>
                            </Flex>
                        </div>
                    ) : (
                        <Info model={model} />
                    )}
                </PageLoader>
                <SingleUserAddressesDialogsView model={model.userAddresses} />
            </div>
        );
    }
);

export default EmployeeDataView;
