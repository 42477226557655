import BaseDialog from 'admin/helpers/BaseDialog';
import { UserInfoDto } from 'model/Api/Individuals/Model/UserInfoDto';
import { observable } from 'mobx';
import {
    bindableForm,
    RequiredFieldValidator,
    RequiredEmailValidator
} from 'react-mvvm/forms';
import { submitFormCommand } from 'react-mvvm/commands';
import { TicketDto } from 'model/Api/Tickets/Model/TicketDto';
import { UpdateUserInfoRequest } from 'model/Api/Individuals/UpdateUserInfoRequest';

class UserInfoEditDialog extends BaseDialog<UserInfoDto> {
    @observable userInfo: Partial<UserInfoDto> = {};
    @observable confirmTicket: boolean = false;
    ticketDto: TicketDto = {
        id: 0,
        version: 0
    };
    updateUserInfoRequest: UpdateUserInfoRequest = {
        pin: '',
        ticketRef: 0
    };

    constructor(
        close: (c: UserInfoDto | undefined) => void,
        private onChangeRequested: (
            userInfo: UserInfoDto
        ) => Promise<TicketDto>,
        private onChangeConfirmed: (
            ticketRef: number,
            pin: string
        ) => Promise<UserInfoDto>,
        userInfo: UserInfoDto | undefined
    ) {
        super(close);
        this.userInfo = userInfo ?? {};
    }

    userInfoForm = bindableForm<UserInfoDto>()
        .addField('given', { validator: RequiredFieldValidator })
        .addField('family', { validator: RequiredFieldValidator })
        .addField('email', { validator: RequiredEmailValidator })
        .addField('phoneNumber', { validator: RequiredFieldValidator })
        .bindTo(() => this.userInfo);

    userInfoConfirmationForm = bindableForm<UpdateUserInfoRequest>()
        .addField('pin', { validator: RequiredFieldValidator })
        .bindTo(() => this.updateUserInfoRequest);

    save = submitFormCommand(this.userInfoForm, async () => {
        this.ticketDto = await this.onChangeRequested(
            this.userInfo as UserInfoDto
        );
        this.confirmTicket = true;
    });

    confirm = submitFormCommand(this.userInfoConfirmationForm, async () => {
        const user = await this.onChangeConfirmed(
            this.ticketDto.id,
            this.updateUserInfoRequest.pin
        );
        this.confirmTicket = false;
        this.close(user);
    });
}

export default UserInfoEditDialog;
