import BaseDialog from 'admin/helpers/BaseDialog';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';
import PrivateParty from 'common/components/PrivateParty/PrivateParty';
import CompanyParty from 'common/components/CompanyParty/CompanyParty';

class AddressDialog extends BaseDialog<PartyDto> {
    privateParty: PrivateParty;
    companyParty?: CompanyParty;
    type?: 'private' | 'company';

    constructor(
        close: (p: PartyDto | undefined) => void,
        privateParty: PrivateParty,
        companyParty?: CompanyParty,
        type?: 'private' | 'company'
    ) {
        super(close);

        this.privateParty = privateParty;
        this.companyParty = companyParty;
        this.type = type;
    }
}

export default AddressDialog;
