import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import style from './AntTextArea.module.scss';
import {useTranslation} from "react-i18next";

const { TextArea } = Input;

type Props = {
    value?: string | undefined;
    onChange?: (value: string) => void;
    onCommit?: () => void;
    maxLength?: number;
};

const AntTextArea = ({
    value,
    onChange,
    onCommit,
    maxLength,
    ...props
}: Props & Omit<TextAreaProps, 'value' | 'onChange'>) => {
    const [isMax, setIsMax] = useState(false);
    const { t } = useTranslation('form');

    const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (!!maxLength) {
            setIsMax(e.target.value.length >= maxLength);
        }
        return onChange && onChange(e.target.value);
    };

    return (
        <>
            <TextArea
                {...props}
                maxLength={maxLength}
                className={style.textarea}
                value={value ?? ''}
                onChange={onInputChange}
                onBlur={() => onCommit && onCommit()}
            />
            {!!maxLength && (
                <div className={style.maxLength}>
                    {isMax ? maxLength : value?.length || 0} / {maxLength}{` ${t('characters')}`}
                </div>
            )}
        </>
    );
};

export default AntTextArea;
