import { observable } from 'mobx';
import { Key } from 'react';

export class List<T extends { id: number | string }> {
    @observable.ref selection: ReadonlyArray<T> = [];
    @observable.ref items: ReadonlyArray<T & { isNew?: boolean }> = [];
    @observable.ref public sortOrder:
        | {
              readonly field: keyof T;
              readonly direction: 'descend' | 'ascend' | undefined;
          }
        | undefined;
    @observable.ref public filters?: Record<string, Key[] | null> = undefined;

    get length() {
        return this.items.length;
    }

    push(item: T) {
        this.items = [...this.items, item];
    }

    removeItems(items: ReadonlyArray<T>) {
        this.items = this.items.filter((i) => items.indexOf(i) < 0);
    }

    removeAllItems() {
        this.items = [];
    }

    prependNewItem(item: T) {
        this.items = [Object.assign(item, { isNew: true }), ...this.items];
    }

    prependNewItems(items: T[]) {
        const newItems = items.map((i) => Object.assign(i, { isNew: true }));
        this.items = [...newItems, ...this.items];
    }

    patchItem(item: T, patch: Partial<T>) {
        this.items = this.items.map((i: any) =>
            i === item ? { ...i, ...(patch as any) } : i
        );
    }
    
    find(predicate: (value: T, index: number, obj: readonly T[]) => boolean) : T | undefined {
        return this.items.find(predicate);
    }
    
    field<P extends keyof T>(key: P): keyof T {
        return key;
    }
}
