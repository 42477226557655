import {
    Page,
    bindableForm,
    RequiredFieldValidator,
    asyncCommand
} from 'react-mvvm';
import queryString from 'query-string';
import { observable } from 'mobx';
import hello from 'hellojs';
import {
    setPasswordByPhone,
    SetPasswordByPhoneRequest
} from 'model/Api/Account/SetPasswordByPhoneRequest';
import HomePage from 'web/screen/HomePage/HomePage';

class ResetPasswordByPhonePage extends Page {
    @observable.ref setPasswordRequest: SetPasswordByPhoneRequest;
    @observable errorMsg: string;
    @observable isLoading: boolean;

    constructor(private parent: HomePage) {
        super();
        this.errorMsg = '';
        this.isLoading = false;

        const query = queryString.parse(globalThis.location.search);

        this.setPasswordRequest = {
            userId: !query.userId
                ? ''
                : Array.isArray(query.userId)
                ? query.userId[0]
                : query.userId,
            pin: '',
            password: '',
            confirmPassword: ''
        };
    }

    setPasswordForm = bindableForm<SetPasswordByPhoneRequest>()
        .addField('pin', { validator: RequiredFieldValidator })
        .addField('password', { validator: RequiredFieldValidator })
        .addField('confirmPassword', { validator: RequiredFieldValidator })
        .bindTo(() => this.setPasswordRequest);

    onSubmit = asyncCommand(
        async () => {
            this.errorMsg = '';
            if (!(await this.setPasswordForm.validate())) return;

            this.isLoading = true;

            this.setPasswordForm.commit();
            try {
                const token = await setPasswordByPhone(this.setPasswordRequest);
                if ('accessToken' in token) {
                    hello.utils.store('customJWT', {
                        access_token: token.accessToken.token,
                        refresh_token: token.refreshToken.token
                    });
                    window.location.href = '/';
                }
            } catch (e) {
                this.isLoading = false;
                if (
                    e.response.data.errors &&
                    e.response.data.errors.length != 0
                ) {
                    this.errorMsg = e.response.data.errors.join(', ');
                }
            }
        },
        () => this.setPasswordForm.isDirty
    );
}

export default ResetPasswordByPhonePage;
