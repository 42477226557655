import { useTranslation } from 'react-i18next';

import { EvaluationCompetitionListItemDto } from 'model/Api/Evaluation/Model/EvaluationCompetitionListItemDto';
import * as React from 'react';
import { Button, Col, Row, Steps, Typography } from 'antd';
import style from './Introduction.module.scss';
import Flex from 'common/components/Flex';
import { CompetitionStatus, JudgingSchema } from 'model/Externals';
import { observer } from 'mobx-react';

const getCurrentStep = (item: EvaluationCompetitionListItemDto): number => {
    switch (item.competitionStatus) {
        case CompetitionStatus.phaseOneJudgingInProgress:
        case CompetitionStatus.phaseOneJudgingClosed:
            return 0;
        case CompetitionStatus.phaseTwoJudgingInProgress:
        case CompetitionStatus.phaseTwoJudgingClosed:
            return 1;
        case CompetitionStatus.closed:
            return 2;
        default:
            return 0;
    }
};

interface IntroductionProps {
    onStart: () => void;
    competitionListItem: EvaluationCompetitionListItemDto;
}

const Introduction: React.FC<IntroductionProps> = observer(
    ({ onStart, competitionListItem }) => {
        const { t } = useTranslation('judging');
        return (
            <>
                <Row justify={'center'}>
                    <Col span={12}>
                        <Typography.Title level={1} className={style.center}>
                            {t('judgingIntro')}
                        </Typography.Title>
                        {competitionListItem.judgingSchema ===
                            JudgingSchema.individualPlusGroup && (
                            <Steps
                                current={getCurrentStep(competitionListItem)}
                                progressDot={(dot: any) => dot}
                                className={style.steps}>
                                <Steps.Step title={t('stepIndividual')} />
                                <Steps.Step title={t('stepGroup')} />
                                <Steps.Step title={t('stepResult')} />
                            </Steps>
                        )}
                        <Typography.Paragraph className={style.description}>
                            {competitionListItem.judgingPhaseDescription}
                        </Typography.Paragraph>
                        <Flex justifyContent={'center'}>
                            <Button
                                type={'primary'}
                                onClick={onStart}
                                className={style.button}>{t('startJudging')}</Button>
                        </Flex>
                    </Col>
                </Row>
            </>
        );
    }
);

export default Introduction;
