import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface UpdateMemberEmploymentDataRequest {
    workPosition: string
    employerPartyId: number
}
export const updateMemberEmploymentData = (updateMemberEmploymentDataRequest: UpdateMemberEmploymentDataRequest): Promise<any> => {
    return send("UpdateMemberEmploymentData", updateMemberEmploymentDataRequest)
}
