import * as React from 'react';
import style from './CategoryList.module.scss';
import { Col, Row, Space, Typography } from 'antd';
import PageWrapper from 'web/components/PageWrapper';
import Arrow from 'web/components/Arrow';

interface CategoryListHeaderProps {
    title: string;
    description?: string;
    onClick?: () => void;
    customStyle?: string;
}

const CategoryListHeader: React.FC<CategoryListHeaderProps> = ({
    title,
    description,
    onClick,
    customStyle = ''
}) => {
    return (
        <div onClick={() => !!onClick && onClick()} className={customStyle}>
            <div className={style.collapseRow}>
                {!!onClick && (
                    <span className={style.collapseIcon}>
                        <Arrow />
                    </span>
                )}
                <div className={style.collapseBox}>
                    <Typography.Title level={2} className={style.collapseTitle}>
                        {title}
                    </Typography.Title>
                    {!!description && (
                        <span className={`${style.dsc} ${style.collapseDesc}`}>
                            {description}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CategoryListHeader;
