import PageLoader from 'common/components/PageLoader';
import * as React from 'react';
import style from './Submissions.module.scss';
import Submissions from './Submissions';
import PageWrapper from 'web/components/PageWrapper';
import {observer} from 'mobx-react';
import {Button, Empty, Tabs, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {ContentView} from 'react-mvvm';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import ConfirmationModalView from 'common/components/ConfirmationModal/ConfirmationModalView';
import PageTitle from 'web/components/PageTitle';
import Flex from 'common/components/Flex';
import EntriesTable from "web/screen/Submissions/EntriesTable";
import {CompetitionEntryStatus, CompetitionStatus, PaymentMethod, PaymentStatus} from "model/Externals";
import {homeRoute, submissionFormRoute} from "web/routes";
import {ReactComponent as Info} from 'common/icons/Info.svg';
import {ReactComponent as PenNib} from 'common/icons/PenNib.svg';
import {ReactComponent as Bin} from 'common/icons/Bin.svg';
import SubmittedStatus from "web/components/SubmittedStatus";
import {PlusOutlined} from '@ant-design/icons';

interface SubmissionsViewProps {
    model: Submissions;
}

const SubmissionsView: React.FC<SubmissionsViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation(['competitions', 'common']);
        
        return (
            <ContentView content={model.childPage}>
                <PageWrapper>
                    <PageLoader loader={model.api}>
                        <div className={style.container}>
                            <div className={style.submissionBox}>
                                <Flex justifyContent={"space-between"} alignItems={"flex-start"}>
                                    <div className={style.intro}>
                                        <PageTitle
                                            title={t('competitions:submissionsTitle')}
                                            headerNumber={2}
                                        />
                                        <Typography.Text className={style.helpText}>
                                            {t('competitions:submissionsHelp')}
                                        </Typography.Text>
                                    </div>
                                    <Button
                                        type={'primary'}
                                        className={style.addButton}
                                        onClick={() => {
                                            window.location.href =
                                                '/competitions';
                                        }}>
                                        <PlusOutlined />
                                        {t('competitions:addContribution')}
                                    </Button>
                                </Flex>
                                <Tabs defaultActiveKey={'0'} className={style.tabList}>
                                    <Tabs.TabPane tab={t('competitions:openEntry')} key={'0'}>
                                        {model.sortedEntry.open.length > 0 ?
                                            <EntriesTable entries={model.sortedEntry.open}
                                                          withSelection={true}
                                                          withEditLink={true}
                                                          onSelect={model.onSelectRecord}
                                                          onAllSelect={model.onSelectAllRecords}
                                                          selectedRows={model.selectedEntries}
                                                          onSubmitAll={model.showHandelkurvPage}
                                                          lastEditedEntry={model.lastEditedEntry}
                                                          actionButtons={(record) => {
                                                              return <Flex justifyContent={"space-between"}
                                                                           alignItems={"center"}
                                                                           customClass={style.actionButtons}>
                                                                  {!record.isReadyToApprove && <span
                                                                      className={style.status}><Info/><>{t('competitions:mandatoryFields')}</></span>}
                                                                  {(record.paymentStatus === PaymentStatus.pending && record.paymentMethod === PaymentMethod.bambora && record.competitionEntryStatus === CompetitionEntryStatus.submitted) &&
                                                                      <span
                                                                          className={style.status}><Info/><>{t('competitions:paymentFailed')}</></span>}
                                                                  <Flex justifyContent={'flex-start'}
                                                                        alignItems={"center"}>
                                                                      {record.competitionEntryStatus !== CompetitionEntryStatus.submitted && <>
                                                                          <Button size={"small"} icon={<PenNib/>}
                                                                                  onClick={() => {
                                                                                      homeRoute.historyPush(
                                                                                          `${submissionFormRoute.getPath({
                                                                                              competitionId: record.competition.id.toString(),
                                                                                              categoryId: record.category.id.toString(),
                                                                                              entryId: record.id.toString()
                                                                                          })}`,
                                                                                          {backUrl: '/submissions'})
                                                                                  }}/>
                                                                          <Button size={"small"} icon={<Bin/>}
                                                                                  onClick={() => model.onRemoveEntry(record)}/>
                                                                      </>}
                                                                  </Flex>
                                                                  {
                                                                      record.competitionEntryStatus === CompetitionEntryStatus.submitted ?
                                                                          record.paymentStatus === PaymentStatus.pending && record.paymentMethod === PaymentMethod.bambora ?
                                                                              <Button size={"small"}
                                                                                      onClick={() => model.onSubmitSingleEntry(record.id)}>{t('competitions:retryPay')}</Button> :
                                                                          <SubmittedStatus/> : <Button size={"small"}
                                                                                                       onClick={() => model.onSubmitSingleEntry(record.id)}
                                                                                                       disabled={!record.isReadyToApprove || record.competition.competitionStatus !== CompetitionStatus.submissionOpen}>{t('competitions:sendIn')}</Button>
                                                                  }

                                                              </Flex>;
                                                          }}   /> :
                                            <Empty description={'No active competitions'} className={style.empty}>
                                                <Button
                                                    type={'default'}
                                                    className={style.addButton}
                                                    onClick={() => {
                                                        window.location.href =
                                                            '/competitions';
                                                    }}>
                                                    <PlusOutlined />
                                                    {t('competitions:addContribution')}
                                                </Button>
                                            </Empty>
                                        }
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={t('competitions:pastEntry')} key={'1'}>
                                        {model.sortedEntry.past.length > 0 ?
                                            <EntriesTable entries={model.sortedEntry.past} withSelection={false} withEditLink={true} actionButtons={(record) => {
                                                return <><Flex justifyContent={"flex-end"} alignItems={"center"}>
                                                    <SubmittedStatus />
                                                </Flex></>;
                                            }}  /> : 
                                            <Empty description={'No past competitions'} className={style.empty} /> 
                                        }
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>
                        {model.dialog instanceof ConfirmationModal && (
                            <ConfirmationModalView
                                title={t('competitions:deleteEntryTitle')}
                                dialog={model.dialog}>
                                {t('competitions:deleteEntryDsc')}{' '}
                                <strong>{model.dialog.subject}</strong>?
                            </ConfirmationModalView>
                        )}
                    </PageLoader>
                </PageWrapper>
            </ContentView>
        );
    }
);

export default SubmissionsView;
