import {
  FormField,
  FormFieldValidator,
} from 'react-mvvm/forms';
import { computed, observable } from 'mobx';

class CaptchaField extends FormField<string | undefined> {
  @observable token: string | undefined;
  public fieldValidator: FormFieldValidator<string>;

  constructor(
      value: string | undefined,
      validator: FormFieldValidator<string>
  ) {
      super(value, validator);
      this.fieldValidator = validator;
      this.value = value;
  }

  @computed get value(): string | undefined {
      return this.token;
  }

  set value(token: string | undefined) {
    this.token = token;
}

  onVerifyCaptcha = (token: string) => {
    this.value = token;
  }
}

export default CaptchaField;
