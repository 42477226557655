import ConfirmPhoneNumberPage from './ConfirmPhoneNumberPage';
import { observer } from 'mobx-react';
import * as React from 'react';
import style from './ConfirmPhoneNumberPageView.module.scss';
import AntField from 'common/components/antHelpers/AntField';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import AntForm from 'common/components/antHelpers/AntForm';
import { Typography, Spin, Button } from 'antd';
import Flex from 'common/components/Flex';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AntPassword from 'common/components/antHelpers/AntPassword';

interface ConfirmPhoneNumberPageViewProps {
    model: ConfirmPhoneNumberPage;
}

const ConfirmPhoneNumberPageView: React.FC<ConfirmPhoneNumberPageViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('confirmPhoneNumber');
        return (
            <div className={style.container}>
                <div className={style.content}>
                    {model.isLoading && (
                        <div className={style.loader}>
                            <Spin />
                        </div>
                    )}
                    <Typography.Title level={3} className={style.title}>
                        {t('title')}
                    </Typography.Title>
                    <Typography.Text className={style.help}>
                        {t('description')}
                    </Typography.Text>
                    <AntForm
                        onChange={model.resetErrors}
                        form={model.confirmPhoneForm}
                        id={'ConfirmPhoneForm'}
                        layout={'vertical'}>
                        {(fields) => {
                            return (
                                <>
                                    <AntField field={fields.phoneNumber}>
                                        {(props) => (
                                            <AntTextInput
                                                disabled={true}
                                                name={'phoneNumber'}
                                                placeholder={t('phoneNumber')}
                                                {...props}
                                            />
                                        )}
                                    </AntField>
                                    <AntField field={fields.pin}>
                                        {(props) => (
                                            <AntTextInput
                                                autoComplete='pin'
                                                name={'pin'}
                                                placeholder={t('pinCode')}
                                                {...props}
                                            />
                                        )}
                                    </AntField>
                                    <AntField field={fields.password}>
                                        {(props) => (
                                            <AntPassword
                                                autoComplete='new-password'
                                                name={'password'}
                                                placeholder={t('password')}
                                                {...props}
                                            />
                                        )}
                                    </AntField>
                                    <AntField field={fields.confirmPassword}>
                                        {(props) => (
                                            <AntPassword
                                                autoComplete='new-password'
                                                name={'repeatPassword'}
                                                placeholder={t(
                                                    'confirmPassword'
                                                )}
                                                {...props}
                                            />
                                        )}
                                    </AntField>
                                </>
                            );
                        }}
                    </AntForm>
                    <Flex
                        flexDirection={'row'}
                        justifyContent={'space-between'}>
                        <Button
                            type={'primary'}
                            htmlType={'submit'}
                            onClick={() => model.onSubmit.execute()}>
                            {t('confirmPhoneNumber')}
                        </Button>
                    </Flex>
                    {model.errorMessage && (
                        <Typography.Paragraph
                            className={`${style.message} ${style.messageError}`}>
                            <ExclamationCircleFilled
                                className={style.errorIcon}
                            />
                            {model.errorMessage}
                        </Typography.Paragraph>
                    )}
                </div>
            </div>
        );
    }
);

export default ConfirmPhoneNumberPageView;
