import * as React from 'react';
import CompanyParty from './CompanyParty';
import AntTextInput from 'common/components/antHelpers/AntTextInput';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import AntForm from 'common/components/antHelpers/AntForm';
import AntField from 'common/components/antHelpers/AntField';
import style from './CompanyParty.module.scss';
import Flex from 'common/components/Flex';
import { useTranslation } from 'react-i18next';
import AddressFieldView from '../AddressField/AddressFieldView';
import AddressField from '../AddressField/AddressField';

interface CompanyPartyViewProps {
    model: CompanyParty;
}

const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
};

const CompanyPartyView: React.FC<CompanyPartyViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation([
            'userAddresses',
            'common',
            'addressField'
        ]);

        return (
            <AntForm form={model.partyForm} {...formLayout}>
                {(fields) => (
                    <>
                        <div className={style.fields}>
                            <AntField
                                field={fields.name}
                                label={t('userAddresses:companyName')}>
                                {(props) => <AntTextInput {...props} />}
                            </AntField>
                            <AntField
                                field={fields.careOf}
                                label={t('addressField:companyTo')}>
                                {(props) => <AntTextInput {...props} />}
                            </AntField>
                            <AntField
                                field={fields.orgNo}
                                label={t('addressField:organisationNumber')}>
                                {(props) => {
                                  const value = model.getOnlyNumbers(props.value);
                                  return <AntTextInput {...props} value={value} />
                                }}
                            </AntField>
                            <AddressFieldView
                                model={fields.address as AddressField}
                            />
                        </div>
                        <Flex justifyContent={'flex-end'}>
                            <Button
                                className={style.btn}
                                type={'primary'}
                                onClick={() => model.save()}
                                disabled={model.partyForm.isPristine}>
                                {t('common:save')}
                            </Button>
                        </Flex>
                    </>
                )}
            </AntForm>
        );
    }
);

export default CompanyPartyView;
