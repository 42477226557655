import * as React from 'react';
import style from './JudgingEntriesPageView.module.scss';
import JudgingEntriesPage, {
    JudgeEntriesSorting
} from 'web/screen/JudgingEntriesPage/JudgingEntriesPage';
import { ContentView } from 'react-mvvm';
import EvaluationOptionList from 'web/components/EvaluationOptionList';
import PageLoader from 'common/components/PageLoader';
import { CompetitionStatus } from 'model/Externals';
import { observer } from 'mobx-react';
import IndividualPhase from 'web/screen/JudgingEntriesPage/IndividualPhase';
import GroupPhase from 'web/screen/JudgingEntriesPage/GroupPhase';
import { useTranslation } from 'react-i18next';

interface JudgingEntriesPageViewProps {
    model: JudgingEntriesPage;
}

const JudgingEntriesPageView: React.FC<JudgingEntriesPageViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('judging');
        const [view, setView] = React.useState<'tiles' | 'list'>('list');

        return (
            <ContentView content={model.childPage}>
                <div className={style.container}>
                    <div className={style.judgingHeader}>
                        <EvaluationOptionList
                            view={view}
                            setView={setView}
                            value={model.sortableValue}
                            onChange={model.onSortChange}
                            selectOptions={[
                                {
                                    id: JudgeEntriesSorting.title,
                                    value: t('sortingAlphabetically')
                                },
                                {
                                    id:
                                        model.competitionStatus ===
                                        CompetitionStatus.phaseOneJudgingInProgress
                                            ? JudgeEntriesSorting.phaseOneEvaluation
                                            : JudgeEntriesSorting.evaluationResult,
                                    value: t('sortingResults')
                                }
                            ]}
                        />
                    </div>
                    <PageLoader loader={model.api}>
                        <div className={style.artworkContainer}>
                            {model.competitionStatus ===
                                CompetitionStatus.phaseOneJudgingInProgress ||
                            model.competitionStatus ===
                                CompetitionStatus.phaseOneJudgingClosed ? (
                                <IndividualPhase model={model} view={view} />
                            ) : (
                                <GroupPhase model={model} view={view} />
                            )}
                        </div>
                    </PageLoader>
                </div>
            </ContentView>
        );
    }
);

export default JudgingEntriesPageView;
