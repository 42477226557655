import * as React from 'react';
import style from './Membership.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MembershipDto } from 'model/Api/Members/Model/MembershipDto';
import { Button, Tag } from 'antd';
import Flex from 'common/components/Flex';
import InfoRow from 'common/components/InfoRow/InfoRow';
import { PaymentMethod } from 'model/Externals';

interface SubscriptionInfoWithPeriodProps {
    membership: MembershipDto;
    isActive: boolean;
    onChangeSubscription: () => void;
}

const SubscriptionInfoWithPeriod: React.FC<SubscriptionInfoWithPeriodProps> = ({
    membership,
    onChangeSubscription,
    isActive
}) => {
    const { t } = useTranslation(['membership', 'common']);

    return (
        <InfoRow>
            <div>
                <Flex justifyContent={'space-between'}>
                    <span
                        className={
                            style.rowTxt
                        }>{`Betalinsperiode: ${membership.currentSubscription.subscriptionType}`}</span>
                    {isActive && <Button
                        type={'link'}
                        onClick={onChangeSubscription}
                        className={style.titleLink}>
                        {t('changeSubscriptionData')}
                    </Button>}
                </Flex>
                <span className={style.rowTxt}>{`${t(
                    'membership:selectedPaymentMethod'
                )} ${
                    membership.currentSubscription.paymentMethod ===
                    PaymentMethod.bambora
                        ? t('common:payByCart')
                        : t('common:payByInvoice')
                }`}</span>
                {membership.paidTo ?
                    (<span className={style.rowTxt}>{`${t('membership:BillingTil')} ${moment(
                    membership.paidTo
                ).format('DD.MM.YYYY')}`}</span>)
                : (<span>{'-'}</span>)}
                <Button
                    type={'link'}
                    className={style.btnLink}
                    onClick={() => {
                        window.location.href = '/invoices';
                    }}>
                    {t('membership:seeYourInvoices')}
                </Button>
            </div>
        </InfoRow>
    );
};

export default SubscriptionInfoWithPeriod;
