import { PartySnapshotDto } from 'model/Api/Parties/Model/PartySnapshotDto';

import * as React from 'react';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';
import style from './UserAddresses.module.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';

interface PartyProps {
    party: PartyDto | PartySnapshotDto;
    customStyle?: string;
}

const Party: React.FC<PartyProps> = observer(({ party, customStyle }) => {
    const partyStyle = classNames(style.party, !!customStyle && customStyle);
    return (
        <span className={partyStyle}>
            <span className={style.partyLine}>{party.name}</span>
            <span className={style.partyLine}>{party.address.line}</span>
            <span
                className={
                    style.partyLine
                }>{`${party.address.postalCode} ${party.address.city}`}</span>
        </span>
    );
});

export default Party;
