import Membership from 'web/screen/Membership/Membership';
import { Form, FormField, RequiredFieldValidator } from 'react-mvvm/forms';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { MemberDto } from 'model/Api/Members/Model/MemberDto';

class SchoolInfo {
    parent: Membership;
    form: Form<any> | undefined;
    @observable isEdit: boolean;

    constructor(parent: Membership) {
        this.parent = parent;
        this.isEdit = false;
    }

    @computed get member(): MemberDto | undefined {
        return this.parent.member;
    }

    onInit = async () => {
        if (!!this.member) {
            this.form = await this.buildForm(this.member);
        }
    };

    buildForm = async (member: MemberDto | undefined) => {
        if(!member) return;
        
        return new Form({
            schoolName: new FormField(
                member.schoolName,
                RequiredFieldValidator
            ),
            expectedGraduationDate: new FormField(
                !member.expectedGraduationDate
                    ? undefined
                    : moment(new Date(member.expectedGraduationDate)),
                RequiredFieldValidator
            )
        });
    };

    onSave = async () => {
        if (!!this.member && !!this.form) {
            const { fields } = this.form;

            await this.parent.api.updateMemberEducationData(fields.schoolName.value, 
                fields.expectedGraduationDate.value);
            this.parent.member = await this.parent.api.getMember();
            this.isEdit = false;
        }
    };

    @action
    onEdit = () => {
        this.isEdit = true;
    };

    @action
    onCancel = () => {
        this.isEdit = false;
    };
}

export default SchoolInfo;
