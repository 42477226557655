import { action, computed, observable } from 'mobx';
import { CompetitionListItemDto } from 'model/Api/Competitions/Model/CompetitionListItemDto';
import loader from 'react-mvvm/loading/loader';
import { getPublishedCompetitions } from 'model/Api/Submission/GetPublishedCompetitionsRequest';
import { CompetitionStatus } from 'model/Externals';

class CompetitionsList {
    @observable list: CompetitionListItemDto[] = [];

    api = loader({
        getPublishedCompetitions: async () =>
            await getPublishedCompetitions({
                filter: {
                    includeCompetitionStatuses: [
                        CompetitionStatus.published,
                        CompetitionStatus.submissionOpen
                    ]
                }
            })
    });

    onDetails = async (id: number) => {};

    @action
    getList = async () => {
        this.list = await this.api.getPublishedCompetitions();
    };

    @computed get sorted(): {
        future: CompetitionListItemDto[];
        current: CompetitionListItemDto[];
    } {
        const future: CompetitionListItemDto[] = [];
        const current: CompetitionListItemDto[] = [];
        this.list.forEach((item) => {
            if (item.competitionStatus === CompetitionStatus.published) {
                future.push(item);
                return;
            }

            if (item.competitionStatus === CompetitionStatus.submissionOpen) {
                current.push(item);
                return;
            }
        });

        return {
            future,
            current
        };
    }
}

export default CompetitionsList;
