import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {CategoryEvaluationStatus} from "../../Externals"


export interface SetCategoryEvaluationStatusRequest {
    categoryRef: number
    categoryEvaluationStatus: CategoryEvaluationStatus
}
export const setCategoryEvaluationStatus = (setCategoryEvaluationStatusRequest: SetCategoryEvaluationStatusRequest): Promise<any> => {
    return send("SetCategoryEvaluationStatus", setCategoryEvaluationStatusRequest)
}
