import * as React from 'react';
import style from './MembershipApplication.module.scss';
import { Typography } from 'antd';
import { ReactElement } from 'react';

interface FormNameProps {
    title: string;
    helpTxt?: string;
    helpElement?: ReactElement;
}

const FormName: React.FC<FormNameProps> = ({ title, helpTxt, helpElement }) => {
    return (
        <div className={style.formName}>
            <Typography.Title className={style.formTitle}>
                {title}
            </Typography.Title>
            {!!helpTxt && (
                <Typography.Text className={style.formHelp}>
                    {helpTxt}
                </Typography.Text>
            )}
            {!!helpElement && helpElement}
        </div>
    );
};

export default FormName;
