import { IPage, Page } from 'react-mvvm';
import { computed, observable } from 'mobx';
import JudgingCompetitions from 'web/screen/JudgingCompetitions/JudgingCompetitions';
import { EvaluationCompetitionListItemDto } from 'model/Api/Evaluation/Model/EvaluationCompetitionListItemDto';
import JudgingCategoryPage from 'web/screen/JudgingCategoryPage/JudgingCategoryPage';
import hello from 'hellojs';
import { HeaderBreadcrumbsData } from 'web/components/HeaderBreadcrumbs/HeaderBreadcrumbs';

class JudgingIntroPage extends Page {
    pageName: string = 'Start';
    @observable competitionId: number;
    @observable parent: JudgingCompetitions;

    constructor(competitionId: number, parent: JudgingCompetitions) {
        super();
        this.competitionId = competitionId;
        this.parent = parent;
    }

    @computed get breadcrumbs(): HeaderBreadcrumbsData[] {
        const breadcrumbsPages: IPage[] = [this];
        let currentPage: Page = this;
        while (!!currentPage.childPage) {
            breadcrumbsPages.push(currentPage.childPage);
            currentPage = currentPage.childPage as Page;
        }

        return breadcrumbsPages.map((page, index) => {
            if (breadcrumbsPages.length - 1 === index) {
                return { name: page.pageName ?? '' };
            }

            return {
                name: page.pageName ?? '',
                onClick: () => {
                    this.activateChildPage(page as Page);
                }
            };
        });
    }

    @computed get competitionListItem():
        | EvaluationCompetitionListItemDto
        | undefined {
        return this.parent.evaluations.find((e) => e.id === this.competitionId);
    }

    removeAllChildPages = async (page: Page) => {
        const allChild: IPage[] = [page];

        const checkChild = async (p: IPage) => {
            const child = p.childPage;
            if (!child) {
                return;
            }
            allChild.push(child as IPage);
            await checkChild(child as IPage);
        };
        await checkChild(page);
        await allChild
            .reverse()
            .forEach(async (page) => await page.removeChildPage());
    };

    activateChildPage = async (p: Page) => {
        await this.removeAllChildPages(p);
        await p.activate();
    };

    showJudgingCategoryPage = async () => {
        return await this.showChildPage(
            new JudgingCategoryPage(this.competitionId, this)
        );
    };

    signOutClicked = () => {
        hello.logout('customJWT');
        hello.utils.store('customJWT', '');
        window.location.href = `/login?redirect_uri=${encodeURIComponent(
            window.location.href
        )}`;
    };
}

export default JudgingIntroPage;
