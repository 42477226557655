import { asyncCommand } from 'react-mvvm/commands';
import { IActivatable } from '../../../react-mvvm';

class SubmissionApprovedModal implements IActivatable {
    constructor(
        private onConfirm: () => void,
        private close: (r: boolean) => void,
        public subject?: string
    ) {}

    confirm = asyncCommand(async () => {
        await this.onConfirm();
        this.close(true);
    });

    cancel = () => this.close(false);

    activate(): Promise<void> {
        return Promise.resolve();
    }
}

export default SubmissionApprovedModal;
