import * as React from 'react';
import { observer } from 'mobx-react';
import OnlinePaymentStatusPage from './OnlinePaymentStatusPage';
import { Result, Button } from 'antd';
import PageLoader from 'common/components/PageLoader';
import { useTranslation } from 'react-i18next';

interface OnlinePaymentStatusPageViewProps {
    model: OnlinePaymentStatusPage;
}

const OnlinePaymentStatusPageView: React.FC<OnlinePaymentStatusPageViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('payment');
        return (
            <PageLoader loader={{ isLoading: model.isLoading }}>
                {!model.isDeclined && (
                    <Result
                        status='success'
                        title={t('thanksForSubmission')}
                        subTitle={t('thanksForSubmissionDsc', {
                            orderId: model.confirmPaymentResponse.orderId
                        })}
                        extra={[
                            <Button
                                type='primary'
                                key='console'
                                onClick={model.onRemoveChildPage}>
                                {t('goToMyPage')}
                            </Button>
                        ]}
                    />
                )}
                {model.isDeclined && (
                    <Result
                        status='error'
                        title={t('thanksForSubmissionDeclined')}
                        subTitle={t('thanksForSubmissionDscDeclined')}
                        extra={[
                            <Button
                                type='primary'
                                key='console'
                                onClick={model.onRemoveChildPage}>
                                {t('goToMyPage')}
                            </Button>
                        ]}
                    />
                )}
            </PageLoader>
        );
    }
);

export default OnlinePaymentStatusPageView;
