import { Page } from 'react-mvvm';
import { observable } from 'mobx';
import { bindableForm, RequiredFieldValidator } from 'react-mvvm/forms';
import { asyncCommand } from 'react-mvvm/commands';
import { authenticateUser } from 'model/Api/Account/AuthenticateUserRequest';
import hello from 'hellojs';
import { LoginUserDto } from 'model/Api/Account/Model/LoginUserDto';
import { getRedirectUri } from 'auth/Authentication';
import AskForPasswordResetPage from '../AskForPasswordResetPage/AskForPasswordResetPage';
import CreateAccountPage from '../CreateAccountPage/CreateAccountPage';
import loader from 'react-mvvm/loading/loader';

class LoginPage extends Page {
    @observable.ref login: LoginUserDto;
    @observable errorMessage: string = '';
    @observable isLoading: boolean = false;

    constructor() {
        super();

        this.login = {
            userName: '',
            password: ''
        };
    }

    loginDtoMetadata: {
        [P in keyof LoginUserDto]: { required?: boolean };
    } = {
        userName: {
            required: true
        },
        password: {
            required: true
        }
    };

    /*api = loader({
        authenticate: async () =>
            await authenticateUser({
                login: this.login
            })
    });*/

    loginForm = bindableForm<LoginUserDto>(this.loginDtoMetadata)
        .addField('userName', { validator: RequiredFieldValidator })
        .addField('password', { validator: RequiredFieldValidator })
        .bindTo(() => this.login);

    onSubmit = asyncCommand(
        async () => {
            if (!(await this.loginForm.validate())) return;
            this.isLoading = true;

            this.loginForm.commit();
            try {
                const token = await authenticateUser({
                    login: this.login
                });
                if ('accessToken' in token) {
                    hello.utils.store('customJWT', {
                        access_token: token.accessToken.token,
                        refresh_token: token.refreshToken.token
                    });
                    window.location.href = getRedirectUri();
                }
            } catch (e) {
                this.isLoading = false;
                if (
                    e.response.data.errors &&
                    e.response.data.errors.length != 0
                ) {
                    this.errorMessage = e.response.data.errors.join(', ');
                }
            }
        },
        () => this.loginForm.isDirty
    );

    showPasswordResetPage = async () => {
        return this.showChildPage(new AskForPasswordResetPage(this));
    };

    showCreateAccountPage = async () => {
        return this.showChildPage(new CreateAccountPage(this));
    };
}

export default LoginPage;
