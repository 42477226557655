import * as React from 'react';
import style from './JudgingCategoryPageView.module.scss';
import JudgingCategoryPage, {
    JudgingCategorySorting
} from './JudgingCategoryPage';
import { ContentView } from 'react-mvvm';
import EvaluationOptionList from 'web/components/EvaluationOptionList';
import { CompetitionStatus } from 'model/Externals';
import SubmissionCard from './SubmissionCard';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface JudgingCategoryPageViewProps {
    model: JudgingCategoryPage;
}

const JudgingCategoryPageView: React.FC<JudgingCategoryPageViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('judging');
        const [view, setView] = React.useState<'tiles' | 'list'>('list');
        const containerStyle = classNames(
            { [style.submissions]: view === 'list' },
            { [style.submissionsTiles]: view === 'tiles' }
        );

        return (
            <ContentView content={model.childPage}>
                <div className={style.container}>
                    <EvaluationOptionList
                        view={view}
                        setView={setView}
                        onChange={model.onSortChange}
                        value={model.sortingValue}
                        selectOptions={[
                            {
                                id: JudgingCategorySorting.name,
                                value: t('sortingAlphabetically')
                            },
                            {
                                id: JudgingCategorySorting.assessedSubmissions,
                                value: t('sortingProgress')
                            }
                        ]}
                    />
                    <div className={containerStyle}>
                        {model.categories.map((category) => {
                            return (
                                <SubmissionCard
                                    key={category.id}
                                    type={view}
                                    onSelect={() =>
                                        model.showJudgingEntriesPage(
                                            category.id
                                        )
                                    }
                                    evaluatedCategory={category}
                                    isEvaluationButton={
                                        model.competitionStatus ===
                                        CompetitionStatus.phaseOneJudgingInProgress
                                    }
                                />
                            );
                        })}
                    </div>
                </div>
            </ContentView>
        );
    }
);

export default JudgingCategoryPageView;
