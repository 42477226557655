import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface SetPhaseTwoEvaluationRequest {
    competitionEntryRef: number
    evaluationRef: number
}
export const setPhaseTwoEvaluation = (setPhaseTwoEvaluationRequest: SetPhaseTwoEvaluationRequest): Promise<any> => {
    return send("SetPhaseTwoEvaluation", setPhaseTwoEvaluationRequest)
}
