import { Page } from 'react-mvvm';
import queryString from 'query-string';
import { observable } from 'mobx';
import HomePage from 'web/screen/HomePage/HomePage';
import { getPaymentDetails } from "../../../../model/Api/Payments/GetPaymentDetailsRequest";
import { PaymentStatus } from "../../../../model/Externals";
import { PaymentConfirmationResponse } from "../../../../model/Api/Payments/Model/PaymentConfirmationResponse";

async function delay(ms: number) {
    return new Promise(r => setTimeout(r, ms));
}

class OnlinePaymentStatusPage extends Page {
    @observable confirmPaymentResponse: PaymentConfirmationResponse = {
        orderId: '0',
        status: PaymentStatus.paid,
        transactionId: '0',
        isPaymentForCompetitionEntry: true
    };
    @observable isDeclined: boolean = false;
    @observable isLoading: boolean = true;

    constructor(private parent: HomePage, private expectedPaymentStatus: PaymentStatus) {
        super();
        this.isDeclined = expectedPaymentStatus === PaymentStatus.failed;
    }

    protected async onActivated(): Promise<any> {
        if (this.expectedPaymentStatus === PaymentStatus.failed){
            await delay(5000);
            await this.onRemoveChildPage();
            return;
        }
        
        const query = queryString.parse(globalThis.location.search);

        const orderId = !query.orderid
            ? ''
            : Array.isArray(query.orderid)
            ? query.orderid[0]
            : query.orderid;

        if (!orderId) {
            return;
        }

        const outcome = await this.getPaymentOutcomeWithRetries(orderId);
        this.isDeclined = outcome !== "accepted";
        this.isLoading = false;
        await delay(5000);
        await this.onRemoveChildPage();
    }

    private async getPaymentOutcomeWithRetries(orderId: string) {
        for (let attempt = 0; attempt < 3; attempt++) {
            await delay(attempt * 2000);
            try {
                this.confirmPaymentResponse = await getPaymentDetails({orderId});
                if (this.expectedPaymentStatus === PaymentStatus.paid && this.confirmPaymentResponse.status === PaymentStatus.paid) {
                    return 'accepted';
                }
                if (this.expectedPaymentStatus === PaymentStatus.pending && this.confirmPaymentResponse.transactionId) {
                    return 'accepted';
                }
                if (this.confirmPaymentResponse.status === PaymentStatus.failed)
                    return 'declined';
            }
            catch {
            }
        }
        return 'inconclusive';
    }

    onRemoveChildPage = async () => {
        if(this.confirmPaymentResponse.orderId === '0') {
            window.location.href = '/submissions';
        } else {
            if(this.confirmPaymentResponse.isPaymentForCompetitionEntry) {
                window.location.href = '/submissions';
            } else {
                window.location.href = '/membership';
            }
        }
    };
}

export default OnlinePaymentStatusPage;
