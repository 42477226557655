import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {CreateAccountDto} from "./Model/CreateAccountDto"


export interface CreateAccountRequest {
    account: CreateAccountDto
}
export const createAccount = (createAccountRequest: CreateAccountRequest): Promise<any> => {
    return send("CreateAccount", createAccountRequest)
}
