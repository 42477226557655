import * as React from 'react';
import ChangeBillingAddressDialog from './ChangeBillingAddressDialog';
import AntModal from 'common/components/antHelpers/AntModal';
import { useTranslation } from 'react-i18next';
import AllUsersAddressDropdown from 'common/components/UserAddresses/AllUsersAddressDropdown';
import { observer } from 'mobx-react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import PrivatePartyView from '../PrivateParty/PrivatePartyView';
import CompanyPartyView from '../CompanyParty/CompanyPartyView';

const { TabPane } = Tabs;

interface ChangeBillingAddressDialogViewProps {
    dialog: ChangeBillingAddressDialog;
}

const ChangeBillingAddressDialogView: React.FC<ChangeBillingAddressDialogViewProps> = observer(
    ({ dialog }) => {
        const { t } = useTranslation(['membership', 'userAddresses', 'common']);

        return (
            <AntModal
                width={850}
                cancel={dialog.cancel}
                okCommand={dialog.confirm}
                title={t('membership:changeBillingAddress')}>
                <AllUsersAddressDropdown
                    model={dialog.userAddresses}
                    withCreateAddress={false}
                />
                {!dialog.isNewAddressVisible && (
                    <Button
                        type={'text'}
                        size={'small'}
                        onClick={() => dialog.setNewAddressVisible(true)}>
                        <PlusOutlined /> {t('userAddresses:addAddress')}
                    </Button>
                )}

                {dialog.isNewAddressVisible && (
                    <div>
                        <Tabs defaultActiveKey='1'>
                            <TabPane tab={t('privateAddress')} key='1'>
                                <PrivatePartyView model={dialog.privateParty} />
                            </TabPane>
                            <TabPane tab={t('companyAddress')} key='2'>
                                {!!dialog.companyParty && (
                                    <CompanyPartyView
                                        model={dialog.companyParty}
                                    />
                                )}
                            </TabPane>
                        </Tabs>
                    </div>
                )}
            </AntModal>
        );
    }
);

export default ChangeBillingAddressDialogView;
