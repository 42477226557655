import { IPage, Page } from 'react-mvvm';
import { computed, observable } from 'mobx';
import { CompetitionListItemDto } from 'model/Api/Competitions/Model/CompetitionListItemDto';
import Competition from 'web/screen/Competition/Competition';
import { HeaderBreadcrumbsData } from 'web/components/HeaderBreadcrumbs/HeaderBreadcrumbs';
import CompetitionsList from 'web/components/CompetitionsList/CompetitionsList';

class CompetitionsListPage extends Page {
    @observable competitions: CompetitionsList = new CompetitionsList();
    @observable selectedCompetitionId: number | undefined;
    @observable competitionsList: CompetitionListItemDto[] = [];
    @observable competitionsPage: Competition | undefined;

    constructor() {
        super();
        this.competitions.onDetails = async (id: number) => {
            await this.showCompetitionPage(id);
        };
    }

    protected async onActivated() {
        await this.competitions.getList();
        this.competitionsList = this.competitions.list;
    }

    @computed get selectedCompetition(): CompetitionListItemDto | undefined {
        if (!this.selectedCompetitionId) {
            return undefined;
        }

        return this.competitionsList.find(
            (competition) => competition.id === this.selectedCompetitionId
        );
    }

    @computed get breadcrumbs(): HeaderBreadcrumbsData[] {
        if (!!this.competitionsPage) {
            return this.competitionsPage.breadcrumbs;
        }
        return [];
    }

    showCompetitionPage = async (competitionId: number) => {
        this.selectedCompetitionId = competitionId;
        this.competitionsPage = new Competition(competitionId, this);
        return this.showChildPage(this.competitionsPage);
    };

    onRemoveChildPage = async () => {
        await this.removeChildPage();
        await this.onActivated();
    };
}

export default CompetitionsListPage;
