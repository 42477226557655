import Link from 'web/components/Link';

import * as React from 'react';
import style from './ArtWork.module.scss';
import classNames from 'classnames';
import ActionsPhaseFirst from 'web/components/ActionsPhaseFirst';
import ActionsPhaseSecond from 'web/components/ActionsPhaseSecond';
import { CompetitionEntryListItemResponse } from 'model/Api/Evaluation/Model/CompetitionEntryListItemDto';
import { observer } from 'mobx-react';
import { CompetitionStatus, PhaseOneEvaluation } from 'model/Externals';
import { EvaluationResultDto } from 'model/Api/Competitions/Model/EvaluationResultDto';
import RenderIcon from 'common/components/Preview/RenderIcon';

interface ArtWorkProps {
    type: 'tiles' | 'list';
    onEvaluationPhaseOne?: (value: PhaseOneEvaluation) => void;
    onEvaluationPhaseTwo?: (
        entry: CompetitionEntryListItemResponse,
        evaluationRef: number
    ) => void;
    competitionStatus: CompetitionStatus;
    competitionEntry: CompetitionEntryListItemResponse;
    seeDetails: () => void;
    evaluationResults: EvaluationResultDto[];
    isEditable: boolean;
}

const ArtWork: React.FC<ArtWorkProps> = observer(
    ({
        type,
        onEvaluationPhaseOne,
        onEvaluationPhaseTwo,
        competitionEntry,
        competitionStatus,
        seeDetails,
        evaluationResults,
        isEditable
    }) => {
        const tile = classNames(style.box, { [style.tiles]: type === 'tiles' });
        const list = classNames(style.box, { [style.list]: type === 'list' });

        const [privateNoteVisible, setPrivateNoteVisible] = React.useState<
            boolean
        >(false);
        const [publicNoteVisible, setPublicNoteVisible] = React.useState<
            boolean
        >(false);

        const onPublicNotesVisible = () => {
            setPrivateNoteVisible(false);
            setPublicNoteVisible(!publicNoteVisible);
        };

        const onPrivateNotesVisible = () => {
            setPublicNoteVisible(false);
            setPrivateNoteVisible(!privateNoteVisible);
        };

        return (
            <div className={type === 'tiles' ? tile : list}>
                <div className={style.crop} onClick={seeDetails}>
                    <RenderIcon
                        type={competitionEntry.titleThumbnailType}
                        url={competitionEntry.titleThumbnailUrl}
                    />
                </div>
                <div className={style.title}>
                    {publicNoteVisible && (
                        <div className={style.notes}>
                            {competitionEntry.publicNote}
                        </div>
                    )}
                    {privateNoteVisible && (
                        <div className={style.notes}>
                            {competitionEntry.privateNote}
                        </div>
                    )}
                    <div onClick={seeDetails} className={style.titleBox}>
                        <span className={style.titleLink}>
                            {competitionEntry.title}
                        </span>
                        
                    </div>
                    <div className={style.tag}>{`id: ${competitionEntry.id}`}</div>
                </div>
                {(competitionStatus ===
                    CompetitionStatus.phaseOneJudgingInProgress ||
                    competitionStatus ===
                        CompetitionStatus.phaseOneJudgingClosed) && (
                    <ActionsPhaseFirst
                        evaluationValue={competitionEntry.phaseOneEvaluation}
                        onEvaluationValueChange={onEvaluationPhaseOne}
                        isEditable={isEditable}
                        isPrivateNote={!!competitionEntry.privateNote}
                        privateNoteActive={privateNoteVisible}
                        onSetPrivateNoteState={() =>
                            setPrivateNoteVisible(!privateNoteVisible)
                        }
                        isList={type === 'list'}
                    />
                )}
                {(competitionStatus ===
                    CompetitionStatus.phaseTwoJudgingInProgress ||
                    competitionStatus ===
                        CompetitionStatus.phaseTwoJudgingClosed) && (
                    <ActionsPhaseSecond
                        evaluationValue={competitionEntry.phaseTwoEvaluation}
                        evaluationResults={evaluationResults}
                        onEvaluationPhaseTwo={(evaluationRef: number) => {
                            !!onEvaluationPhaseTwo &&
                                onEvaluationPhaseTwo(
                                    competitionEntry,
                                    evaluationRef
                                );
                        }}
                        isList={type === 'list'}
                        isEditable={isEditable}
                        isPrivateNote={!!competitionEntry.privateNote}
                        privateNoteActive={privateNoteVisible}
                        onSetPrivateNoteState={onPrivateNotesVisible}
                        isPublicNote={!!competitionEntry.publicNote}
                        publicNoteActive={publicNoteVisible}
                        onSetPublicNoteState={onPublicNotesVisible}
                    />
                )}
            </div>
        );
    }
);

export default ArtWork;
