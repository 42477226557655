import * as React from 'react';
import UserAddresses from 'common/components/UserAddresses/UserAddresses';
import style from './SingleUsersAddressDropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'antd';
import {
    PlusOutlined,
    DownOutlined,
    CloseCircleFilled
} from '@ant-design/icons';
import MenuDropdown from 'common/components/UserAddresses/MenuDropdown';
import { PartyDto } from 'model/Api/Parties/Model/PartyDto';
import Flex from 'common/components/Flex';

interface SingleUsersAddressDropdownProps {
    model: UserAddresses | undefined;
    type: 'private' | 'company';
    disabled?: boolean;
    witheClear?: boolean;
    userId?: number;
}

const SingleUsersAddressDropdown: React.FC<SingleUsersAddressDropdownProps> = ({
    model,
    type,
    disabled,
    witheClear = false,
    userId
}) => {
    const { t } = useTranslation(['userAddresses', 'common']);
    const [visible, setVisible] = React.useState<boolean>(false);
    if (!model || (!!model && !model.isSelectable)) {
        return null;
    }
    const partyType: PartyDto | undefined = model.doubleSelectedPartyData[type];

    return (
        <div>
            <Dropdown
                disabled={disabled}
                visible={visible}
                trigger={['click']}
                onVisibleChange={(e) => setVisible(e)}
                overlay={
                    <MenuDropdown
                        type={type}
                        data={model.sortedParties[type]}
                        model={model}
                        setVisible={setVisible}
                        userId={userId}
                    />
                }
                className={style.dropdown}>
                <Button type={'text'}>
                    {!partyType ? (
                        t('userAddresses:select')
                    ) : (
                        <Flex
                            justifyContent={'space-between'}
                            customClass={style.selected}>
                            <span>
                                {!!partyType?.name && `${partyType?.name}, `}
                                {!!partyType.careOf && `${!!partyType.orgNo ? 'att. ' : 'c/o '} ${partyType.careOf}, `}
                                {!!partyType?.address.line &&
                                    `${partyType?.address.line}, `}
                                {`${partyType?.address.postalCode} ${partyType?.address.city}`}
                            </span>
                            {witheClear && (
                                <span
                                    onClick={model.onClear}
                                    className={style.clear}>
                                    <CloseCircleFilled />
                                </span>
                            )}
                        </Flex>
                    )}
                    <DownOutlined />
                </Button>
            </Dropdown>
            <Button
                disabled={disabled}
                type={'text'}
                size={'small'}
                onClick={() => model.onCreateAddress(type, userId)}>
                <PlusOutlined /> {t('userAddresses:addAddress')}
            </Button>
        </div>
    );
};

export default SingleUsersAddressDropdown;
