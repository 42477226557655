import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {ResetPasswordResult} from "./Model/ResetPasswordResult"

export interface PasswordResetByPhoneRequest {
    phoneNumber: string
}
export const passwordResetByPhone = (passwordResetByPhoneRequest: PasswordResetByPhoneRequest): Promise<ResetPasswordResult> => {
    return send("PasswordResetByPhone", passwordResetByPhoneRequest)
}
