import * as React from 'react';
import style from './EvaluationOptionList.module.scss';
import classNames from 'classnames';
import Flex from 'common/components/Flex';
import { ReactComponent as Tiles } from 'common/icons/tile_menu.svg';
import { ReactComponent as List } from 'common/icons/list_menu.svg';
import { Select, Button } from 'antd';
import { JudgeEntriesSorting } from 'web/screen/JudgingEntriesPage/JudgingEntriesPage';
import { JudgingCategorySorting } from 'web/screen/JudgingCategoryPage/JudgingCategoryPage';
import { observer } from 'mobx-react';
import ListOptionsLinks from 'web/components/ListOptionsLinks';

interface EvaluationOptionListProps {
    view: 'tiles' | 'list';
    setView: (v: 'tiles' | 'list') => void;
    onChange: (prop: any) => Promise<void>;
    value: JudgeEntriesSorting | JudgingCategorySorting;
    selectOptions: { id: string; value: string }[];
}

const EvaluationOptionList: React.FC<EvaluationOptionListProps> = observer(
    ({ view, setView, onChange, value, selectOptions }) => {
        const iconStyle = classNames(style.iconBtn);
        const iconSelected = classNames(style.iconBtn, style.iconBtnSelected);

        return (
            <div className={style.container}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Button
                        type={'link'}
                        className={view === 'list' ? iconSelected : iconStyle}
                        onClick={() => setView('list')}>
                        <List className={style.icon} />
                    </Button>
                    <Button
                        type={'link'}
                        className={view === 'tiles' ? iconSelected : iconStyle}
                        onClick={() => setView('tiles')}>
                        <Tiles className={style.icon} />
                    </Button>
                    <ListOptionsLinks
                        value={value}
                        onChange={onChange}
                        options={selectOptions}
                    />
                </Flex>
            </div>
        );
    }
);

export default EvaluationOptionList;
