import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface DeleteDocumentAttachmentRequest {
    documentAttachmentRef: number
}
export const deleteDocumentAttachment = (deleteDocumentAttachmentRequest: DeleteDocumentAttachmentRequest): Promise<any> => {
    return send("DeleteDocumentAttachment", deleteDocumentAttachmentRequest)
}
