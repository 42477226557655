import { Page } from 'react-mvvm';
import { computed, observable, runInAction } from 'mobx';
import JudgingCategoryPage from 'web/screen/JudgingCategoryPage/JudgingCategoryPage';
import loader from 'react-mvvm/loading/loader';
import { ListOptions } from 'model/Api/GenericList/ListOptions';
import { getCompetitionEntries } from 'model/Api/Evaluation/GetCompetitionEntriesRequest';
import { CompetitionEntryListItemResponse } from 'model/Api/Evaluation/Model/CompetitionEntryListItemDto';
import {
  CategoryEvaluationStatus,
  CompetitionStatus,
  JudgingSchema,
  PhaseOneEvaluation
} from 'model/Externals';
import { setPhaseOneEvaluation } from 'model/Api/Evaluation/SetPhaseOneEvaluationRequest';
import { EvaluationResultDto } from 'model/Api/Competitions/Model/EvaluationResultDto';
import { setPhaseTwoEvaluation } from 'model/Api/Evaluation/SetPhaseTwoEvaluationRequest';
import { SortDirection } from 'model/Api/GenericList/SortDirection';
import JudgingEntryDetails from 'web/screen/JudgingEntryDetails/JudgingEntryDetails';
import { nominateCompetitionEntry } from 'model/Api/Evaluation/NominateCompetitionEntryRequest';
import { EvaluationCategoryListItemDto } from 'model/Api/Evaluation/Model/EvaluationCategoryListItemDto';
import { setCategoryEvaluationStatus } from "model/Api/Evaluation/SetCategoryEvaluationStatusRequest";

type GroupPhaseEntries = {
  promoted: CompetitionEntryListItemResponse[];
  notPromoted?: CompetitionEntryListItemResponse[];
};

export enum JudgeEntriesSorting {
  title = 'title',
  phaseOneEvaluation = 'phaseOneEvaluation',
  evaluationResult = 'evaluationResult'
}

class JudgingEntriesPage extends Page {
  @observable categoryId: number;
  @observable parent: JudgingCategoryPage;

  @observable entryList: CompetitionEntryListItemResponse[] = [];
  @observable categoryEvaluationStatus: CategoryEvaluationStatus =
    CategoryEvaluationStatus.pending;
  @observable competitionStatus: CompetitionStatus = CompetitionStatus.draft;
  @observable evaluationResults: EvaluationResultDto[] = [];
  @observable sortableValue: JudgeEntriesSorting = this.competitionStatus ===
  CompetitionStatus.phaseOneJudgingInProgress
    ? JudgeEntriesSorting.phaseOneEvaluation
    : JudgeEntriesSorting.evaluationResult;

  constructor(categoryId: number, parent: JudgingCategoryPage) {
    super();

    this.categoryId = categoryId;
    this.parent = parent;
  }

  @computed get isEditable(): boolean {
    return (
      this.competitionStatus ===
      CompetitionStatus.phaseOneJudgingInProgress ||
      this.competitionStatus ===
      CompetitionStatus.phaseTwoJudgingInProgress
    );
  }

  @computed get judgingSchema(): JudgingSchema | undefined {
    return !this.parent.competitionListItem
      ? undefined
      : this.parent.competitionListItem.judgingSchema;
  }

  api = loader({
    getCompetitionEntries: async (
      categoryId: number,
      options?: ListOptions
    ) =>
      await getCompetitionEntries({
        categoryRef: categoryId,
        options
      })
  });

  apiEvaluation = loader(
    {
      setEvaluationStatus: async () => {
        const categoryEvaluationStatus =
          this.categoryEvaluationStatus ===
          CategoryEvaluationStatus.pending
            ? CategoryEvaluationStatus.fulfilled
            : CategoryEvaluationStatus.pending;
        await setCategoryEvaluationStatus({
          categoryRef: this.categoryId,
          categoryEvaluationStatus
        });
        runInAction(() => (this.categoryEvaluationStatus = categoryEvaluationStatus))
      },
      setEvaluationPhaseOne: async (
        entry: CompetitionEntryListItemResponse,
        evaluation: PhaseOneEvaluation
      ) => {
        await setPhaseOneEvaluation({
          competitionEntryRef: entry.id,
          evaluation
        });

        runInAction(() => (entry.phaseOneEvaluation = evaluation));
      },
      setEvaluationPhaseTwo: async (
        entry: CompetitionEntryListItemResponse,
        evaluationRef: number
      ) => {
        const result: EvaluationResultDto = await setPhaseTwoEvaluation(
          {
            competitionEntryRef: entry.id,
            evaluationRef: evaluationRef ?? null
          }
        );

        runInAction(() => (entry.phaseTwoEvaluation = result));
      },
      nominateEntry: async (competitionEntryRef: number) =>
        await nominateCompetitionEntry({competitionEntryRef})
    },
    {
      setEvaluationStatus:
        'Something went wrong, we can not set your evaluation status',
      setEvaluationPhaseOne:
        'Something went wrong, we can not set your evaluation',
      setEvaluationPhaseTwo:
        'Something went wrong, we can not set your evaluation',
      nominateEntry:
        'Something went wrong, we can not nominate this entry'
    }
  );

  setCategoryEntriesData = async (
    field: JudgeEntriesSorting = this.sortableValue,
    sortDirection: SortDirection = SortDirection.ascending
  ) => {
    const response = await this.api.getCompetitionEntries(this.categoryId, {
      page: 1,
      pageSize: 1000,
      sorting: {
        field,
        sortDirection
      }
    });
    runInAction(() => {
      this.entryList = response.competitionEntries;
      this.competitionStatus = response.competitionStatus;
      this.categoryEvaluationStatus = response.categoryEvaluationStatus;
      this.evaluationResults = response.evaluationResults;
    });
  };

  protected async onActivated(): Promise<any> {
    await this.setCategoryEntriesData();
    const selectedCategory = await this.parent.categories.find(
      (c) => c.id === this.categoryId
    );
  }

  @computed get selectedCategory():
    | EvaluationCategoryListItemDto
    | undefined {
    return this.parent.categories.find((c) => c.id === this.categoryId);
  }

  @computed get pageName() {
    return !!this.selectedCategory ? this.selectedCategory.name : '';
  }

  @computed get isAllEvaluated() {
    if (
      this.competitionStatus !==
      CompetitionStatus.phaseOneJudgingInProgress
    ) {
      return false;
    }
    let evaluation = true;
    this.entryList.map((entry) => {
      if (!entry.phaseOneEvaluation) {
        evaluation = false;
      }
    });
    return evaluation;
  }

  onSortChange = async (prop: JudgeEntriesSorting): Promise<void> => {
    this.sortableValue = prop;
    await this.setCategoryEntriesData(
      prop,
      prop === JudgeEntriesSorting.title
        ? SortDirection.ascending
        : SortDirection.descending
    );
  };

  showJudgingEntryDetails = async (entryId: number) => {
    return this.showChildPage(new JudgingEntryDetails(entryId, this));
  };

  onPromoteEntry = async (entryId: number) => {
    await this.apiEvaluation.nominateEntry(entryId);
    await this.setCategoryEntriesData(
      this.sortableValue,
      this.sortableValue === JudgeEntriesSorting.title
        ? SortDirection.ascending
        : SortDirection.descending
    );
  };

  @computed get getGroupPhaseEntryList(): GroupPhaseEntries {
    if (this.judgingSchema === JudgingSchema.group) {
      return {
        promoted: this.entryList
      };
    }
    const result: GroupPhaseEntries = {
      promoted: [],
      notPromoted: []
    };
    this.entryList.forEach((entry) =>
      entry.hasMovedOn
        ? result.promoted.push(entry)
        : result.notPromoted?.push(entry)
    );

    return result;
  }


}

export default JudgingEntriesPage;
