declare global {
    interface Array<T> {
        _any(predicate?: (x: T) => boolean): T;
        _first(predicate?: (x: T) => boolean): T;
        _last(predicate?: (x: T) => boolean): T;
        _remove(x: T): void;
        _replace(x: T, y: T): void;
    }
}

if (!Array.prototype._any) {
    Array.prototype._any = function <T>(predicate: (x: T) => boolean = x => true): boolean {
        const item = this.find(predicate);
        return !!item
    }
}

if (!Array.prototype._first) {
    Array.prototype._first = function<T>(predicate: (x: T) => boolean = x => true): boolean {
        const items = this.filter(predicate);
        if (items.length > 0) {
            return items[0];
        }
        throw new Error("No item found")
    };
}

if (!Array.prototype._last) {
    Array.prototype._last = function<T>(predicate: (x: T) => boolean = x => true): boolean {
        const items = this.filter(predicate);
        if (items.length > 0) {
            return items[items.length - 1];
        }
        throw new Error("No item found")
    };
}

if (!Array.prototype._remove) {
    Array.prototype._remove = function<T>(item: T): void {
        const index = this.indexOf(item);
        if (index >= 0) {
            this.splice(index, 1);
        }
    };
}

if (!Array.prototype._replace) {
    Array.prototype._replace = function<T>(oldItem: T, newItem: T): void {
        const index = this.indexOf(oldItem);
        if (index >= 0) {
            this.splice(index, 1, newItem);
            return;
        }
        throw new Error("No item found")
    };
}

export default {};