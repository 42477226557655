import * as React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SchoolInfo from 'web/screen/Membership/SchoolInfo/SchoolInfo';
import InfoRow from 'common/components/InfoRow/InfoRow';

interface InfoProps {
    model: SchoolInfo;
}

const Info: React.FC<InfoProps> = observer(({ model }) => {
    const { t } = useTranslation(['membership', 'common']);
    if (!model.member) {
        return null;
    }
    return (
        <>
            <InfoRow title={t('membership:schoolName')}>
                {model.member.schoolName}
            </InfoRow>
            <InfoRow title={t('membership:graduatedDate')}>
                {!!model.member.expectedGraduationDate
                    ? moment(model.member.expectedGraduationDate).format(
                          'DD MMMM YYYY'
                      )
                    : '-'}
            </InfoRow>
        </>
    );
});

export default Info;
