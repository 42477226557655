import * as React from 'react';
import AntModal from 'common/components/antHelpers/AntModal';
import { useTranslation } from 'react-i18next';
import AddCartInformationDialog from './AddCartInformationDialog';

interface AddCartInformationDialogViewProps {
    dialog: AddCartInformationDialog;
}

const AddCartInformationDialogView: React.FC<AddCartInformationDialogViewProps> = ({
    dialog
}) => {
    const { t } = useTranslation(['membership']);
    return (
        <AntModal
            cancel={dialog.cancel}
            okCommand={dialog.confirm}
            title={t('addCartTitle')}>
            <span>{t('addCart')}</span>
        </AntModal>
    );
};

export default AddCartInformationDialogView;
