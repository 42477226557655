import { observable } from 'mobx';
import {notification} from "antd";

export interface ILoader {
    isLoading: boolean;
    error?: string;
}

export default function loader<
    T extends { [key: string]: (...args: any[]) => Promise<any> },
    K extends keyof T
>(api: T, errorMessages?: { [P in keyof T]: string }): T & ILoader {
    let result: any = observable({ isLoading: false, error: null });
    for (let method in api) {
        if (!api.hasOwnProperty(method)) continue;
        result[method] = async function(...args: any[]) {
            result.isLoading = true;
            result.error = null;
            
            try {
                return await api[method].apply(api, args);
            } catch (e) {
               
                if (e.errors && e.errors.length != 0) {
                    result.error = e.errors.join(', ');
                } else if ('response' in e && e.response.status === 400) {
                    result.error = (e.response.data.errors as string[]).map(
                        (value, index) => `${index + 1}. ${value}`
                    );
                } else if (errorMessages) {
                    result.error = errorMessages[method];
                } else {
                    result.error = e;
                    throw e;
                }
                if(!!result.error) {
                    notification.error({
                        message: 'Validation Error',
                        description: result.error,
                        duration: 0
                    })
                }
                
            } finally {
                result.isLoading = false;
            }
        };
    }
    return result;
}
