import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {InvoicePaymentDto} from "./Model/InvoicePaymentDto"

export interface PayForCompetitionEntriesWithInvoiceRequest {
    competitionEntriesRef: number[]
    payingPartyRef: number
    isPaymentTermsAccepted: boolean
}
export const payForCompetitionEntriesWithInvoice = (payForCompetitionEntriesWithInvoiceRequest: PayForCompetitionEntriesWithInvoiceRequest): Promise<InvoicePaymentDto> => {
    return send("PayForCompetitionEntriesWithInvoice", payForCompetitionEntriesWithInvoiceRequest)
}
