import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {OnlinePaymentTokenDto} from "./Model/OnlinePaymentTokenDto"

export interface PayForCompetitionEntriesOnlineRequest {
    competitionEntriesRef: number[]
    payingPartyRef: number
    isPaymentTermsAccepted: boolean
}
export const payForCompetitionEntriesOnline = (payForCompetitionEntriesOnlineRequest: PayForCompetitionEntriesOnlineRequest): Promise<OnlinePaymentTokenDto> => {
    return send("PayForCompetitionEntriesOnline", payForCompetitionEntriesOnlineRequest)
}
