import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {SubscriptionType} from "../../Externals"
import {PaymentMethod} from "../../Externals"

import {InvoicePaymentDto} from "../Payments/Model/InvoicePaymentDto"

export interface SubmitApplicationAndPayWithInvoiceRequest {
    membershipApplicationId: number
    membershipTypeId: number
    subscriptionType: SubscriptionType
    paymentMethod: PaymentMethod
    partyId: number
    paymentTermsAccepted: boolean
}
export const submitApplicationAndPayWithInvoice = (submitApplicationAndPayWithInvoiceRequest: SubmitApplicationAndPayWithInvoiceRequest): Promise<InvoicePaymentDto> => {
    return send("SubmitApplicationAndPayWithInvoice", submitApplicationAndPayWithInvoiceRequest)
}
