import * as React from 'react';
import style from './Membership.module.scss';
import { useTranslation } from 'react-i18next';
import { Space, Button, Alert } from 'antd';
import { MemberDto } from 'model/Api/Members/Model/MemberDto';
import {MembershipKind, MemberStatus} from 'model/Externals';
import moment from 'moment';
import InfoRow from 'common/components/InfoRow/InfoRow';
import Flex from 'common/components/Flex';

interface SubscriptionProps {
    isActive: boolean;
    canUpgrade: boolean;
    member: MemberDto;
    onStopRenewalSubscription: () => void;
    onReapplyForMembership: () => void;
    onUpgradeMembership: () => void;
}

const Subscription: React.FC<SubscriptionProps> = ({
    isActive,
    member,
    canUpgrade,
    onStopRenewalSubscription,
    onReapplyForMembership,
    onUpgradeMembership
}) => {
    const { t } = useTranslation(['membership', 'common']);
    return (
        <div className={style.dataContent}>
            <div className={style.title}>
                <Space>
                    <span className={style.titleTxt}>
                        {t('membership:subscription')}
                    </span>
                </Space>
                <Space>
                    {!!member?.currentMembership?.currentSubscription &&
                        member.currentMembership.currentSubscription
                            .autoRenew && member.isActive && (
                        <Button
                            type={'link'}
                            className={style.subscriptionTileLink}
                            onClick={onStopRenewalSubscription}>
                                {t('membership:stopRenewalSubscription')}
                        </Button>
                        )}
                    {!!member?.currentMembership?.currentSubscription &&
                    !member.isActive && 
                    member.currentMembership.type.membershipKind !== MembershipKind.graduate 
                    && !(member.currentMembership.type.membershipKind === MembershipKind.student && 
                        member.expectedGraduationDate &&
                        moment(member.expectedGraduationDate) < moment())
                    && (
                        <Button
                            type={'link'}
                            className={style.titleLink}
                            onClick={onReapplyForMembership}>
                            {t('membership:reapplyForMembership')}
                        </Button>
                    )}
                </Space>
            </div>
            <InfoRow>
                <Flex justifyContent='space-between' 
                      alignContent='space-between'>
                    <div>
                    {`${t('membership:membershipType')} ${member
                        .currentMembership.type.name ?? '-'}`}
                    </div>
                    {canUpgrade && !!member?.currentMembership?.currentSubscription && (<Button
                        type={'link'}
                        className={style.subscriptionTileLink}
                        onClick={onUpgradeMembership}>
                        {t('membership:upgradeSubscription')}
                    </Button>)}
                </Flex>
                <div>
                    {`${t('membership:membershipStatus')} `}
                    {isActive
                        ? t('membership:activeMembership')
                        : t('membership:inActiveMembership')}
                </div>
                <div>
                    {`${t('membership:memberTil')} `}
                    {!!member.currentMembership && moment(member.currentMembership.periodTo).year() !== 9999 ? (
                        moment(member.currentMembership.periodTo).format(
                            'DD.MM.YYYY'
                        )
                    ) : (
                        <span>{'-'}</span>
                    )}
                </div>
                <div>
                    {`${t('membership:BillingTil')} `}
                    {!!member.currentMembership && member.currentMembership.paidTo ? (
                        moment(member.currentMembership.paidTo).format(
                            'DD.MM.YYYY'
                        )
                    ) : (
                        <span>{'-'}</span>
                    )}
                </div>
                {!!member?.futureMembership && 
                <div className={style.futureMembership}>
                    <div>
                        {`${t('membership:membershipType')} ${member
                        .futureMembership.type.name}`}
                    </div>
                    <div>
                        {`${t('membership:memberFrom')} ${moment(member.futureMembership.periodFrom).format(
                            'DD.MM.YYYY'
                        )}`}
                    </div>
                </div>}
                {/*{!!member?.currentMembership?.currentSubscription &&*/}
                {/*    !member.currentMembership.currentSubscription.autoRenew &&*/}
                {/*    member.currentMembership.memberStatus ===*/}
                {/*        MemberStatus.active && (*/}
                {/*        <Alert*/}
                {/*            className={style.renewalAlert}*/}
                {/*            type={'warning'}*/}
                {/*            message={t('membership:stillWantToBeMember')}*/}
                {/*        />*/}
                {/*    )}*/}
            </InfoRow>
        </div>
    );
};

export default Subscription;
