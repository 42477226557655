import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {ListOptions} from "../GenericList/ListOptions"

import {UserMembershipTypeListItemDto} from "./Model/UserMembershipTypeListItemDto"

export interface GetUserMembershipOptionsRequest {
    itemId?: number
    options?: ListOptions
    filter?: any
}
export const getUserMembershipOptions = (getUserMembershipOptionsRequest: GetUserMembershipOptionsRequest): Promise<UserMembershipTypeListItemDto[]> => {
    return send("GetUserMembershipOptions", getUserMembershipOptionsRequest)
}
