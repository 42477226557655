import * as React from 'react';
import { ContributorsForm } from 'web/screen/SubmissionFormPage/ContributorsField/ContributorsField';
import {Table} from 'antd';
import CustomEmpty from "web/components/CustomEmpty";

interface ContributorsFieldsProps {
    data: string;
}

const ContributorsFields: React.FC<ContributorsFieldsProps> = ({ data }) => {
    const contributions: ContributorsForm[] = JSON.parse(data);
    
    if (!contributions.length) {
        return <CustomEmpty isBottomMargin={true}/>;
    }

    const dataSource = contributions.map((contributor, index) => ({
        key: `Contributor_${contributor.name}_${index}`,
        role: contributor.role,
        name: contributor.name,
        company: contributor.company
    }));
    const columns = [
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company'
        }
    ];
    return <Table dataSource={dataSource} columns={columns} size={'small'} pagination={false} />;
};

export default ContributorsFields;
