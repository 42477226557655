import * as React from 'react';
import {observer} from 'mobx-react';
import SubmissionFormPage from './SubmissionFormPage';
import {ContentView} from 'react-mvvm';
import style from './SubmissionForm.module.scss';
import PageWrapper from 'web/components/PageWrapper';
import {useTranslation} from 'react-i18next';
import PageTitle from 'web/components/PageTitle';
import SubmissionFormView from 'common/components/SubmissionForm/SubmissionFormView';
import SaveDraftButton from 'web/components/SaveDraftButton';
import Flex from 'common/components/Flex';
import CompetitionIntro from "web/components/CompetitionIntro/CompetitionIntro";
import {Button, Spin} from "antd";
import OnBackToSubmissionModal from "web/screen/SubmissionFormPage/OnBackToSubmissionModal/OnBackToSubmissionModal";
import OnBackToSubmissionModalView
    from "web/screen/SubmissionFormPage/OnBackToSubmissionModal/OnBackToSubmissionModalView";
import {CompetitionEntryStatus} from "model/Externals";

interface SubmissionFormProps {
    model: SubmissionFormPage;
}

const SubmissionFormPageView: React.FC<SubmissionFormProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('competitions');
        const errorReference = React.useRef(null);

        return (
            <ContentView content={model.childPage}>
                <div className={style.container}>
                    {model.isLoading && <div className={style.loader}>
                        <Spin size={'large'} className={style.spin}/>
                    </div>}
                    <CompetitionIntro backBtnText={t('backToAllSubmissions')}
                                      onBackBtn={model.onBackToSubmission}/>
                    <PageWrapper>
                        <PageTitle
                            size={'xsmall'}
                            title={t('submissionFormTitle')}
                            headerNumber={2}
                        />
                        <SubmissionFormView
                            model={model.submissionForm}
                            errorReference={errorReference}
                        />
                    </PageWrapper>
                    <div className={style.footer}>
                        <Flex justifyContent={"space-between"} alignItems={"center"} customClass={style.footerContent}>
                            <Button size={"small"} type={"link"}
                                    onClick={model.onBackToSubmission}>{t('backToAllSubmissions')}</Button>
                            {model.submissionForm.competitionEntryDto.competitionEntryStatus !== CompetitionEntryStatus.submitted &&
                                <Flex justifyContent={"flex-end"} alignItems={"center"}>
                                    <SaveDraftButton type={model.submissionForm.isFormDisabled ? "primary" : "default"}
                                                     onClick={model.onSaveAsDraftClicked}
                                    />
                                    {!model.submissionForm.isFormDisabled &&
                                        <Button type={"primary"} className={style.continueBtn}
                                                onClick={() => model.onContinueClick(errorReference)}>{t('approveBtn')}</Button>}
                                </Flex>}
                        </Flex>
                    </div>
                </div>
                {model.dialog instanceof OnBackToSubmissionModal &&
                    <OnBackToSubmissionModalView dialog={model.dialog}/>}
            </ContentView>
        );
    }
);

export default SubmissionFormPageView;
