import {Page} from "react-mvvm";
import loader from 'react-mvvm/loading/loader';
import {getMembershipApplicationPayment} from "../../../../model/Api/Members/GetMembershipApplicationPaymentRequest";
import {retryOnlinePayment} from "../../../../model/Api/Members/RetryOnlinePaymentRequest";
import {observable} from "mobx";
import {PaymentMethod, PaymentStatus} from "../../../../model/Externals";
import {cancelMembershipApplication} from "../../../../model/Api/Members/CancelMembershipApplicationRequest";
import Membership from "../Membership";
import {PaymentDto} from "../../../../model/Api/Payments/Model/PaymentDto";

class AlmostMember extends Page {
    
    constructor(public name: string, public parent: Membership) {
        super();
    }
    
    @observable hasBamboraPaymentFailed : boolean = false;
    applicationPayment?: PaymentDto;
    
    api = loader({
        getMembershipApplicationPayment: async () => getMembershipApplicationPayment({}),
        retryOnlinePayment: async () => retryOnlinePayment({}),
        cancelMembershipApplication: async (membershipApplicationPaymentId: number) => cancelMembershipApplication({
            membershipApplicationPaymentId
        })
    })

    protected async onActivated(): Promise<void> {
        this.applicationPayment = await this.api.getMembershipApplicationPayment();
        
        this.hasBamboraPaymentFailed = this.applicationPayment.paymentMethod === PaymentMethod.bambora && 
            (this.applicationPayment.paymentStatus === PaymentStatus.failed ||
            this.applicationPayment.paymentStatus === PaymentStatus.pending);
    }
    
    public onRetryPaymentClicked = async () => {
        const onlinePaymentToken = await this.api.retryOnlinePayment();
        if (onlinePaymentToken) {
            window.location.href = onlinePaymentToken.url;
        }
    }
    
    public onCancelApplicationClicked = async () => {
        if(this.applicationPayment) {
            await this.api.cancelMembershipApplication(this.applicationPayment.id);
            this.parent.user = await this.parent.api.getUserInfo();
        }
    }
}

export default AlmostMember