import * as React from 'react';
import UserInfo from './UserInfo';
import style from 'web/screen/HomePage/UserInfo/UserInfo.module.scss';
import { Button, Typography } from 'antd';
import UserInfoEditDialog from 'web/screen/HomePage/UserInfoEditDialog/UserInfoEditDialog';
import UserInfoEditDialogView from 'web/screen/HomePage/UserInfoEditDialog/UserInfoEditDialogView';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

interface UserDataProps {
    model: UserInfo;
}

const UserData: React.FC<UserDataProps> = observer(({ model }) => {
    const { t } = useTranslation(['homePage', 'common']);
    return (
        <>
            <div className={style.titleBox}>
                <Typography.Title level={3}>{model.fullName}</Typography.Title>
                <Button
                    type={'text'}
                    size={'small'}
                    className={style.edit}
                    onClick={model.showEditUser}>
                    {t('common:edit')}
                </Button>
            </div>
            <div className={style.list}>
                <span className={style.listItem}>{t('homePage:mobile')}</span>
                <span className={style.listItem}>
                    {model.dto.phoneNumber ?? '-'}
                </span>
            </div>
            <div className={style.list}>
                <span className={style.listItem}>{t('homePage:email')}</span>
                <span className={style.listItem}>{model.dto.email}</span>
            </div>
            {model.parent.dialog instanceof UserInfoEditDialog && (
                <UserInfoEditDialogView dialog={model.parent.dialog} />
            )}
        </>
    );
});

export default UserData;
