import * as React from 'react';
import { observer } from 'mobx-react';
import PageWrapper from 'web/components/PageWrapper';
import { ContentView } from 'react-mvvm';
import style from './CompetitionsList.module.scss';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import CompetitionsListPage from './CompetitionsListPage';
import CompetitionsListView from "web/components/CompetitionsList/CompetitionsListView";

interface CompetitionsListViewProps {
    model: CompetitionsListPage;
}

const CompetitionsListPageView: React.FC<CompetitionsListViewProps> = observer(
    ({ model }) => {
        const { t } = useTranslation('competitions');
        return (
            <ContentView content={model.childPage}>
                <div className={style.container}>
                    <PageWrapper>
                        <Typography.Title level={2} className={style.header}>
                            Konkurransekalender
                        </Typography.Title>
                        <CompetitionsListView model={model.competitions}/>
                    </PageWrapper>
                </div>
            </ContentView>
        );
    }
);

export default CompetitionsListPageView;
