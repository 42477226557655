import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {OnlinePaymentTokenDto} from "./Model/OnlinePaymentTokenDto"

export interface ChangeCreditCardRequest {
}
export const changeCreditCard = (changeCreditCardRequest: ChangeCreditCardRequest): Promise<OnlinePaymentTokenDto> => {
    return send("ChangeCreditCard", changeCreditCardRequest)
}
