import { Page } from 'react-mvvm';
import { computed, observable } from 'mobx';
import { EvaluationCompetitionListItemDto } from 'model/Api/Evaluation/Model/EvaluationCompetitionListItemDto';
import loader from 'react-mvvm/loading/loader';
import { getEvaluationCompetitions } from 'model/Api/Evaluation/GetEvaluationCompetitionsRequest';
import { EvaluationResultDto } from 'model/Api/Competitions/Model/EvaluationResultDto';
import { CompetitionStatus, JudgingSchema } from 'model/Externals';
import JudgingIntroPage from 'web/screen/JudgingIntroPage/JudgingIntroPage';
import { HeaderBreadcrumbsData } from 'web/components/HeaderBreadcrumbs/HeaderBreadcrumbs';

class JudgingCompetitions extends Page {
    @observable evaluations: EvaluationCompetitionListItemDto[] = [];
    @observable selectedId: number = 0;
    @observable judgingIntroPage: JudgingIntroPage | undefined;

    constructor() {
        super();
    }

    protected async onActivated() {
        this.evaluations = await this.api.getEvaluationCompetitions();
    }

    api = loader({
        getEvaluationCompetitions: async () =>
            await getEvaluationCompetitions({})
    });

    @computed get breadcrumbs(): HeaderBreadcrumbsData[] {
        return !!this.judgingIntroPage ? this.judgingIntroPage.breadcrumbs : [];
    }

    showJudgingIntroductionPage = async (competitionRef: number) => {
        this.judgingIntroPage = new JudgingIntroPage(competitionRef, this);
        return await this.showChildPage(this.judgingIntroPage);
    };
}

export default JudgingCompetitions;
