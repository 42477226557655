import { Page } from 'react-mvvm';
import SubmissionFormPage from '../SubmissionFormPage/SubmissionFormPage';
import loader from 'react-mvvm/loading/loader';
import { CompetitionEntryDto } from 'model/Api/Submission/Model/CompetitionEntryDto';
import { updateCompetitionEntry } from 'model/Api/Submission/UpdateCompetitionEntryRequest';
import {
    CompetitionEntryStatus,
    CompetitionStatus,
    QuestionType
} from 'model/Externals';
import { computed, observable } from 'mobx';
import { homeRoute } from 'web/routes';
import SubmissionPayment from 'web/screen/SubmissionPayment/SubmissionPayment';
import SubmissionApprovedModal from 'common/components/SubmissionApprovedModal/SubmissionApprovedModal';
import i18next from 'i18next';
import {Modal} from "antd";

export type QuestionsWithAnswers = {
    questionId: number;
    questionType: QuestionType;
    label: string;
    helpText: string;
    value: string | undefined;
};

class SubmissionFormPreviewPage extends Page<SubmissionApprovedModal> {
    @observable savedCompetitionEntry: CompetitionEntryDto | undefined;
    @observable status: string = '';
    @observable parent: SubmissionFormPage;

    constructor(parent: SubmissionFormPage, private isValidForm: boolean) {
        super();
        this.parent = parent;
    }

    @computed get pageName() {
        return i18next.t('competitions:submissionPreview');
    }

    protected async onActivated(): Promise<any> {
        const state: any | undefined = homeRoute.getHistoryState();
        this.status = !!state && !!state.status ? state.status : '';

        if (!this.isValidForm && this.status !== 'paid') {
            await this.parent.removeChildPage();
        }
    }

    protected async onLeafFocus(): Promise<any> {
        window.scrollTo({ top: 0 });
    }

    @computed get competitionEntryDto() {
        return (
            this.savedCompetitionEntry ??
            this.parent.submissionForm.competitionEntryDto
        );
    }

    @computed get questions() {
        return this.parent.submissionForm.questions;
    }

    @computed get product() {
        return this.parent.submissionForm.product;
    }

    @computed get invoiceFeeProduct() {
        return this.parent.submissionForm.invoiceFeeProduct;
    }

    @computed get competitionStatus() {
        return !!this.parent
            ? this.parent.submissionForm.competitionStatus
            : CompetitionStatus.draft;
    }

    @computed get categoryId() {
        return this.parent.categoryId;
    }

    @computed get entryId() {
        return this.parent.entryId;
    }

    @computed get categoryName() {
        return this.parent.pageName;
    }

    @computed get entryPreviewPageDescription() {
        return this.parent.submissionForm.entryPreviewPageDescription;
    }

    api = loader({
        submitCompetitionEntry: async (entry: CompetitionEntryDto) =>
            await updateCompetitionEntry({
                competitionEntryDto: entry
            })
    });

    get answers(): QuestionsWithAnswers[] {
        return this.questions.map((question) => {
            const answer = this.competitionEntryDto.answers.find(
                (a) => a.questionId === question.id
            );

            return {
                questionId: question.id,
                label: question.label,
                helpText: question.helpText,
                questionType: question.questionType,
                value: !answer ? answer : answer.value
            };
        });
    }

    onBack = async () => {
        const state: any | undefined = homeRoute.getHistoryState();
        if (!!state && !!state.backUrl) {
            homeRoute.historyPush(state.backUrl);
        }
        await this.parent.removeChildPage();
    };

    onSend = async () => {
        const result = await this.showModal<SubmissionApprovedModal, boolean>(
            (close) => new SubmissionApprovedModal(async () => {}, close, '')
        );

        if (!result) return;
        
        this.competitionEntryDto.competitionEntryStatus =
            CompetitionEntryStatus.submitted;
        this.savedCompetitionEntry = await this.api.submitCompetitionEntry(
            this.competitionEntryDto
        );
        Modal.info({content: 'Takk! Din påmelding er nå registrert.'});
        
        homeRoute.historyPush('/submissions');
    };

    showSubmissionPaymentPage = async () => {
        if (
            this.competitionEntryDto.competitionEntryStatus ===
            CompetitionEntryStatus.submitted
        ) {
            return this.showChildPage(new SubmissionPayment(this));
        }

        if (this.competitionEntryDto.answers.length !== 0) {
            this.competitionEntryDto.competitionEntryStatus =
                CompetitionEntryStatus.submitted;
            this.savedCompetitionEntry = await this.api.submitCompetitionEntry(
                this.competitionEntryDto
            );
        }

        return this.showChildPage(new SubmissionPayment(this));
    };

    showSubmissionPaymentPageWithModal = async () => {
        const result = await this.showModal<SubmissionApprovedModal, boolean>(
            (close) => new SubmissionApprovedModal(async () => {}, close, '')
        );

        if (!result) return;

        return this.showSubmissionPaymentPage();
    };

    goToFormPage = async () => {
        return this.parent.removeChildPage();
    };
}

export default SubmissionFormPreviewPage;
