import { send } from "api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import {PartyDto} from "./Model/PartyDto"


export interface CreateExternalPartyRequest {
    userRef?: number
    party: PartyDto
}
export const createExternalParty = (createExternalPartyRequest: CreateExternalPartyRequest): Promise<PartyDto> => {
    return send("CreateExternalParty", createExternalPartyRequest)
}
