import * as React from 'react';
import style from './Membership.module.scss';
import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import SubscriptionInfoWithPeriod from 'web/screen/Membership/SubscriptionInfoWithPeriod';
import Flex from 'common/components/Flex';
import { PaymentMethod } from '../../../model/Externals';
import { MembershipDto } from 'model/Api/Members/Model/MembershipDto';
import Party from 'common/components/UserAddresses/Party';
import ArrowPanel from 'web/components/ArrowPanel';
import InfoRow from 'common/components/InfoRow/InfoRow';

interface PaymentInfoProps {
    membership: MembershipDto;
    isActive: boolean;
    updateCreditCart: () => void;
    onChangeSubscription: () => void;
    onChangeBillingAddress: () => void;
    onAddCreditCart: () => void;
}

const PaymentInfo: React.FC<PaymentInfoProps> = ({
    membership,
    updateCreditCart,
    onChangeSubscription,
    onChangeBillingAddress,
    onAddCreditCart,
    isActive
}) => {
    const { t } = useTranslation(['membership', 'common']);

    const creditCartInfoRow = (
        <Flex justifyContent={'space-between'}>
            <span>{t('membership:cardNumber')}</span>
            <Button
                type={'link'}
                onClick={updateCreditCart}
                className={style.titleLink}>
                {t('membership:updateCartInfo')}
            </Button>
        </Flex>
    );

    const billingAddressTitle = (
        <Flex justifyContent={'space-between'}>
            <span>{t('membership:billingAddress')}</span>
            <Button
                type={'link'}
                onClick={onChangeBillingAddress}
                className={style.titleLink}>
                {t('membership:changeBillingAddress')}
            </Button>
        </Flex>
    );

    return (
        <div className={style.dataContent}>
            {!!membership?.currentSubscription && <>
            <div className={style.title}>
                <span className={style.titleTxt}>
                    {t('membership:paymentInfo')}
                </span>
            </div>
             <SubscriptionInfoWithPeriod
                 isActive={isActive}
                membership={membership}
                onChangeSubscription={onChangeSubscription}
            /></>}
            {!!membership?.currentSubscription && membership.currentSubscription.paymentMethod ===
                PaymentMethod.bambora && (
                <>
                    {!!membership.currentSubscription.cardNumber && (
                        <InfoRow titleRow={creditCartInfoRow}>
                            {membership.currentSubscription.cardNumber}
                            <div>
                                <span>{t('membership:cardExpiration')}</span>{' '}
                                {`${membership.currentSubscription.cardExpirationMonth}. 20${membership.currentSubscription.cardExpirationYear}`}
                            </div>
                        </InfoRow>
                    )}

                    {!membership.currentSubscription.cardNumber && (
                        <div className={style.dataContent}>
                            <ArrowPanel
                                contentBox={
                                    <span className={style.addCardTxt}>
                                        {t('membership:addCartButton')}
                                    </span>
                                }
                                onClick={onAddCreditCart}
                                containerStyle={style.addCard}
                            />
                        </div>
                    )}
                </>
            )}
            {!!membership?.currentSubscription && <InfoRow titleRow={billingAddressTitle}>
                <Party
                    party={membership.currentSubscription.billingAddress}
                    customStyle={style.partyStyle}
                />
            </InfoRow>}
        </div>
    );
};

export default PaymentInfo;
