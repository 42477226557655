import * as React from 'react';
import UserInfo from './UserInfo';
import { Button, Col, Row } from 'antd';
import style from './UserInfo.module.scss';
import { observer } from 'mobx-react';
import PageLoader from 'common/components/PageLoader';
import UserData from 'web/screen/HomePage/UserInfo/UserData';
import UserAddressesView from 'common/components/UserAddresses/UserAddressesView';
import MembershipData from 'web/screen/HomePage/UserInfo/MembershipData';
import { useTranslation } from 'react-i18next';
import CompetitionsListView from 'web/components/CompetitionsList/CompetitionsListView';
import ArrowPanel from 'web/components/ArrowPanel';
import DeleteAccountDialog from './DeleteAccountDialog/DeleteAccountDialog';
import DeleteAccountDialogView from 'web/screen/HomePage/UserInfo/DeleteAccountDialog/DeleteAccountDialogView';
interface UserInfoViewProps {
    model: UserInfo;
}

const UserInfoView: React.FC<UserInfoViewProps> = observer(({ model }) => {
    const { t } = useTranslation(['homePage', 'common', 'subMenu']);

    return (
        <PageLoader loader={model.api}>
            <Row gutter={20}>
                <Col xs={24} lg={8}>
                    <div className={style.box}>
                        <UserData model={model} />
                    </div>
                    <div className={style.box}>
                        {t('homePage:invoiceAddresses')}:
                        <UserAddressesView model={model.userAddress} />
                    </div>
                    {model.parent.userDto.isJudge && (
                        <ArrowPanel
                            contentBox={
                                <span className={style.judgeBtnTxt}>
                                    {t('subMenu:judge')}
                                </span>
                            }
                            onClick={() => model.parent.showJudgePage()}
                            containerStyle={style.judgeBtn}
                        />
                    )}
                    <Button
                        type={'text'}
                        size={'small'}
                        onClick={model.showDeleteAccountModal}
                        className={style.deleteAccount}>
                        {t('homePage:deleteAccountTitle')}
                    </Button>
                </Col>
                <Col xs={24} lg={16}>
                    {model.parent.userDto.isMember !== undefined && (
                        <>
                            {model.parent.userDto.isMember || model.parent.userDto.hasPendingApplication ? (
                                <div className={style.box}>
                                    <MembershipData model={model} />
                                </div>
                            ) : (
                                <div className={style.become}>
                                    <ArrowPanel
                                        title={t('homePage:becomeMember')}
                                        desc={t('homePage:createMembership')}
                                        onClick={
                                            model.parent
                                                .showMembershipApplication
                                        }
                                    />
                                </div>
                            )}

                            <CompetitionsListView
                                model={
                                    model.parent.competitionList.competitions
                                }
                                onDetails={
                                    model.parent.showSelectedCompetitions
                                }
                            />
                        </>
                    )}
                </Col>
            </Row>
            {model.parent.dialog instanceof DeleteAccountDialog && (
                <DeleteAccountDialogView dialog={model.parent.dialog} />
            )}
        </PageLoader>
    );
});

export default UserInfoView;
