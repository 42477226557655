import * as React from 'react';
import {Button, Typography} from 'antd';
import style from './RenderPreview.module.scss';
import { ReactComponent as Zoom } from 'common/icons/zoom_icn.svg';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AttachmentResponse } from 'model/Api/Submission/Model/AttachmentResponse';
import { observer } from 'mobx-react';
import { DownloadOutlined } from '@ant-design/icons';
import PdfLoader from "../../PdfLoader";
import ReactPlayer from "react-player";
import { ReactComponent as Play } from 'common/icons/play_icn.svg';
import Flex from "../../Flex";

interface RenderPreviewProps {
    file: AttachmentResponse;
    onPreview?: () => void;
    mode?: 'form' | 'preview';
}

const RenderPreview: React.FC<RenderPreviewProps> = observer(
    ({file, onPreview, mode}) => {
        switch (file.type) {
            case 'application/pdf':
                return (
                    <Flex flexDirection={'column'} alignContent={"flex-start"} justifyContent={"flex-start"}
                          customClass={style.pdfBox}>
                        <PdfLoader url={file.url} width={mode === 'form' ? 700 : 950}/>
                        <Flex flexDirection={"row"} justifyContent={"flex-end"}>
                            <Button
                                type={'primary'}
                                size={"small"}
                                className={style.pdf}
                                onClick={() => {
                                    window.open(file.url, '_blank');
                                }}>
                                Download:
                                <span className={style.pdfName}>{file.name}</span>
                            </Button>
                        </Flex>
                        {mode === 'form' && <Typography.Text className={style.title}>
                            {file.name}
                        </Typography.Text>}
                    </Flex>

                );
            case 'audio/mpeg': //mp3
                return <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"flex-start"}>
                    <ReactPlayer url={file.url} controls={true} width={320} height={240} light={true}/>
                    {mode === 'form' && <Typography.Text className={style.title}>
                        {file.name}
                    </Typography.Text>}
                </Flex>;
            case 'video/mp4':
                return <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"flex-start"}>
                    <ReactPlayer url={file.url}
                                 controls={true}
                                 light={true}
                                 playIcon={<div className={style.play}><Play className={style.playIcon}/></div>}/>
                    {mode === 'form' && <Typography.Text className={style.title}>
                        {file.name}
                    </Typography.Text>}
                </Flex>
            default:
                const previewStyle = classNames({
                    [style.preview]: !!onPreview
                });

                const url = !!onPreview ? file.url : file.thumbUrl;
                return (
                    <Flex flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                        <div className={previewStyle}>
                            <div
                                className={style.previewImg}
                                onClick={() => (!!onPreview ? onPreview() : null)}>
                                <LazyLoadImage
                                    className={style.img}
                                    placeholderSrc={file.thumbUrl}
                                    src={url}
                                    effect={'blur'}
                                />
                                <Button
                                    type={'link'}
                                    onClick={() => {
                                        window.open(file.url, '_blank');
                                    }}
                                    className={`${style.icon} ${style.download}`}>
                                    <DownloadOutlined/>
                                </Button>
                                <Button
                                    className={`${style.icon} ${style.zoom}`}
                                    type={'link'}
                                    onClick={() =>
                                        !!onPreview ? onPreview() : null
                                    }>
                                    <Zoom/>
                                </Button>
                            </div>
                        </div>
                        {mode === 'form' && <Typography.Text className={style.title}>
                            {file.name}
                        </Typography.Text>}
                    </Flex>

                );
        }
    }
);

export default RenderPreview;
